


import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import classNames from "classnames";


import styles from "../../assets/jss/componentsStyles/selectOptionStyle";

const useStyles = makeStyles(styles);

export default function MultiSelect(props) {
  // selectOptions =[@param(value, label, color)]
  const {
    formControlProps,
    labelText,
    id,
    selectOptions = [],
    labelProps,
    inputProps,
    error,
    handleChange,
    isRequired
  } = props;
  const classes = useStyles();

  const selectFormClasses = classNames({
    [classes.selectFormControl]: !error,
    [classes.selectFormControlError]: error
  });

  const selectStyleClasses = classNames({
    [classes.selectLabel]: !error,
    [classes.selectLabelError]: error
  });


  return (
    <FormControl
      {...formControlProps}
      // className={formControlProps.className + " " + classes.formControl}
      fullWidth
      className={selectFormClasses}
    >

      {labelText !== undefined ? (
        <InputLabel
          // className={classes.labelRoot + labelClasses}
          className={selectStyleClasses}
          htmlFor={id}
          {...labelProps}
        >
          {labelText}
          {isRequired && <sup style={{color:"red"}}>*</sup> }
        </InputLabel>
      ) : null}

      <Select
        classes={{ select: classes.select }}
        MenuProps={{ className: classes.selectMenu }}
        id={id}
        multiple
        onChange={handleChange}
        // value={inputProps.value}
        {...inputProps}
      >
        {selectOptions.map(option => {
          return (
            // (<MenuItem key={option.value} value={option.value} >
            //         {/* {option.label} */}
            //         <Checkbox color="primary" checked={inputProps.value.indexOf(option.value) > -1} />
            //               <ListItemText primary={option.label} />
            //               {/* {option.label} */}
            //     </MenuItem>)
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelectedMultiple
              }}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )

}

import React from "react";

import {
  TableSortLabel,
  TableCell,
  TableRow,
  TableHead,
} from "@material-ui/core";

const EnhancedTableHead = (props) => {
  const {
    classes,
    tableHeaderColor,
    headCells,
    order,
    orderBy,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
      <TableRow className={classes.tableHeadRow}>
        {headCells.map((headCell, key) => {
          return (
            <TableCell
              className={classes.tableCell + " " + classes.tableHeadCell}
              sortDirection={orderBy === headCell.id ? order : false}
              key={key}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={order}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;

import { call, put, takeLatest, all, fork } from 'redux-saga/effects'
import * as actions from '../actions/types'
import axios from '../../utils/axios'
import urls from '../../utils/urls'
import { createUrl, isEmpty } from '../../utils/common'
import {
  masterPermissionList,
  roleList,
  masterRoles
} from '../actions/adminRoleActions'
import {
  userPermissions
} from '../actions/posAction'

import { updateAlertMsg } from 'store/actions/commonAction';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
// #################### Fetch Roles Permissions #####################################################

function fetchRolesPermissions(param) {
  return axios.request({
    method: 'get',
    url: createUrl(`${urls.BASE_URL}${urls.adminRole.BASE_URL}`),
    headers: {
      'Content-Type': 'application/json'
    },
    data: param
  })
}

export function* fetchRolesPermissionsActionEffect(params) {
  const { payload } = params
  try {
    const { data } = yield call(fetchRolesPermissions, payload)
    if (!isEmpty(data)) {
      console.log('Roles Permissions fetched  successfully')
      yield put(roleList(data))
    } else {}
  } catch (e) {
    console.log('Error found while fetching roles permissions')
  }
}

export function* fetchRolesPermissionsWatcher() {
  yield takeLatest(actions.FETCH_ROLES_PERMISSIONS_WATCHER, fetchRolesPermissionsActionEffect)
}

// #################### Fetch Master Permissions #####################################################

function fetchAllPermissions() {
  return axios.request({
    method: 'get',
    url: createUrl(`${urls.BASE_URL}${urls.adminRole.MASTER_PERMISIIONS_URL}`),
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function* fetchMasterPermissionsEffect(params) {

  try {
    const { data } = yield call(fetchAllPermissions)
    if (!isEmpty(data)) {
      console.log('Master Permissions fetched  successfully');
      // setTimeout(function*() {
      yield put(masterPermissionList(data))
        // }, 0)
    } else {}
  } catch (e) {
    console.log('Error found while fetching roles permissions')
  }
}

export function* fetchMasterPermissions() {
  yield takeLatest(actions.FETCH_MASTER_PERMISSIONS_WATCHER, fetchMasterPermissionsEffect)
}

// #################### Save Role Permissions #####################################################

function saveRolePermissions(params) {
  console.log("params in [saveRolePermissions", params)
  const { name, permissions, action = "" } = params;
  return axios.request({
    method: 'post',
    url: createUrl(`${urls.BASE_URL}${urls.adminRole.SAVE_ROLE_PERMISIIONS_URL}`),
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      name,
      permissions,
      action
    }
  })
}

export function* saveRolePermissionsEffect(params) {
  const { payload } = params;
  try {
    const { data } = yield call(saveRolePermissions, payload)
    if (!isEmpty(data)) {
      console.log('Role permission add/updated and  fetched  successfully');
      // setTimeout(function*() {
      yield put(roleList(data))
        // }, 0)
    } else {}
  } catch (e) {
    console.log('Error found while fetching roles permissions')
  }
}

export function* saveRolePermissionsWatcher() {
  yield takeLatest(actions.SAVE_ROLE_PERMISSIONS, saveRolePermissionsEffect)
}

// #################### fetch master roles #####################################################

function fetchMasterRoles() {
  return axios.request({
    method: 'get',
    url: createUrl(`${urls.BASE_URL}${urls.adminRole.BASE_URL}${urls.adminRole.FETCH_MASTER_ROLES_URL}`),
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function* fetchMasterRolesEffect(params) {
  try {
    const { data } = yield call(fetchMasterRoles)
    if (!isEmpty(data)) {
      console.log('Master roles fetched successfully');
      yield put(masterRoles(data))
    } else {}
  } catch (e) {
    console.log('Error found while fetching master roles.')
  }
}

export function* fetchMasterRolesWatcher() {
  yield takeLatest(actions.FETCH_MASTER_ROLES_WATCHER, fetchMasterRolesEffect)
}


// #################### Update Admin User #####################################################

function upadteAdmin(data) {
  return axios.request({
    method: 'post',
    url: createUrl(`${urls.BASE_URL}${urls.adminRole.BASE_URL}${urls.adminRole.UPDATE_ADMIN}`),
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  })
}

export function* upadteAdminEffect(params) {
  const { adminDetails } = params
  try {
    const { data } = yield call(upadteAdmin, adminDetails)
    if (!isEmpty(data)) {
      console.log('admin details updated succwessfully');
      yield put(userPermissions(data))
      const alertMsg = {
        color: 'success',
        icon: CheckCircleOutlineIcon,
        message: 'Password changed successfully.'
      }
      yield put(updateAlertMsg(alertMsg));
    } else {}
  } catch (e) {
    console.log('Error found while updating admin details.')
  }
}

export function* updatedAdminWatcher() {
  yield takeLatest(actions.UPDATE_ADMIN, upadteAdminEffect)
}

export default function* rootSagas() {
  yield all([
    fork(fetchRolesPermissionsWatcher),
    fork(fetchMasterPermissions),
    fork(saveRolePermissionsWatcher),
    fork(fetchMasterRolesWatcher),
    fork(updatedAdminWatcher)
  ]);
}
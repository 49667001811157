import * as React from "react";
import Admin from '../../DashBoard/Admin';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import FixedPayoutList from './FixedPayoutList';
import { fetchPosPayoutWatcher, updatePosPayoutWatcher } from 'store/actions/payoutAction';
import { toggleBackDrop, updateAlertMsg } from 'store/actions/commonAction';
import { withStyles } from '@material-ui/core/styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ErrorIcon from '@material-ui/icons/Error'
import { isEmpty, isDateValid, customParseFloat, isAlphaNumericWithHypenUnderScore } from 'utils/common';
import FixedPayoutUpdateDialog from './FixedPayoutUpdateDialog';
import GridItem from 'components/Grid/GridItem'
import CustomDatePicker from 'components/DatePicker/CustomDatePicker';
import GridContainer from 'components/Grid/GridContainer'
import CustomInput from 'components/CustomInput/CustomInput';
import { fieldIdMapper } from 'utils/constants';
import {  fetchPayoutFrequency } from 'store/actions/payoutFrequencyAction';


const styles = {
  dialogPospName: {
    textAlign: "center",
    fontWeight: "bold",
    textTransform: "capitalize"
  },
  error: {
    fontSize: "11px",
    color: "red",
  }
};

class FixedPayoutPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      openUpdateDialog: false,
      isValid: false,
      selectedRow: {},
      formUpdate: {},
      formErrors: {},
      alertMsg: {},
      amountPaid: '',
      amountPaidOn: '',
      transactionNo: '',
      remarks: '',
      selectedDate: ''
    }
  }

  componentWillMount() {
    const { posPayoutList, fetchPayoutFrequency } = this.props;
    // if (isEmpty(posPayoutList)) {
      this.fetchData();
      fetchPayoutFrequency();

    // } else {
    //   this.setSelectedDate()
    // }
  }

  fetchData = () => {
    let current = new Date();
    debugger;
    const { fetchPosPayoutWatcher } = this.props;
    // let fromDate = new Date(current.getFullYear(), current.getMonth()-1, 1);
   const payoutMonth = this.getPayoutMonth(current);
    // let toDate = new Date(current.getFullYear(), current.getMonth() + 1, 1);
    fetchPosPayoutWatcher({ payoutMonth });
    this.setSelectedDate()
  }
  getPayoutMonth =(date) => {
    const currMonth = date.getMonth() ===0 ? 12: date.getMonth();
    const year = currMonth === 12 ? date.getFullYear() -1 :date.getFullYear();
    const payoutMonth = `${currMonth}-${year}`;
    return payoutMonth;
  }

  setSelectedDate = () => {
    let current = new Date();
    let fromDate = new Date(current.getFullYear(), current.getMonth(), 1);
    this.setState({
      selectedDate: fromDate.getTime()
    })
  }

  onFilter = (filter) => {
    const { toggleBackDrop, fetchPosPayoutWatcher } = this.props;
      let from = new Date(filter);
    const payoutMonth = this.getPayoutMonth(from);
    const params = {
      payoutMonth
    }
    toggleBackDrop(true);
    new Promise((resolve, reject) => {
      fetchPosPayoutWatcher(params, resolve, reject);
    }).then(data => toggleBackDrop(false)).catch(e => toggleBackDrop(false))
  }

  handleDateChange = (date) => {
    const from = date;
    this.setState({
      selectedDate: from
    })
    // let to = new Date(date)
    // to.setMonth(to.getMonth() + 1);
    this.onFilter(from);
  }
  getDetails = (event, id) => {
    event.preventDefault();
    this.props.history.push({ pathname: `/pos-detail/${id}`, state: { posId: id } })
    console.log("pos details id", id);
  }

  toggleUpdateDialog = (open, selectedRow) => {
    this.setState({
      openUpdateDialog: open,
      selectedRow: open ? selectedRow : {}
    })
  }

  handleUpdateDialogClose = (event, obj) => {
    event.preventDefault();
    if (obj) {
      //    this.onSave(event);
      console.log("okay was pressed")
      // this.submitPospStatus();
      this.updatePayout();
    }
    else {
      this.onCancelUpdate()
    }
    this.setState({
      openUpdateDialog: !this.state.openUpdateDialog
    })
  }
  onCancelUpdate = () => {
    this.setState({
      amountPaid: '',
      amountPaidOn: '',
      transactionNo: '',
      remarks: '',
      formErrors: {}
    })
  }
  updatePayout = () => {
    const { selectedRow, amountPaid,
      amountPaidOn, transactionNo, remarks } = this.state;
    const amountPaidOnDate = new Date(amountPaidOn).getTime().toString()
    const { updatePosPayoutWatcher, toggleBackDrop, updateAlertMsg } = this.props
    const params = {
      userId: selectedRow.userId, payout: {
        ...selectedRow, amountPaid,
        amountPaidOn: amountPaidOnDate, transactionNo, remarks
      }
    };
    toggleBackDrop(true);
    new Promise((resolve, reject) => {
      updatePosPayoutWatcher(params, resolve, reject);
    }).then(data => {
      this.fetchData()
      toggleBackDrop(false);
      const alertMsg = {
        color: 'success',
        icon: CheckCircleOutlineIcon,
        message: 'Payout Details updated successfully'
      };
      updateAlertMsg(alertMsg)

    }).catch(e => {
      const alertMsg = {
        color: 'danger',
        icon: ErrorIcon,
        message: 'Something went wrong. Please try again'
      }
      updateAlertMsg(alertMsg);
    })
  }

  isError = (obj) => {

    const { formErrors = {} } = this.state;

    return !isEmpty(formErrors[obj])
  }

  checkIsValid = () => {
    const { amountPaid,
      amountPaidOn, transactionNo, formErrors } = this.state;
    let isValid = false;
    if (isEmpty(formErrors) && !isEmpty(amountPaid) && !isEmpty(transactionNo) && (amountPaidOn)) {
      isValid = true;
    }
    this.setState({
      isValid
    });
  }


  updateKeyValueInState = (value, obj) => {
    const { formErrors = {} } = this.state;
    let isValid = true;
    switch (obj) {
      case 'amountPaid':
        if (value < 0) isValid = false
        else value = customParseFloat(value);
        break;

        case 'transactionNo':
          if(!isAlphaNumericWithHypenUnderScore(value)) isValid = false;
          break;

        default:
          break;
    }
    if(isValid) {
    formErrors[obj] = formErrors[obj] ? formErrors[obj] : {};
    delete formErrors[obj];

    this.setState({
      [obj]: value
    }, (this.checkIsValid));
  }
  }
  validateInput = (value, obj) => {

    const { formErrors = {} } = this.state;


    isEmpty(value) && (formErrors[obj] = "This is mandatory field");

    if (!isEmpty(value)) {
      switch (obj) {
        case 'amountPaidOn':
          if (value.target && !isDateValid(value.target.value)) (formErrors[obj] = `Please enter valid ${fieldIdMapper.get(obj)}`);
          break;
        case 'amountPaid':
          this.updateKeyValueInState(Math.ceil(value), 'amountPaid');
          break;

        default:
          break;
      }
    }
    this.setState({
      formErrors: { ...formErrors },
    });
  }
  dateError = (event) => {
    console.log('in the date Error method', event);
    const { formErrors = {} } = this.state;
    if (isEmpty(event)) {
      delete formErrors['amountPaidOn'];
    }
    else {
      formErrors['amountPaidOn'] = event;
    }

    this.setState({
      formErrors: { ...formErrors },
    }, (this.checkIsValid));
  }

  render() {
    const { amountPaid,
      amountPaidOn, transactionNo, remarks, formErrors, selectedDate } = this.state;
    const { classes, globalPayoutFrequency } = this.props;

    return (
      <Admin>

        <FixedPayoutList
          getDetails={this.getDetails}
          toggleUpdateDialog={this.toggleUpdateDialog}
          onFilter={this.onFilter}
          selectedDate={selectedDate}
          handleDateChange={this.handleDateChange}
          globalPayoutFrequency={globalPayoutFrequency}
          {...this.props} />

        <FixedPayoutUpdateDialog
          open={this.state.openUpdateDialog}
          handleClose={this.handleUpdateDialogClose}
          isValid={this.state.isValid}
        >
          <GridContainer>

            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Amount Paid"
                id="amountPaid"
                formControlProps={{
                  fullWidth: true
                }}
                isRequired
                error={this.isError('amountPaid')}
                onChange={({ target: { value } }) => (value ? this.updateKeyValueInState(value, 'amountPaid') : this.updateKeyValueInState(0, 'amountPaid'))}
                inputProps={{
                  value: amountPaid || '',
                  onBlur: ({ target: { value } }) => this.validateInput(value, 'amountPaid'),
                  error: this.isError('amountPaid'),
                  type: 'number'
                }}
              />
              {this.isError('amountPaid') ? (
                <span className={classes.error}>
                  {formErrors.amountPaid}
                </span>
              ) : null}
            </GridItem>

            <GridItem xs={12} sm={6} md={12}>

              <CustomDatePicker
                id="amountPaidOn"
                label="Amount Paid On"
                isRequired
                inputProps={{
                  format: "dd/MM/yyyy",
                  maxDate: new Date(),
                  onError: this.dateError,

                }}
                rest={{
                  onBlur: (event) => this.validateInput(event, 'amountPaidOn'),
                  error: this.isError('amountPaidOn')

                }}

                value={amountPaidOn || null}
                onChange={(event) => (event ? this.updateKeyValueInState(event, 'amountPaidOn') : this.updateKeyValueInState(null, 'amountPaidOn'))}
              />
              {/* {this.isError('amountPaidOn') ? (
                <span className={classes.error}>
                  {formErrors.amountPaidOn}
                </span>
              ) : null} */}

            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Transaction Number"
                id="transactionNo"
                formControlProps={{
                  fullWidth: true
                }}
                isRequired
                error={this.isError('transactionNo')}
                onChange={({ target: { value } }) => (value ? this.updateKeyValueInState(value, 'transactionNo') : this.updateKeyValueInState(null, 'transactionNo'))}
                inputProps={{
                  value: transactionNo || '',
                  onBlur: ({ target: { value } }) => this.validateInput(value, 'transactionNo'),
                  error: this.isError('transactionNo'),

                }}
              />
              {this.isError('transactionNo') ? (
                <span className={classes.error}>
                  {formErrors.transactionNo}
                </span>
              ) : null}
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Remarks"
                id="remarks"
                formControlProps={{
                  fullWidth: true
                }}
                onChange={({ target: { value } }) => (value ? this.updateKeyValueInState(value, 'remarks') : this.updateKeyValueInState(0, 'remarks'))}
                inputProps={{
                  value: remarks || '',
                  // onBlur: ({ target: { value } }) => this.validateInput(value, 'remarks'),   
                }}
              />

            </GridItem>



          </GridContainer>
        </FixedPayoutUpdateDialog>

      </Admin>
    );
  }
}

function mapStateToProps(state) {
  const { payoutReducer, payoutFrequencyReducer: {globalPayoutFrequency={}} = {} } = state;
  return {
    ...payoutReducer,
    globalPayoutFrequency

  };
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchPosPayoutWatcher,
      updatePosPayoutWatcher,
      toggleBackDrop,
      updateAlertMsg,
      fetchPayoutFrequency
      // add other watcher sagas to this object to map them to props
    },
    dispatch
  )
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FixedPayoutPage));

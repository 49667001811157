import React from 'react'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
// core components
import GridItem from '../../components/Grid/GridItem'
import GridContainer from '../../components/Grid/GridContainer'
import SelectOption from 'components/CustomSelect/SelectOption';
import Card from '../../components/Card/Card'
import Search from '@material-ui/icons/Search'
import CustomInput from '../../components/CustomInput/CustomInput'
import Button from '../../components/CustomButtons/Button'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ErrorIcon from '@material-ui/icons/Error'
import styleHeader from '../../assets/jss/componentsStyles/headerLinksStyle'
import EditIcon from '@material-ui/icons/Edit';
import CustomHeaderContentTabs from '../../components/CustomTabs/CustomHeaderContentTabs'
import AlertBox from '../../components/DialogBox/DialogBox'
import Tooltip from '@material-ui/core/Tooltip'
import AssessmentIcon from '@material-ui/icons/Assessment';
import FuzzySearch from 'fuzzy-search'
import GlobalCommissionTable from './GlobalCommissionTable';
import { CompanyMapper, CompanyReverseMapper, InsTypeMapper } from '../../utils/constants';
import ClearIcon from '@material-ui/icons/Clear';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import BackDrop from '../../components/BackDrop/Backdrop';
import { customParseFloat } from '../../utils/common'
import Slide from '@material-ui/core/Slide';
import cloneDeep from 'lodash/cloneDeep';
import { Typography } from '@material-ui/core'


const styles = {
    cardCategoryWhite: {
        '&,& a,& a:hover,& a:focus': {
            color: 'rgba(255,255,255,.62)',
            margin: '0',
            fontSize: '14px',
            marginTop: '0',
            marginBottom: '0'
        },
        '& a,& a:hover,& a:focus': {
            color: '#FFFFFF'
        }
    },
    cardTitleWhite: {
        color: '#FFFFFF',
        marginTop: '0px',
        minHeight: 'auto',
        fontWeight: '300',
        fontFamily: 'Montserrat',
        marginBottom: '3px',
        textDecoration: 'none',
        '& small': {
            color: '#777',
            fontSize: '65%',
            fontWeight: '400',
            lineHeight: '1'
        }
    },
    inputSearchType: {
        color: 'white',
        padding: '0'
    },
    paddingStyle: {
        padding: '0 12px 0'
    },
    selectFormControl: {
        margin: '-5px 0 3px 0 !important'
    }
}

const useStyles = makeStyles(styles)
const headerStyle = makeStyles(styleHeader)

export default function GlobalCommissionList(props) {
    const classes = useStyles();
    const headerClass = headerStyle();

    const { insuranceDetails = [], fetchGlobalCommissionWatcher,
        updateGlobalCommissionWatcher, setAlert, companies } = props

    const [isEdit, toggleEdit] = React.useState(false);
    const [filteredList, setList] = React.useState([])
    const [formErrors, setFormErrors] = React.useState({});

    const [showModal, toggleModal] = React.useState(false);

    const tabs = [];

    React.useEffect(() => {
        setList(cloneDeep(insuranceDetails)) // api.data.count from api after update store
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [insuranceDetails])

    const onCancel = (event) => {
        event.preventDefault();
        setList(insuranceDetails);
        toggleEdit(!isEdit);
    }
    const handleChange = (value, rowObj, objKey) => {
        debugger;
        const { product, companyId, insuranceType } = rowObj;
        console.log(value);
        let val = '0';
        let flag = false;
    
            if ( (objKey=== 'renewPercent'|| objKey==='newPercent') && value >= 0 && value <= 100) {
                val = customParseFloat(value);
                val = value === 0 ? "0" : value.toString();
                flag = true;
            }
            else if(objKey === 'siLimit'){
                val = customParseFloat(value);
                val = value === 0 ? "0" : value.toString();
                flag = true;
            }
            else if(objKey === 'isPospApprovedPlcy'){
                val = (value === 'true')
                flag = true
            }
            if(objKey === 'payoutWaitDays') {
                let updateList = cloneDeep(filteredList);
                let { payoutWaitDays = '' } = updateList.find(val => val.insuranceType === insuranceType);
                updateList.map(tempVal => {
                    if(tempVal.insuranceType === insuranceType)
                    tempVal['payoutWaitDays'] = value
                    return tempVal;
                })
                
                setList(updateList);  
            }
        
        if (flag) {
            
            let updateList = cloneDeep(filteredList);
            let { companies = [] } = updateList.find(val => val.insuranceType === insuranceType);
            let { products = [] } = companies.find(company => company.companyId === companyId);
            products.map(prod => {
                
                if (prod.productId === product.productId) {
                    prod[objKey] = val;
                }
                return prod;
            })
            setList(updateList);
        }

    }

    const payoutWaitDaysOptions = [];
    for (let i = 0;i <= 60;i++) {
        payoutWaitDaysOptions.push({ value: i.toString(), label: i.toString() });
    }


    const onSubmit = (event, obj) => {
        event.preventDefault();
        if (obj) {


            new Promise((resolve, reject) => {
                updateGlobalCommissionWatcher(filteredList, resolve, reject);
            }).then(data => {

                fetchGlobalCommissionWatcher();
                toggleEdit(!isEdit);
                const alertMsg = {
                    color: 'success',
                    icon: CheckCircleOutlineIcon,
                    message: 'Global Commission updated successfully'
                }
                setAlert(alertMsg);
            }).catch(error => {

                const alertMsg = {
                    color: 'danger',
                    icon: ErrorIcon,
                    message: 'Error occured. Please try again'
                }
                setAlert(alertMsg)
            })
        }
        toggleModal(!showModal);
    }

    filteredList.forEach(insurance => {
        const tab = {
            tabName: InsTypeMapper.get(insurance.insuranceType.toLowerCase()),
            tabIcon: AssessmentIcon,
            tabContent: (<GridContainer>
                <GridItem xs={12} style={{boxShadow: '0px -1px 1px 0px rgba(0, 0, 0, 0.12)'}}>
                   
                            <SelectOption
                                id="payoutWaitDays"
                                labelText={`No. of days after which commission to be paid from purchase date for ${insurance.insuranceType} policies`}
                                selectOptions={payoutWaitDaysOptions}
                                // error={isError('initialData', 'insuranceType')}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                isRequired
                                onSelectChange={({ target: { value } }) => (value ? handleChange(value, {product: null, companyId: null, insuranceType: insurance.insuranceType}, 'payoutWaitDays') : handleChange(null, {product: null, companyId: null, insuranceType: insurance.insuranceType}, 'payoutWaitDays'))}
                                inputProps={{
                                    disabled: !isEdit,
                                    value: insurance.payoutWaitDays || null,
                                    // onBlur: ({ target: { value } }) => validateInput(value, 'payoutOn'),
                                    // error: isError('payoutOn')
                                }}
                            />
                            {/* {isError('payoutOn') ? (
                                <span className={classes.error}>
                                    {formErrors['payoutOn']}
                                </span>
                            ) : null} */}
                 
                </GridItem>
                <GridItem xs={12} >
                    <GlobalCommissionTable
                        companies={insurance.companies}
                        isEdit={isEdit}
                        insuranceType={insurance.insuranceType}
                        companiesList={companies}
                        handleChange={handleChange} />
                </GridItem>
            </GridContainer>
            )
        };
        tabs.push(tab);
    })


    return (
        <>
            <AlertBox
                open={showModal}
                handleClose={onSubmit}
                dialogTitle="Are you sure, you want to update?"
                dialogDescription=""
            />
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <CustomHeaderContentTabs color="primary"
                        headerColor="primary"
                        tabs={tabs}
                        plainTabs={false}
                        classHeader={classes.tabHeader}
                    >
                        <span style={{ alignSelf: 'flex-start' }}>
                            <h4 className={classes.cardTitleWhite}>Global Commission List</h4>
                        </span>
                        {!isEdit ? <span className={headerClass.searchWrapper} style={{ marginLeft: 'auto', alignSelf: 'flex-end' }}>
                            {/* {showInput && <CustomInput
                                id="searchInput"
                                formControlProps={{
                                    className: classNames(headerClass.margin, headerClass.search)
                                }}
                                inputProps={{
                                    inputProps: {
                                        'aria-label': 'Search',
                                        className: classes.inputSearchType
                                    }
                                }}
                                onChange={(event) => filterList(event)}
                            />}
                            <Tooltip title="Seach" aria-label="add">
                                <Button className={classes.paddingStyle} color="white" aria-label="edit" simple onClick={() => toggleInput(!showInput)}>
                                    <Search /> {' '}Search
                </Button>
                            </Tooltip> */}
                            <Tooltip title="Edit" aria-label="add">
                                <Button color="white" className={classes.paddingStyle} aria-label="edit" simple onClick={(event) => { toggleEdit(!isEdit) }}>
                                    <EditIcon /> {' '} Edit
                </Button>
                            </Tooltip>
                        </span> :
                            <Slide direction="left" in={isEdit} mountOnEnter unmountOnExit>
                                <span className={headerClass.searchWrapper} style={{ marginLeft: 'auto', alignSelf: 'flex-end' }}>
                                    <Tooltip title="Cancel" aria-label="add">
                                        <Button className={classes.paddingStyle} color="white" aria-label="edit" simple onClick={(event) => onCancel(event)}>
                                            <ClearIcon /> {' '}Cancel
                </Button>
                                    </Tooltip>
                                    <Tooltip title="Save" aria-label="add">
                                        <Button color="white" className={classes.paddingStyle} aria-label="edit" simple onClick={(event) => toggleModal(!showModal)}>
                                            <SaveOutlinedIcon /> {' '} Save
                </Button>
                                    </Tooltip>
                                </span>
                            </Slide>}
                    </CustomHeaderContentTabs>
                </GridItem>
            </GridContainer>
        </>
    );
}
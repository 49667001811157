import * as React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import SelectOption from 'components/CustomSelect/SelectOption';
import Button from 'components/CustomButtons/Button';
import { FrequencyTypeConst } from 'utils/constants';

export default function PayoutFrequencyForm(props) {

    const { classes, updateKeyValueInState, 
        frequencyType, payoutOn, formErrors, isError, submitForm, validateInput } = props;
       
    const frequencyTypeOptions = [];
        Object.keys(FrequencyTypeConst).forEach(val => frequencyTypeOptions.push({ value: val, label: val }));

      const getPayoutOnOptions = (freqType) => {
            const weekOptions = [{ value: 'MONDAY', label: 'MONDAY' },
            { value: 'TUESDAY', label: 'TUESDAY' },
            { value: 'WEDNESDAY', label: 'WEDNESDAY' },
            { value: 'THURSDAY', label: 'THURSDAY' },
            { value: 'FRIDAY', label: 'FRIDAY' },
            { value: 'SATURDAY', label: 'SATURDAY' },
            { value: 'SUNDAY', label: 'SUNDAY' }];
        
            const dayOptions = [];
            for(let i=1; i<=30; i++){
              dayOptions.push({value: i.toString(), label: i.toString()});
            }
            return (freqType === 'MONTHLY'? dayOptions: weekOptions );
          }

    return(
        <GridContainer>

        <GridItem xs={12} sm={12} md={12} >
          <SelectOption
            id="frequencyType"
            labelText="Payout Frequency Type"
            selectOptions={frequencyTypeOptions}
            // error={isError('initialData', 'insuranceType')}
            formControlProps={{
              fullWidth: true
            }}
            isRequired
            onSelectChange={({ target: { value } }) => (value ? updateKeyValueInState(value, 'frequencyType') : updateKeyValueInState(null, 'frequencyType'))}
            inputProps={{

              value: frequencyType || null,
              onBlur: ({ target: { value } }) => validateInput(value, 'frequencyType'),
              error: isError('frequencyType')
            }}
          />
          {isError('frequencyType') ? (
            <span className={classes.error}>
              {formErrors['frequencyType']}
            </span>
          ) : null}
        </GridItem>

        {/* {(frequencyType === 'WEEKLY' || frequencyType === 'MONTHLY') && <GridItem xs={12} sm={12} md={12} >
          <SelectOption
            id="payoutOn"
            labelText="Payout should be rolled out on?"
            selectOptions={getPayoutOnOptions(frequencyType)}
            // error={isError('initialData', 'insuranceType')}
            formControlProps={{
              fullWidth: true
            }}
            isRequired
            onSelectChange={({ target: { value } }) => (value ? updateKeyValueInState(value, 'payoutOn') : updateKeyValueInState(null, 'payoutOn'))}
            inputProps={{

              value: payoutOn || null,
              onBlur: ({ target: { value } }) => validateInput(value, 'payoutOn'),
              error: isError('payoutOn')
            }}
          />
          {isError('payoutOn') ? (
            <span className={classes.error}>
              {formErrors['payoutOn']}
            </span>
          ) : null}
        </GridItem>} */}

        {/* <GridItem xs={12} sm={12} md={12} style={{marginTop: '15px'}}>
      <Button raised color="primary" onClick={submitForm} > Submit</Button>
    </GridItem> */}
      </GridContainer>
    
    );
}
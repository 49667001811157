import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../assets/jss/componentsStyles/customCheckBoxStyle';
import Check from "@material-ui/icons/Check";

// @material-ui/icons

// core components

const useStyles = makeStyles(styles)

export default function CustomCheckbox (props) {
    // const [checked, setState] = React.useState(false);
  const classes = useStyles();
  const {
      id,
    labelText,
    onChange,
    value,
     checked
  } = props
//   const handleChange = () => {
//     setState(!checked);
//     onChange()
//   }

  return(
    <FormControlLabel
    control={
      <Checkbox
        
      onChange={onChange}
      id={id}
        checkedIcon={<Check className={classes.checkedIcon} />}
        icon={<Check className={classes.uncheckedIcon} />}
        checked={checked}
        value={value}
        classes={{
          checked: classes.checked,
          root: classes.checkRoot
        }}
      />
    }
    classes={{
      label: classes.label,
      root: classes.labelRoot
    }}
    label={labelText}
  />
  )
}
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import moment from 'moment'
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
// core components
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import PolicySoldTable from "./PolicySoldTable";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import Search from "@material-ui/icons/Search";
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button";
import styleHeader from "../../assets/jss/componentsStyles/headerLinksStyle";
import { isEmpty, exportCsv, changeEpoachDateToFormat } from "../../utils/common";
import ImportExportIcon from '@material-ui/icons/ImportExport';
import FilterListIcon from '@material-ui/icons/FilterList';
import FuzzySearch from 'fuzzy-search'
import { fetchPoliciesDetailsWatcher, posList } from "store/actions/posAction";
import { proposalDetails } from "store/actions/productAction";
import { isNull } from "lodash";
import { isValid } from "date-fns";
import { Button as MuiButtom, Divider} from '@material-ui/core';
import { getFeaturePermission, getHiearchyPermisionName } from "utils/dataAccess";

import {
  Typography, 
  TextField, 
  MenuItem
} from "@material-ui/core";
import DateRangeSelector from "./DateRangeSelector";
import { useDispatch } from "react-redux";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "Montserrat",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  inputSearchType: {
    color: "white",
    padding: "0",
  },
  paddingStyle: {
    padding: "0 12px 0"
  }

};
function createData(companyLogo,insuranceType, policyNo, dateOfPayment,   pospName, planName, sumInsured, premium, proposalId, policyDocumentLink) {
  return { companyLogo,insuranceType, policyNo, dateOfPayment,  pospName, planName, sumInsured, premium, proposalId, policyDocumentLink }
}

const useStyles = makeStyles(styles);
const headerStyle = makeStyles(styleHeader);

export default function PolicySoldList(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const headerClass = headerStyle();
  const { policySoldList = [], getDetails, endDate, startDate, proposalType:pType } = props;

  const [selectedValue, setSelectedValue] = React.useState(endDate!=="" && startDate !== "" ?2:1);
  const [open, setOpen] = React.useState(false);
  let label = endDate!=="" && startDate !== "" ? `${startDate} to ${endDate}`: "Select a Date Range";
  const [dateRangeLable, setDateRangeLabel] = React.useState(label);

  let sDate = null, eDate = null;

  if(endDate!=="" && startDate !== ""){
    let splittedStartDate = startDate.split("-");
    let splittedEndtDate = endDate.split("-")
    sDate = new Date(`${splittedStartDate[1]}-${splittedStartDate[0]}=${splittedStartDate[2]}`);
    eDate = new Date(`${splittedEndtDate[1]}-${splittedEndtDate[0]}=${splittedEndtDate[2]}`);

  }
  const [dateRange, setDateRange] = React.useState([sDate, eDate]);

  const [proposalType, setProposalType] = React.useState(pType || "all");
  let rows = [];
  // const detailButton =  (<Button color="primary">Details</Button>)
  policySoldList.forEach(policy => { 
    const policyNo  = policy.policyNumber? policy.policyNumber: "Details";
    let { 
      companyLogo,
      planName , 
      insuranceType,
      motorDetails,
      healthDetails,
      policyDocumentLink,
      proposalId, 
      sumInsured
    } = policy;

    let sumInsuredInLakhs = !isNaN(parseInt(policy.idv)) ? (parseFloat(parseInt(policy.idv)/100000).toPrecision(2) + ' Lakhs'): policy.idv;

    if(!sumInsuredInLakhs){
      sumInsuredInLakhs = (parseFloat(parseInt(sumInsured)/100000).toPrecision(2) + ' Lakhs')
    }
    let premium = motorDetails.premiumBreakup 
    && motorDetails.premiumBreakup
    && motorDetails.premiumBreakup.finalPremium 
    && motorDetails.premiumBreakup.finalPremium.totalPremium ? motorDetails.premiumBreakup.finalPremium.totalPremium: ""
    
    if(insuranceType.toLowerCase() === "health"){
      premium= healthDetails.premiumBreakup 
      && healthDetails.premiumBreakup
      && healthDetails.premiumBreakup.finalPremium 
      && healthDetails.premiumBreakup.finalPremium.totalPremium ? healthDetails.premiumBreakup.finalPremium.totalPremium: ""
      
    }

    const doI = !isEmpty(policy.policyIssuanceDate) ?  policy.policyIssuanceDate : '';


    let soldBy = policy.soldByName || "";
    if(policy.soldByNumber){
      soldBy+= ` (${policy.soldByNumber})`
    }
   
    let row = createData(companyLogo, insuranceType || 'Health', policyNo, doI,   soldBy, planName,sumInsuredInLakhs,  premium, proposalId, policyDocumentLink);
    rows.push(row);
  });


  const [filteredList, setList] = React.useState();

  const [showInput, toggleInput] = React.useState(false);


  React.useEffect(() => {
    setList(rows) // api.data.count from api after update store
  }, [policySoldList])
  //let newList = [...rows];

  React.useEffect(() => { document.getElementById("searchInput") && document.getElementById("searchInput").focus() }, [showInput])

  const filterList = (e) => {
    let currentList = []
    let newList = []

    // If the search bar isn't empty
    if (e.target.value !== '') {
      // Assign the original list to currentList
      currentList = rows
      const searcher = new FuzzySearch(currentList, ['policyNo', 'pospName', 'planName', 'sumInsured', 'premium', 'proposalId'], {
        caseSensitive: false
      })
      newList = searcher.search(e.target.value.toLowerCase())
    } else {
      // If the search bar is empty, set newList to original task list
      newList = [...rows]
    }

    setList(newList)
  }

  const exportPosp = (event) => {
    event.preventDefault();


    const data = [];
    let allPolicies = policySoldList;

    const featurePermission =  getFeaturePermission("download_policies_list"); 
    // if(featurePermission){ 
    //   const hiearchy = getHiearchyPermisionName(featurePermission, "r")
    //   console.log("hiearchy", hiearchy)
    //   if(hiearchy === "my"){
    //     allPolicies = allPolicies.filter(data=>data.dataType === "my");
    //   }

    //   if(hiearchy === "sub_ordinate"){
        allPolicies = allPolicies.filter(data=>data.dataType === "subordinate");
    //   }

    //   if(hiearchy === "my_and_subordinates"){
    //     allPolicies = allPolicies.filter(data=>(data.dataType === "my" || data.dataType === "subordinate" ));
    //   }
    // } else {
    //   allPolicies = [];
    // }

    console.log("allPolicies", allPolicies);

    allPolicies.filter(policy=>!isEmpty(policy.proposalId)).forEach(policy => {
      let toShow = []
      const {
        appointeeDetails = [],
        approvedAdminEmail = "",
        approvedByAdmin = '',
        approvedByAdminDate = '',
        companyLogo = '',
        dateOfPayment = '',
        dateOfPolicyIssued = '',
        dateOfPolicyIssuedEpoch = '',
        isAssistedSales = '',
        memberDetails = [],
        modeOfPayment = '',
        nomineeDetails = {},
        paymentUrl = '',
        planDetails = {},
        policyDocumentLink = '',
        policyIssuanceType = '',
        policyIssuedOnline = '',
        policyNo = '',
        pospName = '',
        premium = '',
        productId = '',
        proposalAmount = '',
        proposalCreatedDate = '',
        proposalCreatedDateEpoch = '',
        proposalId = '',
        proposalStatus = '',
        proposerDetails = {},
        sumInsured = '',
        sumInsuredInLakhs = '',
        underWriting = '',
        updatePolicyDate = '',
        userId = ''
      } = policy;

//      if(appointeeDetails && !isNull(appointeeDetails)){
//      appointeeDetails.forEach(objs => {
//         if (objs) {
//           Object.keys(objs).filter(key => key !== '_id').forEach(key => { if (objs[key] !== null) toShow.push({ [key]: objs[key] ||'' })});
//         }
//      })};

      [nomineeDetails, planDetails, proposerDetails].forEach(objs => {
        if (!isEmpty(objs)) {
          Object.keys(objs).filter(key => key !== '_id').forEach(key => toShow.push({ [key]: objs[key] ||'' }));
        }
      });

      memberDetails.forEach(member => {
        if (!isEmpty(member)) {
          Object.keys(member).filter(key => (key !== '_id' && key !=='address')).forEach(key => toShow.push({ [key]: member[key] ||'' }));
          Array.isArray(member.address) && member['address'].forEach(address => {Object.keys(address).filter(key => (key !== '_id')).forEach(key => toShow.push({ [key]: address[key] ||'' }));
          })
        }
      });

      toShow.push({'Approved Admin Email': approvedAdminEmail || ''});
      toShow.push({'Appoved by Admin': approvedByAdmin || ''});
      toShow.push({'Approved by Admin Date': approvedByAdminDate || ''});
      toShow.push({'Company Logo': companyLogo || ''});
      toShow.push({'Date Of Payment': dateOfPayment || ''});
      toShow.push({'Date Of Policy Issued': dateOfPolicyIssued || ''});
      toShow.push({'Date Of Policy Issed Epoch': dateOfPolicyIssuedEpoch || ''});
      toShow.push({'Is Assisted Sales': isAssistedSales || ''});
      toShow.push({'Mode Of Payment': modeOfPayment || ''});
      toShow.push({'Payment URL': paymentUrl || ''});
      toShow.push({'Policy Document Link': policyDocumentLink || ''});
      toShow.push({'Policy Issuance Type': policyIssuanceType || ''});
      toShow.push({'Policy Issued Online': policyIssuedOnline || ''});
      toShow.push({'Policy Number': policyNo || ''});
      toShow.push({'Posp Name': pospName || ''});
      toShow.push({'Premium': premium || ''});
      toShow.push({'Product ID': productId || ''});
      toShow.push({'Proposal Amount': proposalAmount || ''});
      toShow.push({'Proposal Created Date': proposalCreatedDate || ''});
      toShow.push({'Proposal Created Date Epoch': proposalCreatedDateEpoch || ''});
      toShow.push({'Proposal ID': proposalId || ''});
      toShow.push({'Proposal Status': proposalStatus || ''});
      toShow.push({'Sum Insured': sumInsured || ''});
      toShow.push({'Sum Insured in Lakhs': sumInsuredInLakhs || ''});
      toShow.push({'Under Writing': underWriting || ''});
      toShow.push({'Sum Insured': sumInsured || ''});
      toShow.push({'Update Policy Date': updatePolicyDate || ''});
      toShow.push({'User ID': userId || ''});

      data.push(Object.assign({}, ...toShow))
    })

    // policySoldList.forEach(policy => {
    //   let toShow=[];
    //   const insuranceType = policy.ins_type || policy.insuranceType;
    //   toShow.push({'Insurance Type': insuranceType || 'Health'});
    //   toShow.push({'Insurance Type': insuranceType || 'Health'});
    // });

   console.log("data", JSON.stringify(data[0], null, 2))
    const param = {
      fileName: 'POLICY_SOLD_LIST',
      title: 'POLICY SOLD List',
      data: data
    }
    return exportCsv(param);
  }
    
  const headCells = [
    { id: 'companyLogo', label: 'Insurer' },
    { id: 'insuranceType', label: 'Insurance Type' },
    { id: 'policyNo', label: 'Policy Number' },
    { id: 'dateOfPayment', label: 'Policy Issuance Date' },
    // { id: 'modeOfSale', label: 'Mode Of Sale' },
    { id: 'soldBy', label: 'Sold by' },
    { id: 'planName', label: 'Plan Name' },
    { id: 'sumInsured', label: 'Sum Insured/ IDV' },
    { id: 'premium', label: 'Premium' },
    { id: 'policyDocumentLink', label: "Download Policy" }
  ]; 

  const toggleDateRangeSelector = () =>{
    setOpen(false)
  }

  const getDateRange = (dateRange) => {
    const format = "DD-MM-YYYY"; 
      
    const fromDate = moment(Number(new Date(dateRange[0]).getTime().toString())).format(format);
    const toDate = moment(Number(new Date(dateRange[1]).getTime().toString())).format(format);
    return {fromDate, toDate};
  }

  const getSelectedValue =(dateRange) => {
    setDateRange(dateRange);

    if (dateRange && dateRange[0] && dateRange[1]) { 
      const {fromDate, toDate} = getDateRange(dateRange);
      setDateRangeLabel(`${fromDate} to ${toDate}`);
      props.fetchDataByRange(fromDate, toDate, proposalType);
    }

    if (dateRange && dateRange[0] && dateRange[1]) { 
    setOpen(false);
    }
  }

  const getThisMonthRange = () => {
    const format = "DD-MM-YYYY"; 
    let fromDate = moment(Number(new Date( ).getTime().toString())).format(format).split("-");
    fromDate = `01-${fromDate[1]}-${fromDate[2]}`
    const toDate = moment(Number(new Date().getTime().toString())).format(format);
    return {fromDate, toDate}
  }

  const exportPolicies =()=>{ 
    const format = "DD-MM-YYYY"; 
    if (dateRange && dateRange[0] && dateRange[1]) {
      
      const fromDate = moment(Number(new Date(dateRange[0]).getTime().toString())).format(format);
      const toDate = moment(Number(new Date(dateRange[1]).getTime().toString())).format(format);
      dispatch(fetchPoliciesDetailsWatcher({
        fromDate, 
        toDate,
        proposalIds:[],
        proposalStatus:"success",
        proposalType
      }))
    } else if(selectedValue===2 && startDate!=="" && endDate!==""){
      dispatch(fetchPoliciesDetailsWatcher({
        fromDate: startDate, 
        toDate: endDate,
        proposalIds:[],
        proposalStatus:"success",
        proposalType
      }))
    } else if(selectedValue===1){
      let fromDate = moment(Number(new Date( ).getTime().toString())).format(format).split("-");
      fromDate = `01-${fromDate[1]}-${fromDate[2]}`
      const toDate = moment(Number(new Date().getTime().toString())).format(format);
      dispatch(fetchPoliciesDetailsWatcher({
        fromDate, 
        toDate,
        proposalIds:[],
        proposalStatus:"success",
        proposalType
      }))
      
    }
  }
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <span style={{ float: "left" }}>
              <h4 className={classes.cardTitleWhite}>Policy Sold List</h4>

            </span>
            <span className={headerClass.searchWrapper} style={{ float: "right" }}>
              {showInput && <CustomInput
                id="searchInput"
                formControlProps={{
                  className: classNames(headerClass.margin, headerClass.search)
                }}
                inputProps={{
                  inputProps: {
                    "aria-label": "Search",
                    className: classes.inputSearchType
                  }
                }}
                onChange={(event) => filterList(event)}
              />}
              <Button className={classes.paddingStyle} color="white" aria-label="edit" simple onClick={() => toggleInput(!showInput)}>
                <Search /> {' '}Search
              </Button>

              {/* <Button color="white" className={classes.paddingStyle} aria-label="edit" simple onClick={(event) => exportPosp(event)}>
                <ImportExportIcon /> {' '} Export
              </Button>


              <Button color="white" className={classes.paddingStyle} aria-label="edit" simple >
                <FilterListIcon /> {' '} Filter
              </Button> */}
            </span>

          </CardHeader>
          <CardBody> 
            <Typography align="center">
            <TextField
            style={{display:"inline" }}
              InputProps={{
                disableUnderline: true,
                
              }}
              value={selectedValue}
              // onChange={({target})=>{ 
                // setSelectedValue(target.value);
                // if(target.value===2){
                //   setOpen(true)
                // }
              // }} 
              select
            >
              {[
                {
                  value: 1,
                  label: "This Month Only",
                },
                {
                  value: 2,
                  label: dateRangeLable,
                } 
              ].map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <Typography
                    component="div"
                   style={{color:"#9831b1"}} 
                    className={classes.dateTypo}
                    onClick={({target})=>{ 
                      setSelectedValue(option.value);
                      
                      if(option.value===2){
                        setOpen(true)
                      } else{
                        props.fetchDataByRange(null, null, proposalType );

                       setDateRangeLabel("Select a Date Range");
                      }
                    }} 
                  >
                    {option.label}
                  </Typography>
                </MenuItem>
              ))}
            </TextField>
                 
            <TextField 
              InputProps={{
                disableUnderline: true,
              }}

              style={{left: 10, right:10}}
              value={proposalType}
              onChange={({target})=>{ 
                setProposalType(target.value);
                if (dateRange && dateRange[0] && dateRange[1] && selectedValue!==1) { 
                  const {fromDate, toDate} = getDateRange(dateRange); 
                  props.fetchDataByRange(fromDate, toDate, target.value);
                } else {

                  const {fromDate, toDate} = getThisMonthRange(); 
                  props.fetchDataByRange(fromDate, toDate, target.value);
                }
              }} 
              select
            >
              {[
                {
                  value: "all",
                  label: "All",
                },
                {
                  value: "Health",
                  label: "Health",
                },
                {
                  value: "MOTOR_2W",
                  label: "2 wheeler",
                },
                {
                  value: "MOTOR_4W",
                  label: "Private Car",
                } ,
                {
                  value: "MOTOR_CV",
                  label: "Commercial Vehicle",
                }  
              ].map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <Typography
                    component="div"
                   style={{color:"#9831b1"}} 
                    className={classes.dateTypo}
                     
                  >
                    {option.label}
                  </Typography>
                </MenuItem>
              ))}
            </TextField>
            <MuiButtom  
              style={{padding:"5px 17px", color:"#9831b1", marginLeft:20}} 
              color="default" 
              variant="outlined" 
              onClick={exportPolicies} 
            >
              Export
            </MuiButtom>
            <DateRangeSelector
              open ={open}
              closeDateRangeSelector={toggleDateRangeSelector}
              getSelectedValue ={getSelectedValue}

            />
            </Typography> 
            <PolicySoldTable
              tableHeaderColor="primary"
              headCells={headCells}
              isSelectRequired={false}
              orderByParameter="name"
              orderAs="asc"
              rows={filteredList}
              getDetails={getDetails}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

export const GET_ALL_INSPECTIONS = "GET_ALL_INSPECTIONS";
export const SET_ALL_INSPECTIONS = "SET_ALL_INSPECTIONS";
export const UPDATE_INSPECTION_DETAILS = "UPDATE_INSPECTION_DETAILS";
export const GET_INSPECTIONS_DETAILS = "GET_INSPECTIONS_DETAILS";

export const getInspectionDetails = (inspectionId, resolve, reject) => ({
  type: GET_INSPECTIONS_DETAILS,
  inspectionId,
  resolve,
  reject,
});

export const getAllInspectionLeads = ({ skip, limit, match }) => ({
  type: GET_ALL_INSPECTIONS,
  skip,
  limit,
  match,
});

export const updateInspectionDetails = (params, resolve, reject) => ({
  type: UPDATE_INSPECTION_DETAILS,
  params,
  resolve,
  reject,
});

import * as actions from './types';

export function updateNextButtonAction(clicked) {
    return {
      type: actions.NEXT_BUTTON,
      payload: clicked,
    };
  }

  export function toggleBackDrop(openBackDrop) {
    return {
      type: actions.OPEN_BACKDROP,
      openBackDrop
    }
  }

  export function updateAlertMsg(alertMsg) {
    return {
      type: actions.ALERT_MSG,
      alertMsg
    }
  }

  export function companiesList(data) {
    return {
      type: actions.COMPANIES_LIST,
      data
    }
  }

  
import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '../CustomButtons/Button'

let resolve

export default function AlertBox (props) {
  const { open, handleClose, dialogTitle, dialogDescription, children } = props
  const onEnter = () => {
    return new Promise((res) => {
      resolve = res
    })
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onEnter ={onEnter}
    >
      <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {dialogDescription}
        </DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={(event) => { handleClose(event); resolve(false) }} color="warning" size="sm" >
            Cancel
        </Button>
        <Button onClick={(event) => { handleClose(event, 'okay'); resolve(true) }} color="primary" size="sm" >
            Update
        </Button>
      </DialogActions>
    </Dialog>
  )
}

import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { push } from 'react-router-redux';
import { getCookie } from '../utils/common';

import * as routes from './routeConstants';

import {store} from '../store';
import componentRoute from 'Pages/componentRoute';
import { ROUTE_FEATURE_MAP , SUB_ROUTES} from './constants';
import LoginPage from 'Pages/Login/LoginPage';

/**
 * Component to authenticate routes.
 */
class PrivateRoute extends Component {
  state = {
    isLoggedIn: false
  };

  setLogin(bool) {
    this.setState({ isLoggedIn: bool });
  }

  isAuthorized = ()=>{ 
    let cRoutes = componentRoute;

    let permissions =  getCookie("permissions");
    let allowedFeaturesPaths = ["/no-permissions"];
    
    permissions = permissions ? JSON.parse(permissions):[]; 
    let pathPermission = {};
    for(let i=0; i<permissions.length; i++){
      pathPermission[ROUTE_FEATURE_MAP[permissions[i].feature]] = permissions[i];
      if(permissions[i].feature === "POSP_onboarding") {
        pathPermission["pos-eligibility"] = permissions[i];
         
      }
      
      if(permissions[i].feature === "users_list") {
        const permission = permissions[i].permissions.find(p => p.name === "c");
        if(permission && permission.hiearchy !==""){
          pathPermission["special-user-create"] = permissions[i];
        }
      }

      if(permissions[i].feature === "generate_otp") {
        const permission = permissions[i].permissions.find(p => p.name === "c"); 
        if( permission && permission.hiearchy ===""){
        delete pathPermission["generate-otp"];
        }
      }

      allowedFeaturesPaths = [...allowedFeaturesPaths, ...SUB_ROUTES[permissions[i].feature]]
    }

    allowedFeaturesPaths = [...allowedFeaturesPaths, ...Object.keys(pathPermission)];
    allowedFeaturesPaths = allowedFeaturesPaths.filter((value, index, self)=> self.indexOf(value) === index);
    // console.log("allowedFeaturesPaths", allowedFeaturesPaths);

    let allowedPaths = cRoutes.filter(cr=>{
      let isMatched =false; 

      if(allowedFeaturesPaths.indexOf(cr.path)>-1 && pathPermission[cr.path]) {
        let readPermissions = pathPermission[cr.path].permissions.find(p=>p.name==="r");
        if(readPermissions && readPermissions.hiearchy!==""){
          isMatched =true
        }
      }
          return isMatched;
    }).map(cr=>`/${cr.path}`);

    allowedPaths = [...allowedPaths, ...allowedFeaturesPaths.filter(v=>v.indexOf("/:"))]

    // console.log("allowedPaths", allowedPaths, this.props.path);

  return allowedPaths.indexOf(this.props.path)>-1;
  }

  componentDidMount() {
    if (!getCookie("token")  ) {
      this.setLogin(false);
      store.dispatch(push(routes.LOGIN));
    } else {
      this.setLogin(true);
    }
  }

  

  render() {
    let { isLoggedIn } = this.state;
    // console.log("!this.isAuthorized()", !this.isAuthorized());
    if(!this.isAuthorized()){

      this.setLogin(false);
      store.dispatch(push(routes.LOGIN));
     return  <Route path={`/${routes.LOGIN}`} component={LoginPage} />
    }

    return isLoggedIn ? <Route {...this.props} /> : null;

  }
}

export default PrivateRoute;
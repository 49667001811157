
import React, { useState } from 'react' 

import GridContainer from 'components/Grid/GridContainer';
import GridItem from '../Grid/GridItem'
import { makeStyles } from '@material-ui/core';
import {styles} from './PermissionContainerStyle';
import CancelIcon from '@material-ui/icons/Cancel';
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step'; 

import all from 'assets/img/permissions/all.png'
import my from 'assets/img/permissions/my.png'
import noData from 'assets/img/permissions/no_data.png'
import notAllowed from 'assets/img/permissions/not_allowed.png'
import subordinates from 'assets/img/permissions/subordinates.png'
import myAndSubordinate from 'assets/img/permissions/my_and_subordinates.png'



import {splitAndCamelCase } from '../../utils/common';
import WarningModal from './WarningModal';

const useStyles = makeStyles(styles);
const permissionTextMaaping = {
  c:"Create",
  r:"Read",
  u:"Update",
  d: "Delete"
}

const PermissionContainer = (props) => { 
  const classes = useStyles();

  const { 
    permissionObj
    , roleName
    , updatedRolesPermissions
    , removePermission
  } = props;  

  const {permissions, possiblePermissions} = permissionObj;

  const [openWarningModal, setOpenWarningModal] = useState(false);

  let permissionTemplate = {
    c: possiblePermissions.find(p=>p.name.toLowerCase()==="c") ? true: false,
    r: possiblePermissions.find(p=>p.name.toLowerCase()==="r") ? true: false,
    u: possiblePermissions.find(p=>p.name.toLowerCase()==="u") ? true: false,
    d: possiblePermissions.find(p=>p.name.toLowerCase()==="d") ? true: false,
  }

  const handleUpdatePermission = ( hiearchy, forced=false) => {
    if(hiearchy!=="all" || forced) {
      let permissionArr = [...permissions];

      if(!!permissionArr.find(p=>p.name===selectedPermission)){
        permissionArr = permissionArr.map(p=>{
          if(p.name!== selectedPermission)return p;
          return {...p, hiearchy}
        })
      } else {
        permissionArr=[...permissionArr, {name:selectedPermission, hiearchy}]
      }

      updatedRolesPermissions(roleName,permissionObj, permissionArr)
    } else {
      console.log("This action will allow access to all data of the system.");
      setOpenWarningModal(!openWarningModal);
    }
  }

  const forcedUpdate = () => {
    handleUpdatePermission("all", true);
    setOpenWarningModal(!openWarningModal);
  }
  
  const removeRolePermission = ( feature) => { 
    removePermission(roleName, feature);
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedPermission, setSelectedPermissions] = useState("");

  const handleClick = (event, recordPermission) => {
    setSelectedPermissions(recordPermission);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (value) => { 
    setAnchorEl(null);
  };

  const toggleWarningModal = () => {
    setOpenWarningModal(!openWarningModal);
  }

  const permissionImg = (recordPermissiosn) =>{
     
    const type = permissions.find(p=>p.name===recordPermissiosn)?.hiearchy || "";
   
    switch(type){
      case "all" :
        return (<Tooltip title={"All Data"} arrow placement="top"><img className={classes.permissionApliedIcon} src={ all} onClick={(event)=>handleClick(event, recordPermissiosn)} /></Tooltip>);
      case "my" :
        return (<Tooltip title={"My Data"} arrow placement="top"><img className={classes.permissionApliedIcon} src={ my} onClick={(event)=>handleClick(event, recordPermissiosn)}  /></Tooltip>);
      case "sub_ordinate" :
        return (<Tooltip title={"Subordinates Data"} arrow placement="top"><img className={classes.permissionApliedIcon} src={ subordinates} onClick={(event)=>handleClick(event, recordPermissiosn)}  /></Tooltip>);
      case "my_and_subordinates" :
        return (<Tooltip title={"My and Subordinates Data"} arrow placement="top"><img className={classes.permissionApliedIcon} src={ myAndSubordinate} onClick={(event)=>handleClick(event, recordPermissiosn)}  /></Tooltip>);
      default:
        return (<Tooltip title={"No Data"} arrow placement="top"><img className={classes.permissionApliedIcon} src={ noData} onClick={(event)=>handleClick(event, recordPermissiosn)}  /></Tooltip>);
  
    } 
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <GridContainer className={classes.mtb}>
    <GridItem sx={12} sm={4}> 
        <div style={{marginTop:14}}>{splitAndCamelCase(permissionObj.feature)}</div> 
    </GridItem>
      <GridItem sx={12} sm={8}>
 
        <div className={classes.flexContainer}>
        {
          Object.entries(permissionTemplate).map(p=>{
            return (
              <div>
                {
                  p[1] && permissionImg(p[0])
                  
                }

                {
                  !p[1] && (
                    <Tooltip title={"Not Allowed"} arrow placement="top">
                      <img className={classes.notAllowed} src={ notAllowed}  />
                    </Tooltip>
                  )
                }
               {possiblePermissions.length>0 &&(
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                <div>
                  <Stepper  style={{padding:0}}>
                    
                    <Step>
                      <Tooltip arrow placement="bottom" title="All Data">
                        <img className={classes.popoverIcons} src={all} alt="all data"  onClick={()=>handleUpdatePermission( `all`)}/>
                      </Tooltip>
                    </Step>
                    <Step>

                      <Tooltip arrow placement="bottom" title="My and Subordinates Data">
                        <img className={classes.popoverIcons} src={myAndSubordinate} alt="My and Subordinated"  onClick={()=>handleUpdatePermission( "my_and_subordinates")}/>
                        </Tooltip>
                    </Step>
                    <Step>
                      <Tooltip arrow placement="bottom" title="Subordinates Data">
                        <img className={classes.popoverIcons}  src={subordinates} alt="Subordinates"  onClick={()=>handleUpdatePermission( "sub_ordinate")}/>
                        </Tooltip>
                    </Step>
                    <Step>
                      <Tooltip arrow placement="bottom" title="My Data">
                        <img className={classes.popoverIcons} src={my} alt="My data"  onClick={()=>handleUpdatePermission("my")}/>
                        </Tooltip>
                    </Step>
                    <Step>
                      <Tooltip arrow placement="bottom" title="No Data">
                        <img className={classes.popoverIcons}  src={noData} alt="No Data"  onClick={()=>handleUpdatePermission("")}/>
                        </Tooltip>
                    </Step>
                  </Stepper>
                </div>
                </Popover>
                )}
              </div>
            )
          })
        } 

          <div >
            <Tooltip arrow placement="top"  title={`Remove ${splitAndCamelCase(permissionObj.feature)} Permission`}>
              <CancelIcon 
                className={classes.removeFeature} 
                color="error"
                onClick={()=>removeRolePermission(permissionObj.feature)}
              />
              </Tooltip>
          </div>
        </div>
      </GridItem>

      <GridItem>
        <WarningModal
          open={openWarningModal}
          toggleWarningModal={toggleWarningModal}
          update={forcedUpdate}
          feature= { splitAndCamelCase(permissionObj.feature) }
          title={"Sharing ALL DATA"}
          okIsPrimary = {false}
          message ={<span>Are you sure, you want to share <b>ALL DATA</b> with this role?</span>}
        />
      </GridItem>
  
    </GridContainer> 
  )
}


export default PermissionContainer;
import React, { Fragment } from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

export default function CollapsableMenu(props) {
  const { group = [], classes = {}, menuName = "", color } = props;

  const [open, setOpen] = React.useState(false);

  const handleClick = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };

  const activeRoute = (routeName) => {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  };

  React.useEffect(() => {
    const path = window.location.href.split("/").pop();
    console.log("path is", path);
    group.forEach((prop) => {
      if (prop.path === path) {
        setOpen(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <ListItem button onClick={handleClick}>
        <ListItemText
          primary={menuName}
          className={classNames(classes.itemText, {})}
          disableTypography={true}
        />
        {open ? (
          <ExpandLess
            className={classNames(classes.itemIcon, classes.whiteFont)}
          />
        ) : (
          <ExpandMore
            className={classNames(classes.itemIcon, classes.whiteFont)}
          />
        )}
      </ListItem>
      <Collapse in={open} timeout="auto">
        <List className={classes.list}>
          {group.map((prop, key) => {
            var activePro = " ";
            var listItemClasses;
            const isActiveRoute = activeRoute(prop.layout + prop.path);

            listItemClasses = classNames({
              [" " + classes[color]]: isActiveRoute,
            });

            const whiteFontClasses = classNames({
              [" " + classes.whiteFont]: isActiveRoute,
            });
            // if(prop.path.indexOf("pos") == -1){
            return (
              <NavLink
                to={prop.layout + prop.path}
                className={activePro + classes.item}
                activeClassName="active"
                key={key}
              >
                <ListItem button className={classes.itemLink + listItemClasses}>
                  {typeof prop.icon === "string" ? (
                    <Icon
                      className={classNames(
                        classes.itemIcon,
                        whiteFontClasses,
                        {
                          [classes.itemIconRTL]: props.rtlActive,
                        }
                      )}
                    >
                      {prop.icon}
                    </Icon>
                  ) : (
                    <prop.icon
                      className={classNames(
                        classes.itemIcon,
                        whiteFontClasses,
                        {
                          [classes.itemIconRTL]: props.rtlActive,
                        }
                      )}
                    />
                  )}
                  <ListItemText
                    primary={props.rtlActive ? prop.rtlName : prop.name}
                    className={classNames(classes.itemText, whiteFontClasses, {
                      [classes.itemTextRTL]: props.rtlActive,
                    })}
                    disableTypography={true}
                  />
                </ListItem>
              </NavLink>
            );
          })}
        </List>
      </Collapse>
    </Fragment>
  );
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import classNames from "classnames";
import styles from "../../assets/jss/componentsStyles/selectOptionStyle";
import FormHelperText from '@material-ui/core/FormHelperText'

const useStyles = makeStyles(styles);

export default function SelectOption(props) {
    // selectOptions =[@param(value, label, color)]
    const {
        formControlProps,
        labelText,
        id,
        selectOptions=[],
        labelProps,
        inputProps,
        error,
        onSelectChange,
        isRequired,
        success,
        helperText,
      } = props;
    const classes = useStyles();

      const selectFormClasses = classNames({
        [classes.selectFormControl]: !error,
        [classes.selectFormControlError]: error
      });

      const selectStyleClasses = classNames({
        [classes.selectLabel]: !error,
        [classes.selectLabelError]: error
      });
      const helpTextClasses = classNames({
        [classes.selectLabelError]: error,
        [classes.selectLabel]: success && !error
      })

  return (
    <FormControl
      {...formControlProps}
    // className={formControlProps.className + " " + classes.formControl}
    fullWidth
    className={selectFormClasses}
    >

      {labelText !== undefined ? (
        <InputLabel
          // className={classes.labelRoot + labelClasses}
          className={selectStyleClasses}
          htmlFor={id}
          {...labelProps}
        >
          {labelText}
          {isRequired && <sup style={{color:"red"}}>*</sup> }
        </InputLabel>
      ) : null}
      <Select
       MenuProps={{
        className: classes.selectMenu
      }}
      classes={{
        select: classes.select
      }}
          id={id}
        onChange={onSelectChange}
        {...inputProps}

      >
      {selectOptions.map(option => {
          return(
      // ( <option value={option.value}>{option.label}</option>)
      <MenuItem
      classes={{
        root: classes.selectMenuItem,
        selected: option.value? classes.selectMenuItemSelected: ''
      }}
      value={option.value}
      disabled ={option.value ? false: true}
    >
      {option.label}
    </MenuItem>
          )
      })}
      </Select>
      {helperText !== undefined ? (
        <FormHelperText id={id + '-text'} className={helpTextClasses}>
          {helperText}
        </FormHelperText>
      ) : null}
    </FormControl>
  )

}

//   checked={personName.indexOf(name) > -1}


const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "Montserrat",
        marginBottom: "3px",
        textDecoration: "none"
    },
    approved: {
        color: "#008000"
    },
    unapproved: {
        color: "#f00"
    },
    cardCategory: {
        marginBlockStart: "1.33em",
        marginBlockEnd: "1.33em"
    },
    cardBody: {
        paddingLeft: "0px",
        paddingRight: "0px"
    },

    cardTitle: {
        margin: "0px",
    },
    cardBackground: {
        background: "#ffffff"
    },
    tabHeader: {
        width: "calc(100% + 40px)",
        marginLeft: "-20px"
    },
    error: {
        fontSize: "11px",
        color: "red",
    },

    divBlock: {
        color: "#757575",
        padding: "12px",
        display: "block",
        float: "left"
    },
    closeButton: {
        zIndex: "1",
        // position: 'absolute',
        // right: theme.spacing(1),
        //  top: theme.spacing(1),
        // color: theme.palette.grey[500],
        border: "2px",
        borderColor: "#929798",
        borderStyle: "double",
        zoom: "1",
        padding: "0px",


    },
    leftAlign: {
        alignSelf: "flex-start"
    },
    rightAlign: {
        marginLeft: "auto",
        alignSelf: "flex-end"
    },
    checkBoxLabel: {
        color: "#272525",
        '& > .MuiTypography-body1': {
            fontSize: "0.9em"
        }

    },
    checkBox: {
        padding: "0px"
    }

};

export default styles;
import * as React from "react";
import Admin from '../DashBoard/Admin';
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import { Typography, Tooltip } from "@material-ui/core";
import CardBody from "../../components/Card/CardBody";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from '../../components/CustomButtons/Button';
import { withStyles } from '@material-ui/core/styles';
import styles from './OtpPageStyle';
import InputAdornment from "@material-ui/core/InputAdornment";
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import CardFooter from "../../components/Card/CardFooter";
import DialpadIcon from '@material-ui/icons/Dialpad';
import {isPhoneNumberValid, isEmpty, generateOtp} from '../../utils/common';
import { tumblrColor } from "../../assets/jss/material-dashbord-react";
import RefreshIcon from '@material-ui/icons/Refresh';

class GenerateOtpPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            phoneNumber:'',
            isError:false,
            showOtp:false,
            otp:'',
            error:''
        }
    }


    updateValue= (value) => {
        this.setState({
            phoneNumber:value
        })
    }

    generateOtp =(event) => {
        event.preventDefault();
    
    
        this.validate().then(() => {
            const otp = generateOtp(this.state.phoneNumber);
            this.setState({
                showOtp:tumblrColor,
                otp
            })

        }).catch(error => {
            this.setState({isError: true, error})
        });
    }
    validate =() =>{
        return new Promise((resolve, reject) => {
            const {phoneNumber} = this.state;
            let isError = false;
            let error =''

            if(isEmpty(phoneNumber)){
                error = "Please enter the valid Phone Number";
                isError = true;
            }
            if(!isPhoneNumberValid(phoneNumber)){
            error = "Please enter the valid Phone Number";
            isError=true;
            }
            isError? reject(error): resolve();

        });
    };
    refresh =() =>{
        this.setState({
            phoneNumber:'',
            isError:false,
            showOtp:false,
            otp:'',
            error:''
        });
    }

    render() {
        const { classes } = this.props;
        const {showOtp, otp, isError, phoneNumber} = this.state;

        return (
            <Admin>
  
                <div className={classes.container}>
                    <GridContainer justify="center">
                        <GridItem xs={12} sm={6} md={5}>
                            <Card login >
                                <CardHeader
                                    className={`${classes.cardHeader} ${classes.textCenter}`}
                                    color="primary"
                                >
                                    <h4 className={classes.cardTitle}>Generate OTP</h4>
                                </CardHeader>
                                <CardBody profile>
                                    <CustomInput
                                        labelText="Phone  Number"
                                        id="phone"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        isRequired
                                        helperText={this.state.error}
                                        onChange={({ target: { value } }) => (value ? this.updateValue(value) : this.updateValue(null))}                                   
                                        error={isError}
                                       
                                        inputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <PhoneAndroidIcon className={classes.inputAdornmentIcon} />
                                                </InputAdornment>
                                            ),
                                            error:isError,
                                            value:phoneNumber
                                        }}
                                    />
                                   

                                </CardBody>
                                <CardFooter className={classes.footer}>
                                 {!showOtp ?   (<Button color="primary" block onClick={(event) => this.generateOtp(event)}>
                                     <DialpadIcon />   Generate Otp
                                 </Button>): (<div><Typography variant="button" display="block"> OTP Is:  <b>{otp}</b></Typography> 
                                 <Tooltip title="Refresh"><Button color="primary" simple className={classes.typoButton} onClick={()=>this.refresh()}> <RefreshIcon /> </Button></Tooltip>
                                 </div>)}
                                     
                                </CardFooter>
                            </Card>
                        </GridItem>

                    </GridContainer>
                </div>

            </Admin>
        )
    }
}
export default (withStyles(styles)(GenerateOtpPage));
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import { Grid, Tooltip } from "@material-ui/core";
import {
  Search,
  TrendingUp,
  History,
  CheckCircleOutline,
  NotInterested,
} from "@material-ui/icons";

import Card from "components/Card/Card";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import { ONBOARD_STATUS, BREAK_IN_STATUS } from "utils/constants";

import styleHeader from "assets/jss/componentsStyles/headerLinksStyle";
import { exportCsv, changeDateFormatFromProvidedFormat } from "utils/common";
import CustomHeaderContentTabs from "components/CustomTabs/CustomHeaderContentTabs";
import FuzzySearch from "fuzzy-search";

import InspectionsTable from "./InspectionsTable";

import { getAllInspectionLeads } from "../actions";

import styles from "./InspectionsList.style";

function createData(
  fullName,
  phone,
  aadharNo,
  panNo,
  highestQualification,
  isExamPassed,
  approval,
  _id
) {
  return {
    fullName,
    phone,
    aadharNo,
    panNo,
    highestQualification,
    isExamPassed,
    approval,
    _id,
  };
}

const headerStyle = makeStyles(styleHeader);

const InspectionsList = (props) => {
  const classes = styles();
  const headerClass = headerStyle();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [leadStatus, setLeadStatus] = React.useState([0, 1, 11]);

  const { getAllInspectionLeads, inspectionsLeads = {} } = props;
  const { LeadCount = 0, leadInspections = [] } = inspectionsLeads;

  useEffect(() => {
    getAllInspectionLeads({
      skip: page,
      limit: rowsPerPage,
      match: {
        status: leadStatus,
      },
    });
  }, []);

  const { posList = [], getDetails, handleClose } = props;
  const rows = [];

  const [filteredList, setList] = React.useState();

  const [showInput, toggleInput] = React.useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getAllInspectionLeads({
      skip: newPage * rowsPerPage,
      limit: rowsPerPage,
      match: {
        status: leadStatus,
      },
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getAllInspectionLeads({
      skip: 0,
      limit: parseInt(event.target.value, 10),
      match: {
        status: leadStatus,
      },
    });
  };

  // const detailButton =  (<Button color="primary">Details</Button>)
  posList.forEach((pos) => {
    const row = createData(
      pos.fullName,
      pos.phone,
      pos.aadharNo,
      pos.panNo.toUpperCase(),
      pos.highestQualification,
      pos.isExamPassed,
      pos.approvalStatus,
      pos.userId
    );
    rows.push(row);
  });

  //   React.useEffect(() => {
  //     setList(rows); // api.data.count from api after update store
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [posList]);

  React.useEffect(() => {
    document.getElementById("searchInput") &&
      document.getElementById("searchInput").focus();
  }, [showInput]);

  const filterList = (e) => {
    // Variable to hold the original version of the list
    let currentList = [];
    // Variable to hold the filtered list before putting into state

    let newList = [];
    // If the search bar isn't empty
    if (e.target.value !== "") {
      // Assign the original list to currentList
      currentList = rows;
      const searcher = new FuzzySearch(
        currentList,
        [
          "fullName",
          "phone",
          "aadharNo",
          "panNo",
          "highestQualification",
          "isExamPassed",
          "_id",
          "approval",
        ],
        {
          caseSensitive: false,
        }
      );
      newList = searcher.search(e.target.value.toLowerCase());
    } else {
      // If the search bar is empty, set newList to original task list
      newList = [...rows];
    }
    setList(newList);
  };

  const headCells = [
    { id: "quoteName", label: "Quote Name" },
    { id: "posFullName", label: "POSP Name" },
    { id: "companyLogo", label: "Insurer" },
    { id: "inspectionCreateEpoch", label: "Proposal Date" },
    { id: "status", label: "Status" },
  ];

  const tableData = (tabName) => {
    return (
      <InspectionsTable
        tableHeaderColor="primary"
        headCells={headCells}
        isSelectRequired={false}
        orderByParameter="name"
        orderAs="asc"
        rows={leadInspections}
        totalCount={LeadCount}
        tabName={tabName}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    );
  };

  const tabs = [
    {
      tabName: "Pending",
      tabIcon: History,
      tabContent: tableData(BREAK_IN_STATUS.PENDING),
    },
    {
      tabName: "Recommended",
      tabIcon: CheckCircleOutline,
      tabContent: tableData(BREAK_IN_STATUS.APPROVED),
    },
    {
      tabName: "Not Recommended",
      tabIcon: NotInterested,
      tabContent: tableData(BREAK_IN_STATUS.REJECTED),
    },
  ];

  const handleTabChange = (value) => {
    setRowsPerPage(25);
    setPage(0);
    switch (value) {
      case 0:
        setLeadStatus([0, 1, 11]);
        getAllInspectionLeads({
          skip: 0,
          limit: 25,
          match: {
            status: [0, 1, 11],
          },
        });
        break;
      case 1:
        setLeadStatus([2]);
        getAllInspectionLeads({
          skip: 0,
          limit: 25,
          match: {
            status: [2],
          },
        });
        break;
      case 2:
        setLeadStatus([4]);
        getAllInspectionLeads({
          skip: 0,
          limit: 25,
          match: {
            status: [4],
          },
        });
        break;
    }
  };
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12}>
        <Card>
          <CustomHeaderContentTabs
            color="primary"
            headerColor="primary"
            tabs={tabs}
            plainTabs={false}
            handleTabChange={handleTabChange}
          >
            <span style={{ alignSelf: "flex-start" }}>
              <h4 className={classes.cardTitleWhite}>Break-in Inspections</h4>
            </span>
            <span
              className={headerClass.searchWrapper}
              style={{ marginLeft: "auto", alignSelf: "flex-end" }}
            >
              {showInput && (
                <CustomInput
                  id="searchInput"
                  formControlProps={{
                    className: classNames(
                      headerClass.margin,
                      headerClass.search
                    ),
                  }}
                  inputProps={{
                    inputProps: {
                      "aria-label": "Search",
                      className: classes.inputSearchType,
                    },
                  }}
                  onChange={(event) => filterList(event)}
                />
              )}
              <Tooltip title="Seach" aria-label="add">
                <Button
                  className={classes.paddingStyle}
                  color="white"
                  aria-label="edit"
                  simple
                  onClick={() => toggleInput(!showInput)}
                >
                  <Search /> Search
                </Button>
              </Tooltip>
            </span>
          </CustomHeaderContentTabs>
        </Card>
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getAllInspectionLeads }, dispatch);

const mapStateToProps = (state) => ({
  inspectionsLeads: state.breakInInspectionsReducer.inspections,
});

export default connect(mapStateToProps, mapDispatchToProps)(InspectionsList);

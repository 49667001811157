import * as actions from '../actions/types';

const initialState = {
  rolePermissions: [],
  masterPermissions: [],
  masterRoles: []
};

const adminRoleReducer = (state = initialState, action) => {
  // console.log("state", state, action)
  switch (action.type) {

    case actions.FETCH_ROLES_PERMISSIONS:
      return {
        ...state,
        rolePermissions: action.rolePermissions,
      };

    case actions.FETCH_MASTER_PERMISSIONS:
      return {
        ...state,
        masterPermissions: action.masterPermissions,
      };
    case actions.MASTER_ROLES:
      return {
        ...state,
        masterRoles: action.masterRoles
      }
    default:
      return {
        ...state
      };
  }
};

export default adminRoleReducer;
import * as actions from '../actions/types';

const initialState = {};

const payoutFrequencyReducer = (state = initialState, action) => {
    switch (action.type) {

        case actions.GLOBAL_PAYOUT_FREQUENCY:
            return {
                ...state,
                globalPayoutFrequency: action.globalPayoutFrequency,
            };

        default:
            return state;
    }
}
export default payoutFrequencyReducer;
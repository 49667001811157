 
import React from 'react'
import Admin from '../DashBoard/Admin';

const NoPermissionsPage = () =>{
  return (
    <Admin>
    <div style={{
      display:"flex",
      height: "70vh",
      alignItems:"center",
      justifyContent:"center",
      fontSize:20,
      fontWeight:"bold",
      color:"#9c27b0"
    }}>This admin user does not have any permission.</div>
    </Admin>
  )
}

export default NoPermissionsPage
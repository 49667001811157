import {
    container,
    cardTitle,
    whiteColor,
    grayColor
  } from "assets/jss/material-dashbord-react";

const PageStyle =theme => ({

  container: {
    ...container,
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "Montserrat",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  paddingStyle: {
    padding: '0 12px 0'
  },
  cardHeader: {
    marginBottom: "20px"
  },
  textCenter: {
    textAlign: "center"
  },
  inputAdornment: {
    marginRight: "18px"
  },
  inputAdornmentIcon: {
    color: grayColor[6]
  },
  footer:{
      marginTop:"15px",
      paddingTop:"20px",
      flexDirection:"column"
  },
  typoButton:{
    marginTop: "0px",
    marginBottom: "0px"
  },
  error: {
    fontSize: "11px",
    color: "red",
  },
  labelHorizontal: {
    color: "rgba(0, 0, 0, 0.26)",
    cursor: "pointer",
    display: "inline-flex",
    fontSize: "14px",
    lineHeight: "1.428571429",
    fontWeight: "700",
    paddingTop: "39px",
    marginRight: "0",
    // "@media (min-width: 992px)": {
    //   float: "right"
    // }
  },
  label: {
    fontWeight: '400',
    color: '#797878',
    paddingRight: '15px'
  },
  spacer: {
    flex: '1 1 auto'
  },

});
export default PageStyle;
import * as actions from '../actions/types';

const initialState = {};

const posCommissionReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.POS_COMMISSION:
            return {
                ...state,
                posCommission: action.posCommission,
            };
            case actions.GLOBAL_COMMISSION:
                return {
                    ...state,
                    globalCommission: action.globalCommission,
                };
        default:
            return state;
    }
};
export default posCommissionReducer;

import { call, put, takeLatest, all, fork } from 'redux-saga/effects'
import * as actions from '../actions/types'
import axios from '../../utils/axios'
import urls from '../../utils/urls'
import { createUrl, isEmpty } from '../../utils/common'
import {
  insuranceTypeList, proposalList,
  offlineSalesList, getPreviousInsurerList,
  proposalDetails, initalTitleList, maritalStatusList, relationList,
  occupationList, idProofList, gstTypeList, userRolesList, bqpUsersList,
  searchUsersList
} from '../actions/productAction'
import { companiesList } from '../actions/commonAction';
import { LocalConvenienceStoreOutlined } from '@material-ui/icons'

// ############ Get Policy Types ################################

function fetchinsuranceTypeList() {
  return axios.request({
    method: 'get',
    url: createUrl(urls.BASE_URL + urls.product.POLICY_TYPES),
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

// Saga function that handles the side effect when the loginActionWatcher is triggered
export function* fetchInsuranceTypeActionEffect() {
  // let { resolve, reject } = posAction;

  try {
    const { data } = yield call(fetchinsuranceTypeList)

    if (!isEmpty(data)) {
      console.log('insurance Type List fetched')

      yield put(insuranceTypeList(data))
    } else {
      yield put(insuranceTypeList([]))
    }
  } catch (e) {
    //   alert.error(e.message || 'Authentication Error');
    //   yield put(authError(e));
    console.log('Error found while fetching pos')
    // if (reject) reject(e);
    yield put(insuranceTypeList([]))
  }
}

export function* fetchInsuranceTypeActionWatcher() {
  yield takeLatest(actions.INSURANCE_TYPE_WATCHER, fetchInsuranceTypeActionEffect)
}

// ########## GET INSURANCE COMPANY BY POLICY TYPE ############################

function fetchInsuranceCompanyByType(param) {
  return axios.request({
    method: 'get',
    url: createUrl(urls.BASE_URL + urls.product.POLICY_TYPES) + urls.product.GET_COMPANIES,
    headers: {
      'Content-Type': 'application/json'
    },
    params: {
      // insuranceType: 'Health'
      insuranceType: param.charAt(0).toUpperCase() + param.slice(1).toLowerCase()
    }
  })
}

export function* fetchInsuranceCompanyByTypeActionEffect(param) {
  const { payload, resolve, reject } = param

  try {
    const { data } = yield call(fetchInsuranceCompanyByType, payload)

    if (!isEmpty(data)) {
      console.log('insurance company list fetched')

      if (!payload) {
        const dataObj = {};
        data.map(abc => {
          dataObj[abc.companyId] = abc
        })
        yield put(companiesList(dataObj));
      }
      resolve(data)
    } else {
      reject(data)
    }
  } catch (e) {
    //   alert.error(e.message || 'Authentication Error');
    //   yield put(authError(e));
    console.log('Error found while fetching pos')
    reject(e)
    // if (reject) reject(e);
  }
}

export function* fetchInsuranceCompanyByTypeActionWatcher() {
  yield takeLatest(actions.INSURANCE_COMPANY_BY_TYPE_WATCHER, fetchInsuranceCompanyByTypeActionEffect)
}

// ########## GET PREVIOUS INSURER BASED ON POLICY TYPE ############################

function fetchPreviousInsurer(param) {
  const mainUrl = (param === 'HEALTH') ? urls.product.HEALTH_PREVIOUS_INSURER : urls.product.MOTOR_PREVIOUS_INSURER
  return axios.request({
    method: 'get',
    url: createUrl(urls.BASE_URL + mainUrl),
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function* fetchPreviousInsurerActionEffect(param) {
  const { payload, resolve, reject } = param

  try {
    const { data } = yield call(fetchPreviousInsurer, payload)

    if (!isEmpty(data)) {
      console.log('insurance company list fetched')
      yield put(getPreviousInsurerList(data))

      resolve(data)
    } else {
      yield put(getPreviousInsurerList([]))
      reject(data)
    }
  } catch (e) {
    //   alert.error(e.message || 'Authentication Error');
    //   yield put(authError(e));
    console.log('Error found while fetching pos')
    yield put(getPreviousInsurerList([]))
    reject(e)
    // if (reject) reject(e);
  }
}

export function* fetchPreviousInsurerActionWatcher() {
  yield takeLatest(actions.PREVIOUS_INSURER_WATCHER, fetchPreviousInsurerActionEffect)
}

// ######### AllProduct ByCompany ByPolicyType #######################

function fetchAllProductByCompanyByPolicyType(param) {
  return axios.request({
    method: 'get',
    url: createUrl(urls.BASE_URL + urls.product.POLICY_TYPES) + urls.product.GET_COMPANY_PRODUCTS,
    headers: {
      'Content-Type': 'application/json'
    },
    params: {
      insuranceType: param.insuranceType.charAt(0).toUpperCase() + param.insuranceType.slice(1).toLowerCase(),
      companyId: param.companyId

    }
  })
}

export function* fetchAllProductByCompanyByPolicyTypeActionEffect(param) {
  const { payload, resolve, reject } = param

  try {
    const { data } = yield call(fetchAllProductByCompanyByPolicyType, payload)

    if (!isEmpty(data)) {
      console.log('insurance product list fetched')

      resolve(data)
    } else {
      reject(data)
    }
  } catch (e) {
    //   alert.error(e.message || 'Authentication Error');
    //   yield put(authError(e));
    console.log('Error found while fetching pos')
    reject(e)
    // if (reject) reject(e);
  }
}

export function* fetchAllProductByCompanyByPolicyTypeActionWatcher() {
  yield takeLatest(actions.INSURANCE_PRODUCTS_BY_COMPANY_BY_TYPE_WATCHER, fetchAllProductByCompanyByPolicyTypeActionEffect)
}

// ######### Fetch all the inital titles ##################################################

function fetchInitialTitle() {
  return axios.request({
    method: 'get',
    url: createUrl(urls.BASE_URL + urls.product.INITIAL_TITLE_INFO),
    headers: {
      'Content-Type': 'application/json'
    }
    // params: {
    //   //  insuranceType: param.insuranceType,
    //   // companyId : param.companyId,
    //   cid: 5
    // }
  })
}

export function* fetchInitialTitleActionEffect(param) {
  const { resolve, reject } = param

  try {
    const { data } = yield call(fetchInitialTitle)

    if (!isEmpty(data)) {
      console.log('insurance product list fetched')
      yield put(initalTitleList(data))
      resolve(data)
    } else {
      reject(data)
    }
  } catch (e) {
    //   alert.error(e.message || 'Authentication Error');
    //   yield put(authError(e));
    console.log('Error found while fetching pos')
    reject(e)
    // if (reject) reject(e);
  }
}

export function* fetchInitialTitleActionWatcher() {
  yield takeLatest(actions.INITIAL_TITLE_WATCHER, fetchInitialTitleActionEffect)
}

// ############# Get All Occupation Details ########################################

function fetchAllOccupationDetails(param) {
  return axios.request({
    method: 'get',
    url: createUrl(urls.BASE_URL + urls.product.OCCUPATION_DETAILS),
    headers: {
      'Content-Type': 'application/json'
    },
    params: {
      //  insuranceType: param.insuranceType,
      // companyId : param.companyId,
      cid: param.companyId,
      productId: param.productId

    }
  })
}

export function* fetchAllOccupationDetailsActionEffect(param) {
  const { payload, resolve, reject } = param

  try {
    const { data } = yield call(fetchAllOccupationDetails, payload)

    if (!isEmpty(data)) {
      console.log('Occupations list fetched')
      yield put(occupationList(data))
      resolve(data)
    } else {
      yield put(occupationList([]))
      reject(data)

    }
  } catch (e) {
    //   alert.error(e.message || 'Authentication Error');
    //   yield put(authError(e));
    console.log('Error found while fetching pos')
    yield put(occupationList([]))

    reject(e)
    // if (reject) reject(e);
  }
}

export function* fetchAllOccupationDetailsActionWatcher() {
  yield takeLatest(actions.ALL_OCCUPATION_DETAILS_WATCHER, fetchAllOccupationDetailsActionEffect)
}

// ############# Get Id Proof Info ########################################

function fetchAllIdProofInfo(cid) {
  return axios.request({
    method: 'get',
    url: createUrl(urls.BASE_URL + urls.product.ID_PROOF_INFO),
    headers: {
      'Content-Type': 'application/json'
    },
    params: {
      //  insuranceType: param.insuranceType,
      // companyId : param.companyId,
      cid: cid
    }
  })
}

export function* fetchAllIdProofInfoActionEffect(param) {
  const { payload, resolve, reject } = param

  try {
    const { data } = yield call(fetchAllIdProofInfo, payload)

    if (!isEmpty(data)) {
      console.log('Get Id proof info fetched')
      yield put(idProofList(data))
      resolve(data)
    } else {
      yield put(idProofList([]))
      reject(data)
    }
  } catch (e) {
    //   alert.error(e.message || 'Authentication Error');
    //   yield put(authError(e));
    console.log('Error found getting id proof info')
    yield put(idProofList([]))
    reject(e)
    // if (reject) reject(e);
  }
}

export function* fetchAllIdProofInfoActionWatcher() {
  yield takeLatest(actions.ID_PROOF_INFO_WATCHER, fetchAllIdProofInfoActionEffect)
}

// ############# Get Gst Type Info ########################################

function fetchGstTypeInfo(cid) {
  return axios.request({
    method: 'get',
    url: createUrl(urls.BASE_URL + urls.product.GST_TYPE_INFO),
    headers: {
      'Content-Type': 'application/json'
    },
    params: {
      //  insuranceType: param.insuranceType,
      // companyId : param.companyId,
      cid: cid
    }
  })
}

export function* fetchGstTypeInfoActionEffect(param) {
  const { payload, resolve, reject } = param
  console.log('gst for company id ', payload)
  try {
    const { data } = yield call(fetchGstTypeInfo, payload)

    if (!isEmpty(data)) {
      console.log('Get gst type info fetched')
      yield put(gstTypeList(data))
      resolve(data)
    } else {
      yield put(gstTypeList([]))

      reject(data)
    }
  } catch (e) {
    //   alert.error(e.message || 'Authentication Error');
    //   yield put(authError(e));
    console.log('Error found getting id proof info')
    yield put(gstTypeList([]))

    reject(e)
    // if (reject) reject(e);
  }
}

export function* fetchGstTypeInfoActionWatcher() {
  yield takeLatest(actions.GST_TYPE_INFO_WATCHER, fetchGstTypeInfoActionEffect)
}

// ############# Get relations Info ########################################

function fetchRelationsInfo() {
  return axios.request({
    method: 'get',
    url: createUrl(urls.BASE_URL + urls.product.RELATIONS_INFO),
    headers: {
      'Content-Type': 'application/json'
    }
    // params: {
    //   //  insuranceType: param.insuranceType,
    //   // companyId : param.companyId,
    //   cid: 5
    // }
  })
}

export function* fetchRelationsInfoActionEffect(param) {
  const { resolve, reject } = param

  try {
    const { data } = yield call(fetchRelationsInfo)

    if (!isEmpty(data)) {
      console.log('Get gst type info fetched')
      yield put(relationList(data))

      resolve(data)
    } else {
      reject(data)
    }
  } catch (e) {
    //   alert.error(e.message || 'Authentication Error');
    //   yield put(authError(e));
    console.log('Error found getting id proof info')
    reject(e)
    // if (reject) reject(e);
  }
}

export function* fetchRelationsInfoActionWatcher() {
  yield takeLatest(actions.RELATIONS_INFO_WATCHER, fetchRelationsInfoActionEffect)
}

// ############ Get marital status Info ##########################

function fetchMaritalStatusInfo() {
  return axios.request({
    method: 'get',
    url: createUrl(urls.BASE_URL + urls.product.MARITAL_STATUS_INFO),
    headers: {
      'Content-Type': 'application/json'
    }

  })
}

export function* fetchMaritalStatusInfoActionEffect(param) {
  const { resolve, reject } = param

  try {
    const { data } = yield call(fetchMaritalStatusInfo)

    if (!isEmpty(data)) {
      console.log(' Marital status info fetched')
      yield put(maritalStatusList(data))

      resolve(data)
    } else {
      reject(data)
    }
  } catch (e) {
    //   alert.error(e.message || 'Authentication Error');
    //   yield put(authError(e));
    console.log('Error found getting id proof info')
    reject(e)
    // if (reject) reject(e);
  }
}

export function* fetchMaritalStatusInfoActionWatcher() {
  yield takeLatest(actions.MARITAL_STATUS_INFO_WATCHER, fetchMaritalStatusInfoActionEffect)
}

// ############################# Get user roles ###############################

function fetchUserRoles() {
  return axios.request({
    method: 'get',
    url: createUrl(urls.BASE_URL+'/role/getUserRole'),
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function* fetchUserRolesActionEffect() {
  try {
    const { data } = yield call(fetchUserRoles);
    if (!isEmpty(data)) {
      yield put(userRolesList(data))
    } else {
      yield put(userRolesList([]))
    }
  } catch (e) {
    console.log('Error found getting user roles.');
  }
}

export function* fetchUserRolesActionWatcher() {
  yield takeLatest(actions.USER_ROLES_WATCHER, fetchUserRolesActionEffect)
}


// ############################# Search BQP users by phone/name ###############################

function fetchBQPUsers(params) {
  return axios.request({
    method: 'post',
    url: createUrl(urls.BASE_URL+'/user/special/searchBQPUsers'),
    headers: {
      'Content-Type': 'application/json'
    },
    data: params
  })
}

export function* fetchBQPUserByPhoneOrNameActionEffect(params) {
  const { payload } = params;
  try {
    const { data } = yield call(fetchBQPUsers, payload);
    if (!isEmpty(data)) {
      yield put(bqpUsersList(data))
    } else {
      yield put(bqpUsersList([]))
    }
  } catch (e) {
    console.log('Error found getting user roles.');
  }
}

export function* fetchBQPUserByPhoneOrNameActionWatcher() {
  yield takeLatest(actions.BQP_USERS_WATCHER, fetchBQPUserByPhoneOrNameActionEffect)
}

// ############################# Search users by phone/name ###############################

function fetchAllUsers(params) {
  return axios.request({
    method: 'post',
    url: createUrl(urls.BASE_URL+'/user/special/searchAllUsers'),
    headers: {
      'Content-Type': 'application/json'
    },
    data: params
  })
}

export function* fetchUsersByPhoneOrNameActionEffect(params) {
  const { payload } = params;
  try {
    const { data } = yield call(fetchAllUsers, payload);
    if (!isEmpty(data)) {
      console.log('Matched users fetched.')
      yield put(searchUsersList(data))
    } else {
      yield put(searchUsersList([]))
    }
  } catch (e) {
    console.log('Error found getting users.');
  }
}

export function* fetchUsersByPhoneOrNameActionWatcher() {
  yield takeLatest(actions.SEARCH_USERS_WATCHER, fetchUsersByPhoneOrNameActionEffect)
}

// ########### Create offline proposal #########################################

function createOfflineProposal(param) {
  const url = createUrl(urls.BASE_URL + urls.product.OFFLINE_SALES) + urls.product.CREATE_PROPOSAL
  console.log(url)
  return axios.request({
    method: 'post',
    url: url,
    headers: {
      'Content-Type': 'application/json',
      // userId: param.userId
    },
    data: param
  })
}

export function* createOfflineProposalActionEffect(params) {
  const { payload, resolve, reject } = params

  try {
    const { data } = yield call(createOfflineProposal, payload)
    if (!isEmpty(data)) {
      console.log('offline proposal Created')
      resolve(data)
    } else {
      reject(data)
    }
  } catch (e) {
    console.log('Error found while fetching dsa')
    reject(e)
  }
}

export function* createOfflineProposalActionWatcher() {
  yield takeLatest(actions.CREATE_OFFLINE_PROPOSAL_WATCHER, createOfflineProposalActionEffect)
}

// ########### Get all proposals #################################################

function fetchAllProposal() {
  return axios.request({
    method: 'get',
    url: createUrl(urls.BASE_URL + urls.product.PROPOSALS),
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

// Saga function that handles the side effect when the loginActionWatcher is triggered
export function* fetchAllProposalActionEffect() {
  // let { resolve, reject } = posAction;

  try {
    const { data } = yield call(fetchAllProposal)

    if (!isEmpty(data)) {
      console.log('proposal fetched')

      yield put(proposalList(data.result))
    } else {
      // reject(data);
    }
  } catch (e) {
    //   alert.error(e.message || 'Authentication Error');
    //   yield put(authError(e));
    console.log('Error found while fetching pos')
    // if (reject) reject(e);
  }
}

export function* fetchAllProposalActionWatcher() {
  yield takeLatest(actions.FETCH_ALL_PROPOSAL_WATCHER, fetchAllProposalActionEffect)
}

// ######## Fetch all offline sales #####################################################

function fetchAllOfflineSales() {
  return axios.request({
    method: 'get',
    url: createUrl(urls.BASE_URL + urls.product.OFFLINE_SALES),
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function* fetchAllOfflineSalesActionEffect() {
  try {
    const { data } = yield call(fetchAllOfflineSales)

    if (!isEmpty(data)) {
      console.log('all offline sales fetched')

      yield put(offlineSalesList(data))
    } else {
      // reject(data);
    }
  } catch (e) {
    //   alert.error(e.message || 'Authentication Error');
    //   yield put(authError(e));
    console.log('Error found while fetching pos')
    // if (reject) reject(e);
  }
}

export function* fetchAllOfflineSalesActionWatcher() {
  yield takeLatest(actions.FETCH_ALL_OFFLINE_SALES_WATCHER, fetchAllOfflineSalesActionEffect)
}

//####### Get proposal Details ################################################################

function fetchProposalDetails(payload) {
  const { proposalId = '', insuranceType = '' } = payload;
  let url = createUrl(urls.BASE_URL);
  // switch (insuranceType.toLowerCase()) {
  //   case 'motor_4w':
  //   case 'motor_2w':
      // url = url + urls.product.MOTOR_PROPOSAL + `/${proposalId}`;
    //   break;
    // default:
      // url = url + urls.product.PROPOSALS + `/${proposalId}`
  // }
  return axios.request({
    method: 'POST', 
    url: url+="/proposal/policiesdetail",
    data: {
      "proposalId": [proposalId],
      "startDate":"",
      "endDate":"",
      "proposalStatus": "success"
      },
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function* fetchProposalDetailsActionEffect(params) {
  const { payload, resolve, reject } = params
  try {
    const { data } = yield call(fetchProposalDetails, payload)

    if (data && !isEmpty(data.policyDetails)) {
      console.log('policy details fetched')

      yield put(proposalDetails(data.policyDetails[0]))
      resolve(data.policyDetails[0])
    } else {
      yield put(proposalDetails({}))
      reject(data);
    }
  } catch (e) {
    //   alert.error(e.message || 'Authentication Error');
    //   yield put(authError(e));
    console.log('Error found while fetching policy details')
    yield put(proposalDetails({}))
    reject(e)
    // if (reject) reject(e);
  }
}

export function* fetchProposalDetailsActionWatcher() {
  yield takeLatest(actions.FETCH_PROPOSAL_DETAILS_WATCHER, fetchProposalDetailsActionEffect)
}

export default function* rootSaga() {
  yield all([
    fork(fetchInsuranceTypeActionWatcher),
    fork(fetchInsuranceCompanyByTypeActionWatcher),
    fork(fetchAllProductByCompanyByPolicyTypeActionWatcher),
    fork(fetchInitialTitleActionWatcher),
    fork(fetchAllOccupationDetailsActionWatcher),
    fork(fetchAllIdProofInfoActionWatcher),
    fork(fetchGstTypeInfoActionWatcher),
    fork(fetchRelationsInfoActionWatcher),
    fork(fetchMaritalStatusInfoActionWatcher),
    fork(createOfflineProposalActionWatcher),
    fork(fetchAllProposalActionWatcher),
    fork(fetchAllOfflineSalesActionWatcher),
    fork(fetchPreviousInsurerActionWatcher),
    fork(fetchProposalDetailsActionWatcher),
    fork(fetchUserRolesActionWatcher),
    fork(fetchBQPUserByPhoneOrNameActionWatcher),
    fork(fetchUsersByPhoneOrNameActionWatcher)
  ])
}
import React from 'react'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { connect } from 'react-redux'
import {
    Formik, Form, Field, ErrorMessage,
} from 'formik';
import { renderDatePicker } from 'components/ReduxComponents/reduxComponents';

import * as Yup from 'yup';

function VehicleForm(props) {
    const { classes,
        vehicleForm: {
            isVehicleNew = '',
            licenseNumber = '',
            manufactureDate = null,
            registrationDate = null,
            vehicleChassisNumber = '',
            engineNumber = '',
            isPreviousInsurerKnown = '',
            previousInsurerName = '',
            previousPolicyNumber = '',
            policyExpiryDate = null,
            isPreviousInsuranceClaimed = '',
            previousPolicyType = '',
            ncb = ''
        } = {},
        handleSubmit = () => { }, bindSubmitForm,
        setHeaderName, renderSelect, renderInput, previousInsurerList = [] } = props;

    React.useEffect(() => { setHeaderName('Vehicle Details') }, []);

    const vehicleNewOption = [{ label: 'NO', value: 'No' },
    { label: 'YES', value: 'Yes' }];

    const policyTypeOption = [{ label: 'Comprehensive', value: 'comprehensive' },
    { label: 'Third Party', value: 'thirdparty' },
    { label: 'Own Damage', value: 'owndamage' }]


    const getPreviousInsuranceOptions = () => {
        const previousInsuranceOptions = []
        previousInsurerList.forEach(val => {
            previousInsuranceOptions.push({ value: val, label: val })
        })
        return previousInsuranceOptions
    }

    const initialValues = {
        isVehicleNew,
        licenseNumber,
        manufactureDate,
        registrationDate,
        vehicleChassisNumber,
        engineNumber,
        isPreviousInsurerKnown,
        previousInsurerName,
        previousPolicyNumber,
        policyExpiryDate,
        isPreviousInsuranceClaimed,
        previousPolicyType,
        ncb
    }

    const validationSchema = Yup.object().shape({
        isVehicleNew: Yup.string().required('Required'),
        licenseNumber: Yup.string().required('Required'),
        manufactureDate: Yup.date().nullable(),
        registrationDate: Yup.date().required('Required').nullable(),
        vehicleChassisNumber: Yup.string(),
        engineNumber: Yup.string(),
        isPreviousInsurerKnown: Yup.string().when('isVehicleNew', {
            is: 'No',
            then: Yup.string().required('Required'),
            otherwise: Yup.string()
        }),
        previousInsurerName: Yup.string().when('isPreviousInsurerKnown', {
            is: 'Yes',
            then: Yup.string().required('Required'),
            otherwise: Yup.string()
        }),
        previousPolicyNumber: Yup.string(),
        policyExpiryDate: Yup.date().when('isPreviousInsurerKnown', {
            is: 'Yes',
            then: Yup.date().nullable().required('Required'),
            otherwise: Yup.date().nullable()
        }),
        isPreviousInsuranceClaimed: Yup.string().when('isVehicleNew', {
            is: 'No',
            then: Yup.string().required('Required'),
            otherwise: Yup.string()
        }),
        previousPolicyType: Yup.string().when('isVehicleNew', {
            is: 'No',
            then: Yup.string().required('Required'),
            otherwise: Yup.string()
        }),
        ncb: Yup.string().when('isPreviousInsuranceClaimed', {
            is: 'No',
            then: Yup.string().required('Required'),
            otherwise: Yup.string()
        })
    })

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(e) => handleSubmit(e, 'vehicleForm')}
            validationSchema={validationSchema} >
            {
                (formProps) => {
                    const {
                        values,
                        touched,
                        errors,
                        dirty,
                        isSubmitting,
                        isValid,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        handleReset,
                        setFieldValue
                    } = formProps;
                    bindSubmitForm(handleSubmit)
                    return (
                        <form onSubmit={handleSubmit}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    {renderSelect({
                                        name: "isVehicleNew", label: "Is It a New Vehicle?", options: vehicleNewOption, touched, errors,
                                        handleChange: handleChange('isVehicleNew'), values, handleBlur, isRequired: true
                                    })}
                                </GridItem>

                                <GridItem xs={12} sm={12} md={3}>
                                    {
                                        renderInput({
                                            label: "License Plate Number", name: "licenseNumber",
                                            isRequired: true, touched, errors, handleChange, handleBlur, values
                                        })
                                    }
                                </GridItem>

                                <GridItem xs={12} sm={6} md={3}>
                                    <Field name="manufactureDate" component={renderDatePicker} format="dd/MM/yyyy" label="Manufacturing Date" />
                                </GridItem>

                                <GridItem xs={12} sm={6} md={3}>
                                    <Field name="registrationDate" component={renderDatePicker} format="dd/MM/yyyy" label="Registration Date" isRequired />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={4}>
                                    {
                                        renderInput({
                                            label: "Chasis Number", name: "vehicleChassisNumber",
                                            isRequired: false, touched, errors, handleChange, handleBlur, values
                                        })
                                    }
                                </GridItem>

                                <GridItem xs={12} sm={12} md={4}>
                                    {
                                        renderInput({
                                            label: "Engine Number", name: "engineNumber",
                                            isRequired: false, touched, errors, handleChange, handleBlur, values
                                        })
                                    }
                                </GridItem>

                                {values.isVehicleNew === 'No' && <> <GridItem xs={12} sm={12} md={4}>
                                    {renderSelect({
                                        name: "isPreviousInsurerKnown", label: "Do you know your Previous Insurer?", options: vehicleNewOption, touched, errors,
                                        handleChange: handleChange('isPreviousInsurerKnown'), values, handleBlur, isRequired: true
                                    })}
                                </GridItem>

                                    <GridItem xs={12} sm={12} md={12}>
                                        {renderSelect({
                                            name: "previousInsurerName", label: "Previous Insurer Name", options: getPreviousInsuranceOptions(), touched, errors,
                                            handleChange: handleChange('previousInsurerName'), values, handleBlur, isRequired: true
                                        })}
                                    </GridItem>


                                    <GridItem xs={12} sm={12} md={3}>
                                        {
                                            renderInput({
                                                label: "Previous Policy Number", name: "previousPolicyNumber",
                                                isRequired: false, touched, errors, handleChange, handleBlur, values
                                            })
                                        }
                                    </GridItem>

                                    <GridItem xs={12} sm={6} md={3}>
                                        <Field name="policyExpiryDate" component={renderDatePicker} format="dd/MM/yyyy" label="Previous Policy Expiry Date" />
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={3}>
                                        {renderSelect({
                                            name: "isPreviousInsuranceClaimed", label: "Did you file claim last year?", options: vehicleNewOption, touched, errors,
                                            handleChange: handleChange('isPreviousInsuranceClaimed'), values, handleBlur, isRequired: true
                                        })}
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={3}>
                                        {renderSelect({
                                            name: "previousPolicyType", label: "Policy type in previous policy", options: policyTypeOption, touched, errors,
                                            handleChange: handleChange('previousPolicyType'), values, handleBlur, isRequired: true
                                        })}
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={4}>
                                        {
                                            renderInput({
                                                label: "No Claim Bonus on Previous Insurance", name: "ncb",
                                                isRequired: true, touched, errors, handleChange, handleBlur, values,
                                                disabled: values.isPreviousInsuranceClaimed === 'Yes'
                                            })
                                        }
                                    </GridItem> </>}


                            </GridContainer>
                        </form>
                    )
                }
            }
        </Formik>
    );
}

function mapStateToProps(state) {
    const { productReducer } = state
    return {
        previousInsurerList: productReducer.previousInsurerList

    }
}
export default connect(
    mapStateToProps,
    null
)(VehicleForm)
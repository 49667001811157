import React, { useEffect, useState, useLayoutEffect } from "react";
import Admin from "Pages/DashBoard/Admin";
import styles from "./DashboardAnalyticsStyle";
import config from "../../config";
// const embedUrl = "https://app.powerbi.com/view?r=eyJrIjoiZDc5YTYyNTAtZjRhMi00YjgyLThhMzEtYjQ5NTQ2ZmViZGQ5IiwidCI6ImMxZDk2MjZiLTNjMGItNGQyZC05YmQ0LWEyNTIwZTBiZWFiOSJ9&pageName=ReportSection";
const embedUrl = config.api.DASHBOARD_URL || "";
function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

const DashBoardBI = () => {
  const classes = styles();
  const [clipHeight, setClipHeight] = useState(600);

  useEffect(() => {
    const iframeEl = document.getElementById("iframe");
    setClipHeight(iframeEl.clientHeight - 40);
  }, [useWindowSize()]);

  return (
    <Admin>
      <div className={classes.iframeContainer}>
        <iframe
          id="iframe"
          src={embedUrl}
          frameborder="0"
          style={{
            overflow: "hidden",
            // "overflow-x":"hidden",
            // "overflow-y":"scroll",
            height: "104.5%",
            width: "100%",
            position: "absolute",
            top: "0%",
            left: "0px",
            right: "0px",
            bottom: "0px",
            marginBottom: "200px",
            clip: `rect(0px,1366px,${clipHeight}px,0px)`,
          }}
          height="100%"
          width="100%"
        ></iframe>
      </div>
    </Admin>
  );
};

export default DashBoardBI;

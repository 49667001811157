import React,{Fragment} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
// core components
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import styles from "../../assets/jss/componentsStyles/customTabsStyle.js";
import { AppBar } from "@material-ui/core";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

const useStyles = makeStyles(styles);

export default function CustomTabs(props) {

  const [value, setValue] = React.useState(0);
  const handleChange = (event, value) => {
    setValue(value);
  };
  const classes = useStyles();
  const { headerColor, tabs, title, rtlActive, classHeader } = props;
  const cardTitle = classNames({
    [classes.cardTitle]: true,
    [classes.cardTitleRTL]: rtlActive
  });
  const cardHeader = classNames({
    [classes.cardHeader]: true,
    [classes[headerColor + "CardHeader"]]: headerColor,
    [classHeader]: classHeader
  });
  return (
    // <Card plain={plainTabs}>
    <Fragment>
      <AppBar color={headerColor}  className={cardHeader} position="static">
        {title !== undefined ? <div className={cardTitle}>{title}</div> : null}
        <Tabs
          value={value}
          onChange={handleChange}
          classes={{
            root: classes.tabsRoot,
            indicator: classes.displayNone,
            scrollButtons: classes.displayNone
          }}
          variant="fullWidth"
          scrollButtons="auto"
          // variant="fullWidth"
        >
          {tabs.map((prop, key) => {
            var icon = {};
            if (prop.tabIcon) {
              icon = {
                icon: <prop.tabIcon />
              };
            }
            return (
              <Tab
                classes={{
                  root: classes.tabRootButton,
                  selected: classes.tabSelected,
                  wrapper: classes.tabWrapper
                }}
                key={key}
                label={prop.tabName}
                {...icon}
              />
            );
          })}
        </Tabs>
      </AppBar>
       {/* <CardBody> */}
      {/* <Box>
        {tabs.map((prop, key) => {
          if (key === value) {
            return (
            <div key={key}>{prop.tabContent}</div>);
          }
          return null;
        })}
        </Box> */}
         {tabs.map((prop, key) => {
          if (key === value) {
            return (
              <TabPanel value={value} index={key}>
               {prop.tabContent}
            </TabPanel>);
           
          }
          return null;
        })}
      {/* </CardBody> */}
    {/* </Card> */}
    </Fragment>
  );
}

CustomTabs.propTypes = {
  headerColor: PropTypes.oneOf([
    "warning",
    "success",
    "danger",
    "info",
    "primary",
    "rose"
  ]),
  title: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabName: PropTypes.string.isRequired,
      tabIcon: PropTypes.object,
      tabContent: PropTypes.node.isRequired
    })
  ),
  rtlActive: PropTypes.bool,
  plainTabs: PropTypes.bool,
  centered:PropTypes.bool
};

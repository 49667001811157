import React from "react";

import Admin from "Pages/DashBoard/Admin";
import InspectionsList from "./List";

const Inspections = () => {
  return (
    <Admin>
      <InspectionsList />
    </Admin>
  );
};

export default Inspections;

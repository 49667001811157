import * as React from "react";
import Admin from '../DashBoard/Admin';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { fetchPolicySoldWatcher } from "../../store/actions/posAction";
import { fetchPoliciesDetailsWatcher, posList } from "store/actions/posAction";
import PolicySoldList from './PolicySoldList';
import { isEmpty } from "../../utils/common";
import moment from 'moment'

class PolicySoldPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      startDate: this.props.location.state? this.props.location.state.startDate : "",
      endDate: this.props.location.state? this.props.location.state.endDate : "",
      proposalType: this.props.location.state? this.props.location.state.proposalType : "",
    } 

  }

  getThisMonthRange = () => {
    const format = "DD-MM-YYYY"; 
    let fromDate = moment(Number(new Date( ).getTime().toString())).format(format).split("-");
    fromDate = `01-${fromDate[1]}-${fromDate[2]}`
    const toDate = moment(Number(new Date().getTime().toString())).format(format);
    return {fromDate, toDate}
  }

  componentWillMount() { 
      
    const {fromDate, toDate} = this.getThisMonthRange();
    const {startDate, endDate, proposalType="all"} = this.state;
    // if(!this.props.policySoldList){
      this.props.fetchPoliciesDetailsWatcher({
        "fromDate":startDate||fromDate,
        "toDate":endDate||toDate,
        proposalIds:[ ],
        proposalStatus:"success",
        isTable:true,
        proposalType
      }) 
    // }
    
  }

  

  getDetails = (event, {proposalId, insuranceType}) => {
    event.preventDefault();
    this.props.history.push({ pathname: `/policy-details/${insuranceType.split(" ").join("").toLowerCase()}/${proposalId}`, state: { proposalId, insuranceType } })
  }

  fetchDataByRange = (startDate, endDate, proposalType) => {
    const {fromDate, toDate} = this.getThisMonthRange();

    this.setState({
      startDate:startDate||fromDate,
      endDate: endDate || toDate,
      proposalType
    },()=>{
      window.history.replaceState({ key: this.props.history.location.key, state: this.state}, "test")
    })

    this.props.fetchPoliciesDetailsWatcher({
      fromDate:startDate||fromDate,
      toDate: endDate || toDate,
      proposalIds:[ ],
      proposalStatus:"success",
      isTable:true,
      proposalType
    })
  }

  render() { 
    const { policySoldList = {} } = this.props;
    const {policiesSold = []} = policySoldList; 
    const {startDate, endDate, proposalType} = this.state;
    return (
      <Admin> 
         <PolicySoldList
          policySoldList={policiesSold}
          getDetails={this.getDetails}
          fetchDataByRange = {this.fetchDataByRange}
          startDate={startDate}
          endDate={endDate}
          proposalType={proposalType}
         />
      </Admin>
      
    );
  }
}
function mapStateToProps(state) {
  const { posReducer } = state;
  return {
    policySoldList: posReducer.policySoldList

  };
}
// mapping dispatch functions to the props of LoginForm component
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchPolicySoldWatcher,
      fetchPoliciesDetailsWatcher
      // add other watcher sagas to this object to map them to props
    },
    dispatch
  )
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PolicySoldPage);
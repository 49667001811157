import React from 'react'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import SelectOption from 'components/CustomSelect/SelectOption'
import CustomInput from 'components/CustomInput/CustomInput'
import { sortBy } from 'utils/common'
import * as CONSTANT from 'utils/constants'
import CustomDatePicker from 'components/DatePicker/CustomDatePicker'
import {
    Formik, Form, Field, ErrorMessage,
} from 'formik';
import { renderDatePicker } from 'components/ReduxComponents/reduxComponents';

import { isEmpty } from 'utils/common';
import * as Yup from 'yup';

export default function ProposerForm(props) {
    const { classes, idProofList = [],
        proposerForm: {
            title= '',
            firstName= '',
            middleName= '',
            lastName= '',
            dob= null,
            email= '',
            phone= '',
            gender= '',
            maritalStatus= '',
            occupation= '',
            idProofType= '',
            idProofNumber= '',
            gstType= '',
            GSTIN= '',
            nationality= '',
            communicationFlatNumber= '',
            communicationStreetNumber= '',
            communicationStreetName= '',
            communicationCity= '',
            communicationDistrict= '',
            communicationState= '',
            communicationPincode= '',
            communicationCountry= ''
        }={},
        handleSubmit = () => { }, bindSubmitForm, titleList = [], maritalStatusList = [],
        occupationList = [], insuranceType = '', gstTypeList = [], renderSelect, renderInput, setHeaderName } = props;

        React.useEffect(() => {setHeaderName('Proposer Details')}, [])

    const getTitleOptions = () => {
        const titleOptions = []
        titleList.map(title => titleOptions.push({ value: title.name, label: title.name }))
        return titleOptions
    }

    const getMaritalStatusOptions = () => {
        const martialStatusOptions = []
        maritalStatusList.map(maritalStatus => martialStatusOptions.push({ value: maritalStatus.name, label: maritalStatus.name }))
        return martialStatusOptions
    }

    const getIdProofOptions = () => {
        const idProofOptions = []
        idProofList.map(idProof => idProofOptions.push({ value: idProof.name, label: idProof.name }))
        return idProofOptions
    }

    const getOccupationOptions = () => {
        const occupationOptions = []
        occupationList.map(occupation => occupationOptions.push({ value: occupation.name, label: occupation.name }))
        return occupationOptions
    }

    const getGstTypeOptions = () => {
        const gstTypeOptions = [{ value: null, label: 'None' }]
        gstTypeList.map(gst => gstTypeOptions.push({ value: gst.name, label: gst.name }))
        return gstTypeOptions
    }

    const genderOptions = [{ value: 'male', label: 'Male' },
    { value: 'feMale', label: 'Female' },
    { value: 'others', label: 'Others' }];

    const nationalityOptions = [{ value: 'Indian', label: 'Indian' },
    { value: 'Foreigner', label: 'Foreigner' }]

    const initialValues = {
        title,
        firstName,
        middleName,
        lastName,
        dob,
        email,
        phone,
        gender,
        maritalStatus,
        occupation,
        idProofType,
        idProofNumber,
        gstType,
        GSTIN,
        nationality,
        communicationFlatNumber,
        communicationStreetNumber,
        communicationStreetName,
        communicationCity,
        communicationDistrict,
        communicationState,
        communicationPincode,
        communicationCountry

    };
    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Required'),
        firstName: Yup.string().required('Required'),
        middleName: Yup.string(),
        lastName: Yup.string().required('Required'),
        dob: Yup.date().required('Required').nullable(),
        email: Yup.string().email(),
        phone: Yup.string().required('Requried').length(10),
        gender: Yup.string().required('Required'),
        maritalStatus: Yup.string().test(
            'not empty',
            'Required',
            (value) => {
                if (insuranceType === 'HEALTH' && isEmpty(value))
                    return false;
                else return true
            }),

        occupation: Yup.string(),
        idProofType: Yup.string().test(
            'not empty',
            'Required',
            (value) => {
                if ((insuranceType === 'HEALTH' || insuranceType === 'TERM') && isEmpty(value))
                    return false;
                else return true
            }),
        // idProofType: Yup.string().when(insuranceType, {
        //     is: 'HEALTH',
        //     then: Yup.string().required('Required'),
        //     otherwise: Yup.string()
        // }).when(insuranceType, {
        //     is: 'TERM',
        //     then: Yup.string().required('Requried'),
        //     otherwise: Yup.string()
        // }),
        idProofNumber:  Yup.string().test(
            'not empty',
            'Required',
            (value) => {
                if ((insuranceType === 'HEALTH' || insuranceType === 'TERM') && isEmpty(value))
                    return false;
                else return true
            }),
        // idProofNumber: Yup.string().when(insuranceType, {
        //     is: 'HEALTH',
        //     then: Yup.string().required('Required'),
        //     otherwise: Yup.string()
        // }).when(insuranceType, {
        //     is: 'TERM',
        //     then: Yup.string().required('Requried'),
        //     otherwise: Yup.string()
        // }),
        gstType: Yup.string(),
        GSTIN: Yup.string(),
        nationality:  Yup.string().test(
            'not empty',
            'Required',
            (value) => {
                if ( insuranceType === 'TERM' && isEmpty(value))
                    return false;
                else return true
            }),
        // nationality: Yup.string().when(insuranceType, {
        //     is: 'TERM',
        //     then: Yup.string().required('Requried'),
        //     otherwise: Yup.string()
        // }),
        communicationFlatNumber: Yup.string().test(
            'not empty',
            'Required',
            (value) => {
                if ( insuranceType !== 'HEALTH' && isEmpty(value))
                    return false;
                else return true
            }),
        // flatNumber: Yup.string().when(insuranceType, {
        //     is: 'HEALTH',
        //     then: Yup.string(),
        //     otherwise: Yup.string().required('Requried')
        // }),
        communicationStreetNumber: Yup.string().test(
            'not empty',
            'Required',
            (value) => {
                if ( insuranceType !== 'HEALTH' && isEmpty(value))
                    return false;
                else return true
            }),
        // streetNumber: Yup.string().when(insuranceType, {
        //     is: 'HEALTH',
        //     then: Yup.string(),
        //     otherwise: Yup.string().required('Requried')
        // }),
        communicationStreetName: Yup.string(),
        communicationCity: Yup.string().test(
            'not empty',
            'Required',
            (value) => {
                if ( insuranceType !== 'HEALTH' && isEmpty(value))
                    return false;
                else return true
            }),
        // city: Yup.string().when(insuranceType, {
        //     is: 'HEALTH',
        //     then: Yup.string(),
        //     otherwise: Yup.string().required('Requried')
        // }),
        communicationDistrict: Yup.string().test(
            'not empty',
            'Required',
            (value) => {
                if ( insuranceType !== 'HEALTH' && isEmpty(value))
                    return false;
                else return true
            }),
        // district: Yup.string().when(insuranceType, {
        //     is: 'HEALTH',
        //     then: Yup.string(),
        //     otherwise: Yup.string().required('Requried')
        // }),
        communicationState: Yup.string().test(
            'not empty',
            'Required',
            (value) => {
                if ( insuranceType !== 'HEALTH' && isEmpty(value))
                    return false;
                else return true
            }),
        // state: Yup.string().when(insuranceType, {
        //     is: 'HEALTH',
        //     then: Yup.string(),
        //     otherwise: Yup.string().required('Requried')
        // }),
        communicationCountry: Yup.string().test(
            'not empty',
            'Required',
            (value) => {
                if ( insuranceType !== 'HEALTH' && isEmpty(value))
                    return false;
                else return true
            }),
        // country: Yup.string().when(insuranceType, {
        //     is: 'HEALTH',
        //     then: Yup.string(),
        //     otherwise: Yup.string().required('Requried')
        // }),
        communicationPincode: Yup.string().test(
            'not empty',
            'Required',
            (value) => {
                if ( insuranceType !== 'HEALTH' && isEmpty(value))
                    return false;
                else return true
            }),
        // pincode: Yup.string().when(insuranceType, {
        //     is: 'HEALTH',
        //     then: Yup.string(),
        //     otherwise: Yup.string().required('Requried')
        // }),

    })

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(e) => handleSubmit(e, 'proposerForm')}
            validationSchema={validationSchema} >
            {
                (formProps) => {
                    const {
                        values,
                        touched,
                        errors,
                        dirty,
                        isSubmitting,
                        isValid,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        handleReset,
                        setFieldValue
                    } = formProps;
                    bindSubmitForm(handleSubmit)
                    return (
                        <form onSubmit={handleSubmit}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={2}>
                                    {renderSelect({
                                        name: "title", label: "Title", options: getTitleOptions(), touched, errors,
                                        handleChange: handleChange('title'), values, handleBlur, isRequired: true
                                    })}
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    {
                                        renderInput({
                                            label: "First Name", name: "firstName",
                                            isRequired: true, touched, errors, handleChange, handleBlur, values
                                        })
                                    }
                                </GridItem>

                                <GridItem xs={12} sm={12} md={3}>
                                    {
                                        renderInput({
                                            label: "Middle Name", name: "middleName",
                                            isRequired: false, touched, errors, handleChange, handleBlur, values
                                        })
                                    }
                                </GridItem>

                                <GridItem xs={12} sm={12} md={3}>
                                    {
                                        renderInput({
                                            label: "Last Name", name: "lastName",
                                            isRequired: true, touched, errors, handleChange, handleBlur, values
                                        })
                                    }
                                </GridItem>

                                <GridItem xs={12} sm={6} md={3}>
                                    <Field name="dob" component={renderDatePicker} format="dd/MM/yyyy" label="Date of Birth" isRequired />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={5}>
                                    {
                                        renderInput({
                                            label: "Email", name: "email",
                                            isRequired: false, touched, errors, handleChange, handleBlur, values
                                        })
                                    }
                                </GridItem>

                                <GridItem xs={12} sm={12} md={4}>
                                    {
                                        renderInput({
                                            label: "Phone Number", name: "phone",
                                            isRequired: true, touched, errors, handleChange, handleBlur, values, type: 'number'
                                        })
                                    }
                                </GridItem>

                                <GridItem xs={12} sm={12} md={2}>
                                    {renderSelect({
                                        name: "gender", label: "Gender", options: genderOptions, touched, errors,
                                        handleChange: handleChange('gender'), values, handleBlur, isRequired: true
                                    })}
                                </GridItem>

                                {insuranceType === 'HEALTH' && <GridItem xs={12} sm={12} md={2}>
                                    {renderSelect({
                                        name: "maritalStatus", label: "Marital Status", options: getMaritalStatusOptions(), touched, errors,
                                        handleChange: handleChange('maritalStatus'), values, handleBlur, isRequired: true
                                    })}
                                </GridItem>}

                                {insuranceType === 'HEALTH' && <GridItem xs={12} sm={12} md={5}>
                                    {renderSelect({
                                        name: "occupation", label: "Occupation", options: getOccupationOptions(), touched, errors,
                                        handleChange: handleChange('occupation'), values, handleBlur, isRequired: false
                                    })}
                                </GridItem>}

                                {(insuranceType === 'HEALTH' || insuranceType === 'TERM') && <GridItem xs={12} sm={12} md={3}>
                                    {renderSelect({
                                        name: "idProofType", label: "Id Proof Type", options: getIdProofOptions(), touched, errors,
                                        handleChange: handleChange('idProofType'), values, handleBlur, isRequired: true
                                    })}

                                </GridItem>}

                                {(insuranceType === 'HEALTH' || insuranceType === 'TERM') && <GridItem xs={12} sm={12} md={3}>
                                    {
                                        renderInput({
                                            label: "Id Proof Number", name: "idProofNumber",
                                            isRequired: true, touched, errors, handleChange, handleBlur, values
                                        })
                                    }

                                </GridItem>}
                                {insuranceType !== 'HEALTH' &&
                                    [{ label: 'Flat Number', name: 'communicationFlatNumber', isRequired: true },
                                    { label: 'Street Number', name: 'communicationStreetNumber', isRequired: true },
                                    { label: 'Street Name', name: 'communicationStreetName', isRequired: false },
                                    { label: 'City', name: 'communicationCity', isRequired: true },
                                    { label: 'District', name: 'communicationDistrict', isRequired: true },
                                    { label: 'State', name: 'communicationState', isRequired: true },
                                    { label: 'Pincode', name: 'communicationPincode', isRequired: true },
                                    { label: 'Country', name: 'communicationCountry', isRequired: true }].map(val => (
                                        <GridItem xs={12} sm={12} md={3}>
                                            {
                                                renderInput({
                                                    label: val.label, name: val.name,
                                                    isRequired: val.isRequired, touched, errors, handleChange, handleBlur, values
                                                })
                                            }

                                        </GridItem>
                                    ))
                                }
                                {/* {(insuranceType !== 'HEALTH') && <GridItem xs={12} sm={12} md={3}>
                                    {
                                        renderInput({
                                            label: "Id Proof Number", name: "idProofNumber",
                                            isRequired: true, touched, errors, handleChange, handleBlur, values
                                        })
                                    }

                                </GridItem>} */}


                                {(insuranceType === 'HEALTH') && <GridItem xs={12} sm={12} md={3}>
                                    {renderSelect({
                                        name: "gstType", label: "GST Type", options: getGstTypeOptions(), touched, errors,
                                        handleChange: handleChange('gstType'), values, handleBlur, isRequired: false
                                    })}

                                </GridItem>}

                                {(insuranceType === 'HEALTH') && <GridItem xs={12} sm={12} md={3}>
                                    {
                                        renderInput({
                                            label: "GSTIN Number", name: "GSTIN",
                                            isRequired: false, touched, errors, handleChange, handleBlur, values
                                        })
                                    }

                                </GridItem>}

                                {(insuranceType === 'TERM') && <GridItem xs={12} sm={12} md={3}>
                                    {renderSelect({
                                        name: "nationality", label: "Nationality", options: nationalityOptions, touched, errors,
                                        handleChange: handleChange('nationality'), values, handleBlur, isRequired: false
                                    })}

                                </GridItem>}

                            </GridContainer>
                        </form>
                    );
                }
            }
        </Formik>
    );
}

import {
    blackColor,
    hexToRgb
  } from "../material-dashbord-react";
  
  const stepperStyle = (theme => ({
    root: {
      width: '100%',
      
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      // float: "left",
    },
    cardRaised: {
      boxShadow:
        "0 16px 38px -12px rgba(" +
        hexToRgb(blackColor) +
        ", 0.56), 0 4px 25px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(blackColor) +
        ", 0.2)"
    },
  }));
  
  export default stepperStyle;
  
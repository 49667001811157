export const styles = {
    roleName: {
        textAlign: 'center',
        fontWeight: "bold",
        fontSize: 16
    },
    configText: {
        float: "left",
        padding: "5px 12px",
        background: "#efeaea",
        borderRadius: 30,
        margin: "10px 0px",
        cursor: "pointer",
        "&:hover": {
            textDecoration: "underline"
        }
    },
    permissionCount: {
        float: "right",
        padding: "5px 13px",
        background: "linear-gradient(  60deg , #ab47bc, #8e24aa)",
        borderRadius: 30,
        margin: "10px 0px",
        color: "#fff",
        fontWeight: "bold"
    },
    pc: {
        padding: 5
    }
}
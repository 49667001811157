import * as React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { withStyles } from '@material-ui/core/styles';
import styles from './PageStyle';
import Admin from '../DashBoard/Admin';
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import { isEmpty } from "utils/common";
import CardHeader from "components/Card/CardHeader";
import Button from 'components/CustomButtons/Button';
import ErrorIcon from '@material-ui/icons/Error'
import EditIcon from '@material-ui/icons/Edit';
import { updateAlertMsg } from 'store/actions/commonAction';
import { createPayoutFrequency, fetchPayoutFrequency } from 'store/actions/payoutFrequencyAction';
import PayoutFrequencyForm from './PayoutFrequencyForm';
import PayoutFrequencyDisplay from './PayoutFrequencyDisplay';
import ClearIcon from '@material-ui/icons/Clear';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';

class PayoutFrequencyPage extends React.Component {
  constructor(props) {
    super(props);
    props.fetchPayoutFrequency()
    this.state = {
      formErrors: {},
      frequencyType: '',
      
      isEdit: false,
      
    }

  }

  // static getDerivedStateFromProps(props, current_state) {
  //   if((current_state.isDataPresent !== props.isDataPresent) && !current_state.isUpdatedOnce) {
  //     return{
        
  //       isUpdatedOnce: true
  //     }
  //   }
  //   return null;
  // }

  isError = (obj) => {

    const { formErrors = {} } = this.state;

    return !isEmpty(formErrors[obj])
  }
  updateKeyValueInState = (value, obj) => {
    let { formErrors = {} } = this.state;
    delete formErrors[obj];

    this.setState({
      [obj]: value,
      
      formErrors
    })
  }
  cancelSubmit =() => {
    this.props.fetchPayoutFrequency()
    this.setState({
      isEdit: false
    })
  }
  editData =() => {
    console.log("inside edit data method")
    const {globalPayoutFrequency:{frequencyType=null}} = this.props;

    this.setState({
      isEdit: true,
      frequencyType,
      
    })
  }

  validateInput = (value, obj) => {

    const { formErrors = {} } = this.state;
    isEmpty(value) && (formErrors[obj] = "This is mandatory field");
    this.setState({
      formErrors: { ...formErrors },
    });
  }



  submitForm = () => {
    debugger;
    const { formErrors, frequencyType } = this.state;
    const { updateAlertMsg, createPayoutFrequency } = this.props
    if (isEmpty(formErrors)) {
 
        new Promise((resolve, reject) => {
          createPayoutFrequency({ frequencyType }, resolve, reject);
        }).then(val => {
          debugger;
          console.log('payout updated successfully', val);
          this.cancelSubmit();
        })
      
    }



  }

  render() {
    const { classes,globalPayoutFrequency } = this.props;
    const {submitForm, validateInput, isError, updateKeyValueInState} = this;
    const methods = {submitForm, validateInput, isError, updateKeyValueInState}

    return (
      <Admin>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={7}>
            <Card>
              <CardHeader   
                color="primary"
              >
                <span style={{ float: "left" }}>
                <h4 className={classes.cardTitleWhite}>Payout Frequency</h4>
                </span>
                {/* <CardText color="primary"> */}
             
            {/* </CardText> */}
            {
              !this.state.isEdit ?   <span  style={{ float: "right" }}>
              <Button simple color="white" className={classes.paddingStyle}  onClick={this.editData} >  <EditIcon /></Button>
            </span> :  <span  style={{ float: "right" }}>
            <Button simple color="white" className={classes.paddingStyle}  onClick={this.cancelSubmit} >  <ClearIcon /></Button>
            <Button simple color="white" className={classes.paddingStyle}  onClick={this.submitForm} >  <SaveOutlinedIcon /></Button>

          </span>

            }
          
         
              </CardHeader>
              <CardBody profile>
      {!this.state.isEdit ? <PayoutFrequencyDisplay 
                globalPayoutFrequency={globalPayoutFrequency}
                classes={classes}
                editData={this.editData}
                />: <PayoutFrequencyForm
                {...this.state}
                {...methods}
                {...this.props}
                />}
                
                
              </CardBody> 
            </Card>

          </GridItem>
        </GridContainer>
      </Admin>
    );
  }
}

function mapStateToProps(state) {
  const { payoutFrequencyReducer: {globalPayoutFrequency={}} = {} } = state;
  return {
    globalPayoutFrequency,
    
  };
}
// mapping dispatch functions to the props of LoginForm component
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      updateAlertMsg,
      createPayoutFrequency,
      fetchPayoutFrequency
      // add other watcher sagas to this object to map them to props
    },
    dispatch
  )
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PayoutFrequencyPage));
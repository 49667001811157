import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
  chartCard: {
    marginBottom: 15,
  },
  dialogContent: {
    width: 550,
    height: "50vh",
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
    },
  },
  appBar: {
    marginBottom: 15,
  },
  iframeContainer: {
    height: "133vh",
    position: "relative",
  },
}));

export default styles;

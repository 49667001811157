//  Login Watcher Actions
export const LOG_IN_WATCHER = 'LOG_IN_WATCHER'
export const LOG_OUT_WATCHER = 'LOG_OUT_WATCHER'
export const GET_USER_PROFILE_WATCHER = 'GET_USER_PROFILE_WATCHER'

// Login Reducer Actions
export const AUTH_ERROR = 'AUTH_ERROR'
export const CLEAR_SESSION = 'CLEAR_SESSION'

// POS Watcher Action
export const POS_LIST_WATCHER = 'POS_LIST_WATCHER'
export const POS_ONBOARDING_LIST_WATCHER = 'POS_ONBOARDING_LIST_WATCHER'
export const POS_DETAIL_WATCHER = 'POS_DETAIL_WATCHER'
export const UPDATE_EDUCATION_INFO_WATCHER = 'UPDATE_EDUCATION_INFO_WATCHER'
export const UPDATE_PERSONAL_INFO_WATCHER = 'UPDATE_PERSONAL_INFO_WATCHER'
export const UPDATE_BANK_INFO_WATCHER = 'UPDATE_BANK_INFO_WATCHER'
export const UPDATE_BUSINESS_INFO_WATCHER = 'UPDATE_BUSINESS_INFO_WATCHER'
export const UPDATE_POS_APPROVAL_STATUS_WATCHER = 'UPDATE_POS_APPROVAL_STATUS'
export const FETCH_POLICY_SOLD_WATCHER = 'FETCH_POLICY_SOLD_WATCHER'
export const UPDATE_POS_ELIGIBILITY_STATUS_WATCHER = 'UPDATE_POS_ELIGIBILITY_STATUS_WATCHER'
export const DOCUMENTS_GET_WATCHER = 'DOCUMENTS_GET_WATCHER'
export const UPDATE_CERTIFICATION_INFO_WATCHER = 'UPDATE_CERTIFICATION_INFO_WATCHER'
export const DOCUMENT_UPLOAD_WATCHER = 'DOCUMENT_UPLOAD_WATCHER'
export const APPLICATION_FORM_WATCHER = 'APPLICATION_FORM_WATCHER'
export const UPDATE_POSP_ROLE_WATCHER = 'UPDATE_POSP_ROLE_WATCHER'
export const UPDATE_POSP_SENIOR_WATCHER = 'UPDATE_POSP_SENIOR_WATCHER'
export const FETCH_POSP_EARNING_WATCHER = 'FETCH_POSP_EARNING_WATCHER'
export const UPLOAD_POSP_UTILITY_WATCHER = 'UPLOAD_POSP_UTILITY_WATCHER'
export const FETCH_USER_PERMISSIONS = 'FETCH_USER_PERMISSIONS'
export const SAVE_USER_PERMISSIONS = 'SAVE_USER_PERMISSIONS'
  // POS Commission Watcher Action
export const FETCH_POS_COMMISSION_WATCHER = 'FETCH_POS_COMMISSION_WATCHER'
export const UPDATE_POS_COMMISSION_WATCHER = 'UPDATE_POS_COMMISSION_WATCHER'
export const FETCH_GLOBAL_COMMISSION_WATCHER = 'FETCH_GLOBAL_COMMISSION_WATCHER';
export const UPDATE_GLOBAL_COMMISSION_WATCHER = 'UPDATE_GLOBAL_COMMISSION_WATCHER';

// DSA List Watcher Action
export const DSA_LIST_WATCHER = 'DSA_LIST_WATCHER'
export const DSA_DETAIL_WATCHER = 'DSA_DETAIL_WATCHER'
export const DSA_COMMISSION_DETAIL_WATCHER = 'DSA_COMMISSION_DETAIL_WATCHER'
export const UPDATE_DSA_CHAIN_COMMISSION_WATCHER = 'UPDATE_DSA_CHAIN_COMMISSION_WATCHER'
export const POSP_LIST_UNDER_DSA_WATCHER = 'POSP_LIST_UNDER_DSA_WATCHER'
export const CREATE_DSA_WATCHER = 'CREATE_DSA_WATCHER'
export const UPDATE_DSA_WATCHER = 'UPDATE_DSA_WATCHER'
export const FETCH_GLOBAL_CHAIN_COMMISSION_WATCHER = 'FETCH_GLOBAL_CHAIN_COMMISSION';
export const UPDATE_GLOBAL_CHAIN_COMMISSION_WATCHER = 'UPDATE_GLOBAL_CHAIN_COMMISSION'

// Product Action
export const INSURANCE_TYPE_WATCHER = 'INSURANCE_TYPE_WATCHER'
export const INSURANCE_COMPANY_BY_TYPE_WATCHER = 'INSURANCE_COMPANY_BY_TYPE_WATCHER'
export const INSURANCE_PRODUCTS_BY_COMPANY_BY_TYPE_WATCHER = 'INSURANCE_PRODUCTS_BY_COMPANY_BY_TYPE_WATCHER'
export const ALL_OCCUPATION_DETAILS_WATCHER = 'ALL_OCCUPATION_DETAILS_WATCHER'
export const ID_PROOF_INFO_WATCHER = 'ID_PROOF_INFO_WATCHER'
export const GST_TYPE_INFO_WATCHER = 'GST_TYPE_INFO_WATCHER'
export const INITIAL_TITLE_WATCHER = 'INITIAL_TITLE_WATCHER'
export const RELATIONS_INFO_WATCHER = 'RELATIONS_INFO_WATCHER'
export const MARITAL_STATUS_INFO_WATCHER = 'MARITAL_STATUS_INFO_WATCHER'
export const USER_ROLES_WATCHER = 'USER_ROLES_WATCHER'
export const BQP_USERS_WATCHER = 'BQP_USERS_WATCHER'
export const ROT_USERS_WATCHER = 'ROT_USERS_WATCHER'
export const SEARCH_USERS_WATCHER = 'SEARCH_USERS_WATCHER'
export const CREATE_OFFLINE_PROPOSAL_WATCHER = 'CREATE_OFFLINE_PROPOSAL_WATCHER'
export const FETCH_ALL_PROPOSAL_WATCHER = 'FETCH_ALL_PROPOSAL_WATCHER'
export const FETCH_ALL_OFFLINE_SALES_WATCHER = 'FETCH_ALL_OFFLINE_SALES_WATCHER'
export const PREVIOUS_INSURER_WATCHER = 'PREVIOUS_INSURER_WATCHER'
export const FETCH_PROPOSAL_DETAILS_WATCHER = 'FETCH_PROPOSAL_DETAILS_WATCHER'

//Payout Action Watcher
export const FETCH_ALL_PAYOUT_WATCHER = 'FETCH_ALL_PAYOUT_WATCHER';
export const UPDATE_POS_PAYOUT_WATCHER = 'UPDATE_POS_PAYOUT_WATCHER';
export const FETCH_POLICYWISE_EARNINGS_WATCHER = 'FETCH_POLICYWISE_EARNINGS_WATCHER';

//Notification Watcher
export const SEND_NOTIFICATION_WATCHER = 'SEND_NOTIFICATION_WATCHER';

//Payout Frequency watcher
export const FETCH_PAYOUT_FREQUENCY = 'FETCH_PAYOUT_FREQUENCY';
export const CREATE_PAYOUT_FREQUENCY = 'CREATE_PAYOUT_FREQUENCY';

//Special User Actions
export const SPECIAL_USER_LIST_WATCHER = 'SPECIAL_USER_LIST_WATCHER';
export const SPECIAL_USER_DETAIL_WATCHER = 'SPECIAL_USER_DETAIL_WATCHER';
export const CREATE_SPECIAL_USER_WATCHER = 'CREATE_SPECIAL_USER_WATCHER';
export const UPDATE_SPECIAL_USER_WATCHER = 'UPDATE_SPECIAL_USER_WATCHER';
export const FETCH_ASSISTED_SALES_WATCHER = 'FETCH_ASSISTED_SALES_WATCHER';

//Special User Reducer
export const SPECIAL_USER_LIST = 'SPECIAL_USER_LIST';
export const SPECIAL_USER_DETAIL = 'SPECIAL_USER_DETAIL';
export const ASSISTED_SALES = 'ASSISTED_SALES';



// POS Commission Reducer Action
export const POS_COMMISSION = 'POS_COMMISSION'
export const GLOBAL_COMMISSION = 'GLOBAL_COMMISSION';

//Payout Frequency Reducer
export const GLOBAL_PAYOUT_FREQUENCY = 'GLOBAL_PAYOUT_FREQUENCY';

// POS Reducer Actions
export const POS_LIST = 'POS_LIST'
export const POS_ONBOARDING_LIST = 'POS_ONBOARDING_LIST'
export const POS_DETAIL = 'POS_DETAIL'
export const POLICY_SOLD_LIST = 'POLICY_SOLD_LIST'
export const PENDING_PROPOSALS_LIST = 'PENDING_PROPOSALS_LIST'
export const POSP_EARNING = 'POSP_EARNING'
export const USER_PERMISSIONS = 'USER_PERMISSIONS'

// DSA Reducer Actions
export const DSA_LIST = 'DSA_LIST'
export const DSA_DETAIL = 'DSA_DETAIL'
export const DSA_COMMISSION_DETAIL = 'DSA_COMMISSION_DETAIL'
export const POSP_LIST_UNDER_DSA = 'POSP_LIST_UNDER_DSA'
export const GLOBAL_CHAIN_COMMISSION = 'GLOBAL_CHAIN_COMMISSION'


// Product Reducer Action
export const INSURANCE_TYPE_LIST = 'INSURANCE_TYPE_LIST'
export const INITAL_TITLE_LIST = 'INITAL_TITLE_LIST'
export const RELATION_LIST = 'RELATION_LIST'
export const MARITAL_STATUS_LIST = 'MARITAL_STATUS_LIST'
export const USER_ROLES_LIST = 'USER_ROLES_LIST'
export const BQP_USERS_LIST = 'BQP_USERS_LIST'
export const ROT_USERS_LIST = 'ROT_USERS_LIST'
export const SEARCH_USERS_LIST = 'SEARCH_USERS_LIST'
export const PROPOSAL_LIST = 'PROPOSAL_LIST'
export const OFFLINE_SALES_LIST = 'OFFLINE_SALES_LIST'
export const PREVIOUS_INSURER_LIST = 'PREVIOUS_INSURER_LIST'
export const PROPOSAL_DETAILS = 'PROPOSAL_DETAILS'
export const OCCUPATION_LIST = 'OCCUPATION_LIST';
export const ID_PROOF_LIST = 'ID_PROOF_LIST';
export const GST_TYPE_LIST = 'GST_TYPE_LIST';

// Common Reducer
export const NEXT_BUTTON = 'NEXT_BUTTON'
export const OPEN_BACKDROP = 'OPEN_BACKDROP';
export const ALERT_MSG = 'ALERT_MSG'
export const COMPANIES_LIST = 'COMPANIES_LIST';

//Payout Reducer
export const POS_PAYOUT = 'POS_PAYOUT';
export const POLICYWISE_EARNINGS = 'POLICYWISE_EARNINGS'

export const FETCH_POLICIES_DETAIL_WATCHER = 'FETCH_POLICIES_DETAIL_WATCHER'
export const FETCH_PAYMENT_URL_WATCHER = "FETCH_PAYMENT_URL_WATCHER";

export const POLICY_DOWNLOAD_LINK_WATCHER = "POLICY_DOWNLOAD_LINK_WATCHER";
// Admin Roles Reducers
export const FETCH_ROLES_PERMISSIONS = 'FETCH_ROLES_PERMISSIONS';
export const FETCH_ROLES_PERMISSIONS_WATCHER = 'FETCH_ROLES_PERMISSIONS_WATCHER';
export const FETCH_MASTER_PERMISSIONS_WATCHER = "FETCH_MASTER_PERMISSIONS_WATCHER";
export const FETCH_MASTER_PERMISSIONS = "FETCH_MASTER_PERMISSIONS";
export const SAVE_ROLE_PERMISSIONS = "SAVE_ROLE_PERMISSIONS";
export const FETCH_MASTER_ROLES_WATCHER = "FETCH_MASTER_ROLES_WATCHER";
export const MASTER_ROLES = "MASTER_ROLES";
export const UPDATE_ADMIN = "UPDATE_ADMIN";

export const FETCH_PARTNERS = "FETCH_PARTNERS";
export const PARTNERS_LIST = "PARTNERS_LIST";
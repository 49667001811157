import * as React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import Admin from '../DashBoard/Admin';
import Card from "components/Card/Card";
import CustomTabs from "components/CustomTabs/CustomTabs";
import CardBody from "components/Card/CardBody";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { withStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import ListIcon from '@material-ui/icons/List';
import {createNotification} from 'store/actions/notificationAction';
import {fetchposOnboardingListWatcher} from 'store/actions/posAction';
import {updateAlertMsg, toggleBackDrop} from 'store/actions/commonAction';
import {styles} from './NotificationPageCss';
import NotificationForm from './Create/NotificationForm';
import ErrorIcon from '@material-ui/icons/Error'
import { isEmpty, isValidImageUrl } from 'utils/common';

class NotificationPage extends React.Component {

    constructor(props) {
        super(props);
        props.fetchposOnboardingListWatcher()
        this.state ={
          formErrors: {},
          isValid :false,
          title: '',
          content: '',
          image: null,
          imagePreviewUrl: '',
          selectedPosp: [],
          sendToAll:false,
          imageUrl: '',
          isSubmitDisabled:false
        }
    }

    handleChange =(event, key) => {
      
      let formErrors = { ...this.state.formErrors }
      delete formErrors[key];
      let value = event.target.value;
      let isValid = true
      switch(key) {
        case 'title': 
        if(value.length > 45){
          formErrors['title'] = 'Word count cannot exceed 45';
          isValid = false
        }
        break;

        case 'content':
          if(value.length > 500){
            formErrors['content'] = 'Word count cannot exceed 500';
            isValid = false
          }
          break;

        case 'selectedPosp' :
          let sendToAll =false;
          if(value.indexOf('selectAll') > -1){
            value  = this.props.posList;
            sendToAll = true;
          } 
          else if(value.indexOf('unselectAll') > -1) {
            value = [];
            sendToAll =false;
          }
          this.setState({sendToAll})
          break;

        default: 
        break;
      }
 
      if(isValid){
      this.setState({
        [key]: value,
        formErrors
      });
    }
    else {
      this.setState({
        formErrors
      })
    }
    }
    isImageUrlValid =(value) => {
      let formErrors = { ...this.state.formErrors }
        if( !isEmpty(value) && !isValidImageUrl(value)) {
          formErrors['imageUrl'] = 'Enter the valid URL (e.g: https://www.url.image.jpg)';
          this.setState({
            formErrors
          })
        }
       
    }

    isValid = () => {
      const {formErrors, selectedPosp, title, content} = this.state;

      let isValid = false;
      if (isEmpty(formErrors) && !isEmpty(selectedPosp) && !isEmpty(title) && (content)) {
        isValid = true;
      }
    return isValid; 
    }

     handleImageChange = e => {
      e.preventDefault();
      const errs = []
      let reader = new FileReader();
      let file = e.target.files[0];
      const types = ['image/png', 'image/jpeg']
      if (types.every(type => file.type !== type)) {
        errs.push(`'${file.type}' is not a supported format`)
      }

      if (file.size > 4000) {
        errs.push(`'${file.name}' is too large, please pick a smaller file`)
      }
      if(isEmpty(errs))
      reader.onloadend = () => {
        this.setState({
          image: file,
          imagePreviewUrl: reader.result
        })
   
      };
      reader.readAsDataURL(file);
    };

    removeImage =(event) => {
      event.preventDefault();
      this.setState({
        image: null,
        imagePreviewUrl: ''
      })
    }
    onSelectPosp =(value) => {
      if(value.value.indexOf('selectAll') > -1) {
        this.setState({selectedPosp: this.props.posList})
        return
      }
      this.setState({
        selectedPosp: value.value
      })
    }

    submitForm =(event) => {
      event.preventDefault()
      const {updateAlertMsg, toggleBackDrop, createNotification} = this.props;
      const{imageUrl, title, content, sendToAll, selectedPosp } = this.state
      if(this.isValid()){
        let param ={
          title,
          content
        }
        const phone =[];
        if(!sendToAll) {
          selectedPosp.map(pos => phone.push(pos.phone));
          param.phone = phone
        }else{
          param['send_to_all'] = true;
        }
        if(!isEmpty(imageUrl)) param.image = imageUrl;
        toggleBackDrop(true);
        new Promise((resolve, reject) => {
         createNotification(param, resolve, reject);
        }).then (resolve => {
          toggleBackDrop(false)
          console.log('notification sent successfully', resolve);
          const alertMsg = {
            color: 'success',
            icon: CheckCircleOutlineIcon,
            message: 'Notification sent successfully'
          }
          updateAlertMsg(alertMsg)
          this.setState({isSubmitDisabled: true})

        }).catch(reject => {
          toggleBackDrop(false);
          const alertMsg = {
            color: 'danger',
            icon: ErrorIcon,
            message: 'Something went wrong. Please try again'
          }
          updateAlertMsg(alertMsg)
        })

      }
      else{
        const alertMsg = {
          color: 'danger',
          icon: ErrorIcon,
          message: 'Please fill in the form details properly'
        }
        updateAlertMsg(alertMsg)
      }
    }


    render() {

        const {classes} = this.props;

        const tabs = [{
            tabName: "Create Notification", tabIcon: CreateIcon,
            tabContent: <NotificationForm classes={classes} 
            handleChange={this.handleChange} handleImageChange={this.handleImageChange}
             posList={this.props.posList} onSelectPosp={this.onSelectPosp} 
             removeImage={this.removeImage} submitForm={this.submitForm} 
             isImageUrlValid={this.isImageUrlValid} {...this.state} />
          },
          { tabName: "Notification List", tabIcon: ListIcon, tabContent: 'Under Construction' }
        ];

        return(
            <Admin>
            <GridContainer>
            <GridItem xs={12}>
              <Card plain className={classes.profileBackground}>
                <CardBody plain>
                  <CustomTabs
                    headerColor="primary"
                    tabs={tabs}
                    plainTabs={false}
                    classHeader={classes.tabs}
      
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          </Admin>
        );
    }
}
function mapStateToProps(state) {
    const { posReducer  } = state;
    return {
      posList: posReducer.posOnboardingList
    };
  }
  
  
  // mapping dispatch functions to the props of LoginForm component
  const mapDispatchToProps = dispatch => {
    return bindActionCreators(
      {
        createNotification,
        fetchposOnboardingListWatcher,
        updateAlertMsg,
         toggleBackDrop
        // add other watcher sagas to this object to map them to props
      },
      dispatch
    )
  };

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(NotificationPage));
import * as actions from './types';

export function fetchPosCommissionWatcher(param) {
    return { type: actions.FETCH_POS_COMMISSION_WATCHER, payload: param };
}

export function fetchGlobalCommissionWatcher() {
    return { type: actions.FETCH_GLOBAL_COMMISSION_WATCHER };
}

export function updatePosCommissionWatcher(param, resolve, reject) {
    return {type: actions.UPDATE_POS_COMMISSION_WATCHER, payload: param, resolve, reject};
}

export function updateGlobalCommissionWatcher(param, resolve, reject) {
    return {type: actions.UPDATE_GLOBAL_COMMISSION_WATCHER, payload: param, resolve, reject};
}


// Reducer
export function posCommission(posCommission) {
    return {
        type: actions.POS_COMMISSION,
        posCommission
    };
}

export function globalComission(globalCommission) {
    return {
        type: actions.GLOBAL_COMMISSION,
        globalCommission
    };
}
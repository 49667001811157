import * as actions from "./actions";

const breakInInspectionsReducer = (
  state = {
    inspections: [],
  },
  action
) => {
  switch (action.type) {
    case actions.SET_ALL_INSPECTIONS:
      return {
        ...state,
        inspections: action.data,
      };

    default:
      return state;
  }
};

export default breakInInspectionsReducer;

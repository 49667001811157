import * as actions from './types';

export function fetchRolesPermissionsWatcher(param = {}) {
  return { type: actions.FETCH_ROLES_PERMISSIONS_WATCHER, payload: param };
}

export function roleList(rolePermissions) {
  return { type: actions.FETCH_ROLES_PERMISSIONS, rolePermissions }
}

export function fetchMasterPermissions(params = {}) {
  return { type: actions.FETCH_MASTER_PERMISSIONS_WATCHER, payload: params }
}

export function masterPermissionList(masterPermissions) {
  return { type: actions.FETCH_MASTER_PERMISSIONS, masterPermissions }
}

export function saveRolePermissionsWatcher(params) {
  return { type: actions.SAVE_ROLE_PERMISSIONS, payload: params }
}

export function fetchMasterRolesWatcher(params) {
  return { type: actions.FETCH_MASTER_ROLES_WATCHER, payload: params }
}

export function masterRoles(masterRoles) {
  return { type: actions.MASTER_ROLES, masterRoles }
}

export function updateAdminDetails(adminDetails) {
  return { type: actions.UPDATE_ADMIN, adminDetails }
}
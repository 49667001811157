import * as actions from '../actions/types';

const initialState = {};

const payoutReducer =(state = initialState, action) =>{

    switch (action.type) {

        case actions.POS_PAYOUT:
          return {
            ...state,
            posPayoutList: action.posPayoutList,
          };

        case actions.POLICYWISE_EARNINGS:
          return {
            ...state,
            policyWiseEarnings: action.policyWiseEarnings
          };

          default:
            return state;
        }
    
}

export default payoutReducer;
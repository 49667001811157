import React from 'react'
import GridContainer from '../../../components/Grid/GridContainer'
import Card from '../../../components/Card/Card'
import CardBody from '../../../components/Card/CardBody'
import GridItem from '../../../components/Grid/GridItem'
import CustomInput from '../../../components/CustomInput/CustomInput'

export default function BasicFormStep (props) {
  const { classes, basicDetail, validateInput, updateKeyValueInState, formErrors = {}, isError = {} } = props

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card raised className={classes.cardBackground}>
          <CardBody raised >
            <GridContainer>
              {/* Full Name option */}
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Name"
                  id="fullName"
                  formControlProps={{
                    fullWidth: true
                  }}
                  isRequired
                  // error={isError('basicDetail', 'fullName')}
                  onChange={({ target: { value } }) => (value ? updateKeyValueInState('basicDetail', 'fullName', value) : updateKeyValueInState('basicDetail', 'fullName', null))}
                  inputProps={{
                    value: basicDetail.fullName ? basicDetail.fullName : '',
                    onBlur: ({ target: { value } }) => validateInput('basicDetail', 'fullName', value),
                    error: isError('basicDetail', 'fullName')
                  }}
                />
                {isError('basicDetail', 'fullName') ? (
                  <span className={classes.error}>
                    {formErrors.basicDetail.fullName}
                  </span>
                ) : null}
              </GridItem>

              {/* Business Entity Name */}
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Business Entity Name"
                  id="businessEntityName"
                  formControlProps={{
                    fullWidth: true
                  }}
                  isRequired
                  error={isError('basicDetail', 'businessEntityName')}
                  onChange={({ target: { value } }) => (value ? updateKeyValueInState('basicDetail', 'businessEntityName', value) : updateKeyValueInState('basicDetail', 'businessEntityName', null))}
                  inputProps={{
                    value: basicDetail.businessEntityName ? basicDetail.businessEntityName : '',
                    onBlur: ({ target: { value } }) => validateInput('basicDetail', 'businessEntityName', value),
                    error: isError('basicDetail', 'businessEntityName')
                  }}
                />
                {isError('basicDetail', 'businessEntityName') ? (
                  <span className={classes.error}>
                    {formErrors.basicDetail.businessEntityName}
                  </span>
                ) : null}
              </GridItem>

              {/* Business Address */}
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  labelText="Business Address"
                  id="businessAddress"
                  formControlProps={{
                    fullWidth: true
                  }}
                  isRequired
                  error={isError('basicDetail', 'businessAddress')}
                  onChange={({ target: { value } }) => (value ? updateKeyValueInState('basicDetail', 'businessAddress', value) : updateKeyValueInState('basicDetail', 'businessAddress', null))}
                  inputProps={{
                    value: basicDetail.businessAddress ? basicDetail.businessAddress : '',
                    onBlur: ({ target: { value } }) => validateInput('basicDetail', 'businessAddress', value),
                    error: isError('basicDetail', 'businessAddress')
                  }}
                />
                {isError('basicDetail', 'businessAddress') ? (
                  <span className={classes.error}>
                    {formErrors.basicDetail.businessAddress}
                  </span>
                ) : null}
              </GridItem>

              {/* Email Id */}
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Email Id"
                  id="email"
                  formControlProps={{
                    fullWidth: true
                  }}
                  isRequired
                  error={isError('basicDetail', 'email')}
                  onChange={({ target: { value } }) => (value ? updateKeyValueInState('basicDetail', 'email', value) : updateKeyValueInState('basicDetail', 'email', null))}
                  inputProps={{
                    value: basicDetail.email ? basicDetail.email : '',
                    onBlur: ({ target: { value } }) => validateInput('basicDetail', 'email', value),
                    error: isError('basicDetail', 'email')
                  }}
                />
                {isError('basicDetail', 'email') ? (
                  <span className={classes.error}>
                    {formErrors.basicDetail.email}
                  </span>
                ) : null}
              </GridItem>

              {/* Phone Number */}
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Phone Number"
                  id="phone"
                  formControlProps={{
                    fullWidth: true
                  }}
                  isRequired
                  error={isError('basicDetail', 'phone')}
                  onChange={({ target: { value } }) => (value ? updateKeyValueInState('basicDetail', 'phone', value) : updateKeyValueInState('basicDetail', 'phone', null))}
                  inputProps={{
                    value: basicDetail.phone ? basicDetail.phone : '',
                    type: 'number',

                    onBlur: ({ target: { value } }) => validateInput('basicDetail', 'phone', value),
                    error: isError('basicDetail', 'phone')
                  }}
                />
                {isError('basicDetail', 'phone') ? (
                  <span className={classes.error}>
                    {formErrors.basicDetail.phone}
                  </span>
                ) : null}
              </GridItem>

              {/* Pan Number */}
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Pan Number"
                  id="panNo"
                  formControlProps={{
                    fullWidth: true
                  }}
                  isRequired
                  error={isError('basicDetail', 'panNo')}
                  onChange={({ target: { value } }) => (value ? updateKeyValueInState('basicDetail', 'panNo', value) : updateKeyValueInState('basicDetail', 'panNo', null))}
                  inputProps={{
                    value: basicDetail.panNo ? basicDetail.panNo : '',
                    onBlur: ({ target: { value } }) => validateInput('basicDetail', 'panNo', value),
                    error: isError('basicDetail', 'panNo')
                  }}
                />
                {isError('basicDetail', 'panNo') ? (
                  <span className={classes.error}>
                    {formErrors.basicDetail.panNo}
                  </span>
                ) : null}
              </GridItem>

              {/* Aadhar Number */}
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Aadhar Number"
                  id="aadharNo"
                  formControlProps={{
                    fullWidth: true
                  }}
                  isRequired
                  error={isError('basicDetail', 'aadharNo')}
                  onChange={({ target: { value } }) => (value ? updateKeyValueInState('basicDetail', 'aadharNo', value) : updateKeyValueInState('basicDetail', 'aadharNo', null))}
                  inputProps={{
                    value: basicDetail.aadharNo ? basicDetail.aadharNo : '',
                    type: 'number',

                    onBlur: ({ target: { value } }) => validateInput('basicDetail', 'aadharNo', value),
                    error: isError('basicDetail', 'aadharNo')
                  }}
                />
                {isError('basicDetail', 'aadharNo') ? (
                  <span className={classes.error}>
                    {formErrors.basicDetail.aadharNo}
                  </span>
                ) : null}
              </GridItem>

            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import Button from 'components/CustomButtons/Button'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import { isEmpty } from 'utils/common'
import ErrorIcon from '@material-ui/icons/Error'
import GridContainer from 'components/Grid/GridContainer'
import MemberForm from './MemberForm';

const style = {
    cardHeader: {
        padding: '0 20px 0 20px'
    },
    cardBody: {
        paddingTop: '0px'
    }
}

const useStyles = makeStyles(style);

function MultipleMember(props) {
    const classes = useStyles();

    const { setHeaderName, membersForm = [], 
        setMembersForm, bindSubmitForm, handleSubmit } = props;

        let isAddMember = false

    let submitMemberForm = null;

    React.useEffect(() => { setHeaderName('Member Details') }, [])


    const bindSubmitMemberForm = (submitForm) => {
        submitMemberForm = submitForm;
        bindSubmitForm(submitForm)
    }

    const [membersData, updateMembersArray] = React.useState(membersForm)
    const [sizeOfMember, updateSize] = React.useState(1)

    const addMemberForm = (e) => {
        isAddMember = true
        if (submitMemberForm)
            submitMemberForm(e);
    }

    const handleMemberSubmit = (e,index) => {

        membersForm[index-1] = e;
        setMembersForm(membersForm);

        if(isAddMember)
        updateSize(sizeOfMember + 1)
        else handleSubmit(e);
        
        console.log(e);
    }

    const handleClose = (index) => {
        if (membersData.length === sizeOfMember) {
            membersData.splice(index - 1, 1)
            updateMembersArray(membersData)
        }
        updateSize(sizeOfMember - 1)
    }

    const getMemberForm = (index) => {
        if (index === 0) return (<MemberForm index={index + 1} membersData={membersData[index]} {...props} bindSubmitMemberForm={bindSubmitMemberForm} handleMemberSubmit={handleMemberSubmit} />)
        else return (<MemberForm index={index + 1} handleClose={handleClose} membersData={membersData[index]} {...props} bindSubmitMemberForm={bindSubmitMemberForm} handleMemberSubmit={handleMemberSubmit} />)
    }


    return (
        <Card plain>

            <CardBody className={classes.cardBody}>
                {
                    Array.from(Array(sizeOfMember), (e, i) => getMemberForm(i))

                }
                <Button fullWidth color="primary" type="submit" onClick={addMemberForm}> <PersonAddIcon /> Add Member</Button>
            </CardBody>
        </Card>
    );
}
function mapStateToProps(state) {
    const { productReducer } = state
    return {
        previousInsurerList: productReducer.previousInsurerList

    }
}
export default connect(
    mapStateToProps,
    null
)(MultipleMember)




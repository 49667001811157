export const styles = {
  permissionText: {
    border: "1px solid #ffffff",
    padding: "7px 10px",
    borderRadius: "100%",
    cursor: "pointer"
  },
  mtb: {
    marginTop: 20,
    marginBottom: 20
  },
  flexContainer: {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    "&>div": {
      width: 100,
      textAlign: "center"
    }
  },
  removeFeature: {
    cursor: "pointer"
  },
  formControl: {
    minWidth: 180,
    float: "right"
  },
  popoverIcons: {
    height: 45,
    marginRight: 5,
    marginLeft: 5,
    cursor: "pointer"
  },
  permissionApliedIcon: {
    height: 45,
    cursor: "pointer"
  },
  notAllowed: {
    height: 45,
    cursor: "not-allowed"
  }

}
import React, { Fragment } from "react";
// nodejs library that concatenates headerClasses
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
// core components
import styles from "../../assets/jss/componentsStyles/customTabsStyle.js";
import headerStyle from "../../assets/jss/componentsStyles/cardHeaderStyle";
import { AppBar } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const header = makeStyles(headerStyle);

const useStyles = makeStyles(styles);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

export default function CustomHeaderContentTabs(props) {
  const classes = useStyles();
  const headerClasses = header();

  const {
    className,
    children,
    color,
    plain,
    image,
    contact,
    signup,
    stats,
    icon,
    text,
    handleTabChange,
    ...rest
  } = props;
  const { headerColor, tabs = [], title, rtlActive, classHeader } = props;

  const cardHeaderClasses = classNames({
    [headerClasses.cardHeader]: true,
    [headerClasses[color + "CardHeader"]]: color,
    [headerClasses.cardHeaderPlain]: plain,
    [headerClasses.cardHeaderImage]: image,
    [headerClasses.cardHeaderContact]: contact,
    [headerClasses.cardHeaderSignup]: signup,
    [headerClasses.cardHeaderStats]: stats,
    [headerClasses.cardHeaderIcon]: icon,
    [headerClasses.cardHeaderText]: text,
    [className]: className !== undefined,
  });

  const [value, setValue] = React.useState(0);
  const handleChange = (event, value) => {
    setValue(value);
    handleTabChange(value);
  };
  const cardTitle = classNames({
    [classes.cardTitle]: true,
    [classes.cardTitleRTL]: rtlActive,
  });
  const cardHeader = classNames({
    [classes.cardHeader]: true,
    [classes[headerColor + "CardHeader"]]: headerColor,
    [classHeader]: classHeader,
  });

  return (
    <Fragment>
      <div
        className={cardHeaderClasses}
        {...rest}
        style={{ padding: "15px 0 0 0" }}
      >
        <div style={{ paddingLeft: "15px", display: "flex" }}>{children}</div>
        <AppBar
          color={headerColor}
          className={cardHeader}
          position="static"
          style={{ marginTop: "15px", borderTop: "solid 1px lightgrey" }}
        >
          {title !== undefined ? (
            <div className={cardTitle}>{title}</div>
          ) : null}
          <Tabs
            value={value}
            onChange={handleChange}
            classes={{
              root: classes.tabsRoot,
              indicator: classes.displayNone,
              scrollButtons: classes.displayNone,
            }}
            variant="fullWidth"
            scrollButtons="auto"
            // variant="fullWidth"
          >
            {tabs.map((prop, key) => {
              var icon = {};
              if (prop.tabIcon) {
                icon = {
                  icon: <prop.tabIcon />,
                };
              }
              return (
                <Tab
                  classes={{
                    root: classes.tabRootButton,
                    selected: classes.tabSelected,
                    wrapper: classes.tabWrapper,
                  }}
                  key={key}
                  label={prop.tabName}
                  {...icon}
                />
              );
            })}
          </Tabs>
        </AppBar>
      </div>

      {tabs.map((prop, key) => {
        if (key === value) {
          return (
            <TabPanel value={value} index={key}>
              {prop.tabContent}
            </TabPanel>
          );
        }
        return null;
      })}
    </Fragment>
  );
}

CustomHeaderContentTabs.propTypes = {
  headerColor: PropTypes.oneOf([
    "warning",
    "success",
    "danger",
    "info",
    "primary",
    "rose",
  ]),
  title: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabName: PropTypes.string.isRequired,
      tabIcon: PropTypes.object,
      tabContent: PropTypes.node.isRequired,
    })
  ),
  plainTabs: PropTypes.bool,
};

import {
    primaryColor,
    dangerColor,
    grayColor,
    hexToRgb,
    blackColor,
    roseColor
  } from "../material-dashbord-react";

  const customCheckBoxStyle ={
    checkRoot: {
        padding: "10px",
        "&:hover": {
          backgroundColor: "unset"
        }
      },
      checked: {
        color: primaryColor[0] + "!important"
      },
      checkedIcon: {
        width: "15px",
        height: "15px",
        border: "1px solid rgba(" + hexToRgb(blackColor) + ", .54)",
        borderRadius: "3px"
      },
      uncheckedIcon: {
        width: "0px",
        height: "0px",
        padding: "7.5px",
        border: "1px solid rgba(" + hexToRgb(blackColor) + ", .54)",
        borderRadius: "3px"
      },
      label: {
        cursor: "pointer",
        paddingLeft: "0",
        color: '#3C4858 !important',
        fontSize: "12px",
        lineHeight: "1.428571429",
        fontWeight: "400",
        display: "inline-flex",
        transition: "0.3s ease all",
        letterSpacing: "unset"
      },
      labelRoot: {
        marginLeft: "-14px"
      },
      labelHorizontal: {
        color: "rgba(" + hexToRgb(blackColor) + ", 0.26)",
        cursor: "pointer",
        display: "inline-flex",
        fontSize: "14px",
        lineHeight: "1.428571429",
        fontWeight: "400",
        paddingTop: "39px",
        marginRight: "0",
        "@media (min-width: 992px)": {
          float: "right"
        }
      },
      labelHorizontalRadioCheckbox: {
        paddingTop: "22px"
      },
      labelLeftHorizontal: {
        color: "rgba(" + hexToRgb(blackColor) + ", 0.26)",
        cursor: "pointer",
        display: "inline-flex",
        fontSize: "14px",
        lineHeight: "1.428571429",
        fontWeight: "400",
        paddingTop: "22px",
        marginRight: "0"
      },
      labelError: {
        color: dangerColor[0]
      },
      inlineChecks: {
        marginTop: "8px"
      },
      iconCheckbox: {
        height: "116px",
        width: "116px",
        color: grayColor[0],
        padding: "0",
        margin: "0 auto 20px",
        "& > span:first-child": {
          borderWidth: "4px",
          borderStyle: "solid",
          borderColor: grayColor[9],
          textAlign: "center",
          verticalAlign: "middle",
          borderRadius: "50%",
          color: "inherit",
          transition: "all 0.2s"
        },
        "&:hover": {
          color: roseColor[0],
          "& > span:first-child": {
            borderColor: roseColor[0]
          }
        }
      },
      iconCheckboxChecked: {
        color: roseColor[0],
        "& > span:first-child": {
          borderColor: roseColor[0]
        }
      },
      iconCheckboxIcon: {
        fontSize: "40px",
        lineHeight: "111px"
      },
  }
  export default customCheckBoxStyle;
import Admin from '../DashBoard/Admin';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchPoliciesDetailsWatcher } from 'store/actions/posAction';
import PendingProposalsList from './PendingProposalList';
import moment from 'moment'

const PendingProposalsPage = (props) => {

  const dispatch = useDispatch();

  const { pendingProposalsList = {} } = useSelector((state) => state.posReducer);
  const { pendingProposals = [] } = pendingProposalsList;

  const getThisMonthRange = () => {
    const format = "DD-MM-YYYY";
    let fromDate = moment(Number(new Date().getTime().toString())).format(format).split("-");
    fromDate = `01-${fromDate[1]}-${fromDate[2]}`
    const toDate = moment(Number(new Date().getTime().toString())).format(format);
    return { fromDate, toDate }
  }

  const {fromDate, toDate} = getThisMonthRange();
  let {sDate="", fDate="",proposalType: pType=""} = props.location.state ||{};
  // if(props.location.state===null){
  //   sDate=fromDate;
  //   fDate=toDate;
  // }
  const [startDate, setStartDate] = useState(sDate)
  const [endDate, setEndDate] = useState(fDate)
  const [proposalType, setProposalType] = useState(pType) 

  useEffect(() => {
    const {fromDate, toDate} = getThisMonthRange();
    let {sDate="", fDate="", proposalType="all"} = props.location.state ||{};

    if(props.location.state===null){
      sDate=fromDate;
      fDate=toDate;
    }

    dispatch(fetchPoliciesDetailsWatcher({
      fromDate: sDate,
      toDate: fDate,
      proposalIds: [],
      proposalStatus: "pending",
      isTable: true,
      proposalType
    }))
  }, []);

  const fetchDataByRange = (startDate, endDate, proposalType) => {
    const { fromDate, toDate } = getThisMonthRange();
    const sDate = startDate||fromDate;
    const fDate = endDate || toDate

    setStartDate(sDate);
    setEndDate(fDate);
    setProposalType(proposalType)

    window.history.replaceState({ key: props.history.location.key, state: {sDate, fDate, proposalType}}, "test")

    dispatch(fetchPoliciesDetailsWatcher({
      fromDate: sDate,
      toDate: fDate,
      proposalIds: [],
      proposalStatus: "pending",
      isTable: true,
      proposalType
    }))
  }

  const getDetails = (event, { proposalId, insuranceType }) => {
    event.preventDefault();
    props.history.push({ pathname: `/policy-details/${insuranceType.split(" ").join("").toLowerCase()}/${proposalId}`, state: { proposalId, insuranceType } })
  }

    return (
    <Admin>
      <PendingProposalsList
        PendingProposalsList pendingProposals = { pendingProposals }
        getDetails = { getDetails }
        fetchDataByRange = { fetchDataByRange }
        startDate = { startDate }
        endDate = { endDate }
        proposalType = { proposalType }
      />
    </Admin>
  );
}


export default PendingProposalsPage
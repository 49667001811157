import * as actions from './types';

export function fetchPayoutFrequency() {
    return { type: actions.FETCH_PAYOUT_FREQUENCY };
  }

  export function createPayoutFrequency(param, resolve, reject) {
    return ({
      type: actions.CREATE_PAYOUT_FREQUENCY,
      payload: param, resolve, reject
    });
  }

  //Reducer Actions
  export function globalPayoutFrequency(globalPayoutFrequency) {
    return ({
      type: actions.GLOBAL_PAYOUT_FREQUENCY,
      globalPayoutFrequency
    })
  }
import * as actions from '../actions/types'

const initialState = {}

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.INSURANCE_TYPE_LIST:
      return {
        ...state,
        insuranceTypeList: action.insuranceType
      }
    case actions.PREVIOUS_INSURER_LIST:
      return {
        ...state,
        previousInsurerList: action.previousInsurerList
      }

      case actions.INITAL_TITLE_LIST:
        return {
          ...state,
          initialTitles: action.initialTitles
        }

      case actions.MARITAL_STATUS_LIST:
        return {
          ...state,
          maritalStatusList: action.maritalStatusList
        }

      case actions.USER_ROLES_LIST:
      return {
        ...state,
        userRolesList: action.userRolesList
      }

      case actions.BQP_USERS_LIST:
      return {
        ...state,
        bqpUsersList: action.bqpUsersList
      }

      case actions.ROT_USERS_LIST:
      return {
        ...state,
        rotUsersList: action.rotUsersList
      }

      case actions.SEARCH_USERS_LIST:
      return {
        ...state,
        searchUsersList: action.searchUsersList
      }
      
      case actions.RELATION_LIST: 
      return {
        ...state,
        relationList: action.relationList
      }
      case actions.OCCUPATION_LIST: 
      return {
        ...state,
        occupationList: action.occupationList
      }

      case actions.ID_PROOF_LIST: 
      return {
        ...state,
        idProofList: action.idProofList
      }

      case actions.GST_TYPE_LIST: 
      return {
        ...state,
        gstTypeList: action.gstTypeList
      }

    case actions.PROPOSAL_LIST:
      return {
        ...state,
        proposalList: action.proposalList
      }

    case actions.PROPOSAL_DETAILS:
        return {
          ...state,
          proposalDetails: action.proposalDetails
        }

    case actions.OFFLINE_SALES_LIST:
      return {
        ...state,
        offlineSalesList: action.offlineSalesList
      }

    default:
      return state
  }
}

export default productReducer

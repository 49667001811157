import React from 'react'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
// core components
import GridItem from '../../components/Grid/GridItem'
import GridContainer from '../../components/Grid/GridContainer'
import Card from '../../components/Card/Card'
import PosOnboardingTable from './PosOnboardingTable'
import Search from '@material-ui/icons/Search'
import CustomInput from '../../components/CustomInput/CustomInput'
import Button from '../../components/CustomButtons/Button'
import HistoryIcon from '@material-ui/icons/History'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import { ONBOARD_STATUS } from '../../utils/constants'
import styleHeader from '../../assets/jss/componentsStyles/headerLinksStyle'
import { exportCsv, changeDateFormatFromProvidedFormat } from '../../utils/common'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import CustomHeaderContentTabs from '../../components/CustomTabs/CustomHeaderContentTabs'
import Tooltip from '@material-ui/core/Tooltip'
import FuzzySearch from 'fuzzy-search'

const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0'
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF'
    }
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: 'Montserrat',
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1'
    }
  },
  inputSearchType: {
    color: 'white',
    padding: '0'
  },
  paddingStyle: {
    padding: '0 12px 0'
  }
}

function createData (fullName, phone, aadharNo, panNo, highestQualification, isExamPassed, approval, _id, dataType) {
  return { fullName, phone, aadharNo, panNo, highestQualification, isExamPassed, approval, _id , dataType}
}

const useStyles = makeStyles(styles)
const headerStyle = makeStyles(styleHeader)

export default function PosOnboardingList (props) {
  const classes = useStyles()
  const headerClass = headerStyle()

  const { posList = [], getDetails, handleClose } = props
  const rows = []

  const [filteredList, setList] = React.useState()

  const [showInput, toggleInput] = React.useState(false)

  // const detailButton =  (<Button color="primary">Details</Button>)
  posList.forEach(pos => {
    const row = createData(pos.fullName, pos.phone, pos.aadharNo, pos.panNo.toUpperCase(), pos.highestQualification, pos.isExamPassed, pos.approvalStatus, pos.userId, pos.dataType)
    rows.push(row)
  })

  React.useEffect(() => {
    setList(rows) // api.data.count from api after update store
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [posList])

  React.useEffect(() => { document.getElementById('searchInput') && document.getElementById('searchInput').focus() }, [showInput])

  const filterList = (e) => {
    // Variable to hold the original version of the list
    let currentList = []
    // Variable to hold the filtered list before putting into state

    let newList = []
    // If the search bar isn't empty
    if (e.target.value !== '') {
      // Assign the original list to currentList
      currentList = rows
      const searcher = new FuzzySearch(currentList, ['fullName', 'phone', 'aadharNo', 'panNo', 'highestQualification', 'isExamPassed', '_id', 'approval'], {
        caseSensitive: false
      })
      newList = searcher.search(e.target.value.toLowerCase())
    } else {
      // If the search bar is empty, set newList to original task list
      newList = [...rows]
    }
    setList(newList)
  }

  const exportPosp = (event) => {
    event.preventDefault()

    const data = []
    posList.filter(row => row.approvalStatus === ONBOARD_STATUS.ONBOARDED).forEach(pos => {
      data.push({ PAN: pos.panNo , UID: pos.aadharNo, PoSPFName: pos.pospFName, PoSPMName: pos.pospMName, PoSPLName: pos.pospLName, Dob: changeDateFormatFromProvidedFormat(pos.dob, 'DD/MM/YYYY', 'YYYY-MM-DD'), City: pos.city, Pin: pos.pinCode, AppointmentDate: changeDateFormatFromProvidedFormat(pos.appointmentDate, 'DD/MM/YYYY', 'YYYY-MM-DD'), Email: pos.email, Mobile: pos.phone, Status: pos.isExamPassed ? 'Y' : 'N', InternalPosCode: pos.internalPosCode })
    })
    const param = {
      fileName: 'POSP_ONBOARDING_LIST',
      title: 'POSP Onoarding List',
      data: data
    }
    return exportCsv(param)
  }

  const headCells = [
    { id: 'fullName', label: 'Name' },
    { id: 'phone', label: 'Phone No' },
    { id: 'aadharNo', label: 'Aadhaar No' },
    { id: 'panNo', label: 'Pan Card No' },
    { id: 'highQual', label: 'Hightest Qualification' },
    { id: 'examStatus', label: 'Exam Status' },
    // {id: '_id', label: 'Details'},
    { id: 'approval', label: 'Action' }
  ]

  const posTableData = (tabName) => {
    return (
      <PosOnboardingTable
        tableHeaderColor="primary"
        headCells={headCells}
        isSelectRequired={false}
        orderByParameter="name"
        orderAs="asc"
        rows={filteredList}
        getDetails={getDetails}
        isApproval={true}
        handleClose={handleClose}
        tabName={tabName}
      />
    )
  }

  const tabs = [{ tabName: 'Pending', tabIcon: HistoryIcon, tabContent: posTableData(ONBOARD_STATUS.APPROVED) },
    { tabName: 'Onboarded', tabIcon: CheckCircleOutlineIcon, tabContent: posTableData(ONBOARD_STATUS.ONBOARDED) },
    { tabName: 'Rejected', tabIcon: NotInterestedIcon, tabContent: posTableData(ONBOARD_STATUS.REJECTED) }]

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CustomHeaderContentTabs color="primary"
            headerColor="primary"
            tabs={tabs}
            plainTabs={false}
            classHeader={classes.tabHeader}
          >
            <span style={{ alignSelf: 'flex-start' }}>
              <h4 className={classes.cardTitleWhite}>POSP Onboarding List</h4>
            </span>
            <span className={headerClass.searchWrapper} style={{ marginLeft: 'auto', alignSelf: 'flex-end' }}>
              {showInput && <CustomInput
                id="searchInput"
                formControlProps={{
                  className: classNames(headerClass.margin, headerClass.search)
                }}
                inputProps={{
                  inputProps: {
                    'aria-label': 'Search',
                    className: classes.inputSearchType
                  }
                }}
                onChange={(event) => filterList(event)}
              />}
              <Tooltip title="Seach" aria-label="add">
                <Button className={classes.paddingStyle} color="white" aria-label="edit" simple onClick={() => toggleInput(!showInput)}>
                  <Search /> {' '}Search
                </Button>
              </Tooltip>
              <Tooltip title="Export Onboarded POSP List" aria-label="add">
                <Button color="white" className={classes.paddingStyle} aria-label="edit" simple onClick={(event) => exportPosp(event)}>
                  <ImportExportIcon /> {' '} Export
                </Button>
              </Tooltip>
            </span>
          </CustomHeaderContentTabs>
          {/* <CardHeader color="primary">
            <span style={{ float: "left" }}>
              <h4 className={classes.cardTitleWhite}>POSP Onboarding List</h4>
            </span>
            <span className={headerClass.searchWrapper} style={{ float: "right" }}>
              {showInput && <CustomInput
                id="searchInput"
                formControlProps={{
                  className: classNames(headerClass.margin, headerClass.search)
                }}
                inputProps={{
                  inputProps: {
                    "aria-label": "Search",
                    className: classes.inputSearchType
                  }
                }}
                onChange={(event) => filterList(event)}
              />}
              <Button className={classes.paddingStyle} color="white" aria-label="edit" simple onClick={() => toggleInput(!showInput)}>
                <Search /> {' '}Search
              </Button>
              <Button color="white" className={classes.paddingStyle} aria-label="edit" simple onClick={(event) => exportPosp(event)}>
                <ImportExportIcon /> {' '} Export
              </Button>
            </span>
          </CardHeader>
          <CardBody>
            <CustomTabs
              headerColor="primary"
              tabs={tabs}
              plainTabs={false}
              classHeader={classes.tabHeader}
            />

          </CardBody> */}
        </Card>
      </GridItem>
    </GridContainer>
  )
}

import { call, put, takeLatest, all, fork } from "redux-saga/effects";
import _ from "lodash";
import axios from "utils/axios";
import urls from "utils/urls";
import { createUrl, isEmpty } from "utils/common";
import { toggleBackDrop } from "store/actions/commonAction";

import * as actions from "Pages/Inspections/actions";

const APi_SERVER_URL = createUrl(urls.BASE_URL);

// ##################### Get all inspections leads ##########################################################

function getAllInspectionsLeads(params) {
  const { skip = 0, limit = 10, match } = params;
  return axios.request({
    method: "post",
    url: `${APi_SERVER_URL}/motor/inspectionRoutes/getAllinspectionLeads`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      skip,
      limit,
      sort: { inspectionCreateEpoch: -1 },
      match,
    },
  });
}

export function* fetchInspectionListActionEffect(params) {
  try {
    yield put(toggleBackDrop(true));
    yield put({
      type: actions.SET_ALL_INSPECTIONS,
      data: [],
    });
    const { data } = yield call(getAllInspectionsLeads, params);
    if (!isEmpty(data)) {
      yield put({
        type: actions.SET_ALL_INSPECTIONS,
        data: {
          ...data,
          leadInspections: _.orderBy(
            data.leadInspections,
            ["inspectionCreateEpoch"],
            ["desc"]
          ),
        },
      });
    }
    yield put(toggleBackDrop(false));
  } catch (e) {
    yield put(toggleBackDrop(false));
    console.log("Error found while fetching pos");
  }
}

export function* fetchInspectionsListActionWatcher() {
  yield takeLatest(
    actions.GET_ALL_INSPECTIONS,
    fetchInspectionListActionEffect
  );
}

// ##################### Get all inspections leads ##########################################################

function updateInspectionsLeads(payload) {
  return axios.request({
    method: "post",
    url: `${APi_SERVER_URL}/motor/inspectionRoutes/updateStatusInspectionLead`,
    headers: {
      "Content-Type": "application/json",
    },
    data: payload,
  });
}

export function* fetchUpdateInspectionActionEffect(requestObj) {
  const { params, resolve, reject } = requestObj;
  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(updateInspectionsLeads, params);
    resolve(data);
    yield put(toggleBackDrop(false));
  } catch (e) {
    yield put(toggleBackDrop(false));
    reject(e);
    console.log("Error found while fetching pos");
  }
}

export function* fetchUpdateInspectionActionWatcher() {
  yield takeLatest(
    actions.UPDATE_INSPECTION_DETAILS,
    fetchUpdateInspectionActionEffect
  );
}

// GET INSPECTIONS DETAILS
function getInspectionsDetails(payload) {
  return axios.request({
    method: "get",
    url: `${APi_SERVER_URL}/motor/inspectionRoutes`,
    headers: {
      "Content-Type": "application/json",
    },
    params: payload,
  });
}

// Saga function that handles the side effect
export function* getInspectionDetailsEffect(params) {
  const { inspectionId, resolve, reject } = params;
  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(getInspectionsDetails, { inspectionId });
    yield put(toggleBackDrop(false));
    resolve(data);
  } catch (e) {
    yield put(toggleBackDrop(false));
    let errMsg = "Something went wrong. Please try again!";
    if (e && e.response) {
      errMsg = e.message;
      if (e.response.data && e.response.data.message) {
        errMsg = e.response.data.message;
      }
    }
    /* yield put(
      updateAlertMsg({
        message: errMsg,
        messageType: "error",
      })
    ); */
    console.log("Something went wrong");
    // reject(e);
  }
}

export function* getInspectionDetailsActionWatcher() {
  yield takeLatest(actions.GET_INSPECTIONS_DETAILS, getInspectionDetailsEffect);
}

export default function* rootSagas() {
  yield all([
    fork(fetchInspectionsListActionWatcher),
    fork(fetchUpdateInspectionActionWatcher),
    fork(getInspectionDetailsActionWatcher),
  ]);
}

import * as actions from '../actions/types'
import axios from '../../utils/axios'
import urls from '../../utils/urls'
import { call, put, takeLatest, fork, all } from 'redux-saga/effects'
import { createUrl, isEmpty } from '../../utils/common'
import { posCommission, globalComission } from '../actions/posCommisionAction'
import {toggleBackDrop} from 'store/actions/commonAction';

// ######### Get Pos Commission ###################################################

function fetchPosCommission (posId) {
  return axios.request({
    method: 'get',
    url: createUrl(urls.BASE_URL + urls.pos.BASE_URL) + '/' + posId + urls.pos.COMMISSION,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function * fetchPosCommissionActionEffect (posDetail) {
  console.log('payload user id ---->', posDetail)
  try {
    const { data } = yield call(fetchPosCommission, posDetail.payload)

    if (!isEmpty(data)) {
      console.log('POS Data fetched')

      yield put(posCommission(data))
    } else {
      // reject(data);
      yield put(posCommission([]))
    }
  } catch (e) {
    console.log('Error found while fetching pos')
    yield put(posCommission([]))
  }
}

export function * fetchPosCommissionActionWatcher () {
  yield takeLatest(actions.FETCH_POS_COMMISSION_WATCHER, fetchPosCommissionActionEffect)
}

// ########## Fetch Global commission ##############################################

function fetchGlobalCommission () {
  return axios.request({
    method: 'get',
    url: createUrl(urls.BASE_URL + urls.pos.BASE_URL) + urls.pos.GLOBAL_COMISSION,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}


export function * fetchGlobalCommissionActionEffect () {
  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(fetchGlobalCommission)
    yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      console.log('Global Commission fetched')

      yield put(globalComission(data))
    } else {
      yield put(globalComission([]));
      // reject(data);
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    console.log('Error found while fetching pos');
    yield put(globalComission([]));
  }
}

export function * fetchGlobalCommissionActionWatcher () {
  yield takeLatest(actions.FETCH_GLOBAL_COMMISSION_WATCHER, fetchGlobalCommissionActionEffect)
}

// ########### update pos fixed commission #######################################################

function updatePosCommission (param) {
  const url = createUrl(urls.BASE_URL + urls.pos.BASE_URL + urls.pos.DETAIL) + '/' + param.userId + urls.pos.UPLOAD_COMMISSION
  console.log(url)
  const params = { insuranceDetails: param.insuranceDetails }
  return axios.request({
    method: 'put',
    url: url,
    headers: {
      'Content-Type': 'application/json'
    },
    data: params
  })
}

export function * updatePosCommissionActionEffect (param) {
  const { payload, resolve, reject } = param
  console.log('payload user id ---->', payload)
  try {
    const { data } = yield call(updatePosCommission, payload)
    if (!isEmpty(data)) {
      console.log('POS Data fetched')
      // yield put(fetchposDetailWatcher(payload.posId));
      resolve(data)

      //  yield put(posDetail(data));
    } else {
      reject(data)
    }
  } catch (e) {
    reject(e)
    console.log('Error found while fetching pos')
  }
}

export function * updatePosCommissionActionWatcher () {
  yield takeLatest(actions.UPDATE_POS_COMMISSION_WATCHER, updatePosCommissionActionEffect)
}

// ######### Update Global Commission #############################

function updateGlobalCommission (param) {
  const url = createUrl(urls.BASE_URL + urls.pos.BASE_URL + urls.pos.DETAIL) + urls.pos.UPLOAD_GLOBAL_COMMISSION
  console.log(url)
  const params = { insuranceDetails: [...param] }
  return axios.request({
    method: 'put',
    url: url,
    headers: {
      'Content-Type': 'application/json'
    },
    data: params
  })
}

export function * updateGlobalCommissionActionEffect (param) {
  const { payload, resolve, reject } = param
  console.log('payload user id ---->', payload)
  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(updateGlobalCommission, payload)
    yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      console.log('POS Data fetched')
      // yield put(fetchposDetailWatcher(payload.posId));
      resolve(data)

      //  yield put(posDetail(data));
    } else {
      yield put(toggleBackDrop(false));
      reject(data)
    }
  } catch (e) {
    reject(e)
    console.log('Error found while fetching pos')
  }
}

export function * updateGlobalCommissionActionWatcher () {
  yield takeLatest(actions.UPDATE_GLOBAL_COMMISSION_WATCHER, updateGlobalCommissionActionEffect)
}

export default function * rootSaga() {
  yield all([
    fork(fetchPosCommissionActionWatcher),
    fork(updatePosCommissionActionWatcher),
    fork(fetchGlobalCommissionActionWatcher),
    fork(updateGlobalCommissionActionWatcher)
  ])
}
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from "../../assets/jss/componentsStyles/tableStyle.js";
import { makeStyles } from '@material-ui/core/styles';
import {POSProleMapper, LevelUnderDSARank} from '../../utils/constants';
import CustomInput from '../../components/CustomInput/CustomInput';

import {
    TableBody,
    Checkbox,
    TableCell,
    TableRow,
    TableHead,
    Table,
} from '@material-ui/core';


function desc(a, b, orderBy) {
    if (LevelUnderDSARank[b[orderBy]] < LevelUnderDSARank[a[orderBy]]) {
        return -1;
    }
    if (LevelUnderDSARank[b[orderBy]] > LevelUnderDSARank[a[orderBy]]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

//headCell -{id:"", label: ''}
function EnhancedTableHead(props) {
    const { classes, tableHeaderColor, headCells, onSelectAllClick, order, orderBy, numSelected, rowCount, isSelectRequired } = props;

    return (
        <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
                {isSelectRequired && <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all' }}
                    />
                </TableCell>}
                {headCells.map((headCell, key) => {
                    return (
                        <TableCell
                            className={classes.tableCell + " " + classes.tableHeadCell}
                            sortDirection={orderBy === headCell.id ? order : false}
                            key={key}
                        >

                            {/* <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={order}
                                onClick={createSortHandler(headCell.id)}
                            > */}
                                {headCell.label}
                                {/* {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null} */}
                            {/* </TableSortLabel> */}
                        </TableCell>
                    );
                })}

            </TableRow>
        </TableHead>

    );
}
/*
@props( rows, orderAs, orderByParameter, isSelectRequired, tableHeaderColor, headCells, getDetails)
*/

const useStyles = makeStyles(styles);
export default function GlobalChainCommissionTable(props) {

    const { rows = [], orderAs = "", orderByParameter = "",
        isSelectRequired = false, tableHeaderColor = "", headCells = [], isEdit, handleChange
         } = props;
    const classes = useStyles();
    const [order] = React.useState(orderAs);
    const [orderBy] = React.useState(orderByParameter);
    const [selected, setSelected] = React.useState([]);
    const [page] = React.useState(0);
    // const [dense, setDense] = React.useState(false);
    const [rowsPerPage] = React.useState(10);

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const isSelected = name => selected.indexOf(name) !== -1;

    const handleSelectAllClick = event => {
        if (event.target.checked) {
            const newSelecteds = rows.map(n => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    return (
        <Fragment>
            <div className={classes.tableResponsive}>
                <Table className={classes.table} stickyHeader>

                    <EnhancedTableHead
                        classes={classes}
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        // onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                        isSelectRequired={isSelectRequired}
                        tableHeaderColor={tableHeaderColor}
                        headCells={headCells}
                    />

                    <TableBody>
                        {stableSort(rows, getSorting(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                const isItemSelected = isSelected(row.name);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        hover
                                        onClick={event => handleClick(event, row.name)}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.name}
                                        selected={isItemSelected}
                                        className={classes.tableBodyRow}
                                    >
                                        {isSelectRequired && <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={isItemSelected}
                                                inputProps={{ 'aria-labelledby': labelId }}
                                            />
                                        </TableCell>}

                                        {Object.keys(row).map((key, index) => {
                                            let display = null;
                                            switch (key) {
                                                case 'roleCode':
                                                    // display = (<Button color="primary" round size="sm" id={row[key]} onClick={(event) => getDetails(event, row[key])}>Details</Button>);
                                                    display = (POSProleMapper.get(row[key]));
                                                    break;

                                                case 'commissionPercentage':
                                                    display = isEdit ? (<CustomInput 
                                                        id={`percentage${index}`}
                                                        formControlProps={{
                                                        fullWidth: true,
                                                        style: {paddingTop: '0px', maxWidth: '340px'}
                                                        }}
                                                         onChange={({ target: { value } }) => value ? handleChange(value, row) : handleChange(0, row)}
                                                        inputProps={{
                                                          value: (row[key] || ''),
                                                          type: 'number',
                                                           inputProps: { min:"0",
                                                                       max: "100"},
                                                          onBlur: ({ target: { value } }) => handleChange( +value, row),
                                                       //    error: isError('fullName')
                                                        }}
                                                        className={classes.inputText}
                                                      />) : row[key];
                                                        break;

                                                default:
                                                    display = (row[key]);
                                                    break;
                                            }
                                            return (
                                                <TableCell className={classes.tableCell} key={index}>
                                                    {display}
                                                </TableCell>
                                            );
                                        })}


                                    </TableRow>
                                );
                            })}

                    </TableBody>
                </Table>

            </div>

        </Fragment>
    );
}

GlobalChainCommissionTable.propTypes = {
    rows: PropTypes.array.isRequired,
    orderAs: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderByParameter: PropTypes.string.isRequired,
    isSelectRequired: PropTypes.bool.isRequired,
    tableHeaderColor: PropTypes.string.isRequired,
    headCells: PropTypes.array.isRequired
}
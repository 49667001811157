import { call, takeLatest, all, fork } from 'redux-saga/effects'
import * as actions from '../actions/types'
import axios from 'utils/axios'
import urls from 'utils/urls'
import { createUrl, isEmpty } from 'utils/common'

//######## Create Notification ##########################################

function createNotification (param) {
    const url = createUrl(urls.BASE_URL + urls.notification.BASE_URL) + urls.notification.SEND_NOTIFICATION
    console.log(url)
    return axios.request({
      method: 'post',
      url: url,
      headers: {
        'Content-Type': 'application/json'
      },
      data: param
    })
  }
  
  export function * createNotificationActionEffect (params) {
    const { payload, resolve, reject } = params
  
    try {
      const { data } = yield call(createNotification, payload)
      if (!isEmpty(data)) {
        console.log('Notification Created')
        resolve(data)
      } else {
        reject(data)
      }
    } catch (e) {
      console.log('Error found while fetching dsa')
      reject(e)
    }
  }

export function * createNotificationActionWatcher () {
    yield takeLatest(actions.SEND_NOTIFICATION_WATCHER, createNotificationActionEffect)
  }


export default function * rootSaga() {
    yield all([
        fork(createNotificationActionWatcher)
    ])
}
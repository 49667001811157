/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import Card from 'components/Card/Card'
import Search from '@material-ui/icons/Search'
import CustomInput from 'components/CustomInput/CustomInput'
import Button from 'components/CustomButtons/Button'
import HistoryIcon from '@material-ui/icons/History'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import styleHeader from 'assets/jss/componentsStyles/headerLinksStyle'
import CustomHeaderContentTabs from 'components/CustomTabs/CustomHeaderContentTabs'
import FuzzySearch from 'fuzzy-search'
import Tooltip from '@material-ui/core/Tooltip';
import EcoIcon from '@material-ui/icons/Eco';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PayoutTable from './PayoutTable';
import CustomMonthPicker from './CustomMonthPicker';
import moment from 'moment'

const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0'
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF'
    }
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: 'Montserrat',
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1'
    }
  },
  inputSearchType: {
    color: 'white',
    padding: '0'
  },
  paddingStyle: {
    padding: '0 12px 0'
  },
  headerStyle: {
    marginLeft: 'auto', 
    alignSelf: 'flex-end', 
  }
}

function createData(fullName, phone, amountToBePaid, amountPaid, dateOfPayoutCreation, amountPaidOn, userId, payoutMonth, transactionNo) {
  const dueDate = moment(parseInt(dateOfPayoutCreation)).set('date',7).add(1,'M').format('DD/MM/YYYY')
  // const datePC = changeEpoachDateToFormat(parseInt(dateOfPayoutCreation), 'DD/MM/YYYY');
  // const datePC  = new Date(dateOfPayoutCreation);

  return { fullName, phone, amountToBePaid: parseFloat(amountToBePaid).toFixed(2), amountPaid: parseFloat(amountPaid).toFixed(2), dueDate, amountPaidOn, userId, payoutMonth, transactionNo }
}

const useStyles = makeStyles(styles)
const headerStyle = makeStyles(styleHeader)

export default function FixedPayoutList(props) {
  const { posPayoutList = [], getDetails, toggleUpdateDialog, selectedDate, handleDateChange, globalPayoutFrequency={} } = props;
  const classes = useStyles()
  const headerClass = headerStyle()
  const [showInput, toggleInput] = React.useState(false)
  const [filteredList, setList] = React.useState([]);
  const { frequencyType='' } = globalPayoutFrequency;

  const rows = []

  posPayoutList.forEach(pos => {
    const row = createData(pos.fullName, pos.phone, pos.amountToBePaid, pos.amountPaid, pos.dateOfPayoutCreation, pos.amountPaidOn, pos.userId, pos.payoutMonth, pos.transactionNo)
    rows.push(row)
  })

  React.useEffect(() => {
    setList(rows) // api.data.count from api after update store
  }, [posPayoutList])

  React.useEffect(() => { document.getElementById('searchInput') && document.getElementById('searchInput').focus() }, [showInput])


  const filterList = (e) => {
    // Variable to hold the original version of the list
    let currentList = []
    // Variable to hold the filtered list before putting into state

    let newList = []
    // If the search bar isn't empty
    if (e.target.value !== '') {
      // Assign the original list to currentList
      currentList = rows
      const searcher = new FuzzySearch(currentList, ['fullName', 'phone'], {
        caseSensitive: false
      })
      newList = searcher.search(e.target.value.toLowerCase())

    } else {
      // If the search bar is empty, set newList to original task list
      newList = [...rows]
    }

    setList(newList)
  }

  const headCellsPending = [
    { id: 'fullName', label: 'POSP Name' },
    { id: 'phone', label: 'Phone No' },
    { id: 'amountToBePaid', label: 'Amount To Be Paid (Net)' },
    // { id: 'amountPaid', label: 'Amount Paid' },
    { id: 'duDate', label: 'Due Date' },
    { id: 'userId', label: 'Update' }
  ];

  const headCellsCompleted =[
    { id: 'fullName', label: 'POSP Name' },
    { id: 'phone', label: 'Phone No' },
    { id: 'amountToBePaid', label: 'Amount To Be Paid (Net)' },
    { id: 'amountPaid', label: 'Amount Paid' },
    { id: 'amountPaidOn', label: 'Amount Paid On'},
    {id: 'transactionNo', label: 'Transaction No'}
  ];

  const payoutTableContent = (tabName) => {
    let filterList = [...filteredList];
    filterList = filterList .filter(row => {if(tabName === 'pending'){
      return(parseInt(row.amountToBePaid) > parseInt(row.amountPaid))
  }
   
else return parseInt(row.amountToBePaid) <= parseInt(row.amountPaid)})
    return (
      <PayoutTable
        tableHeaderColor="primary"
        headCells={tabName === 'pending' ? headCellsPending : headCellsCompleted}
        isSelectRequired={false}
        orderByParameter="name"
        orderAs="asc"
        rows={filterList}
        getDetails={getDetails}
        toggleUpdateDialog={toggleUpdateDialog}
        isApproval={true}
        tabName={tabName}
      />
    );
  }

  const tabs = [{ tabName: 'Pending', tabIcon: HistoryIcon, tabContent: payoutTableContent('pending') },
  { tabName: 'Completed', tabIcon: CheckCircleOutlineIcon, tabContent: payoutTableContent('completed') }];


  return (

    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CustomHeaderContentTabs color="primary"
            headerColor="primary"
            tabs={tabs}
            plainTabs={false}
            classHeader={classes.tabHeader}
          >
            <span style={{ alignSelf: 'flex-start', maxHeight: '38px', marginLeft: '15px' }}>
              {/* <h4 className={classes.cardTitleWhite}>Fixed Payout</h4> */}
              <CustomMonthPicker 
              handleDateChange={handleDateChange}
              value={selectedDate}
              />
            </span>
                
            <span className={headerClass.searchWrapper + " " + classes.headerStyle}>
            <span><label style={{color:'white'}}>Frequency Type: </label> {' '}<strong>{frequencyType}</strong>{'   '}|{'   '}</span>
              {showInput && <CustomInput
                id="searchInput"
                formControlProps={{
                  className: classNames(headerClass.margin, headerClass.search)
                }}
                onChange={(event) => filterList(event)}
                inputProps={{
                  inputProps: {
                    'aria-label': 'Search',
                    className: classes.inputSearchType
                  }
                }}
              // onChange={(event) => filterList(event)}
              />}
              <Button className={classes.paddingStyle} color="white" aria-label="edit" simple onClick={() => toggleInput(!showInput)}>
                <Search /> {' '}Search
              </Button>
              {/* <Tooltip title="Download Sample file to upload Payout List">
                <Button color="white" className={classes.paddingStyle} aria-label="edit" simple >
                  <EcoIcon /> {' '} Download Sample
              </Button>
              </Tooltip>

              <Tooltip title="Upload Payout List">
                <Button color="white" className={classes.paddingStyle} aria-label="edit" simple component="label" variant="contained">
                  <CloudUploadIcon /> {' '} Upload List
                <input style={{ display: 'none' }} type="file" id='file' name="file" />
                </Button>
              </Tooltip> */}

            </span>
          </CustomHeaderContentTabs>
        </Card>
      </GridItem>
    </GridContainer>

  );
}  

import React from 'react';

import { Button as MuiButton}  from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import WarningIcon from '@material-ui/icons/Warning';

import Button from 'components/CustomButtons/Button';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
 

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="bottom" ref={ref} {...props} />;
});

export default function WarningModal(props) {
  const { 
    open
    , toggleWarningModal 
    , update 
    , title
    , message
    , okIsPrimary = true
  } = props;

  const handleClose = () => {
    toggleWarningModal();
  };

  return (
    <div> 
      <Dialog
      style={{zIndex:1301}}
        open={open}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">

          <GridContainer>
            <GridItem>
            <WarningIcon color="secondary"/>
            </GridItem>
            <GridItem style={{marginTop:-5, marginLeft:-20}}>
            <span>{title}</span>
            </GridItem>
          </GridContainer> 
          
          </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {message}
            {/* Are you sure, you want to share <b> ALL DATA </b> with this role? */}
          </DialogContentText>
        </DialogContent>
          {
            okIsPrimary && (
              <DialogActions>
                <MuiButton  style={{padding:"7px 17px"}} color="default" variant="outlined"  onClick={handleClose}  >
                  Cancel
                </MuiButton>
                
                <Button onClick={update} color="primary" >
                  Ok
                </Button>
              </DialogActions>
            )
          }

          {
            !okIsPrimary && (
              <DialogActions>
                <MuiButton onClick={update}  style={{padding:"7px 17px"}} color="default" variant="outlined"  onClick={update} >
                  Ok
                </MuiButton>
                <Button  color="primary"  onClick={handleClose}>
                  Cancel
                </Button>
              </DialogActions>
            )
          }
      </Dialog>
    </div>
  );
}

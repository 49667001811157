import {grayColor} from 'assets/jss/material-dashbord-react';


export const styles = {
    cardCategoryWhite: {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "Montserrat",
      marginBottom: "3px",
      textDecoration: "none"
    },
    approved: {
      color: "#008000"
    },
    unapproved: {
      color: "#f00"
    },
    cardCategory: {
      marginBlockStart: "1.33em",
      marginBlockEnd: "1.33em"
    },
    cardBody: {
      paddingLeft: "0px",
      paddingRight: "0px"
    },
  
    cardTitle: {
      margin: "0px",
    },
    profileBackground: {
      background: "#ffffff"
    },
    tabHeader: {
      width: "calc(100% + 40px)",
      marginLeft: "-20px"
    },
    buttonFloat: {
      float: 'right'
    },

    formControlStyle :{
      borderColor: grayColor[4] + "!important"
  
    },
    outlinedClass: {
      borderColor:grayColor[4],
      '&:hover fieldset': {
            borderColor: 'yellow',
          },
    
      
      // '& label.Mui-focused': {
      //   color: 'green',
      // },
      // '& .MuiInput-underline:after': {
      //   borderBottomColor: 'green',
      // },
      // '& .MuiOutlinedInput-root': {
      //   '& fieldset': {
      //     borderColor: 'red',
      //   },
      //   '&:hover fieldset': {
      //     borderColor: 'yellow',
      //   },
      //   '&.Mui-focused fieldset': {
      //     borderColor: 'green',
      //   },
      // },

    },
    buttonStyle: {
      border: '1px solid',
      // '&:hover': {
      //   backgroundColor: '#EDDAED',
      // },
    },
    error: {
      fontSize: "11px",
      color: "red",
  },

  

  
  };
import { call, put, takeLatest, all, fork } from 'redux-saga/effects'
import * as actions from '../actions/types'
import axios from 'utils/axios'
import urls from 'utils/urls'
import { createUrl, isEmpty } from 'utils/common'

import {globalPayoutFrequency} from '../actions/payoutFrequencyAction';
import ErrorIcon from '@material-ui/icons/Error'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import {updateAlertMsg} from 'store/actions/commonAction';
import {toggleBackDrop} from 'store/actions/commonAction';


// ################### fetch the payout frequency saga ############################################

function fetchPayoutFrequency () {
    return axios.request({
      method: 'get',
      url: createUrl(urls.BASE_URL + urls.global.BASE_URL+urls.global.PAYOUT_FREQUENCY),
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }
  
  export function * fetchPayoutFrequencyActionEffect () {
    try {
      const { data } = yield call(fetchPayoutFrequency)
  
      if (!isEmpty(data)) {
        console.log('Payout Frequency Data fetched')
  
        yield put(globalPayoutFrequency((data && data.result) || {}))
      } else {
       yield put(globalPayoutFrequency({}))
      }
    } catch (e) {
        const alertMsg = {
            color: 'danger',
            icon: ErrorIcon,
            message: 'Something went wrong while fetching data. Please try again later.'
          }
          yield put(updateAlertMsg(alertMsg));
      console.log('Error found while fetching dsa')
    }
  } 
  export function * fetchPayoutFrequencyActionWatcher () {
    yield takeLatest(actions.FETCH_PAYOUT_FREQUENCY, fetchPayoutFrequencyActionEffect)
  }

  // ############ Create Payout frequency  ################################################

  function createPayoutFrequency (param) {
    const url = createUrl(urls.BASE_URL + urls.global.BASE_URL) +urls.global.PAYOUT_FREQUENCY
    return axios.request({
      method: 'post',
      url: url,
      headers: {
        'Content-Type': 'application/json'
      },
      data: param
    })
  }
  
  export function * createPayoutFrequencyEffect (params) {
    const { payload, resolve, reject } = params
  
    try {
        yield put(toggleBackDrop(true));
      const { data } = yield call(createPayoutFrequency, payload)
      yield put(toggleBackDrop(false));

      if (!isEmpty(data)) {
        resolve(data)
        const alertMsg = {
            color: 'success',
            icon: CheckCircleOutlineIcon,
            message: 'Payout Frequency Set Successfully.'
          }
          yield put(updateAlertMsg(alertMsg));
        
      } else {
        const alertMsg = {
            color: 'danger',
            icon: ErrorIcon,
            message: 'Something went wrong while fetching data. Please try again later.'
          }
          yield put(updateAlertMsg(alertMsg));
        reject(data)
      }
    } catch (e) {
      console.log('Error found while fetching dsa')
      yield put(toggleBackDrop(false));
      const alertMsg = {
        color: 'danger',
        icon: ErrorIcon,
        message: 'Something went wrong while fetching data. Please try again later.'
      }
      yield put(updateAlertMsg(alertMsg));
      reject(e)
    }
  }
  
  export function * createPayoutFrequencyWatcher () {
    yield takeLatest(actions.CREATE_PAYOUT_FREQUENCY, createPayoutFrequencyEffect)
  }


  export default function * rootSaga() {
    yield all([
      fork(fetchPayoutFrequencyActionWatcher),
      fork(createPayoutFrequencyWatcher)
    ])
  }
import { call, put, takeLatest, all, fork } from 'redux-saga/effects'
import * as actions from '../actions/types'
import axios from '../../utils/axios'
import urls from '../../utils/urls'
import startCase from 'lodash/startCase'
import { createUrl, exportCsv, isEmpty } from '../../utils/common'
import {
  posList,
  posDetail,
  policySoldList,
  posOnboardingList,
  fetchposDetailWatcher,
  fetchposListWatcher,
  fetchposOnboardingListWatcher,
  posEarning,
  rotUsersList,
  userPermissions,
  pendingProposalsList
} from '../actions/posAction'
import { toggleBackDrop, updateAlertMsg } from 'store/actions/commonAction';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
// ##################### Get all pos list ##########################################################

function fetchPosList() {
  return axios.request({
    method: 'get',
    url: createUrl(urls.BASE_URL + urls.pos.BASE_URL),
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

// Saga function that handles the side effect when the loginActionWatcher is triggered
export function* fetchPosListActionEffect() {
  // let { resolve, reject } = posAction;

  try {
    const { data } = yield call(fetchPosList)

    if (!isEmpty(data)) {
      console.log('POS Data fetched')

      yield put(posList(data))
    } else {
      // reject(data);
    }
  } catch (e) {
    //   alert.error(e.message || 'Authentication Error');
    //   yield put(authError(e));
    console.log('Error found while fetching pos')
      // if (reject) reject(e);
  }
}

// Saga function that is initiated in the beginning to be able to listen to LOG_IN_WATCHER action
export function* fetchPosListActionWatcher() {
  yield takeLatest(actions.POS_LIST_WATCHER, fetchPosListActionEffect)
}

// ##################### Get pos Earning Details ##########################################################

function fetchPosEarning(param) {
  const posId = param.posId
  delete param.posId

  return axios.request({
    method: 'get',
    url: createUrl(urls.BASE_URL + urls.pos.BASE_URL + urls.pos.DETAIL) + `/${posId}${urls.pos.EARNING}`,
    headers: {
      'Content-Type': 'application/json'
    },
    params: !isEmpty(param) ? {...param } : ''
  })
}

// Saga function that handles the side effect when the loginActionWatcher is triggered
export function* fetchPosEarningActionEffect(param) {
  const { payload, resolve, reject } = param

  try {
    const { data } = yield call(fetchPosEarning, payload)

    if (!isEmpty(data) && !isEmpty(data.userId)) {
      console.log('POS Earning data fetched')

      yield put(posEarning(data))
      resolve(data)
    } else {
      reject(data)
      yield put(posEarning({}))
    }
  } catch (e) {
    yield put(posEarning({}))
    reject(e)
      //   alert.error(e.message || 'Authentication Error');
      //   yield put(authError(e));

    console.log('Error found while fetching pos')
      // if (reject) reject(e);
  }
}

// Saga function that is initiated in the beginning to be able to listen to LOG_IN_WATCHER action
export function* fetchPosEarningActionWatcher() {
  yield takeLatest(actions.FETCH_POSP_EARNING_WATCHER, fetchPosEarningActionEffect)
}

// ##################### Get pos Onboarding list ##########################################################

function fetchPosOnboardingList() {
  return axios.request({
    method: 'get',
    url: createUrl(urls.BASE_URL + urls.pos.BASE_URL + urls.pos.ONBOARDING_LIST),
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

// Saga function that handles the side effect when the loginActionWatcher is triggered
export function* fetchPosOnboardingListEffect() {
  // let { resolve, reject } = posAction;

  try {
    const { data } = yield call(fetchPosOnboardingList)

    if (!isEmpty(data)) {
      console.log('POS Data fetched')

      yield put(posOnboardingList(data))
    } else {
      // reject(data);
    }
  } catch (e) {
    //   alert.error(e.message || 'Authentication Error');
    //   yield put(authError(e));
    console.log('Error found while fetching pos')
      // if (reject) reject(e);
  }
}

// Saga function that is initiated in the beginning to be able to listen to LOG_IN_WATCHER action
export function* fetchPosOnboardingListActionWatcher() {
  yield takeLatest(actions.POS_ONBOARDING_LIST_WATCHER, fetchPosOnboardingListEffect)
}

// ###################### Fetch Pos User Details #################################################

function fetchPosDetail(posId) {
  const url = createUrl(urls.BASE_URL + urls.pos.BASE_URL + urls.pos.DETAIL) + '/' + posId
  console.log(url)
  return axios.request({
    method: 'get',
    url: url,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function* fetchPosDetailActionEffect(posDetails) {
  const { payload } = posDetails
  console.log('payload user id ---->', payload)
  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(fetchPosDetail, payload)
    yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      console.log('POS Data fetched')

      yield put(posDetail(data))
    } else {
      // reject(data);
      yield put(posDetail({}))
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    console.log('Error found while fetching pos')
    yield put(posDetail({}))
  }
}

export function* fetchPosDetailActionWatcher() {
  yield takeLatest(actions.POS_DETAIL_WATCHER, fetchPosDetailActionEffect)
}

// ##################### Update Pos Education Info ################################################

function updatePosEducationInfo(posDetail) {
  const url = createUrl(urls.BASE_URL + urls.pos.BASE_URL + urls.pos.DETAIL) + '/' + posDetail.posId + urls.pos.EDUCATION_INFO
  console.log(url)
  return axios.request({
    method: 'put',
    url: url,
    headers: {
      'Content-Type': 'application/json'
    },
    data: posDetail
  })
}

export function* updatePosEducationInfoActionEffect(posDetails) {
  const { payload, resolve, reject } = posDetails
  console.log('payload user id ---->', payload)
  try {
    const { data } = yield call(updatePosEducationInfo, payload)
    if (!isEmpty(data)) {
      console.log('POS Data fetched')
      yield put(fetchposDetailWatcher(payload.posId))
      resolve()

      //  yield put(posDetail(data));
    } else {
      reject(data)
    }
  } catch (e) {
    reject(e.data)
    console.log('Error found while fetching pos')
  }
}

export function* updatePosEducationInfoActionWatcher() {
    yield takeLatest(actions.UPDATE_EDUCATION_INFO_WATCHER, updatePosEducationInfoActionEffect)
  }
  // ############################# Update Pos Bank Info ###############################################################

function updatePosBankInfo(posDetail) {
  const url = createUrl(urls.BASE_URL + urls.pos.BASE_URL + urls.pos.DETAIL) + '/' + posDetail.posId + urls.pos.BANK_INFO
  console.log(url)
  return axios.request({
    method: 'put',
    url: url,
    headers: {
      'Content-Type': 'application/json'
    },
    data: posDetail
  })
}

export function* updatePosBankInfoActionEffect(posDetails) {
  const { payload, resolve, reject } = posDetails
  console.log('payload user id ---->', payload)
  try {
    const { data } = yield call(updatePosBankInfo, payload)
    if (!isEmpty(data)) {
      console.log('POS Data fetched')
      yield put(fetchposDetailWatcher(payload.posId))
      resolve()

      //  yield put(posDetail(data));
    } else {
      reject(data)
    }
  } catch (e) {
    reject(e.data)
    console.log('Error found while fetching pos')
  }
}

export function* updatePosBankInfoActionWatcher() {
  yield takeLatest(actions.UPDATE_BANK_INFO_WATCHER, updatePosBankInfoActionEffect)
}

// ################# Update Pos Personal Info ####################################

function updatePosPersonalInfo(posDetail) {
  const url = createUrl(urls.BASE_URL + urls.pos.BASE_URL + urls.pos.DETAIL) + '/' + posDetail.posId + urls.pos.PERSONAL_INFO
  console.log(url)
  return axios.request({
    method: 'put',
    url: url,
    headers: {
      'Content-Type': 'application/json'
    },
    data: posDetail
  })
}

export function* updatePosPersonalInfoActionEffect(posDetails) {
  const { payload, resolve, reject } = posDetails
  console.log('payload user id ---->', payload)
  try {
    const { data } = yield call(updatePosPersonalInfo, payload)
    if (!isEmpty(data)) {
      console.log('POS Data fetched')
      yield put(fetchposDetailWatcher(payload.posId))
      resolve()
        // yield put(posDetail(data));
    } else {
      reject(data)
    }
  } catch (e) {
    reject(e.data)
    console.log('Error found while fetching pos')
  }
}

export function* updatePosPersonalInfoActionWatcher() {
  yield takeLatest(actions.UPDATE_PERSONAL_INFO_WATCHER, updatePosPersonalInfoActionEffect)
}

// ##################### Update Pos Business Info Action Watcher #########################

function updatePosBusinessInfo(posDetail) {
  const url = createUrl(urls.BASE_URL + urls.pos.BASE_URL + urls.pos.DETAIL) + '/' + posDetail.posId + urls.pos.BUSINESS_INFO
  console.log(url)
  return axios.request({
    method: 'put',
    url: url,
    headers: {
      'Content-Type': 'application/json'
    },
    data: posDetail
  })
}

export function* updatePosBusinessInfoActionEffect(posDetails) {
  const { payload, resolve, reject } = posDetails
  console.log('payload user id ---->', payload)
  try {
    const { data } = yield call(updatePosBusinessInfo, payload)
    if (!isEmpty(data)) {
      console.log('POS Data fetched')
      yield put(fetchposDetailWatcher(payload.posId))
      resolve()
        //  yield put(posDetail(data));
    } else {
      reject(data)
    }
  } catch (e) {
    reject(e.data)
    console.log('Error found while fetching pos')
  }
}

export function* updatePosBusinessInfoActionWatcher() {
    yield takeLatest(actions.UPDATE_BUSINESS_INFO_WATCHER, updatePosBusinessInfoActionEffect)
  }
  // ############### POS Certification Status Update #################################

function updatePosCertificationInfo(posDetail) {
  const url = createUrl(urls.BASE_URL + urls.pos.BASE_URL + urls.pos.DETAIL) + '/' + posDetail.posId + urls.pos.CERTIFICATION_INFO
  console.log(url)
  return axios.request({
    method: 'put',
    url: url,
    headers: {
      'Content-Type': 'application/json'
    },
    data: posDetail
  })
}

export function* updatePosCertificationInfoActionEffect(posDetails) {
  const { payload, resolve, reject } = posDetails
  payload.isExamPassed = (payload.isExamPassed === 'true')
  try {
    const { data } = yield call(updatePosCertificationInfo, payload)
    if (!isEmpty(data)) {
      console.log('POS Data fetched')
      yield put(fetchposDetailWatcher(payload.posId))
      resolve()
        //  yield put(posDetail(data));
    } else {
      reject(data)
    }
  } catch (e) {
    reject(e.data)
    console.log('Error found while fetching pos')
  }
}

export function* updatePosCertificationInfoActionWatcher() {
  yield takeLatest(actions.UPDATE_CERTIFICATION_INFO_WATCHER, updatePosCertificationInfoActionEffect)
}

// ################ PoS status upload ##################################################

function updatePosApprovalStatus(params) {
  const url = createUrl(urls.BASE_URL + urls.pos.BASE_URL + urls.pos.DETAIL) + '/' + params.posId + urls.pos.UPLOAD_STATUS
  console.log(url)
  return axios.request({
    method: 'put',
    url: url,
    headers: {
      'Content-Type': 'application/json'
    },
    data: params
  })
}

export function* updatePosApprovalStatusActionEffect(params) {
  const { payload, resolve, reject } = params
  console.log('payload user id ---->', payload)
  try {
    const { data } = yield call(updatePosApprovalStatus, payload)
    if (!isEmpty(data)) {
      console.log('Pos status updated successfully')
      yield put(fetchposOnboardingListWatcher())
      resolve(data)
        //  yield put(posDetail(data));
    } else {
      reject(data)
        // reject(data);
    }
  } catch (e) {
    reject(e.data)
    console.log('Error found while fetching pos')
  }
}

export function* updatePosApprovalStatusActionWatcher() {
  yield takeLatest(actions.UPDATE_POS_APPROVAL_STATUS_WATCHER, updatePosApprovalStatusActionEffect)
}

// #################### Fetch Policy Sold #####################################################

function fetchPolicySoldList(param) {
  return axios.request({
    method: 'post',
    url: createUrl(`${urls.BASE_URL}${urls.pos.BASE_URL}${urls.pos.DETAIL}${urls.pos.POLICY_SOLD}`),
    headers: {
      'Content-Type': 'application/json'
    },
    data: param
  })
}

export function* fetchPolicySoldListActionEffect(params) {
  const { payload } = params
  console.log('payload user id ---->', payload)
  try {
    const { data } = yield call(fetchPolicySoldList, payload)
    if (!isEmpty(data)) {
      console.log('Pos status updated successfully', data)

      yield put(policySoldList(data))

      //  yield put(posDetail(data));
    } else {
      // reject(data);
      yield put(policySoldList({}));
    }
  } catch (e) {
    yield put(policySoldList({}));
    console.log('Error found while fetching pos')
  }
}

export function* fetchPolicySoldListActionWatcher() {
    yield takeLatest(actions.FETCH_POLICY_SOLD_WATCHER, fetchPolicySoldListActionEffect)
  }
  // ################# POS ELIGIBILITY STATUS UPLOAD ###############################

function updatePosEligibiltyStatus(params) {
  const url = createUrl(urls.BASE_URL + urls.pos.BASE_URL) + '/' + params.posId + urls.pos.UPLOAD_ELIGIBILITY_STATUS
  console.log(url)
  return axios.request({
    method: 'put',
    url: url,
    headers: {
      'Content-Type': 'application/json'
    },
    data: params
  })
}

export function* updatePosEligibiltyStatusActionEffect(params) {
  const { payload, resolve, reject } = params
  console.log('payload user id ---->', payload)
  try {
    const { data } = yield call(updatePosEligibiltyStatus, payload)
    if (!isEmpty(data)) {
      console.log('Pos status updated successfully')
      yield put(fetchposListWatcher())
      resolve(data)
        //  yield put(posDetail(data));
    } else {
      reject(data)
    }
  } catch (e) {
    reject(e.data)
    console.log('Error found while fetching pos')
  }
}

export function* updatePosEligibiltyStatusActionWatcher() {
  yield takeLatest(actions.UPDATE_POS_ELIGIBILITY_STATUS_WATCHER, updatePosEligibiltyStatusActionEffect)
}

// ####################### POS DOCUMENTS FETCH ###################################################

function getDocuments(params) {
  const url = createUrl(urls.BASE_URL + urls.pos.DOWNLOAD_DOCUMENTS)
  console.log(url)
  return axios.request({
    method: 'get',
    url: url,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    params: { url: params }
  })
}

export function* getDocumentsActionEffect(params) {
  const { payload, resolve, reject } = params

  console.log('payload is ---->', payload)
  try {
    const { data } = yield call(getDocuments, payload)

    if (!isEmpty(data)) {
      resolve(data)
    } else {
      reject(data)
    }
  } catch (e) {
    reject(e)
    console.log('Error found while fetching pos')
  }
}

export function* getDocumentsActionWatcher() {
  yield takeLatest(actions.DOCUMENTS_GET_WATCHER, getDocumentsActionEffect)
}

// ######################## POSP Application Form ###############################

function getApplicationForm(params) {
  const { userId } = params;
  const url = createUrl(urls.BASE_URL + urls.pos.APPLICATION_FORM + '/' + userId)
  return axios.request({
    method: 'get',
    url: url,
    headers: {
      'Content-Type': 'application/pdf'
    }
  })
}


export function* getPOSPApplicationFormActionEffect(params) {
  const { payload, resolve, reject } = params
  try {
    const { data } = yield call(getApplicationForm, payload)
    if (!isEmpty(data)) {
      resolve(data)
    } else {
      reject(data)
    }
  } catch (e) {
    reject(e)
    console.log('Error found while fetching application form')
  }
}

export function* getPOSPApplicationFormWatcher() {
  yield takeLatest(actions.APPLICATION_FORM_WATCHER, getPOSPApplicationFormActionEffect)
}


// ######### Upload Document #####################################################

function uploadDocument(params) {
  const { posId, documentType, documentName, documentSubType, documentNumber, documentImage } = params
  const url = createUrl(urls.BASE_URL + urls.pos.BASE_URL) + '/' + posId + urls.pos.UPLOAD_DOCUMENT
  console.log(url)
  return axios.request({
    method: 'post',
    url: url,
    headers: {
      'Content-Type': 'multipart/form-data',
      // 'Content-Type': 'text/plain',
      'x-document-type': documentType || '',
      'x-document-name': documentName || '',
      'x-document-subtype': documentSubType || '',
      'x-document-number': documentNumber || ''
    },
    data: documentImage
  })
}

export function* uploadDocumentActionEffect(params) {
  const { payload, resolve, reject } = params

  console.log('payload is ---->', payload)
  try {
    const { data } = yield call(uploadDocument, payload)

    if (!isEmpty(data)) {
      resolve(data)
      yield put(fetchposDetailWatcher(payload.posId))
    } else {
      reject(data)
    }
  } catch (e) {
    console.log('Error found while fetching pos')
    reject(e)
  }
}

export function* uploadDocumentActionWatcher() {
    yield takeLatest(actions.DOCUMENT_UPLOAD_WATCHER, uploadDocumentActionEffect)
  }
  // ################ Update POSP Role ################################################

function updatePospRole(params) {
  const url = createUrl(urls.BASE_URL + urls.pos.BASE_URL) + '/' + params.posId + urls.pos.ROLE
  console.log(url)
  return axios.request({
    method: 'put',
    url: url,
    headers: {
      'Content-Type': 'application/json'
    },
    data: params
  })
}

export function* updatePospRoleActionEffect(params) {
  const { payload, resolve, reject } = params
  console.log('payload user id ---->', payload)
  try {
    const { data } = yield call(updatePospRole, payload)
    if (!isEmpty(data)) {
      console.log('Pos role updated successfully')
      resolve(data)
        //  yield put(posDetail(data));
    } else {
      reject(data)
        // reject(data);
    }
  } catch (e) {
    reject(e)
    console.log('Error found while fetching pos')
  }
}

export function* updatePospRoleActionWatcher() {
  yield takeLatest(actions.UPDATE_POSP_ROLE_WATCHER, updatePospRoleActionEffect)
}

// ############### Update Senior To POSP ##############################################

function updatePospSenior(params) {
  const url = createUrl(urls.BASE_URL + urls.pos.BASE_URL) + '/' + params.posId + urls.pos.SENIOR
  console.log(url)
  return axios.request({
    method: 'put',
    url: url,
    headers: {
      'Content-Type': 'application/json'
    },
    data: params
  })
}

export function* updatePospSeniorActionEffect(params) {
  const { payload, resolve, reject } = params
  console.log('payload user id ---->', payload)
  try {
    const { data } = yield call(updatePospSenior, payload)
    if (!isEmpty(data)) {
      console.log('Pos role updated successfully')
      resolve(data)
        //  yield put(posDetail(data));
    } else {
      reject(data)
        // reject(data);
    }
  } catch (e) {
    reject(e)
    console.log('Error found while fetching pos')
  }
}

export function* updatePospSeniorActionWatcher() {
  yield takeLatest(actions.UPDATE_POSP_SENIOR_WATCHER, updatePospSeniorActionEffect)
}

//##### Upload Posp Utility Watcher ######################################

function uploadPospUtility(param) {

  const url = createUrl(urls.BASE_URL + urls.pos.BROKER) + urls.pos.UPLOAD_POSP
  console.log(url)
  return axios.request({
    method: 'post',
    url: url,
    headers: {
      'Content-Type': 'multipart/form-data',
      // 'Content-Type': 'text/plain',

    },
    data: param
  })
}

export function* uploadPospUtilityActionEffect(params) {
  const { payload, resolve, reject } = params
  console.log('payload user id ---->', payload)
  try {
    const { data } = yield call(uploadPospUtility, payload)
    if (!isEmpty(data)) {
      console.log('Posp list uploaded successfully')
      resolve(data)
        //  yield put(posDetail(data));
    } else {
      reject(data)
        // reject(data);
    }
  } catch (e) {
    reject(e)
    console.log('Error found while fetching pos')
  }
}


export function* uploadPospUtilityActionWatcher() {
  yield takeLatest(actions.UPLOAD_POSP_UTILITY_WATCHER, uploadPospUtilityActionEffect)
}


// ############################# Search ROT users by phone ###############################

function fetchROTUser(params) {
  return axios.request({
    method: 'post',
    url: createUrl(urls.BASE_URL + '/pos/searchUserByNumberAndRole'),
    headers: {
      'Content-Type': 'application/json'
    },
    data: params
  })
}

export function* fetchROTUserByPhoneActionEffect(params) {
  const { payload } = params;
  try {
    const { data } = yield call(fetchROTUser, payload);
    if (!isEmpty(data)) {
      yield put(rotUsersList(data))
    } else {
      yield put(rotUsersList([]))
    }
  } catch (e) {
    console.log('Error found getting user roles.');
  }
}

export function* fetchROTUserByPhoneActionWatcher() {
  yield takeLatest(actions.ROT_USERS_WATCHER, fetchROTUserByPhoneActionEffect)
}

// ############################# Fetych policies Details API ###############################

function fetchPoliciesDetail(params) {
  console.log("params", params);
  const {
    proposalIds = [],
      fromDate,
      toDate,
      proposalStatus = "",
      proposalType
  } = params;
  return axios.request({
    method: 'post',
    url: createUrl(urls.BASE_URL + '/proposal/policiesdetail'),
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      proposalId: proposalIds,
      startDate: fromDate,
      endDate: toDate,
      proposalStatus: proposalStatus,
      proposalType

    }
  })
}

export function* fetchPoliciesDetailActionEffect(params) {
  const { payload } = params;
  try {
    const { data } = yield call(fetchPoliciesDetail, payload);
    // console.log("fetchPoliciesDetail data", data);

    function formatDate(date) {
      let tDate = date.split("-");
      return `${tDate[0]}-${tDate[1]}-${tDate[2]}`;
    }

    if (!isEmpty(data.policyDetails)) {

      const {
        isTable,
        fromDate,
        toDate,
        proposalStatus,
      } = payload;
      // console.log("policies details", data);
      if (isTable) {

        // console.log("fetchPoliciesDetail data", proposalStatus);
        if (proposalStatus === "success") {
          yield put(policySoldList({ policiesSold: data.policyDetails }))
        } else {
          yield put(pendingProposalsList({ pendingProposals: data.policyDetails }))
        }

      } else {
        let dataToShow = [],
          policyDetails = data.policyDetails;
        for (let i = 0; i < policyDetails.length; i++) {
          let temp = {
            ...policyDetails[i],
            ...policyDetails[i].motorDetails,
            ...policyDetails[i].healthDetails,
            ...policyDetails[i].endorsementDetails
          };
          // console.log("temp", i, JSON.stringify(temp));
          delete temp.motorDetails;
          delete temp.endorsementDetails;

          const {
            insuranceType,
            planName,
            policyDocumentLink,
            registrationNo = "",
            rtoCode = "",
            rtoCity = "",
            rtoState = "",
            soldByName = "",
            proposerName = "",
            soldByNumber = "",
            insurer,
            idv,
            newNCB = "",
            policyNo = "",
            previousPolicyNo = "",
            policyNumber = "",
            businessType = "",
            policyStartDate = "",
            policyExpiryDate = "",
            paymentDate = "",
            policyIssuanceDate = "",
            fuelType = "",
            make = "",
            model = "",
            variant = "",
            engineNo = "",
            chassisNo = "",
            premiumBreakup = {},
            referredByName,
            linkBQPByName,
            referredByNumber,
            linkBQPByNumber,
            role,
            proposerEmailId,
            proposerMobileNo,
            proposerAddress = "",
            sumInsured = "",
            proposerDetails = {},
            memberDetails = [],
            nomineeDetails = {},
            appointeeDetails = {}
          } = temp;

          let {
            nomineeFirstName = "",
              nomineeLastName = "",
              nomineeDOB = "",
              nomineeRelation = "",
              nomineeAddressLine1,
              nomineeAddressLine2,
              nomineeAddressLine3,
              state,
              city,
              district,
              area,
              pinCode,
              landmark,
              country
          } = nomineeDetails;

          let nomineeName = `${nomineeFirstName} ${nomineeLastName} `,
            nomineeAddress = `${nomineeAddressLine1 || ""} ${nomineeAddressLine2 || ""} ${nomineeAddressLine3 ||""}  ${area || ""} ${landmark || ""} ${city || ""} ${district||""} ${state || ""} ${country ||""} ${pinCode || ""}`;

          let {
            appointeeFirstName = "",
              appointeeLastName = "",
              appointeeDOB = "",
              appointeeRelation = "",
              appointeeAddressLine1 = "",
              appointeeAddressLine2 = "",
              appointeeAddressLine3 = "",
              state: appointeeState,
              city: appointeeCity,
              district: appointeeDistrict,
              area: appointeeArea,
              pinCode: appointeePincode,
              landmark: appointeeLandmark,
              country: appointeeCountry
          } = appointeeDetails;

          let appointeeName = `${appointeeFirstName} ${appointeeLastName}`,
            appointeeAddress = `${appointeeAddressLine1} ${appointeeAddressLine2} ${appointeeAddressLine3 }  ${appointeeArea || ""} ${appointeeLandmark || ""} ${appointeeCity || ""} ${appointeeDistrict||""} ${appointeeState || ""} ${appointeeCountry ||""} ${appointeePincode || ""}`;

          const {
            title = "",
              firstName = "",
              lastName = "",
              dob = "",
              email = "",
              phone = "",
              gender = "",
              maritalStatus = '',
              address = ""

          } = proposerDetails;

          const {
            title: mTitle = "",
            firstName: mFirstName = "",
            lastName: mLastName = "",
            dob: mDOB = "",
            email: mEmail = "",
            phone: mPhone = "",
            gender: mGender = "",
            maritalStatus: mMaritalStatus = '',
            address: mAddress = ""

          } = memberDetails[0] || {};

          let mAdd = "";
          console.log("mAdd", mAddress)
          if (Array.isArray(mAddress) && mAddress.length) {
            let tempAddress = [];
            for (let i = 0; i < mAddress.length; i++) {
              const {
                addressLine1,
                addressLine2,
                addressLine3,
                area,
                state,
                city,
                district,
                country,
                landmark,
                addressType,
                pinCode
              } = mAddress[i];

              let t = `${addressType} Address: ${addressLine1|| ""} ${addressLine2|| ""} ${addressLine3|| ""} ${area || ""} ${landmark || ""} ${city || ""} ${district||""} ${state || ""} ${country ||""} ${pinCode || ""}`;
              tempAddress.push(t);
            }
            mAdd = tempAddress.join(" | ");
            console.log("mAdd", mAdd)
          }

          const { addOnsPremium = {} } = premiumBreakup;
          const { accessoriesPremium = {} } = premiumBreakup;
          const { additionalCoversPremium = {} } = premiumBreakup;
          const { discounts = {} } = premiumBreakup;
          const { finalPremium = {} } = premiumBreakup;

          const {
            roadsideAssistancePremium = "",
              zeroDepreciationPremium = "",
              invoiceCoverPremium = "",
              engineProtectorPremium = "",
              consumablePremium = "",
              ncbProtectionPremium = "",
              keyLockReplacementPremium = "",
              tyreProtectorPremium = "",
              rimDamageCoverPremium = "",
              lossOfPersonalBelongingsPremium = "",
              graphicExtensionPremium = "",
              secureTowingPremium = "",
              emergencyTransportHotelExpensesPremium = "",
              repairGlassFabricPremium = "",
              imt23_cvPremium = ""

          } = addOnsPremium;

          const {
            electricalAccessoriesPremium = "",
              nonElectricalAccessoriesPremium = "",
              externalbiFuelKitPremium = ""
          } = accessoriesPremium;

          const {
            ownerDriverPaCoverPremium = "",
              unnamedPassengerPaCoverPremium = "",
              PaiddriverPaCoverPremium = "",
              DriverLegalLiabilityCoverPremium = ""
          } = additionalCoversPremium;

          const {
            ncbDiscount = "",
              voluntaryDeductiblesDiscount = "",
              tppdDiscount = "",
              others = ""
          } = discounts;

          const {
            netOdPremium = "",
              netTpPremium = "",
              subTotalPremium = "",
              gst = "",
              totalPremium = ""
          } = finalPremium;

          let policyMonth = "",
            PolicyYear = "";
          if (paymentDate) {
            policyMonth = paymentDate.split("-")[1];
          }

          if (paymentDate) {
            PolicyYear = `${paymentDate.split("-")[2]}`;
          }

          let mapping = {
            //Genaral Fields
            "S.NO.": i + 1,
            "Insurance type": temp.insuranceType,
            "Plan Name": temp.planName,
            "Insurer Name": insurer,
            "Sold by": startCase(soldByName.toLowerCase()),
            "Sold by Number": soldByNumber,
            "Referred By": startCase(referredByName.toLowerCase()),
            "Referred By Number": referredByNumber,
            "BQP/SP": startCase(linkBQPByName.toLowerCase()),
            "BQP/SP Number": linkBQPByNumber,
            "Policy Number": policyNumber ? `"${policyNumber}"` : "",
            "Business Type": businessType,
            "Policy Start Date": formatDate(policyStartDate),
            "Policy End Date": formatDate(policyExpiryDate),
            "Policy Issuance Date": formatDate(policyIssuanceDate),
            "Proposer Name": startCase(proposerName.toLowerCase()),
            "Proposer Number": proposerMobileNo,
            "Proposal Email": proposerEmailId,
            "Proposal Address": proposerAddress,
            "Role": role,
            "Subtotal": subTotalPremium,
            "GST": gst,
            "Total Premium": totalPremium,

            //Motor Fields
            "IDV": idv || "",
            "Registration No": registrationNo.toUpperCase().replace(/-/g, ""),
            "RTO Code": rtoCode.toUpperCase(),
            "RTO City": rtoCity,
            "RTO State": rtoState,
            "Fuel Type": fuelType,
            "Make": make.toUpperCase(),
            "Model": model.toUpperCase(),
            "Variant( CC)": variant.toUpperCase(),
            "Engine No.": engineNo.toUpperCase(),
            "Chassis No.": chassisNo.toUpperCase(),
            "Basic OD Premium": premiumBreakup.basicPremium && premiumBreakup.basicPremium.basicOdPremium ? premiumBreakup.basicPremium.basicOdPremium : "",
            "Basic TP Premium": premiumBreakup.basicPremium && premiumBreakup.basicPremium.basicTpPremium ? premiumBreakup.basicPremium.basicTpPremium : "",
            "24x7 Roadside Assistance Premium": roadsideAssistancePremium,
            "Zero Depreciation Premium": zeroDepreciationPremium,
            "Invoice Cover Premium": invoiceCoverPremium,
            "Engine Protector Premium": engineProtectorPremium,
            "NCB Protection Premium	": ncbProtectionPremium,
            "Consumable Premium": consumablePremium,
            "Key and Lock Replacement Premium": keyLockReplacementPremium,
            "Tyre Protector Premium": tyreProtectorPremium,
            "RIM Damage Cover Premium": rimDamageCoverPremium,
            "Loss of Personal Belongings Premium": lossOfPersonalBelongingsPremium,
            "Geographic Extension Premium": graphicExtensionPremium,
            "Secure Towing Premium": secureTowingPremium,
            "Emergency Transport & Hotel Expenses Premium": emergencyTransportHotelExpensesPremium,
            "Repair Glass/Fiber/Plastic Premium": repairGlassFabricPremium,
            "IMT 23": imt23_cvPremium,
            "Electrical  Premium": electricalAccessoriesPremium,
            "Non-Electrical  Premium": nonElectricalAccessoriesPremium,
            "External Bi Fuel Kit Premium": externalbiFuelKitPremium,
            "Owner Driver PA Cover Premium": ownerDriverPaCoverPremium,
            "Unnamed Passenger Cover Premium": unnamedPassengerPaCoverPremium,
            "Paid Driver PA Cover Premium": PaiddriverPaCoverPremium,
            "Legal Liability to Paid Driver": DriverLegalLiabilityCoverPremium,
            "NCB Discount Premium": ncbDiscount,
            "Voluntary Deductibles Premium": voluntaryDeductiblesDiscount,
            "TPPD Premium": tppdDiscount,
            "other Discount": others,
            "Net OD": netOdPremium,
            "Net TP": netTpPremium,

            //Health Detailds
            "Sum Insured": sumInsured,

            "Proposer title": title,
            "Proposer first name": firstName,
            "Proposer last name": lastName,
            "Proposer DOB": dob,
            "Proposer email": email,
            "Proposer phone": phone,
            "Proposer gender": gender,
            "Proposer marital status": maritalStatus,
            "Proposer Address": address,

            "Insurer Member 1 title": mTitle,
            "Insurer Member 1  first name": mFirstName,
            "Insurer Member 1  last name": mLastName,
            "Insurer Member 1  DOB": mDOB,
            "Insurer Member 1  email": mEmail,
            "Insurer Member 1  phone": mPhone,
            "Insurer Member 1  gender": mGender,
            "Insurer Member 1  marital status": mMaritalStatus,
            "Insurer Member 1  Address": mAdd,

            "Nominee name": nomineeName,
            "Nominee DOB": nomineeDOB,
            "Nominee relation": nomineeRelation,
            "Nominee address": nomineeAddress,

            "Appointee name": appointeeName,
            "Appointee DOB": appointeeDOB,
            "Appointee relation": appointeeRelation,
            "Appointee address": appointeeAddress,
            // "WithPA/Without PA": temp.premiumBreakup && temp.premiumBreakup.additionalCoversPremium && temp.premiumBreakup.additionalCoversPremium.ownerDriverPaCoverPremium ? "Yes" : "No",
            // "RMName": "",



            // "NCB%": newNCB,
            // "PolicyMonth": policyMonth,
            // "PolicyYear": PolicyYear,

            // "Payment Date": paymentDate,
            "Policy PDF Link": policyDocumentLink || ""
          }
          dataToShow.push(mapping);
        }

        exportCsv({
          fileName: `policies_details_${fromDate}_${toDate}`,
          title: 'policies_details',
          data: dataToShow
        });
      }
    } else {
      console.log("no policies found");
      const {
        isTable,
        proposalStatus,
      } = payload;

      if (isTable) {
        console.log("fetchPoliciesDetail data");
        // yield put(policySoldList({
        //   policiesSold: []
        // }))

        if (proposalStatus === "success") {
          yield put(policySoldList({ policiesSold: [] }))
        } else {
          yield put(pendingProposalsList({ pendingProposals: [] }))
        }
      }
    }
  } catch (e) {
    console.log('Error found getting policies details.', e);
  }
}

export function* fetchPoliciesDetailWatcher() {
  yield takeLatest(actions.FETCH_POLICIES_DETAIL_WATCHER, fetchPoliciesDetailActionEffect)
}

// ############################# Policy document link API Call ###############################

function policyDownloadLink(params) {
  const { proposalId, type } = params
  let url = "";
  if (type.toLowerCase() === "health") {
    url = createUrl(`${urls.BASE_URL}/healthkits/downloadProposal?source=web&proposalId=${proposalId}`)
  } else {
    url = createUrl(`${urls.BASE_URL}/motorkits/downloadPolicy?source=web&proposalId=${proposalId}`)
  }
  console.log("uurl", url)
  return axios.request({
    method: 'get',
    url,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function* policyDownloadLinkActionEffect(params) {
  let { payload } = params;
  try {
    let { data } = yield call(policyDownloadLink, payload);

    // let name = data.path.split("/");

    if (!isEmpty(data)) {
      if (data && data.path) {
        let name = data.path.split("/");
        const downloadLink = document.createElement("a");
        downloadLink.href = data.path;
        downloadLink.download = name[name.length - 1];
        downloadLink.target = '_blank';
        downloadLink.click();
      }
    } else {


    }
  } catch (e) {
    console.log('Error found getting policy download.');
  }
}

export function* policyDownloadLinkWatcher() {
  yield takeLatest(actions.POLICY_DOWNLOAD_LINK_WATCHER, policyDownloadLinkActionEffect)
}

// ############################# Fetch User Permissions ###############################

function getUserPermision(params) {
  // console.log("paramsparams", params)
  return axios.request({
    method: 'get',
    url: createUrl(`${urls.BASE_URL}${urls.specialUser.BASE_URL}${urls.specialUser.PERMISSION}/${params.userId}`),
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function* getUserPermisionEffect(params) {
  const { payload } = params;
  try {
    const { data } = yield call(getUserPermision, payload);

    yield put(userPermissions(data))

  } catch (e) {
    console.log('Error found getting user roles.');
  }
}

export function* getUserPermisionWatcher() {
  yield takeLatest(actions.FETCH_USER_PERMISSIONS, getUserPermisionEffect)
}

// ############################# Save User Permissions ###############################

function saveUserPermissions(params) {
  console.log("saveUserPermissions", params)
  return axios.request({
    method: 'post',
    url: createUrl(`${urls.BASE_URL}${urls.specialUser.BASE_URL}${urls.specialUser.SAVE_PERMISSION}/${params.userId}`),
    headers: {
      'Content-Type': 'application/json'
    },
    data: params
  })
}

export function* saveUserPermissionsEffect(params) {
  const { payload } = params;
  try {
    const { data } = yield call(saveUserPermissions, payload);
    console.log("dataaa", data)
    if (!data.hasOwnProperty('errorCode')) {
      yield put(userPermissions(data));
      const alertMsg = {
        color: 'success',
        icon: CheckCircleOutlineIcon,
        message: 'User permissions saved Successfully.'
      }
      yield put(updateAlertMsg(alertMsg));
    } else {
      const alertMsg = {
        color: 'danger',
        icon: ErrorOutlineIcon,
        message: 'Admin console user already exists with this admin email id'
      }
      yield put(updateAlertMsg(alertMsg));
    }

  } catch (e) {
    console.log('Error found getting user roles.');

  }
}

export function* saveUserPermissionsWatcher() {
  yield takeLatest(actions.SAVE_USER_PERMISSIONS, saveUserPermissionsEffect)
}

export default function* rootSagas() {
  yield all([
    fork(fetchPosListActionWatcher),
    fork(fetchPosOnboardingListActionWatcher),
    fork(fetchPosDetailActionWatcher),
    fork(updatePosEducationInfoActionWatcher),
    fork(updatePosBankInfoActionWatcher),
    fork(updatePosPersonalInfoActionWatcher),
    fork(updatePosBusinessInfoActionWatcher),
    fork(updatePosApprovalStatusActionWatcher),
    fork(updatePosCertificationInfoActionWatcher),
    fork(fetchPolicySoldListActionWatcher),
    fork(updatePosEligibiltyStatusActionWatcher),
    fork(getDocumentsActionWatcher),
    fork(uploadDocumentActionWatcher),
    fork(updatePospSeniorActionWatcher),
    fork(updatePospRoleActionWatcher),
    fork(fetchPosEarningActionWatcher),
    fork(uploadPospUtilityActionWatcher),
    fork(getPOSPApplicationFormWatcher),
    fork(fetchROTUserByPhoneActionWatcher),
    fork(fetchPoliciesDetailWatcher),
    fork(policyDownloadLinkWatcher),
    fork(getUserPermisionWatcher),
    fork(saveUserPermissionsWatcher)
  ]);
}
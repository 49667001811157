import * as React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { withStyles } from '@material-ui/core/styles';
import Admin from 'Pages/DashBoard/Admin';
import { fetchMaritalStatusInfoWatcher } from 'store/actions/productAction';
import { fetchUserRolesWatcher } from 'store/actions/productAction';
import { fetchBQPUserByPhoneOrNameWatcher } from 'store/actions/productAction';
import { searchUserByPhoneOrNameWatcher } from 'store/actions/productAction';
import { fetchMasterRolesWatcher } from 'store/actions/adminRoleActions';
import { fetchPartnersWatcher } from 'store/actions/specialUserAction';
import {
    getDocumentsWatcher,
    uploadDocumentWatcher
} from '../../../store/actions/posAction';
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/CardHeader'
import CardBody from 'components/Card/CardBody'
import Button from 'components/CustomButtons/Button';
import { Typography } from "@material-ui/core";
import SpecialForm from "./SpecialForm";
import { isEmpty, getCookie } from "utils/common";
import { toggleBackDrop, updateAlertMsg } from 'store/actions/commonAction';
import { createSpecialUserWatcher } from 'store/actions/specialUserAction'
import ErrorIcon from '@material-ui/icons/Error'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import SpecialFormRedux from './SpecialFormRedux';

const styles = {
    error: {
        fontSize: "11px",
        color: "red",
    },
    submitButton: {
        float: 'right',
        marginTop: 25
    }

}
class SpecialUserCreatePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            bqp: '',
            refUser: '',
            formErrors: {},
            selectedFile: null,
            documentSubType: '',
            documentType: '',
            percent: 0,
            selectedPAN: '',
            selectedAadhaarFront: '',
            selectedAadhaarBack: '',
            selectedCertificate:'',
            selectedCheque:''
        }
    }
    componentDidMount() {
     this.props.fetchMaritalStatusInfoWatcher()
     this.props.fetchUserRolesWatcher();
     this.props.fetchMasterRolesWatcher();
     this.props.fetchPartnersWatcher();
     localStorage.removeItem("createdUserId");
     const payload = {searchParam: 'a'};
     this.props.fetchBQPUserByPhoneOrNameWatcher(payload);
     this.props.searchUserByPhoneOrNameWatcher(payload);
    }

    searchBQPParams = (event, value, reason) => {
        if(value.trim() && 'input' === reason && value.length > 3){
            const payload = {searchParam: value};
            this.props.fetchBQPUserByPhoneOrNameWatcher(payload);
        }
    }

    searchUsersParams = (event, value, reason) => {
        if(value.trim() && 'input' === reason && value.length > 3){
            const payload = {searchParam: value};
            this.props.searchUserByPhoneOrNameWatcher(payload);
        }
    }

    selectedBQP = (option, value) => {
        this.bqp = {
            userId: value && value.id,
            fullName: value && value.label.split(' - ')[1] || '',
            phone: value && value.label.split(' - ')[0] || '',
        };
    }

    selectedRefUser = (option, value)=> {
        this.refUser = {
            userId: value && value.id,
            fullName: value && value.label.split(' - ')[1] || '',
            phone: value && value.label.split(' - ')[0] || '',
        };
    }

    fileUploadHandler = async () => {
        const { toggleBackDrop, updateAlertMsg } = this.props;
        let formData = new FormData();
        if(localStorage.getItem('createdUserId')){
            let selectedDocument = {
                documentImage: this.state.selectedFile,
                documentName: this.state.selectedFile.name,
                documentSubType: this.state.documentSubType,
                documentType: this.state.documentType,
                isUploading: true,
                posId: localStorage.getItem('createdUserId')||'5e96d028ccbb5e030d7b3af4'
            };
            const imageURL = URL.createObjectURL(selectedDocument.documentImage);
            const file = await fetch(imageURL).then(r => r.blob()).then(blobFile => new File([blobFile], selectedDocument.documentName, { type: blobFile.type }))
            formData.append('document', file)
            selectedDocument.documentImage = formData;
             new Promise((resolve, reject) => {
              this.props.uploadDocumentWatcher(selectedDocument, resolve, reject);
              toggleBackDrop(true)
            }).then((data) => {
                toggleBackDrop(false);
                const alertMsg = {
                    color: 'success',
                    icon: ErrorIcon,
                    message: "Document uploaded successfully."
                }
                this.state.percent = this.state.percent+10;
                updateAlertMsg(alertMsg);
                this.getFileNameToDisplay('', this.state.documentSubType);
            }).catch(error => {
              toggleBackDrop(false)
              const alertMsg = {
                color: 'danger',
                icon: ErrorIcon,
                message: error.data ? error.data.message : 'Something went wrong. Please try again'
              }
              updateAlertMsg(alertMsg);
            })
        }
    }

    getFileNameToDisplay = (name, documentSubType) =>{
        if(documentSubType==='pan'){
            this.state.selectedPAN = name;
        }else if(documentSubType==='aadhaarFront'){
            this.state.selectedAadhaarFront = name;
        }else if(documentSubType==='aadhaarBack'){
            this.state.selectedAadhaarBack = name;
        }else if(documentSubType==='degree_certificate'){
            this.state.selectedCertificate = name;
        }else if(documentSubType==='chequeleaf_front'){
            this.state.selectedCheque = name;
        }
    }

    validateUploadedFile = (event, documentType, documentSubType, objKey) =>{
        const { toggleBackDrop, updateAlertMsg } = this.props;
        if(localStorage.getItem('createdUserId') === null){
            const alertMsg = {
                color: 'danger',
                icon: ErrorIcon,
                message: 'Before uploading the documents, please submit the basic details.'
            }
            event.target.value = null;
            updateAlertMsg(alertMsg);
            return;
        }
        const errs = [];
        const files = Array.from(event.target.files);
        this.getFileNameToDisplay(files[0].name, documentSubType);
        const types = ['image/png', 'image/jpeg', 'application/pdf', 'image/heif', 'image/heic'];
        files.forEach((file, i) => {
            if (types.every(type => file.type !== type)) {
              errs.push(`'${file.type}' is not a supported format`)
            }
            if (file.size > 10485760) {
              errs.push(`'${file.name}' is too large, please pick a smaller file`)
            } 
            if (isEmpty(errs)) {
                this.setState({
                    documentName: file.name,
                    selectedFile: event.target.files[0],
                    documentSubType: documentSubType,
                    documentType: documentType
                });
                toggleBackDrop(false);
                const alertMsg = {
                    color: 'success',
                    message: 'File is validated. Upload it please!'
                }
                updateAlertMsg(alertMsg);
            } else {
                toggleBackDrop(false);
                const alertMsg = {
                    color: 'danger',
                    icon: ErrorIcon,
                    message: errs.join(', ')
                }
                updateAlertMsg(alertMsg);
            }
          })
    }

    getUploadedFile = (event, documentType, documentSubType, objKey) => {
        event.preventDefault();
        this.validateUploadedFile(event, documentType, documentSubType, objKey);
    }
  
    onSubmit = values => {
        const { createSpecialUserWatcher, toggleBackDrop, updateAlertMsg } = this.props;
        const brokerId = getCookie('brokerId');

        const { fullName, role, aadharNo,
            alternatePhone, 
            email, gender, maritalStatus, panNo, phone,
            addressLine1, addressLine2, addressLine3, area,
            city, state, landmark, district, pinCode, country, qualification, yearOfCompletion,
            accountHolderName, bankAccountNumber, ifsc, adminEmail, password, confirmPassword, adminConsoleUser, ssoPartnerCode=""} = values;
            let {dob} = values
            dob = new Date(dob).getTime().toString();

        const address = {
            addressLine1: addressLine1.trim(), addressLine2: addressLine2.trim(), addressLine3: addressLine3.trim(), area: area.trim(),
            city: city.trim(), state: state.trim(), landmark: landmark.trim(), district: district.trim(), pinCode: pinCode, country: country.trim()
        };

        const education = {
            isComplete: false,
            highestQualification: qualification,
            yearOfCompletion: yearOfCompletion,
            documentsUploaded: []
        }

        const bankDetails = {
            isComplete: false,
            bankName: '',
            accountHolderName: accountHolderName,
            bankAccountNo: bankAccountNumber,
            ifscCode: ifsc,
            documentsUploaded: []
        }

        const param = {
            fullName: fullName.trim(), role, aadharNo, linkedBQP: this.bqp, referredBy: this.refUser,
            alternatePhone, bankInfo: bankDetails, educationInfo: education,
            dob, email: email.trim(), gender, maritalStatus, panNo: panNo.trim(), phone, address, brokerId,
            adminEmail, password, confirmPassword, adminConsoleUser, ssoPartnerCode ,approvalStatus: "",
            eligibilityStatus : ""
        };
 
            new Promise((resolve, reject) => {
                createSpecialUserWatcher(param, resolve, reject);
            }).then((data) => {
                toggleBackDrop(false)
                if(data.errorCode&&data.errorCode==='EAPI-400-0005'){
                    const errorMsg = {
                        color: 'danger',
                        icon: ErrorIcon,
                        message: data.message
                    }
                    updateAlertMsg(errorMsg)
                }else{
                    localStorage.setItem('createdUserId', data.userId);
                    const alertMsg = {
                        color: 'success',
                        icon: CheckCircleOutlineIcon,
                        message: 'Special User created successfully'
                    }
                    updateAlertMsg(alertMsg)
                    this.state.percent = 50;
                    if(role.roleCode === 'BQP' || role.roleCode === 'SP'){
                        this.props.history.push('/specialUsers');
                    }
                }
                
            })
                .catch((error) => {
                    toggleBackDrop(false)
                    const alertMsg = {
                        color: 'danger',
                        icon: ErrorIcon,
                        message: error.data ? error.data.message : 'Something went wrong. Please try again'
                    }
                    updateAlertMsg(alertMsg)
                })
    }

    render() {
        const { classes, maritalStatusList, userRolesList, bqpUsersList, searchUsersList, masterRoles, partnerList } = this.props;
console.log("partnersList", partnerList);
        const initialValues = {
            role: '', fullName: '', dob: null, phone: '',
            email: '', gender: '', maritalStatus: '', alternatePhone: '',
            aadharNo: '',panNo: '', addressLine1: '', addressLine2: '', addressLine3: '',
            city: '', district: '', state: '', area: '', country: 'India', pinCode: '', landmark: '',
            qualification: '', yearOfCompletion: '',
            accountHolderName: '', bankAccountNumber: '', ifsc: '',
            adminEmail: "", password: "", confirmPassword: "", adminConsoleUser: false
          }
        return (
            <Admin>
                <Card>
                    <CardHeader color="primary">
                        <Typography variant="h6">Create User</Typography>
                    </CardHeader>
                    <CardBody>
                        {/* <form onSubmit={this.onSubmit}>
                            <SpecialForm {...meths} {...this.state} classes={classes} />


                        </form> */}
                        <SpecialFormRedux 
                            handleSubmit={this.onSubmit} 
                            percent={this.state.percent} 
                            fileUploadHandler={this.fileUploadHandler} 
                            selectedBQP={this.selectedBQP} 
                            getUploadedFile={this.getUploadedFile} 
                            selectedRefUser={this.selectedRefUser} 
                            searchBQPParams={this.searchBQPParams} 
                            searchUsersParams ={this.searchUsersParams} 
                            {...this.state} 
                            maritalStatusList={maritalStatusList} 
                            userRolesList={userRolesList} 
                            masterRoles={masterRoles}
                            bqpUsersList={bqpUsersList} 
                            searchUsersList={searchUsersList} 
                            classes={classes} 
                            initialValues={initialValues} 
                            partnerList = {partnerList}
                        />
                        {/* <Button className={classes.submitButton} onClick={() => this.onSubmit()} color="primary" >Submit</Button> */}
                    </CardBody>
                </Card>
            </Admin>
        );
    }

}
function mapStateToProps(state) {
    const {productReducer, adminRoleReducer, specialUserReducer} = state;
    const {maritalStatusList=[]} = productReducer;
    const { partnerList =[]} = specialUserReducer;
    const {userRolesList=[]} = productReducer;
    const {bqpUsersList=[]} = productReducer;
    const {searchUsersList=[]} = productReducer;
    const {masterRoles} = adminRoleReducer; 
    return {
        maritalStatusList,
        userRolesList,
        bqpUsersList,
        searchUsersList,
        masterRoles,
        partnerList
    };
}
// mapping dispatch functions to the props of LoginForm component
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            fetchMaritalStatusInfoWatcher,
            fetchUserRolesWatcher,
            fetchBQPUserByPhoneOrNameWatcher,
            searchUserByPhoneOrNameWatcher,
            toggleBackDrop, updateAlertMsg,
            createSpecialUserWatcher,
            getDocumentsWatcher,
            uploadDocumentWatcher,
            fetchMasterRolesWatcher,
            fetchPartnersWatcher

            // add other watcher sagas to this object to map them to props
        },
        dispatch
    )
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(SpecialUserCreatePage));

import React, { Fragment } from 'react';
import styles from "assets/jss/componentsStyles/tableStyle.js";
import { makeStyles } from '@material-ui/core/styles';
import {
    TableBody,
    TableCell,
    TableRow,
    TableHead,
    Table,
} from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Switch from '@material-ui/core/Switch';
import CustomInput from 'components/CustomInput/CustomInput';
import { isEmpty, sortBy } from 'utils/common';
import SelectOption from 'components/CustomSelect/SelectOption';
import { MotorPolicyTypeConst } from 'utils/constants';



const useStyles = makeStyles(styles);
export default function GlobalCommissionTable(props) {
    const classes = useStyles();
    const { companies = [], isEdit, handleChange, companiesList = {}, insuranceType } = props;
    console.log("company list", companiesList);
    return (
        <div className={classes.tableResponsive}>
            <Table className={classes.table} stickyHeader>

                <TableHead className={classes.primaryTableHeader}>
                    <TableRow className={classes.tableHeadRow}>
                        <TableCell className={classes.tableCell + " " + classes.tableHeadCell}>Companies</TableCell>
                        {/* <TableCell className={classes.tableCell + " " + classes.tableHeadCell}>Product</TableCell> */}
                        {/* <TableCell className={classes.tableCell + " " + classes.tableHeadCell}>Percentage</TableCell> */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        companies.map(company => {
                            const { companyId, products = [] } = company;
                            return (
                                <ExpansionPanel>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className={classes.heading}> {!isEmpty(companiesList) ? (companiesList[companyId] ? companiesList[companyId].companyName : companyId) : companyId}</Typography>
                                    </ExpansionPanelSummary>

                                    <ExpansionPanelDetails>
                                        <Table className={classes.table} stickyHeader>

                                            <TableHead className={classes.primaryTableHeader}>
                                                <TableRow className={classes.tableHeadRow}>
                                                    {/* <TableCell className={classes.tableCell + " " + classes.tableHeadCell}>Status</TableCell> */}
                                                    <TableCell className={classes.tableCell + " " + classes.tableHeadCell}>Product</TableCell>
                                                    <TableCell className={classes.tableCell + " " + classes.tableHeadCell}>New Percent</TableCell>
                                                    <TableCell className={classes.tableCell + " " + classes.tableHeadCell}>Renew Percent</TableCell>
                                                    <TableCell className={classes.tableCell + " " + classes.tableHeadCell}>SI Limit</TableCell>
                                                    <TableCell className={classes.tableCell + " " + classes.tableHeadCell}>Is POSP Approved</TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    products.sort((prodA, prodB) => sortBy(prodA, prodB, 'planName')).map(product => {
                                                        const { isPospApprovedPlcy = true, newPercent = '', planName = '', renewPercent = '', siLimit = '', policyType='' } = product
                                                        const planDisplay = planName+ `${!isEmpty(policyType)? ` (${MotorPolicyTypeConst[policyType.toLowerCase()]})`: ''}`
                                                        return (
                                                            <TableRow
                                                                className={classes.tableBodyRow}
                                                            >
                                                                <TableCell className={classes.tableCell}>
                                                                    {planDisplay}
                                                                </TableCell>
                                                                <TableCell className={classes.tableCell}>
                                                                    {
                                                                        isEdit ? (<CustomInput
                                                                            formControlProps={{
                                                                                fullWidth: true,
                                                                                style: { paddingTop: '0px', maxWidth: '340px' }
                                                                            }}
                                                                            onChange={({ target: { value } }) => value ? handleChange(value, { product, companyId, insuranceType }, 'newPercent') : handleChange('0', { product, companyId, insuranceType }, 'newPercent')}
                                                                            inputProps={{
                                                                                value: (newPercent || ''),
                                                                                type: 'number',
                                                                                inputProps: {
                                                                                    min: "0",
                                                                                    max: "100"
                                                                                },
                                                                                onBlur: ({ target: { value } }) => handleChange(parseFloat(value).toPrecision(2), { product, companyId, insuranceType }, 'newPercent')
                                                                                //error: isError('fullName')
                                                                            }}
                                                                            className={classes.inputText} />) : newPercent
                                                                    }

                                                                </TableCell>
                                                                <TableCell className={classes.tableCell}>
                                                                    {
                                                                        isEdit ? (<CustomInput
                                                                            formControlProps={{
                                                                                fullWidth: true,
                                                                                style: { paddingTop: '0px', maxWidth: '340px' }
                                                                            }}
                                                                            onChange={({ target: { value } }) => value ? handleChange(value, { product, companyId, insuranceType }, 'renewPercent') : handleChange('0', { product, companyId, insuranceType }, 'renewPercent')}
                                                                            inputProps={{
                                                                                value: (renewPercent || ''),
                                                                                type: 'number',
                                                                                inputProps: {
                                                                                    min: "0",
                                                                                    max: "100"
                                                                                },
                                                                                onBlur: ({ target: { value } }) => handleChange(parseFloat(value).toPrecision(2), { product, companyId, insuranceType }, 'renewPercent')
                                                                                //error: isError('fullName')
                                                                            }}
                                                                            className={classes.inputText}
                                                                        />) : renewPercent
                                                                    }

                                                                </TableCell>

                                                                <TableCell className={classes.tableCell}>
                                                                    {
                                                                        isEdit ? (<CustomInput
                                                                            formControlProps={{
                                                                                fullWidth: true,
                                                                                style: { paddingTop: '0px', maxWidth: '340px' }
                                                                            }}
                                                                            onChange={({ target: { value } }) => value ? handleChange(value, { product, companyId, insuranceType }, 'siLimit') : handleChange('0', { product, companyId, insuranceType }, 'siLimit')}
                                                                            inputProps={{
                                                                                value: (siLimit || ''),
                                                                                type: 'number',
                                                                                inputProps: {
                                                                                    min: "0",
                                                                                    max: "100"
                                                                                },
                                                                                onBlur: ({ target: { value } }) => handleChange(+value, { product, companyId, insuranceType }, 'siLimit')
                                                                                //error: isError('fullName')
                                                                            }}
                                                                            className={classes.inputText}
                                                                        />) : parseInt(siLimit)
                                                                    }
                                                                </TableCell>

                                                                <TableCell className={classes.tableCell}>
                                                                    {isEdit ? <SelectOption
                                                                        formControlProps={
                                                                            { className: classes.selectFormControl }}

                                                                        selectOptions={[{ value: "true", label: "YES" }, { value: "false", label: "NO" }]}
                                                                        // error={isError('initialData', 'insuranceType')}
                                                                        formControlProps={{
                                                                            fullWidth: true
                                                                        }}
                                                                        isRequired
                                                                        onSelectChange={({ target: { value } }) => (value ? handleChange(value, { product, companyId, insuranceType }, 'isPospApprovedPlcy') : handleChange(null, { product, companyId, insuranceType }, 'isPospApprovedPlcy'))}
                                                                        inputProps={{

                                                                            value: isPospApprovedPlcy,
                                                                            // onBlur: ({ target: { value } }) => validateInput(value, 'payoutOn'),
                                                                            // error: isError('payoutOn')
                                                                        }}
                                                                    /> : (isPospApprovedPlcy ? 'YES' : 'NO')}
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                }

                                            </TableBody>
                                        </Table>

                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            );
                        })
                    }
                </TableBody>

            </Table>
        </div>
    );
}
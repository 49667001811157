/* eslint-disable no-script-url */
import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from "../../assets/jss/componentsStyles/tableStyle.js";
import { makeStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import {
    TableBody,
    TableSortLabel,
    Checkbox,
    TableCell,
    TableRow,
    TableHead,
    Table,
    TablePagination,
    Typography,
    Tooltip
} from '@material-ui/core';
import Button from 'components/CustomButtons/Button';

import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { useDispatch } from 'react-redux';
import { updateAlertMsg } from 'store/actions/commonAction.js';
import { fetchPaymentURLWatcher } from 'store/actions/payoutAction.js';

function desc(a, b, orderBy) {
    
   
    let aCopy = a[orderBy],
        bCopy = b[orderBy];
    if(orderBy === "proposalDate"){
        let aArr = aCopy.split("-");
        let bArr = bCopy.split("-");
        aCopy = new Date(`${aArr[1]}/${aArr[0]}/${aArr[2]}/${aArr[3]}`).getTime() || -Infinity
        bCopy = new Date(`${bArr[1]}/${bArr[0]}/${bArr[2]}/${bArr[3]}`).getTime() || -Infinity
    }  
    // console.log(aCopy, typeof bCopy)
    // if(aCopy ==="Invalid Date" ||bCopy ==="Invalid Date"){
    //     return 1;
    // }
    if (bCopy < aCopy) {
        return -1;
    }
    if (bCopy > aCopy) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

//headCell -{id:"", label: ''}
function EnhancedTableHead(props) {
    const { classes, tableHeaderColor, headCells, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, isSelectRequired } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };
    return (
        <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
                {isSelectRequired && <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all' }}
                    />
                </TableCell>}
                {headCells.map((headCell, key) => {
                    return (
                        <TableCell
                            className={classes.tableCell + " " + classes.tableHeadCell}
                            sortDirection={orderBy === headCell.id ? order : false}
                            key={key}
                        >

                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={order}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {/* {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null} */}
                            </TableSortLabel>
                        </TableCell>
                    );
                })}

            </TableRow>
        </TableHead>

    );
}
/*
@props( rows, orderAs, orderByParameter, isSelectRequired, tableHeaderColor, headCells, getDetails)
*/

const useStyles = makeStyles(styles);
export default function PendingProposalsTable(props) {
    const dispatch = useDispatch();
    let { rows = [], orderAs = "", orderByParameter = "",
        isSelectRequired = false, tableHeaderColor = "", headCells = [], getDetails
         } = props;
         
    const classes = useStyles();
    const [order, setOrder] = React.useState(orderAs);
    const [orderBy, setOrderBy] = React.useState(orderByParameter);
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    // const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

// console.log("orderAs", orderAs)
    const handleRequestSort = (event, property) => {
        console.log()
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const isSelected = name => selected.indexOf(name) !== -1;

    const handleSelectAllClick = event => {
        if (event.target.checked) {
            const newSelecteds = rows.map(n => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const copyPaymentUrl = ( paymentUrl) =>{
        console.log("navigator", navigator.clipboard, window.clipboardData)
        if(paymentUrl && navigator && navigator.clipboard){ 

            navigator.clipboard.writeText(paymentUrl||"No Payment url available") ;
            
              dispatch(updateAlertMsg({
                messageType: 'info', 
                message: 'Payment url copied successfully.'
              }) )
        }   
    }

    const getPaymentUrl =(proposalId, insType) => { 
        // console.log("insType", insType);
        dispatch(fetchPaymentURLWatcher({proposalId, insType}))
        
    }

    const formatDate = (date) =>{
        let tDate = date.split("-");
        return `${tDate[0]}-${tDate[1]}-${tDate[2]}`;
      }

    useEffect(()=>{
        setOrder("desc");
        setOrderBy("proposalDate")
        
    }, [])

    return (
        <Fragment>
            <div className={classes.tableResponsive}>
                <Table className={classes.table} stickyHeader>

                    <EnhancedTableHead
                        classes={classes}
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                        isSelectRequired={isSelectRequired}
                        tableHeaderColor={tableHeaderColor}
                        headCells={headCells}
                    />

                    <TableBody>
                        {stableSort(rows, getSorting(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                const isItemSelected = isSelected(row.name);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                const fieldToExclude = ["proposalStatus", "companyId"];
                                return (
                                    <TableRow
                                        hover
                                        onClick={event => handleClick(event, row.name)}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.name}
                                        selected={isItemSelected}
                                        className={classes.tableBodyRow}
                                    >
                                        {isSelectRequired && <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={isItemSelected}
                                                inputProps={{ 'aria-labelledby': labelId }}
                                            />
                                        </TableCell>}
                                        
                                        {Object.keys(row).filter(key=>fieldToExclude.indexOf(key)===-1).map((key, index) => {
                                            let display = null;
                                            switch (key) {
                                                case 'proposalId':  
                                                display = (<a href="javascript:void(0)" id={row[key]} onClick={(event) => getDetails(event, {proposalId: row.proposalId, insuranceType: row.insuranceType})}>{row[key]  || 'Details'}</a>)
                                                break;
                                                case 'companyLogo':
                                                    // display = (<Button color="primary" round size="sm" id={row[key]} onClick={(event) => getDetails(event, row[key])}>Details</Button>);
                                                    display = (<img style={{maxHeight:"50px", maxWidth:"70px"}} src={row.companyLogo} alt="..." />)
                                                    break;
                                                case 'proposalDate':
                                                    display =  (<Tooltip  arrow placement="top" title={row[key]}><span>{ formatDate(row[key])}</span></Tooltip>)
                                                    break;
                                                case 'policyDocumentLink':
                                                    if(row[key] && row[key]!==""){
                                                        display = (
                                                            <Typography  align="center">
                                                            <a href={row[key]} target="_blank" title={"Download Policy PDF"}>
                                                                <GetAppIcon/>
                                                                {/* <Button color="primary" >Download Policy PDF</Button> */}
                                                            </a>
                                                            </Typography>
                                                        )
                                                    } else {
                                                        display =null
                                                    }
                                                    break;
                                                  case 'paymentUrl':
                                                    let showIconFor = ["Payment_Failed", "Proposal_Created", "Payment_Failed", null, "", "Proposal_Initiated"];
                                                    
                                                    // if((row.companyId === 6 || row.companyId === 13) && row.insuranceType!=="Health"){
                                                    //     showIconFor = ["Payment_Failed", null, ""];
                                                    // }
                                                    // if(showIconFor.indexOf(row.proposalStatus)>-1 ){
                                                        if(row[key] && row[key]!==null && row[key]!==""){
                                                            display = (
                                                                <Tooltip title={row[key]} arrow>
                                                                    <FileCopyOutlinedIcon style={{cursor:"pointer", opacity:.6, color:"#9c27b0"}}  onClick={()=>copyPaymentUrl(row[key])}/>
                                                                </Tooltip>
                                                            )
                                                        } else {
                                                            display = (
                                                                <Tooltip title={"Get payment url"} arrow>
                                                                    <FileCopyOutlinedIcon style={{cursor:"pointer", opacity:.6, color:"#9c27b0"}}  onClick={()=>getPaymentUrl(row.proposalId, row.insuranceType)}/>
                                                                </Tooltip>
                                                            )
                                                        }
                                                        
                                                    // } else {
                                                    //     display= null;
                                                    // }

                                                    break;
                                                default:
                                                    display = (row[key]);
                                                    break;
                                            }
                                            return (
                                                <TableCell className={classes.tableCell} key={index}>
                                                    {display}
                                                </TableCell>
                                            );
                                        })}

                                    </TableRow>
                                );
                            })}

                    </TableBody>
                </Table>
                {
                    rows.length===0&&(
                        <Typography align="center" style={{color:"#9c27b0", padding:25}}>
                            No Proposal found.
                        </Typography>
                    )
                }
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'previous page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'next page',
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </div>

        </Fragment>
    );
}

PendingProposalsTable.propTypes = {
    rows: PropTypes.array.isRequired,
    orderAs: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderByParameter: PropTypes.string.isRequired,
    isSelectRequired: PropTypes.bool.isRequired,
    tableHeaderColor: PropTypes.string.isRequired,
    headCells: PropTypes.array.isRequired
}
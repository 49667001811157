import * as React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { withStyles } from '@material-ui/core/styles';
import { fetchSpecialUserList } from 'store/actions/specialUserAction';
import Admin from 'Pages/DashBoard/Admin';
import { isEmpty } from "utils/common";
import SpecialUserList from './SpecialUserList';


const styles = {

}

class SpecialUserListPage extends React.Component {


    componentWillMount() {
        // if (isEmpty(this.props.specialUserList))
            this.props.fetchSpecialUserList();
    }

    render() {
        const { specialUserList = [] } = this.props

        return (
            <Admin>
                <SpecialUserList
                    specialUserList={specialUserList}
                    // getDetails={this.getDetails}
                    history={this.props.history}
                />
            </Admin>
        );
    }

}
function mapStateToProps(state) {
    const { specialUserReducer } = state;
    return {
        specialUserList: specialUserReducer.specialUserList

    };
}
// mapping dispatch functions to the props of LoginForm component
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            fetchSpecialUserList,
            // add other watcher sagas to this object to map them to props
        },
        dispatch
    )
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SpecialUserListPage);
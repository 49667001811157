import {
  primaryColor,
  dangerColor,
  grayColor
} from '../material-dashbord-react'

const customDatePickerStyle = {
  muipickerTextBox: {
    // marginTop: "27px",
    // marginBottom: "10px"
    margin: '12px 0px 17px 0',
    '& > .MuiFormLabel-root': {
      fontSize: '12px',
      color: '#3C4858 !important',
      '& > .MuiInputLabel-asterisk': {
        color: 'red'
      }
    },
    '& > .MuiFormHelperText-root': {
      fontSize: '12px',
      color: dangerColor[0]
    },

    '& > .MuiInput-underline': {
      '&:hover:not($disabled):before,&:before': {
        borderColor: grayColor[4] + '!important',
        borderWidth: '1px !important'
      },
      '&:after': {
        borderColor: primaryColor[0]
      },
      '& + p': {
        fontWeight: '300'
      }
    },
    '& > .Mui-disabled': {
      color: '#495057'
    }

  },

  muipickerTextBoxError: {
    // marginTop: "27px",
    // marginBottom: "10px"
    margin: '12px 0px 3px 0',
    '& > .MuiFormLabel-root': {
      fontSize: '12px',
      color: dangerColor[0] + '!important',
      '& > .MuiInputLabel-asterisk': {
        color: dangerColor[0]
      }
    },
    '& > .MuiFormHelperText-root': {
      fontSize: '12px',
      color: dangerColor[0]
    },

    '& > .MuiInput-underline': {
      '&:hover:not($disabled):before,&:before': {
        borderColor: grayColor[4] + '!important',
        borderWidth: '1px !important'
      },
      '&:after': {
        borderColor: dangerColor[0]
      },
      '& + p': {
        fontWeight: '300'
      }
    }
  }

}
export default customDatePickerStyle

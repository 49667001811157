import * as React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import Admin from '../DashBoard/Admin';
import { withStyles } from '@material-ui/core/styles';
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import { fetchGlobalCommissionWatcher, updateGlobalCommissionWatcher } from 'store/actions/posCommisionAction';
import {fetchInsuranceCompanyByTypeWatcher} from 'store/actions/productAction';
import Snackbar from '../../components/Snackbar/Snackbar';
import {isEmpty} from '../../utils/common';
import GlobalCommissionList from "./GlobalCommissionList";


const styles = {

}


class GlobalCommissionPage extends React.Component {

  constructor(props) {
    super(props);
    this.props.fetchGlobalCommissionWatcher();
    this.props.fetchInsuranceCompanyByTypeWatcher();
    this.state = {
      alertMsg: {}
    }
  }  

  setAlert =(alertMsg) => {
    this.setState({alertMsg})
    setTimeout(() => {
      this.setState({alertMsg: {}})
    }, 4000);
  }
  render() {

    const {  globalCommission = {}, companies={} } = this.props;
    const { insuranceDetails = [] } = globalCommission;
    const { alertMsg } = this.state;

    // insuranceDetails.forEach(insurance => {
    //   const tab = { tabName: insurance.insuranceType, tabIcon: AssessmentIcon, tabContent: <CommissionTable title={insurance.insuranceType} data={[...insurance.commissionPercentage]} commissionPercentage={[...insurance.commissionPercentage]} setAlert={this.setAlert} updateGlobalCommissionWatcher ={this.props.updateGlobalCommissionWatcher} insuranceDetails={[...insuranceDetails]} fetchGlobalCommissionWatcher={this.props.fetchGlobalCommissionWatcher} /> };
    //   tabs.push(tab);
    // })

    return (
      <Admin>
                  <Snackbar
                    place="tc"
                    color={alertMsg.color}
                    icon={alertMsg.icon}
                    message={alertMsg.message}
                    open={!isEmpty(alertMsg)}
                    closeNotification={() => this.setState({ alertMsg: {} })}
                    close
                />
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <GlobalCommissionList 
              insuranceDetails ={insuranceDetails}
              fetchGlobalCommissionWatcher ={this.props.fetchGlobalCommissionWatcher}
              updateGlobalCommissionWatcher = {this.props.updateGlobalCommissionWatcher}
              setAlert={this.setAlert}
              companies = {companies}
              />
            {/* <CustomHeaderContentTabs color="primary"
            headerColor="primary"
            tabs={tabs}
            plainTabs={false}
            classHeader={classes.tabHeader}
          >
               <span style={{ alignSelf: 'flex-start' }}>
              <h4 className={classes.cardTitleWhite}>Global Commission</h4>
            </span>
            </CustomHeaderContentTabs> */}
              {/* <CardBody plain>
                <CustomTabs
                  headerColor="primary"
                  tabs={tabs}
                  plainTabs={false}
                  classHeader={classes.tabs}

                />
              </CardBody> */}
            </Card>
          </GridItem>
        </GridContainer>

      </Admin>
    );
  }
}
function mapStateToProps(state) {
  const { posCommissionReducer, commonReducer } = state;
  return {
    globalCommission: posCommissionReducer.globalCommission,
    companies: commonReducer.companies

  };
}
// mapping dispatch functions to the props of LoginForm component
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchGlobalCommissionWatcher,
      updateGlobalCommissionWatcher,
      fetchInsuranceCompanyByTypeWatcher
      // add other watcher sagas to this object to map them to props
    },
    dispatch
  )
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(GlobalCommissionPage));
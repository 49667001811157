import * as actions from './types'

export function fetchInsuranceTypeWatcher () {
  return {
    type: actions.INSURANCE_TYPE_WATCHER
  }
}

export function fetchInsuranceCompanyByTypeWatcher (param = '', resolve=()=>{}, reject=()=>{}) {
  return {
    type: actions.INSURANCE_COMPANY_BY_TYPE_WATCHER, payload: param, resolve, reject
  }
}

export function fetchAllProductByCompanyByPolicyTypeWatcher (param, resolve, reject) {
  return {
    type: actions.INSURANCE_PRODUCTS_BY_COMPANY_BY_TYPE_WATCHER, payload: param, resolve, reject
  }
}

export function fetchPreviousInsurerWatcher (param, resolve=()=>{}, reject=()=>{}) {
  return {
    type: actions.PREVIOUS_INSURER_WATCHER, payload: param, resolve, reject
  }
}

export function fetchAllOccupationDetailsWatcher (param, resolve=()=>{}, reject=()=>{}) {
  return {
    type: actions.ALL_OCCUPATION_DETAILS_WATCHER, payload: param, resolve, reject
  }
}


export function fetchAllIdProofInfoWatcher (param, resolve=()=>{}, reject=()=>{}) {
  return {
    type: actions.ID_PROOF_INFO_WATCHER, payload: param, resolve, reject
  }
}



export function fetchGstTypeInfoWatcher (param, resolve=()=>{}, reject=()=>{}) {
  return {
    type: actions.GST_TYPE_INFO_WATCHER, payload: param, resolve, reject
  }
}
export function gstTypeList(gstTypeList) {
  return {
    type: actions.GST_TYPE_LIST, gstTypeList
  }
}

export function fetchInitialTitleWatcher (resolve=()=>{}, reject=()=>{}) {
  return {
    type: actions.INITIAL_TITLE_WATCHER, resolve, reject
  }
}

export function fetchRelationsInfoWatcher (resolve=()=>{}, reject=()=>{}) {
  return {
    type: actions.RELATIONS_INFO_WATCHER, resolve, reject
  }
}
export function fetchMaritalStatusInfoWatcher (resolve=()=>{}, reject=()=>{}) {
  return {
    type: actions.MARITAL_STATUS_INFO_WATCHER, resolve, reject
  }
}

export function fetchUserRolesWatcher (resolve=()=>{}, reject=()=>{}) {
  return {
    type: actions.USER_ROLES_WATCHER, resolve, reject
  }
}

export function fetchBQPUserByPhoneOrNameWatcher(param) {
  return ({
    type: actions.BQP_USERS_WATCHER,
    payload: param
  });
}

export function searchUserByPhoneOrNameWatcher(param) {
  return ({
    type: actions.SEARCH_USERS_WATCHER,
    payload: param
  });
}

export function createOfflineProposalWatcher (param, resolve, reject) {
  return {
    type: actions.CREATE_OFFLINE_PROPOSAL_WATCHER, payload: param, resolve, reject
  }
}

export function fetchAllProposalWatcher () {
  return {
    type: actions.FETCH_ALL_PROPOSAL_WATCHER
  }
}

export function fetchProposalDetailsWatcher(param, resolve, reject) {
  return {
    type: actions.FETCH_PROPOSAL_DETAILS_WATCHER, payload: param, resolve, reject
  }
}

export function fetchAllOfflineSalesWatcher () {
  return {
    type: actions.FETCH_ALL_OFFLINE_SALES_WATCHER
  }
}

export function initalTitleList (initialTitles) {
  return {
    type: actions.INITAL_TITLE_LIST,
    initialTitles
  }
}

export function relationList (relationList) {
  return {
    type: actions.RELATION_LIST,
    relationList
  }
}

export function maritalStatusList (maritalStatusList) {
  return {
    type: actions.MARITAL_STATUS_LIST,
    maritalStatusList
  }
}

export function userRolesList(userRolesList) {
  return {
    type: actions.USER_ROLES_LIST,
    userRolesList
  }
}

export function bqpUsersList(bqpUsersList) {
  return {
    type: actions.BQP_USERS_LIST,
    bqpUsersList
  }
}

export function searchUsersList(searchUsersList) {
  return {
    type: actions.SEARCH_USERS_LIST,
    searchUsersList
  }
}

export function insuranceTypeList (insuranceType) {
  return {
    type: actions.INSURANCE_TYPE_LIST,
    insuranceType
  }
}

export function proposalList (proposalList) {
  return {
    type: actions.PROPOSAL_LIST,
    proposalList
  }
}
export function proposalDetails (proposalDetails) {
  return {
    type: actions.PROPOSAL_DETAILS,
    proposalDetails
  }
}

export function getPreviousInsurerList (previousInsurerList) {
  return {
    type: actions.PREVIOUS_INSURER_LIST,
    previousInsurerList
  }
}

export function occupationList(occupationList) {
  return {
    type: actions.OCCUPATION_LIST, occupationList
  }
}


export function idProofList(idProofList) {
  return {
    type: actions.ID_PROOF_LIST,  idProofList
  }
}

export function offlineSalesList (offlineSalesList) {
  return {
    type: actions.OFFLINE_SALES_LIST,
    offlineSalesList
  }
}

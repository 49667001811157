import * as React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import Admin from '../DashBoard/Admin';
import {fetchGlobalChainCommission, updateGlobalChainCommission} from '../../store/actions/dsaAction';
import Snackbar from '../../components/Snackbar/Snackbar';
import {isEmpty} from '../../utils/common';
import GlobalChainCommissionList from './GlobalChainCommissionList';

class GlobalChainCommissionPage extends React.Component {

    constructor(props) {
      super(props);
      this.props.fetchGlobalChainCommission();
      this.state = {
        alertMsg: {}
      }
    }
    
    
  setAlert =(alertMsg) => {
    this.setState({alertMsg})
    setTimeout(() => {
      this.setState({alertMsg: {}})
    }, 4000);
  }

  render() {
    const {alertMsg} = this.state;
      const {globalChainCommission =[], updateGlobalChainCommission, fetchGlobalChainCommission} = this.props;
      const {roleWiseCommission =[]} = globalChainCommission[0] || {};

      return(
          <Admin>
             <Snackbar
                    place="tc"
                    color={alertMsg.color}
                    icon={alertMsg.icon}
                    message={alertMsg.message}
                    open={!isEmpty(alertMsg)}
                    closeNotification={() => this.setState({ alertMsg: {} })}
                    close
                />
              <GlobalChainCommissionList 
              roleWiseCommission ={roleWiseCommission}
              fetchGlobalChainCommission = {fetchGlobalChainCommission}
              updateGlobalChainCommission = {updateGlobalChainCommission}
              setAlert ={this.setAlert}
              />

          </Admin>
      );
  }
}
function mapStateToProps(state) {
    const { dsaReducer } = state;
    return {
        globalChainCommission: dsaReducer.globalChainCommission
    };
  }
  // mapping dispatch functions to the props of LoginForm component
  const mapDispatchToProps = dispatch => {
    return bindActionCreators(
      {
        fetchGlobalChainCommission,
        updateGlobalChainCommission
        // add other watcher sagas to this object to map them to props
      },
      dispatch
    )
  };
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(GlobalChainCommissionPage);
  
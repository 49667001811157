export const styles = {
  drbg: {
    "&>div:first-child": {
      backgroundColor: '#9831b1'
    },
    "& .MuiPickersDay-root.Mui-selected": {

      backgroundColor: '#9831b1'
    },
    "& .MuiPickersDateRangeDay-rangeIntervalDayHighlight": {
      backgroundColor: '#eaa1f7'
    }
  }

}
import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from 'components/CustomButtons/Button'


export default function FixedPayoutUpdateDialog (props) {
  const { open, handleClose, children, isValid } = props

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth ={true}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <DialogTitle id="alert-dialog-title">Update Payout</DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>

      <DialogActions>
        <Button onClick={(event) => handleClose(event)} color="warning" size="sm">
            Cancel
        </Button>
        <Button onClick={(event) => handleClose(event, 'okay')} color="primary" size="sm" disabled={!isValid}>
            Update
        </Button>
      </DialogActions>

    </Dialog>
  )
}

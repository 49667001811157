import {
    container,
    cardTitle,
    whiteColor,
    grayColor
  } from "assets/jss/material-dashbord-react";

const OtpPageStyle =theme => ({

  container: {
    ...container,
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px"
    }
  },
  cardTitle: {
    ...cardTitle,
    color: whiteColor
  },
  cardHeader: {
    marginBottom: "20px"
  },
  textCenter: {
    textAlign: "center"
  },
  inputAdornment: {
    marginRight: "18px"
  },
  inputAdornmentIcon: {
    color: grayColor[6]
  },
  footer:{
      marginTop:"15px",
      paddingTop:"20px",
      flexDirection:"column"
  },
  typoButton:{
    marginTop: "0px",
    marginBottom: "0px"
  }

});
export default OtpPageStyle;
import React from "react";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbar/navbar";
import Footer from "components/Footer/footer";
import Sidebar from "components/Sidebar/sidebar";
import styles from "./AdminStyle";

import bgImage from "../../assets/img/sidebar-2.jpg";
//import Dashboard from "./DashBoard";
import componentRoute from "../componentRoute";
import BackDrop from "components/BackDrop/Backdrop";
import Snackbar from "components/Snackbar/Snackbar";
import { useSelector, useDispatch } from "react-redux";
import { updateAlertMsg } from "store/actions/commonAction";
import { isEmpty , getCookie} from "utils/common"; 
import { ROUTE_FEATURE_MAP } from "utils/constants";

let ps;

const useStyles = makeStyles(styles);

export default function Admin(props) {
  const dispatch = useDispatch(); 
  let cRoutes = componentRoute;

  let permissions =  getCookie("permissions");
  permissions = permissions ? JSON.parse(permissions):[];
  // console.log("permissions in cookie", permissions);
  // const allowedFeaturesPaths = permissions.map(p=>ROUTE_FEATURE_MAP[p.feature]);
  let pathPermission = {};
  for(let i=0; i<permissions.length; i++){
    pathPermission[ROUTE_FEATURE_MAP[permissions[i].feature]] = permissions[i];
    if(permissions[i].feature === "POSP_onboarding") {
      pathPermission["pos-eligibility"] = permissions[i];
    }
    if(permissions[i].feature === "users_list") {
      const permission = permissions[i].permissions.find(p => p.name === "c");
      // console.log("permission", permission);
      if(permission && permission.hiearchy !==""){
      pathPermission["special-user-create"] = permissions[i];
      }
    }

    if(permissions[i].feature === "generate_otp") {
      const permission = permissions[i].permissions.find(p => p.name === "c"); 
      if( permission && permission.hiearchy ===""){
      delete pathPermission["generate-otp"];
      }
    }
  }
  const allowedFeaturesPaths = Object.keys(pathPermission);
  // console.log("allowedFeaturesPaths", allowedFeaturesPaths);
  // console.log("pathPermission", pathPermission);
    
  cRoutes = cRoutes.filter(cr=>{
    let isMatched =false; 

    if(allowedFeaturesPaths.indexOf(cr.path)>-1 && pathPermission[cr.path]) {
      let readPermissions = pathPermission[cr.path].permissions.find(p=>p.name==="r");
      if(readPermissions && readPermissions.hiearchy!==""){
        isMatched =true
      }
    }
        return isMatched;
  })  
      // console.log("cRoutes", cRoutes)
  // }
  // styles
  const classes = useStyles();
  const { alertMsg = {}, openBackDrop } = useSelector(
    (state) => state.commonReducer
  );
  React.useEffect(() => {
    if (!isEmpty(alertMsg)) {
      setTimeout(() => {
        dispatch(updateAlertMsg({}));
      }, 4000);
    }
  }, [alertMsg]);
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { ...rest } = props;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  return (
    <div className={classes.wrapper}>
      <Snackbar
        place="tc"
        color={alertMsg.color}
        icon={alertMsg.icon}
        message={alertMsg.message}
        open={!isEmpty(alertMsg)}
        closeNotification={() => dispatch(updateAlertMsg({}))}
        close
      />
      <BackDrop openBackdrop={openBackDrop} />
      <Sidebar
        logoText={window.env.BROKER_NAME}
        logo={window.env.BROKER_LOGO_URL}
        image={bgImage}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={"blue"}
        routes={cRoutes}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          handleDrawerToggle={handleDrawerToggle}
          routes={cRoutes}
          {...rest}
        />
        {/* <div className={classes.content}>
            <div className={classes.container}>
            <Dashboard />
            </div>
          </div> */}
        <div className={classes.content}>
          <div className={classes.container}>{props.children}</div>
        </div>

        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {/* {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
        ) : (
          <div className={classes.map}>{switchRoutes}</div>
        )} */}
        {/* {getRoute() ? <Footer /> : null} */}
        <Footer />
      </div>
    </div>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'

import DateFnsUtils from '@date-io/date-fns'
import {LocalizationProvider, DatePicker } from "@material-ui/pickers";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import monthPickerStyle from 'assets/jss/componentsStyles/monthPickerStyle';

// import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
// import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
// import DatePicker from '@material-ui/lab/DatePicker'


const styles = {
    divStyle: {
        display: 'inline-flex',
        alignItems: 'center',
        transform: 'translateY(-6px)'

    },
    iconStyle: {
        fontSize:'20px',
        cursor: 'pointer'
    },

    
}

const useStyles = makeStyles(styles)
const monthStyle = makeStyles(monthPickerStyle);

export default function CustomMonthPicker(props) {
    const {handleDateChange, value} = props;
    const classes = useStyles();
    const monthPickerStyle = monthStyle();

    const changeDate = (param) => {
        let val = new Date(value)
        console.log('date change button clicked', value)
        if(param === 'back') {
            val = val.setMonth(val.getMonth()-1)
            console.log('date change to back', val);
        }
        else {
            val = val.setMonth(val.getMonth()+1)
        }
        handleDateChange(val);
    }

    return(
        <div className={classes.divStyle}>
            <span style={{marginRight: '-6px'}} onClick ={() => changeDate('back')} >
                <ArrowBackIosIcon className={classes.iconStyle}  />
            </span>
            <span>
            <LocalizationProvider utils={DateFnsUtils}>
            <DatePicker
        views={["year", "month"]}
        // disableToolbar
        // variant="inline"
        className={monthPickerStyle.muipickerTextBox}
        minDate={new Date("2019-09-01")}
      
        value={value}
        onChange={handleDateChange}
      />
       </LocalizationProvider>
            </span>
            <span onClick ={() => changeDate('after')}>
                <ArrowForwardIosIcon className={classes.iconStyle}  />
            </span>
        </div>
    );
}
import { call, put, takeLatest, all, fork } from 'redux-saga/effects'
import * as actions from '../actions/types'
import axios from 'utils/axios'
import urls from 'utils/urls'
import { createUrl, isEmpty } from 'utils/common'
import { dsaList, dsaDetail, dsaCommissionDetail, pospListUnderDsa, globalChainCommission } from '../actions/dsaAction'

// ################### fetch the dsa list ############################################

function fetchDsaList () {
  return axios.request({
    method: 'get',
    url: createUrl(urls.BASE_URL + urls.dsa.BASE_URL),
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function * fetchDsaListActionEffect () {
  try {
    const { data } = yield call(fetchDsaList)

    if (!isEmpty(data)) {
      console.log('DSA Data fetched')

      yield put(dsaList(data))
    } else {
      // reject(data);
    }
  } catch (e) {
    //   alert.error(e.message || 'Authentication Error');
    //   yield put(authError(e));
    console.log('Error found while fetching dsa')
    // if (reject) reject(e);
  }
}

export function * fetchDsaListActionWatcher () {
  yield takeLatest(actions.DSA_LIST_WATCHER, fetchDsaListActionEffect)
}

// ########### fetch dos user details ##############################################

function fetchDsaDetail (dsaId) {
  const url = createUrl(urls.BASE_URL + urls.dsa.BASE_URL) + '/' + dsaId
  console.log(url)
  return axios.request({
    method: 'get',
    url: url,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function * fetchDsaDetailActionEffect (dsaDetails) {
  const { payload } = dsaDetails
  console.log('payload user id ---->', payload)
  try {
    const { data } = yield call(fetchDsaDetail, payload)
    if (!isEmpty(data)) {
      console.log('DSA Data fetched')

      yield put(dsaDetail(data))
    } else {
      // reject(data);
    }
  } catch (e) {
    console.log('Error found while fetching dsa')
  }
}

export function * fetchDsaDetailActionWatcher () {
  yield takeLatest(actions.DSA_DETAIL_WATCHER, fetchDsaDetailActionEffect)
}

// ########### fetch dos commission details ##############################################

function fetchDsaCommissionDetail (dsaId) {
  const url = createUrl(urls.BASE_URL + urls.dsa.BASE_URL) + '/' + dsaId + urls.dsa.COMMISSION
  console.log(url)
  return axios.request({
    method: 'get',
    url: url,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function * fetchDsaCommissionDetailActionEffect (dsaDetails) {
  const { payload } = dsaDetails
  console.log('payload user id ---->', payload)
  try {
    const { data } = yield call(fetchDsaCommissionDetail, payload)
    if (!isEmpty(data)) {
      console.log('DSA Data fetched')

      yield put(dsaCommissionDetail(data))
    } else {
      // reject(data);
      yield put(dsaCommissionDetail([]))
    }
  } catch (e) {
    console.log('Error found while fetching dsa')
  }
}

export function * fetchDsaCommissionDetailActionWatcher () {
  yield takeLatest(actions.DSA_COMMISSION_DETAIL_WATCHER, fetchDsaCommissionDetailActionEffect)
}

// ######## Update DSA Chain Commission #####################################

function updateDsaChainCommission (param) {
  const url = createUrl(urls.BASE_URL + urls.dsa.BASE_URL) + '/' + param.dsaUserId + urls.dsa.UPDATE_DSA_CHAIN_COMMISSION
  console.log(url)
  return axios.request({
    method: 'put',
    url: url,
    headers: {
      'Content-Type': 'application/json'
    },
    data: param
  })
}

export function * updateDsaChainCommissionActionEffect (params) {
  const { payload, resolve, reject } = params

  try {
    const { data } = yield call(updateDsaChainCommission, payload)
    if (!isEmpty(data)) {
      console.log('DSA Data fetched')
      resolve(data)
    } else {
      reject(data)
    }
  } catch (e) {
    console.log('Error found while fetching dsa')
    reject(e)
  }
}

export function * updateDsaChainCommissionActionWatcher () {
yield takeLatest(actions.UPDATE_DSA_CHAIN_COMMISSION_WATCHER, updateDsaChainCommissionActionEffect)
}
// ############# Get POSP under DSA ##########################################################

function fetchPospListUnderDsa (dsaId) {
  const url = createUrl(urls.BASE_URL + urls.dsa.BASE_URL) + '/' + dsaId + urls.dsa.POSP_UNDER_DSA
  console.log(url)
  return axios.request({
    method: 'get',
    url: url,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function * fetchPospListUnderDsaActionEffect (dsaDetails) {
  const { payload, resolve, reject } = dsaDetails
  console.log('payload user id ---->', payload)
  try {
    const { data } = yield call(fetchPospListUnderDsa, payload)
    if (!isEmpty(data)) {
      console.log('DSA Data fetched')
      yield put(pospListUnderDsa(data))
      resolve(data)
    } else {
      reject(data)
      yield put(pospListUnderDsa([]))
    }
  } catch (e) {
    console.log('Error found while fetching dsa')
    yield put(pospListUnderDsa([]))
    reject(e)
  }
}
export function * fetchPospListUnderDsaActionWatcher () {
  yield takeLatest(actions.POSP_LIST_UNDER_DSA_WATCHER, fetchPospListUnderDsaActionEffect)
}

// ################## Create DSA User ##########################################################

function createDsa (param) {
  const url = createUrl(urls.BASE_URL + urls.dsa.BASE_URL) + urls.dsa.CREATE_USER
  console.log(url)
  return axios.request({
    method: 'post',
    url: url,
    headers: {
      'Content-Type': 'application/json'
    },
    data: param
  })
}

export function * createDsaActionEffect (params) {
  const { payload, resolve, reject } = params

  try {
    const { data } = yield call(createDsa, payload)
    if (!isEmpty(data)) {
      console.log('DSA Data fetched')
      resolve(data)
    } else {
      reject(data)
    }
  } catch (e) {
    console.log('Error found while fetching dsa')
    reject(e)
  }
}

export function * createDsaActionWatcher () {
  yield takeLatest(actions.CREATE_DSA_WATCHER, createDsaActionEffect)
}
// ################## Update DSA User ######################################

function updateDsa (param) {
  const url = createUrl(urls.BASE_URL + urls.dsa.BASE_URL) + '/' + param.dsaUserId + urls.dsa.UPDATE_DSA
  console.log(url)
  return axios.request({
    method: 'put',
    url: url,
    headers: {
      'Content-Type': 'application/json'
    },
    data: param
  })
}
export function * updateDsaActionEffect (params) {
  const { payload, resolve, reject } = params

  try {
    const { data } = yield call(updateDsa, payload)
    if (!isEmpty(data)) {
      console.log('DSA Data fetched')
      resolve(data)
    } else {
      reject(data)
    }
  } catch (e) {
    console.log('Error found while fetching dsa')
    reject(e)
  }
}

export function * updateDsaActionWatcher () {
  yield takeLatest(actions.UPDATE_DSA_WATCHER, updateDsaActionEffect)
}

// ########### Get global DSA Chain commission

function fetchGlobalChainCommission (dsaId) {
  const url = createUrl(urls.BASE_URL + urls.dsa.BASE_URL) + urls.dsa.GLOBAL_CHAIN_COMMISSION
  console.log(url)
  return axios.request({
    method: 'get',
    url: url,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function * fetchGlobalChainCommissionActionEffect () {
try {
    const { data } = yield call(fetchGlobalChainCommission)
    if (!isEmpty(data)) {
      console.log('Global Chain commission fetched')
      yield put(globalChainCommission(data))
     
    } else {
      
      yield put(globalChainCommission([]))
    }
  } catch (e) {
    console.log('Error found while fetching dsa')
    yield put(globalChainCommission([]))
  }
}

export function * fetchGlobalChainCommissionActionWatcher () {
  yield takeLatest(actions.FETCH_GLOBAL_CHAIN_COMMISSION_WATCHER, fetchGlobalChainCommissionActionEffect)
}

//###### Update global chain commmission #####################################

function updateGlobalChainCommission (param) {
  const url = createUrl(urls.BASE_URL + urls.dsa.BASE_URL + urls.dsa.UPDATE_GLOBAL_CHAIN_COMMISSION)
  console.log(url)
  // const params = { insuranceDetails: [...param] }
  return axios.request({
    method: 'post',
    url: url,
    headers: {
      'Content-Type': 'application/json'
    },
    data: param
  })
}

export function * updateGlobalChainCommissionActionEffect (param) {
  const { payload, resolve, reject } = param
  console.log('payload user id ---->', payload)
  try {
    const { data } = yield call(updateGlobalChainCommission, payload)
    if (!isEmpty(data)) {
      console.log('POS Data fetched')
      // yield put(fetchposDetailWatcher(payload.posId));
      resolve(data)

      //  yield put(posDetail(data));
    } else {
      reject(data)
    }
  } catch (e) {
    reject(e)
    console.log('Error found while fetching pos')
  }
}

export function * updateGlobalChainCommissionActionWatcher () {
  yield takeLatest(actions.UPDATE_GLOBAL_CHAIN_COMMISSION_WATCHER, updateGlobalChainCommissionActionEffect)
}

export default function * rootSaga() {
  yield all([
    fork(fetchDsaListActionWatcher),
    fork(fetchDsaDetailActionWatcher),
    fork(fetchDsaCommissionDetailActionWatcher),
    fork(updateDsaChainCommissionActionWatcher),
    fork(fetchPospListUnderDsaActionWatcher),
    fork(createDsaActionWatcher),
    fork(updateDsaActionWatcher),
    fork(updateGlobalChainCommissionActionWatcher),
    fork(fetchGlobalChainCommissionActionWatcher)
  ])
}
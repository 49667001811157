import * as React from "react";
import Admin from "../../DashBoard/Admin";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { withStyles } from '@material-ui/core/styles';
import AddDsaLayout from "./AddDsaLayout";
import {createDsaWatcher} from "../../../store/actions/dsaAction";
import { isEmpty, isEmailValid, isPhoneNumberValid,
  isAadharNumberValid, isPanNumberValid, hasOnlyCharacters } from "../../../utils/common";
  import {fieldIdMapper} from "../../../utils/constants";

const styles = {


};

class AddDsaPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dsaDetail: {},
      basicDetail: {},
      formErrors: {},
      isFormValid: {},
      operationDetail:{},
      resetStep:false
    }
  }

  validateInput =(obj, key, value) => {
    const formErrors = {...this.state.formErrors};
    formErrors[obj]=  formErrors[obj]? formErrors[obj]: {};

    const requiredFields = [
      'fullName', 'businessEntityName', 'businessAddress', 
      'email','phone', 'panNo', 'aadharNo', 'areaOfOperations', 
      'totalChainCommission', 'incentives', 'frequencyOfPayments',
    'paymentMode'];

    if(requiredFields.indexOf(key) !== -1){
      isEmpty(value) ? (formErrors[obj] = {...formErrors[obj],[key]:`Please enter valid ${fieldIdMapper.get(key)}`}) : formErrors[obj] = {...formErrors[obj],[key]: null};
    }
    switch(key){
      case 'fullName':
        if(!isEmpty(value) && !hasOnlyCharacters(value)){
          formErrors[obj] = {...formErrors[obj],[key]:"Name should contain alphabets only"};
          
        }
        break;
      case 'email':
      if(!isEmpty(value) && !isEmailValid(value)){
        formErrors[obj] = {...formErrors[obj],[key]:"Please enter valid email address"};
        
      }
      break;

      case 'phone':
      if(!isEmpty(value) && !isPhoneNumberValid(value)){
        formErrors[obj] = {...formErrors[obj],[key]:"Please enter valid Phone Number"};
      }
      break;
      case 'aadharNo':
      if(!isEmpty(value) && !isAadharNumberValid(value)){
        formErrors[obj] = {...formErrors[obj],[key]:"Please enter valid Aadhar Number"};
      }
      break;
      case 'panNo':
        if(!isEmpty(value) && !isPanNumberValid(value)){
          formErrors[obj] = {...formErrors[obj],[key]:"Please enter valid PAN Number"};
        }
        break;
      default:
      break;
    }

    this.setState({
      formErrors,
    })

  }

  updateKeyValueInState =(obj, key, value) => {
    const formErrors = {...this.state.formErrors};
    formErrors[obj]=  formErrors[obj]? formErrors[obj]: {};
    delete formErrors[obj][key];
    this.setState({
      [obj]: {...this.state[obj],[key]: value}
    });
  }

  onSubmit = (event) => {
  event.preventDefault();
  const {basicDetail, operationDetail} = this.state;
  const params ={...basicDetail, ...operationDetail, "levelsUnderDSA": [
    "posp1",
    "posp2",
    "manager",
    "srmanager",
    "advisor"
],};


  return new Promise((resolve, reject) => {
    this.props.createDsaWatcher(params,resolve,reject);
  });
  }

  render() {
    const {updateKeyValueInState, validateInput, onSubmit} = this;
    const {history} =this.props;
    const data= {
      updateKeyValueInState,
      validateInput,
      onSubmit
    };
    return (
      <Admin>
  <AddDsaLayout 
    {...this.state}
    {...data}
    history={history}
  />
      </Admin>
    );
  }

}

function mapStateToProps(state) {
  return {

  };
}


// mapping dispatch functions to the props of LoginForm component
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
  createDsaWatcher
      // add other watcher sagas to this object to map them to props
    },
    dispatch
  )
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AddDsaPage));
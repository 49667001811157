import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
// core components
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import DsaTable from "./DsaTable";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import Search from "@material-ui/icons/Search";
import AddIcon from '@material-ui/icons/Add';
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button";
import styleHeader from "../../assets/jss/componentsStyles/headerLinksStyle";
import FuzzySearch from 'fuzzy-search'


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "Montserrat",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  inputSearchType: {
    color: "white"
  },
  paddingStyle: {
    padding: '0 12px 0'
  },

  buttonStyle: {
    margin: "0 12px 0",
  }

};
function createData(fullName, phone, aadharNo, panNo, _id) {
  return { fullName, phone, aadharNo, panNo, _id }
}

const useStyles = makeStyles(styles);
const headerStyle = makeStyles(styleHeader);

export default function DsaList(props) {
  const classes = useStyles();
  const headerClass = headerStyle();
  const { dsaList = [], getDetails } = props;
  let rows = [];

  // const detailButton =  (<Button color="primary">Details</Button>)
  dsaList.forEach(dsa => {
    let row = createData(dsa.fullName, dsa.phone, dsa.aadharNo, dsa.panNo, dsa._id);
    rows.push(row);
  });


  const [filteredList, setList] = React.useState();

  const [showInput, toggleInput] = React.useState(false);

  React.useEffect(() => {
    setList(rows) // api.data.count from api after update store
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dsaList])
  //let newList = [...rows];

  React.useEffect(() => { document.getElementById("searchInput") && document.getElementById("searchInput").focus() }, [showInput])

  const filterList = (e) => {
    let currentList = []
    let newList = []

    // If the search bar isn't empty
    if (e.target.value !== '') {
      // Assign the original list to currentList
      currentList = rows
      const searcher = new FuzzySearch(currentList, ['fullName', 'phone', 'aadharNo', 'panNo', '_id'], {
        caseSensitive: false
      })
      newList = searcher.search(e.target.value.toLowerCase())
    } else {
      // If the search bar is empty, set newList to original task list
      newList = [...rows]
    }

    setList(newList)
  }


  const headCells = [
    { id: 'fullName', label: 'Name' },
    { id: 'phone', label: 'Phone No' },
    { id: 'aadharNo', label: 'Aadhaar Number' },
    { id: 'panNo', label: 'Pan Number' }];
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <span style={{ float: "left" }}>
              <h4 className={classes.cardTitleWhite}>Direct Selling Agency List</h4>
              {/* <p className={classes.cardCategoryWhite}>
                Direct Selling Agency List
            </p> */}
            </span>
            <span className={headerClass.searchWrapper} style={{ float: "right" }}>
              {showInput && <CustomInput
                id="searchInput"
                formControlProps={{
                  className: classNames(headerClass.margin, headerClass.search)
                }}
                inputProps={{
                  inputProps: {
                    "aria-label": "Search",
                    className: classes.inputSearchType
                  }
                }}
                onChange={(event) => filterList(event)}
              />}
                 <Button className={classes.paddingStyle} color="white" aria-label="edit" simple onClick={() => toggleInput(!showInput)}>
                <Search /> {' '}Search
              </Button>


              {/* <Button color="white" aria-label="edit" justIcon className={classes.buttonStyle}>
                <Tooltip title="Add DSA">
                  <AddIcon onClick={() => props.history.push("/dsa-add")} />
                </Tooltip>
              </Button> */}
              <Button color="white" className={classes.paddingStyle} aria-label="edit" simple onClick={() => props.history.push("/dsa-add")}>
                <AddIcon /> {' '} Add DSA
              </Button>

            </span>




          </CardHeader>
          <CardBody>

            <DsaTable
              tableHeaderColor="primary"
              headCells={headCells}
              isSelectRequired={false}
              orderByParameter="name"
              orderAs="asc"
              rows={filteredList}
              getDetails={getDetails}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

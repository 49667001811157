import * as actions from '../actions/types';

const initialState = {};

const dsaReducer = (state = initialState, action) => {

  switch (action.type) {

    case actions.DSA_LIST:
      return {
        ...state,
        dsaList: action.dsaList,
      };

    case actions.DSA_DETAIL:
      return {
        ...state,
        dsaDetail: action.dsaDetail,
      };

    case actions.DSA_COMMISSION_DETAIL:
      return {
        ...state,
        dsaCommissionDetail: action.dsaCommissionDetail,
      };

    case actions.GLOBAL_CHAIN_COMMISSION:
      return {
        ...state,
        globalChainCommission: action.globalChainCommission,
      };
    case actions.POSP_LIST_UNDER_DSA:
      return {
        ...state,
        pospListUnderDsa: action.pospListUnderDsa,
      };

    default:
      return state;
  }
};
export default dsaReducer;
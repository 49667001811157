import * as actions from '../actions/types';

const initialState = {userPermissions:{permissions:[]}};

const posReducer = (state = initialState, action) => {
  switch (action.type) {

    case actions.POS_LIST:
      return {
        ...state,
        posList: action.posList,
      };

    case actions.POS_ONBOARDING_LIST:
      return {
        ...state,
        posOnboardingList: action.posOnboardingList,
      };


    case actions.POS_DETAIL:
      return {
        ...state,
        posDetail: action.posDetail,
      };

    case actions.POLICY_SOLD_LIST:
      return {
        ...state,
        policySoldList: action.policySoldList,
      };
    case actions.PENDING_PROPOSALS_LIST:
      return {
        ...state,
        pendingProposalsList: action.pendingProposalsList,
      };

    case actions.POSP_EARNING:
      return {
        ...state,
        pospEarning: action.pospEarning,
      };
    case actions.USER_PERMISSIONS:
      return {
        ...state,
        userPermissions: action.userPermissions
      }
    default:
      return state;
  }
};

export default posReducer;
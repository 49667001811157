export default {
  BASE_URL: '/api/v1',
  login: {
    BASE_URL: '/admin/users/auth'
  },
  pos: {
    BASE_URL: '/pos',
    DETAIL: '/users',
    ONBOARDING_LIST: '/posOnboarding',
    PERSONAL_INFO: '/personal_info',
    EDUCATION_INFO: '/education_info',
    BANK_INFO: '/bank_info',
    BUSINESS_INFO: '/business_info',
    UPLOAD_STATUS: '/upload_status',
    POLICY_SOLD: '/policiesSold',
    UPLOAD_ELIGIBILITY_STATUS: '/uploadEligibilitystatus',
    DOWNLOAD_DOCUMENTS: '/downloaddocuments',
    APPLICATION_FORM: '/certificate/applicationForm',
    UPLOAD_DOCUMENT: '/docs',
    CERTIFICATION_INFO: '/certificate_info',
    COMMISSION: '/commission',
    ROLE: '/assignMultiUserRoles',
    SENIOR: '/assignpartnerCode',
    UPLOAD_COMMISSION: '/uploadCommission',
    EARNING: '/earnings',
    GLOBAL_COMISSION: '/globalCommission',
    UPLOAD_GLOBAL_COMMISSION: '/uploadGlobalCommission',
    UPLOAD_POSP: '/uploadposp',
    BROKER: '/broker',
    PAYOUT: '/posUserPayouts',
    UPDATE_PAYOUT: '/payouts',
    POLICY_WISE_EARNING: '/policyWiseEarnings'
  },
  dsa: {
    BASE_URL: '/dsa',
    COMMISSION: '/getDsaChainCommission',
    POSP_UNDER_DSA: '/getPosUnderDSA',
    CREATE_USER: '/createUser',
    UPDATE_DSA: '/updateDSADetails',
    UPDATE_GLOBAL_CHAIN_COMMISSION: '/uploadGlobalChainCommission',
    GLOBAL_CHAIN_COMMISSION: '/getGlobalChainCommission',
    UPDATE_DSA_CHAIN_COMMISSION: '/updateChainCommission'
  },
  product: {
    POLICY_TYPES: '/policy_types',
    GET_COMPANIES: '/getCompanies',
    GET_COMPANY_PRODUCTS: '/getCompaniesProducts',
    INITIAL_TITLE_INFO: '/titleInfo',
    OCCUPATION_DETAILS: '/occupationsInfo',
    ID_PROOF_INFO: '/idProofsInfo',
    GST_TYPE_INFO: '/gstTypeInfo',
    RELATIONS_INFO: '/relationsInfo',
    MARITAL_STATUS_INFO: '/maritalStatusInfo',
    OFFLINE_SALES: '/offlineSales',
    CREATE_PROPOSAL: '/create_proposal',
    PROPOSALS: '/proposal',
    MOTOR_PROPOSAL: '/motor/proposal',
    MOTOR_PREVIOUS_INSURER: '/motor/previousInsurerInfo',
    HEALTH_PREVIOUS_INSURER: '/previousInsurerName'
  },
  notification: {
    BASE_URL: '/notifications',
    SEND_NOTIFICATION: '/send_customized_notification'
  },
  global: {
    BASE_URL: '/global',
    PAYOUT_FREQUENCY: '/payoutFrequency'
  },
  specialUser: {
    BASE_URL: '/user/special',
    ASSISTED_SALES: '/assistedSales',
    PERMISSION: '/permissions',
    SAVE_PERMISSION: '/savepermissions'
  },
  role: {
    BASE_URL: '/role',
    USER_ROLES: '/getUserRole'
  },
  adminRole: {
    BASE_URL: '/admin/roles',
    MASTER_PERMISIIONS_URL: '/admin/roles/masterpermissions',
    SAVE_ROLE_PERMISIIONS_URL: "/admin/roles/save",
    FETCH_MASTER_ROLES_URL: "/masterroles",
    UPDATE_ADMIN: "/upadateadmin"
  },
  partners: {
    FETCH_PARTNERS: '/partners'
  }
}
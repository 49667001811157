import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { makeStyles } from '@material-ui/core/styles';
import Admin from 'Pages/DashBoard/Admin';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import {
    fetchposOnboardingListWatcher
} from 'store/actions/posAction';
import { fetchGlobalCommissionWatcher } from 'store/actions/posCommisionAction';
import ProgressMobileStepper from 'components/MobileStepper/MobileStepper';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import CardFooter from 'components/Card/CardFooter';
import { Typography } from '@material-ui/core';
import { fieldIdMapper } from "utils/constants";
import { toggleBackDrop, updateAlertMsg } from 'store/actions/commonAction';
import ErrorIcon from '@material-ui/icons/Error'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import cloneDeep from 'lodash/cloneDeep';
import { POLICY_DETAILS } from 'utils/routeConstants';
import InitialForm from './InitialForm';
import ProposerForm from './ProposerForm';
import MultipleMember from './MultipleMember'
import NomineeForm from './NomineeForm';
import SelectOption from 'components/CustomSelect/SelectOption'
import CustomInput from 'components/CustomInput/CustomInput'

import {
    fetchInsuranceTypeWatcher,
    fetchInsuranceCompanyByTypeWatcher,
    fetchAllProductByCompanyByPolicyTypeWatcher,
    fetchInitialTitleWatcher,
    fetchAllIdProofInfoWatcher,
    fetchAllOccupationDetailsWatcher,
    fetchGstTypeInfoWatcher,
    fetchRelationsInfoWatcher,
    fetchMaritalStatusInfoWatcher,
    createOfflineProposalWatcher,
    fetchPreviousInsurerWatcher,
    proposalDetails
} from 'store/actions/productAction';

import {
    isEmpty, isEmailValid, isPhoneNumberValid, changeDateToEpoach,
    isAadharNumberValid, hasOnlyCharacters, isDateValid, changeDateFormat
} from "utils/common";
import styles from './OfflineProposalStyle';
import VehicleForm from './VehicleForm';


const useStyle = makeStyles(styles);

function OfflineProposalPage(props) {
    const dateFormat = 'DD/MM/YYYY';
    let submitMyForm = null;
    const classes = useStyle();
    const { fetchposOnboardingListWatcher, fetchInitialTitleWatcher,
        fetchMaritalStatusInfoWatcher, fetchInsuranceTypeWatcher, fetchGlobalCommissionWatcher,
        fetchRelationsInfoWatcher, posList, insuranceTypeList, createOfflineProposalWatcher } = props;

    const [steps, setSteps] = useState(4);
    const [insuranceCompanies, setInsuranceCompanies] = React.useState([]);
    const [insuranceProduct, setInsuranceProduct] = React.useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const [headerName, setHeaderName] = useState('');
    const [insuranceType, setInsuranceType] = useState('');
    //Mainly for Motor
    const [policyType, setPolicyType] = React.useState('');

    const [initialForm, setInitalForm] = useState({});
    const [proposerForm, setProposerForm] = useState({});

    const [membersForm, setMembersForm] = useState([]);
    const [vehicleForm, setVehicleForm] = useState({});

    // const useStateCallbackWrapper = (initilValue, callBack) => {
    //     const [state, setState] = useState(initilValue);
    //     useEffect(() => callBack());
    //     return [state, setState];
    // };

    const handleFormSubmit = (e) => {
        console.log("into the form submit page")
        toggleBackDrop(true)
        let params = {};
        let initialData = { ...initialForm };
        let proposerData = { ...proposerForm };
        let membersData = [];
        let nomineeData = { ...e };
        let vehicleData = { ...vehicleForm };

        if (!isEmpty(initialData)) {
            initialData.dateOfPolicyIssued = changeDateToEpoach(initialData.dateOfPolicyIssued);
            initialData.dateOfPayment = changeDateToEpoach(initialData.dateOfPayment);
            const insType = initialData.insuranceType;
            initialData.insuranceType = insType.charAt(0).toUpperCase() + insType.slice(1).toLowerCase();

        }
        if (!isEmpty(proposerData)) {
            proposerData.dob = changeDateFormat(proposerData.dob, dateFormat);
            proposerData.phone = proposerData.phone.toString()
        }
        if (!isEmpty(nomineeData) &&  nomineeData.nomineeDOB) {
           nomineeData.nomineeDOB = changeDateFormat(nomineeData.nomineeDOB, dateFormat);
        }

        if (!isEmpty(vehicleData)) {
            vehicleData.manufactureDate = changeDateToEpoach(vehicleData.manufactureDate);
            vehicleData.policyExpiryDate = changeDateToEpoach(vehicleData.policyExpiryDate);
            vehicleData.registrationDate = changeDateToEpoach(vehicleData.registrationDate);
        }

        if (!isEmpty(membersForm)) {
            membersForm.forEach((member, index) => {
                const {
                    title, firstName, middleName, lastName, dob,
                    email, phone, gender, maritalStatus, occupation,
                    idProofType, idProofNumber, gstType, GSTIN,
                    relation, heightInFeet, heightInInches, weight
                    , previousInsuranceDetails
                } = member
                let address = [];
                address.push({
                    addressType: 'Permanent',
                    addressLine1: member.permanentAddressLine1,
                    addressLine2: member.permanentAddressLine2,
                    addressLine3: member.permanentAddressLine3,
                    city: member.permanentCity,
                    district: member.permanentDistrict,
                    state: member.permanentState,
                    area: member.permanentArea,
                    country: member.permanentCountry,
                    pinCode: member.permanentPinCode,
                    landmark: member.permanentLandmark
                });
                address.push({
                    addressType: 'Communication',
                    addressLine1: member.communicationAddressLine1,
                    addressLine2: member.communicationAddressLine2,
                    addressLine3: member.communicationAddressLine3,
                    city: member.communicationCity,
                    district: member.communicationDistrict,
                    state: member.communicationState,
                    area: member.communicationArea,
                    country: member.communicationCountry,
                    pinCode: member.communicationPinCode,
                    landmark: member.communicationLandmark
                });
                let data = {
                    title, firstName, middleName, lastName, dob: changeDateFormat(dob, dateFormat),
                    email, phone: phone.toString(), gender, maritalStatus, occupation,
                    idProofType, idProofNumber, gstType, GSTIN,
                    relation, heightInFeet, heightInInches, weight,
                    address
                };
                if (previousInsuranceDetails) {
                    const prevIns = []

                    const objVal = { internalCode: '', value: previousInsuranceDetails };
                    prevIns.push(objVal);

                    data.previousInsuranceDetails = prevIns;
                }
                membersData.push(data);
            });
        }
        switch (insuranceType) {
            case 'HEALTH':
                params = {
                    ...initialData,
                    memberDetails: membersData,
                    nomineeDetails: nomineeData,
                    proposerDetails: proposerData
                };
                break;

            case 'TERM':
                params = {
                    ...initialData,
                    nomineeDetails: nomineeData,
                    proposerDetails: proposerData
                }
                break;

            default:
                const subTotalPremiumInfo = { subTotal: initialData.subTotal, tpPremium: initialData.tpPremium };

                const {communicationFlatNumber,
                    communicationStreetNumber,
                    communicationStreetName,
                    communicationCity,
                    communicationDistrict,
                    communicationState,
                    communicationPincode,
                    communicationCountry } = proposerData || {};

                const motorProposerData = {
                    proposerDOB: proposerData.dob,
                    proposerEmail: proposerData.email,
                    proposerTitle: proposerData.title,
                    proposerFirstName: proposerData.firstName,
                    proposerMiddleName: proposerData.middleName,
                    proposerGender: proposerData.gender,
                    proposerLastName: proposerData.lastName,
                    proposerMaritalStatus: proposerData.maritalStatus,
                    proposerNumber: proposerData.phone,
                    communicationFlatNumber,
                    communicationStreetNumber,
                    communicationStreetName,
                    communicationCity,
                    communicationDistrict,
                    communicationState,
                    communicationPincode,
                    communicationCountry
                };

                params = {
                    subTotalPremiumInfo,
                    ...initialData,
                    premiumAnnually: initialData.premium,
                    IDV: initialData.sumInsured,
                    ...motorProposerData,
                    ...vehicleData,
                    ...nomineeData
                }
        }
        new Promise((resolve, reject) => {

            createOfflineProposalWatcher(params, resolve, reject);
        }).then(data => {
            toggleBackDrop(false);
            const alertMsg = {
                color: 'success',
                icon: CheckCircleOutlineIcon,
                message: data.message
            }
            updateAlertMsg(alertMsg)
            props.history.push({ pathname: `/${POLICY_DETAILS}/${insuranceType}/${data.proposalId}`, state: { proposalId: data.proposalId, insuranceType } });
        }).catch((error = {}) => {
            toggleBackDrop(false);
            const errMsg = error.data ? error.data.message : 'Something error. Please try again.';

            const alertMsg = {
                message: errMsg,
                color: 'danger',
                icon: ErrorIcon,
            }
            //   this.props.history.push(routeTo.OFFLINE_PROPOSAL);
            updateAlertMsg(alertMsg)

            console.log("error while creating offline proposal", error)
        });

    }

    const [nomineeForm, setNomineeForm] = useState({});

    useEffect(() => {
        fetchposOnboardingListWatcher();
        fetchInsuranceTypeWatcher();
        fetchGlobalCommissionWatcher();
        fetchInitialTitleWatcher();
        fetchMaritalStatusInfoWatcher();
        fetchRelationsInfoWatcher();
    }, []);

    const fetchInsuranceCompanies = (value) => {
        new Promise((resolve, reject) => {
            props.fetchInsuranceCompanyByTypeWatcher(value, resolve, reject);
        }).then(data => {
            let insuranceCompanies = []
            insuranceCompanies = data;

            setInsuranceCompanies(insuranceCompanies)
        }).catch((e) => {
            setInsuranceCompanies([])
        });
    }

    const fetchProducts = (param) => {
        new Promise((resolve, reject) => {
            props.fetchAllProductByCompanyByPolicyTypeWatcher(param, resolve, reject);
        }).then(data => {

            setInsuranceProduct(data)
        }).catch(e => {
            console.log("error fetching company product", e);
            setInsuranceProduct([])
        })
    }

    const handleNext = (e, ...other) => {
        if (submitMyForm)
            submitMyForm(e, ...other);

        // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSubmit = (e, form) => {

        switch (form) {
            case 'initialForm':
                setInitalForm(e);
                break;

            case 'proposerForm':
                setProposerForm(e);
                break;

            case 'nomineeForm':
                setNomineeForm(e);
                handleFormSubmit(e)
                break;

            case 'vehicleForm':
                setVehicleForm(e);
                break;

            default:
                break;
        }
        if (activeStep === steps - 1) {
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    const bindSubmitForm = (submitForm) => {
        submitMyForm = submitForm;
    }



    const renderSelect = (props) => {
        const { name = '', label = '', options = [],
            touched = {}, errors = {}, handleChange, values = {}, handleBlur, isRequired, multiple = false } = props

        return (
            <SelectOption
                name={name}
                labelText={label}
                selectOptions={options}
                helperText={touched[name] && errors[name]}
                // error={isError('role')}
                error={touched[name] && errors[name]}
                formControlProps={{
                    fullWidth: true,
                }}
                isRequired={isRequired}
                onSelectChange={handleChange}
                inputProps={{
                    value: values[name],
                    onBlur: handleBlur(name),
                    error: touched[name] && errors[name],
                    multiple: multiple
                }}
            />
        )
    }

    const renderInput = (props) => {
        const { name = '', label = '', touched = {},
            errors = {}, handleChange, handleBlur, values = {}, isRequired, ...others } = props
        return (
            <CustomInput
                labelText={label}
                name={name}
                formControlProps={{
                    fullWidth: true
                }}
                isRequired={isRequired}
                error={touched[name] && errors[name]}
                helperText={touched[name] && errors[name]}
                onChange={handleChange(name)}
                inputProps={{
                    value: values[name],
                    onBlur: handleBlur(name),
                    error: touched[name] && errors[name],
                    ...others

                }}
            />
        );
    }


    const getActiveContent = () => {
        switch (activeStep) {
            case 0:
                return (<InitialForm
                    handleSubmit={handleSubmit}
                    fetchInsuranceCompanies={fetchInsuranceCompanies}
                    fetchProducts={fetchProducts}
                    insuranceCompanies={insuranceCompanies}
                    insuranceProduct={insuranceProduct}
                    initialForm={initialForm}
                    classes={classes}
                    posList={posList}
                    setHeaderName={setHeaderName}
                    renderInput={renderInput}
                    renderSelect={renderSelect}
                    insuranceTypeList={insuranceTypeList}
                    bindSubmitForm={bindSubmitForm}
                    setInsuranceType={setInsuranceType}
                    policyType={policyType}
                    setPolicyType={setPolicyType}
                    setSteps={setSteps}
                    {...props}
                />);

            case 1:
                return (<ProposerForm
                    handleSubmit={handleSubmit}
                    insuranceType={insuranceType}
                    renderInput={renderInput}
                    renderSelect={renderSelect}
                    classes={classes}
                    proposerForm={proposerForm}
                    bindSubmitForm={bindSubmitForm}
                    setHeaderName={setHeaderName}
                    {...props}
                />
                );

            case 2:
                if (insuranceType === 'HEALTH') {
                    return (<MultipleMember
                        {...props}
                        renderInput={renderInput}
                        insuranceType={insuranceType}
                        bindSubmitForm={bindSubmitForm}
                        handleSubmit={handleSubmit}
                        membersForm={membersForm}
                        setMembersForm={setMembersForm}
                        renderSelect={renderSelect}
                        setHeaderName={setHeaderName}
                        classes={classes}
                    />)
                } else if (insuranceType === 'TERM') {
                    return (<NomineeForm
                        {...props}
                        renderInput={renderInput}
                        renderSelect={renderSelect}
                        nomineeForm={nomineeForm}
                        setHeaderName={setHeaderName}
                        bindSubmitForm={bindSubmitForm}
                        handleSubmit={handleSubmit}
                        classes={classes}
                    />)
                }
                else {
                    return (<VehicleForm
                        {...props}
                        renderInput={renderInput}
                        renderSelect={renderSelect}
                        insuranceType={insuranceType}
                        vehicleForm={vehicleForm}
                        bindSubmitForm={bindSubmitForm}
                        handleSubmit={handleSubmit}
                        setHeaderName={setHeaderName}
                        classes={classes}
                    />)
                }

            case 3:
                return (<NomineeForm
                    {...props}
                    renderInput={renderInput}
                    renderSelect={renderSelect}
                    nomineeForm={nomineeForm}
                    setHeaderName={setHeaderName}
                    bindSubmitForm={bindSubmitForm}
                    handleSubmit={handleSubmit}
                    classes={classes}
                />)
        }
    }

    return (
        <Admin>
            <Card>
                <CardHeader color="primary">
                    <Typography variant="h6">{headerName}</Typography>
                </CardHeader>
                <CardBody>
                    {
                        getActiveContent()
                    }

                </CardBody>
                <CardFooter>
                    <ProgressMobileStepper handleBack={handleBack}
                        handleNext={handleNext} steps={steps} activeStep={activeStep} />
                </CardFooter>
            </Card>

        </Admin>
    );
}

function mapStateToProps(state) {
    const { posReducer, productReducer, posCommissionReducer } = state;
    const { globalCommission: { insuranceDetails = [] } = {} } = posCommissionReducer;
    const { insuranceTypeList = [], maritalStatusList = [], relationList = [],
        initialTitles = [], gstTypeList = [], occupationList = [], idProofList = [] } = productReducer;
    return {
        posList: posReducer.posOnboardingList,
        insuranceTypeList,
        insuranceDetails,
        maritalStatusList,
        titleList: initialTitles,
        gstTypeList,
        occupationList,
        idProofList,
        relationshipList: relationList

    };
}
// mapping dispatch functions to the props of LoginForm component
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            fetchposOnboardingListWatcher,
            fetchInsuranceTypeWatcher,
            fetchInsuranceCompanyByTypeWatcher,
            fetchAllProductByCompanyByPolicyTypeWatcher,
            fetchInitialTitleWatcher,
            fetchAllOccupationDetailsWatcher,
            fetchGstTypeInfoWatcher,
            fetchRelationsInfoWatcher,
            fetchMaritalStatusInfoWatcher,
            fetchAllIdProofInfoWatcher,
            createOfflineProposalWatcher,
            fetchPreviousInsurerWatcher,
            fetchGlobalCommissionWatcher,
            toggleBackDrop, updateAlertMsg

            // add other watcher sagas to this object to map them to props
        },
        dispatch
    )
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OfflineProposalPage);
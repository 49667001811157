import React from 'react'
import GridContainer from '../../../components/Grid/GridContainer'
import Card from '../../../components/Card/Card'
import CardBody from '../../../components/Card/CardBody'
import GridItem from '../../../components/Grid/GridItem'
import CustomInput from '../../../components/CustomInput/CustomInput'
import MultiSelect from '../../../components/CustomSelect/MultiSelectOption'
import SelectOption from '../../../components/CustomSelect/SelectOption'

const areaOfOperationOptions = [
  { value: 'mysore', label: 'Mysore' },
  { value: 'bangalore', label: 'Bangalore' },
  { value: 'delhi', label: 'Delhi' },
  { value: 'mumbai', label: 'Mumbai' },
  { value: 'pune', label: 'Pune' },
  { value: 'others', label: 'Others' }]

const frequencyOfPaymentOptions = [
  { value: null, label: 'None' },
  { value: 'monthly', label: 'Monthly' },
  { value: 'quarterly', label: 'Quarterly' }
]

const paymentModeOptions = [
  { value: null, label: 'None' },
  { value: 'dsa', label: 'To DSA' },
  { value: 'individual', label: 'To Individual' }
]

export default function OperationFormStep (props) {
  const { classes, operationDetail, validateInput, updateKeyValueInState, formErrors = {}, isError = {} } = props

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card raised className={classes.cardBackground}>
          <CardBody raised >
            <GridContainer>

              {/* Tentative Premium Target option */}
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Tentative Premium Target"
                  id="tentativePremiumTarget"
                  formControlProps={{
                    fullWidth: true
                  }}
                  error={isError('operationDetail', 'tentativePremiumTarget')}
                  onChange={({ target: { value } }) => (value ? updateKeyValueInState('operationDetail', 'tentativePremiumTarget', value) : updateKeyValueInState('operationDetail', 'tentativePremiumTarget', null))}
                  inputProps={{
                    value: operationDetail.tentativePremiumTarget ? operationDetail.tentativePremiumTarget : '',
                    onBlur: ({ target: { value } }) => validateInput('operationDetail', 'tentativePremiumTarget', value),
                    error: isError('operationDetail', 'tentativePremiumTarget'),
                    type: 'number'
                  }}
                />
                {isError('operationDetail', 'tentativePremiumTarget') ? (
                  <span className={classes.error}>
                    {formErrors.operationDetail.tentativePremiumTarget}
                  </span>
                ) : null}
              </GridItem>

              {/* Tentative No Of Agents */}
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Tentative Number Of Agents"
                  id="tentativeNoOfAgents"
                  formControlProps={{
                    fullWidth: true
                  }}
                  error={isError('operationDetail', 'tentativeNoOfAgents')}
                  onChange={({ target: { value } }) => (value ? updateKeyValueInState('operationDetail', 'tentativeNoOfAgents', value) : updateKeyValueInState('operationDetail', 'tentativeNoOfAgents', null))}
                  inputProps={{
                    value: operationDetail.tentativeNoOfAgents ? operationDetail.tentativeNoOfAgents : '',
                    onBlur: ({ target: { value } }) => validateInput('operationDetail', 'tentativeNoOfAgents', value),
                    error: isError('operationDetail', 'tentativeNoOfAgents'),
                    type: 'number'
                  }}
                />
                {isError('operationDetail', 'tentativeNoOfAgents') ? (
                  <span className={classes.error}>
                    {formErrors.operationDetail.tentativeNoOfAgents}
                  </span>
                ) : null}
              </GridItem>

              {/* Area Of operation */}
              <GridItem xs={12} sm={12} md={6}>
                <MultiSelect
                  id="areaOfOperations"
                  labelText="Area of Operations"
                  selectOptions={areaOfOperationOptions}
                  error={isError('operationDetail', 'areaOfOperations')}
                  handleChange={({ target: { value } }) => (value ? updateKeyValueInState('operationDetail', 'areaOfOperations', value) : updateKeyValueInState('operationDetail', 'areaOfOperations', null))}
                  formControlProps={{
                    fullWidth: true
                  }}
                  isRequired
                  inputProps={{
                    value: operationDetail.areaOfOperations ? operationDetail.areaOfOperations : [],
                    onBlur: ({ target: { value } }) => validateInput('operationDetail', 'areaOfOperations', value),
                    multiple: true,
                    error: isError('operationDetail', 'areaOfOperations')

                  }}
                />
                {isError('operationDetail', 'areaOfOperations') ? (
                  <span className={classes.error}>
                    {formErrors.operationDetail.areaOfOperations}
                  </span>
                ) : null}
              </GridItem>

              {/* Total Chain Commission */}
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Total Chain Commission (in percentage)"
                  id="totalChainCommission"
                  formControlProps={{
                    fullWidth: true
                  }}
                  isRequired
                  error={isError('operationDetail', 'totalChainCommission')}
                  onChange={({ target: { value } }) => (value ? updateKeyValueInState('operationDetail', 'totalChainCommission', value) : updateKeyValueInState('operationDetail', 'totalChainCommission', null))}
                  inputProps={{
                    value: operationDetail.totalChainCommission ? operationDetail.totalChainCommission : '',
                    onBlur: ({ target: { value } }) => validateInput('operationDetail', 'totalChainCommission', value),
                    error: isError('operationDetail', 'totalChainCommission'),
                    type: 'number',
                    min: 0,
                    max: 100
                  }}
                />
                {isError('operationDetail', 'totalChainCommission') ? (
                  <span className={classes.error}>
                    {formErrors.operationDetail.totalChainCommission}
                  </span>
                ) : null}
              </GridItem>

              {/* Incentives for DSA */}
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Incentives for DSA (in percentage)"
                  id="incentives"
                  formControlProps={{
                    fullWidth: true
                  }}
                  isRequired
                  error={isError('operationDetail', 'incentives')}
                  onChange={({ target: { value } }) => (value ? updateKeyValueInState('operationDetail', 'incentives', value) : updateKeyValueInState('operationDetail', 'incentives', null))}
                  inputProps={{
                    value: operationDetail.incentives ? operationDetail.incentives : '',
                    onBlur: ({ target: { value } }) => validateInput('operationDetail', 'incentives', value),
                    error: isError('operationDetail', 'incentives'),
                    type: 'number',
                    min: 0,
                    max: 100
                  }}
                />
                {isError('operationDetail', 'incentives') ? (
                  <span className={classes.error}>
                    {formErrors.operationDetail.incentives}
                  </span>
                ) : null}
              </GridItem>

              {/* Frequency of payment for DSA */}
              <GridItem xs={12} sm={12} md={6}>
                <SelectOption
                  id="frequencyOfPayments"
                  labelText="Frequency of Payments"
                  selectOptions={frequencyOfPaymentOptions}
                  error={isError('operationDetail', 'frequencyOfPayments')}
                  formControlProps={{
                    fullWidth: true
                  }}
                  isRequired
                  onSelectChange={({ target: { value } }) => (value ? updateKeyValueInState('operationDetail', 'frequencyOfPayments', value) : updateKeyValueInState('operationDetail', 'frequencyOfPayments', null))}
                  inputProps={{

                    value: operationDetail.frequencyOfPayments ? operationDetail.frequencyOfPayments : null,
                    onBlur: ({ target: { value } }) => validateInput('operationDetail', 'frequencyOfPayments', value),
                    error: isError('operationDetail', 'frequencyOfPayments')
                  }}
                />

                {isError('operationDetail', 'frequencyOfPayments') ? (
                  <span className={classes.error}>
                    {formErrors.operationDetail.frequencyOfPayments}
                  </span>
                ) : null}
              </GridItem>

              {/* Payment Mode */}
              <GridItem xs={12} sm={12} md={6}>
                <SelectOption
                  id="paymentMode"
                  labelText="Payment Mode"
                  selectOptions={paymentModeOptions}
                  error={isError('operationDetail', 'paymentMode')}
                  formControlProps={{
                    fullWidth: true
                  }}
                  isRequired
                  onSelectChange={({ target: { value } }) => (value ? updateKeyValueInState('operationDetail', 'paymentMode', value) : updateKeyValueInState('operationDetail', 'frequencyOfPayments', null))}
                  inputProps={{

                    value: operationDetail.paymentMode ? operationDetail.paymentMode : null,
                    onBlur: ({ target: { value } }) => validateInput('operationDetail', 'paymentMode', value),
                    error: isError('operationDetail', 'paymentMode')
                  }}
                />

                {isError('operationDetail', 'paymentMode') ? (
                  <span className={classes.error}>
                    {formErrors.operationDetail.paymentMode}
                  </span>
                ) : null}
              </GridItem>

            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

import { call, put, takeLatest, all, fork } from 'redux-saga/effects'
import * as actions from '../actions/types'
import axios from 'utils/axios'
import urls from 'utils/urls'
import { createUrl, isEmpty } from 'utils/common'
import { specialUserList, specialUserDetail, assistedSales, parternersList } from '../actions/specialUserAction';
import { toggleBackDrop } from 'store/actions/commonAction';

// ################### fetch the special user list ############################################

function fetchSpecialUserList() {
  return axios.request({
    method: 'get',
    url: createUrl(urls.BASE_URL + urls.specialUser.BASE_URL),
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function* fetchSpecialUserListActionEffect() {
  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(fetchSpecialUserList)
    yield put(toggleBackDrop(false));

    if (!isEmpty(data)) {

      yield put(specialUserList(data))
    } else {
      // reject(data);
      yield put(specialUserList([]))
    }
  } catch (e) {
    yield put(toggleBackDrop(false));

    //   alert.error(e.message || 'Authentication Error');
    //   yield put(authError(e));
    console.log('Error found while fetching dsa')
      // if (reject) reject(e);
  }
}

export function* fetchSpecialUserListActionWatcher() {
  yield takeLatest(actions.SPECIAL_USER_LIST_WATCHER, fetchSpecialUserListActionEffect)
}

// ###### Special User Detail ##############################

function fetchSpecialUserDetail(userId) {
  const url = createUrl(urls.BASE_URL + urls.specialUser.BASE_URL) + '/' + userId
  console.log(url)
  return axios.request({
    method: 'get',
    url: url,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function* fetchSpecialUserDetailActionEffect(userDetails) {
  const { payload } = userDetails
  console.log('payload user id ---->', payload)
  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(fetchSpecialUserDetail, payload)
    yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      console.log('Special User Data fetched')

      yield put(specialUserDetail(data))
    } else {
      // reject(data);
      yield put(specialUserDetail({}))
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    console.log('Error found while fetching special user')
    yield put(specialUserDetail({}))
  }
}

export function* fetchSpecialUserDetailActionWatcher() {
  yield takeLatest(actions.SPECIAL_USER_DETAIL_WATCHER, fetchSpecialUserDetailActionEffect)
}

// ################## Create Special User ##########################################################

function createSpecialUser(param) {
  const url = createUrl(urls.BASE_URL + urls.specialUser.BASE_URL)
    // console.log("createSpecialUser", url, param)
  return axios.request({
    method: 'post',
    url: url,
    headers: {
      'Content-Type': 'application/json'
    },
    data: param
  })
}

export function* createSpecialUserActionEffect(params) {
  const { payload, resolve, reject } = params

  try {
    const { data } = yield call(createSpecialUser, payload)
    if (!isEmpty(data)) {
      console.log('Special User Data fetched')
      resolve(data)
    } else {
      reject(data)
    }
  } catch (e) {
    console.log('Error found while fetching Special User')
    reject(e)
  }
}

export function* createSpecialUserActionWatcher() {
  yield takeLatest(actions.CREATE_SPECIAL_USER_WATCHER, createSpecialUserActionEffect)
}

// ################## Update DSA User ######################################

function updateSpecialUser(param) {
  const url = createUrl(urls.BASE_URL + urls.specialUser.BASE_URL)
  console.log(url)
  return axios.request({
    method: 'put',
    url: url,
    headers: {
      'Content-Type': 'application/json'
    },
    data: param
  })
}
export function* updateSpecialUserActionEffect(params) {
  const { payload, resolve, reject } = params

  try {
    const { data } = yield call(updateSpecialUser, payload)
    if (!isEmpty(data)) {
      console.log('Special User Data fetched')
      resolve(data)
    } else {
      reject(data)
    }
  } catch (e) {
    console.log('Error found while fetching Special User')
    reject(e)
  }
}

export function* updateSpecialUserActionWatcher() {
  yield takeLatest(actions.UPDATE_SPECIAL_USER_WATCHER, updateSpecialUserActionEffect)
}

// ###### Assisted Sales fetch ##############################

function fetchAssistedSales(userId) {
  const url = createUrl(urls.BASE_URL + urls.specialUser.BASE_URL + urls.specialUser.ASSISTED_SALES) + '/' + userId
  console.log(url)
  return axios.request({
    method: 'get',
    url: url,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function* fetchAssistedSalesActionEffect(userDetails) {
  const { payload } = userDetails
  console.log('payload user id ---->', payload)
  try {
    yield put(toggleBackDrop(true));
    const { data } = yield call(fetchAssistedSales, payload)
    yield put(toggleBackDrop(false));
    if (!isEmpty(data)) {
      console.log('Assisted Sales fetched')

      yield put(assistedSales(data))
    } else {
      // reject(data);
      yield put(assistedSales([]))
    }
  } catch (e) {
    yield put(toggleBackDrop(false));
    console.log('Error found while fetching Assisted Sales')
    yield put(assistedSales([]))
  }
}

export function* fetchAssistedSalesActionWatcher() {
  yield takeLatest(actions.FETCH_ASSISTED_SALES_WATCHER, fetchAssistedSalesActionEffect)
}

// ###### Fetch Partner Detail ##############################

function fetchPartners() {
  const url = createUrl(urls.BASE_URL + urls.partners.FETCH_PARTNERS)
  console.log("partnerUrl", url)
  return axios.request({
    method: 'get',
    url: url,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function* fetchPartnersActionEffect() {

  try {
    // yield put(toggleBackDrop(true));
    const { data } = yield call(fetchPartners);
    if (!isEmpty(data)) {
      // console.log('partner details fetched', data)

      yield put(parternersList(data))
    } else {
      // reject(data);
      // yield put(assistedSales([]))
    }
  } catch (e) {
    console.log('Error found while fetching Partners details')
      // yield put(assistedSales([]))
  }
}

export function* fetchPartnersWatcher() {
  yield takeLatest(actions.FETCH_PARTNERS, fetchPartnersActionEffect)
}


export default function* rootSaga() {
  yield all([
    fork(fetchSpecialUserListActionWatcher),
    fork(fetchSpecialUserDetailActionWatcher),
    fork(updateSpecialUserActionWatcher),
    fork(createSpecialUserActionWatcher),
    fork(fetchAssistedSalesActionWatcher),
    fork(fetchPartnersWatcher)
  ])
}
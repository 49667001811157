/*eslint-disable*/
import React, { Fragment } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import CollapsableMenu from "./CollapsableMenu";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

// core components
import AdminNavbarLinks from "../Navbar/adminNavbarLink";
import {isEmpty} from "../../utils/common";

import styles from "../../assets/jss/componentsStyles/sidebarStyle";

const useStyles = makeStyles(styles);

function activeRoute(routeName){
  return window.location.href.indexOf(routeName) > -1 ? true : false;
}

export default function Sidebar(props) {
  const classes = useStyles();
  // verifies if routeName is the one active (in browser input)

 
  const { color, logo, image, logoText, routes=[] } = props;
  let groups ={};
  routes.map(prop => {
    if(!isEmpty(prop.groupBy)){
      groups[prop.groupBy] ? groups[prop.groupBy].push(prop): groups[prop.groupBy] = [{...prop}];
    }
  });


  const singleLink = (props) => {
   return props.map((prop, key) => {
      var activePro = " ";
      var listItemClasses;
      
        listItemClasses = classNames({
          [" " + classes[color]]: activeRoute(prop.layout + prop.path)
        });
      
      const whiteFontClasses = classNames({
        [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path)
      });
      // if(prop.path.indexOf("pos") == -1){
      return (
        <NavLink
          to={prop.layout + prop.path}
          className={activePro + classes.item}
          activeClassName="active"
          key={key}
        >
          <ListItem button className={classes.itemLink + listItemClasses}>
            {typeof prop.icon === "string" ? (
              <Icon
                className={classNames(classes.itemIcon, whiteFontClasses, {
                  [classes.itemIconRTL]: props.rtlActive
                })}
              >
                {prop.icon}
              </Icon>
            ) : (
              <prop.icon
                className={classNames(classes.itemIcon, whiteFontClasses, {
                  [classes.itemIconRTL]: props.rtlActive
                })}
              />
            )}
            <ListItemText
              primary={props.rtlActive ? prop.rtlName : prop.name}
              className={classNames(classes.itemText, whiteFontClasses, {
                [classes.itemTextRTL]: props.rtlActive
              })}
              disableTypography={true}
            />
          </ListItem>
        </NavLink>
      );
    }) 
  }

  var links = (
    <List className={classes.list}>
      {
        Object.keys(groups).map((key, sno) => {
          if(key === 'None' ||  key === "d")
          return singleLink(groups[key]);
          else
           return <CollapsableMenu group ={groups[key]} key ={sno} color ={color} menuName= {key} classes={classes} />
          
        })

      }
    </List>
  );


  var brand = (
    <div className={classes.logo}>
      <a
        href="javascript:void(0)"
        className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive
        })}
      >
        <div className={classes.logoImage}>
          <img src={logo} alt="logo" className={classes.img} />
        </div>
        {logoText}
      </a>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            <AdminNavbarLinks />
            {links}
            
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={ "right"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
          {links}
          
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool
};

import React from 'react'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from '../../../components/Card/CardHeader'
import Typography from '@material-ui/core/Typography'
import SelectOption from 'components/CustomSelect/SelectOption'
import CustomInput from 'components/CustomInput/CustomInput'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { sortBy } from 'utils/common'
import * as CONSTANT from 'utils/constants'
import CustomDatePicker from 'components/DatePicker/CustomDatePicker'
import {
    Formik, Form, Field, ErrorMessage,
} from 'formik';
import { renderDatePicker } from 'components/ReduxComponents/reduxComponents';
import { FormGroup } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { isEmpty } from 'utils/common';
import * as Yup from 'yup';

export default function MemberForm(props) {
    const {
        classes,
        relationshipList = [],
         membersData: {
            title= '',
            firstName= '',
            middleName= '',
            lastName= '',
            dob= null,
            email= '',
            phone= '',
            gender= '',
            maritalStatus= '',
            occupation= '',
            idProofType= '',
            idProofNumber= '',
            gstType= '',
            GSTIN= '',
            relation= '',
            heightInFeet= '',
            heightInInches= '',
            weight= '',
            previousInsuranceDetails= '',
            permanentAddressLine1= '',
            permanentAddressLine2= '',
            permanentAddressLine3= '',
            permanentCity= '',
            permanentDistrict= '',
            permanentState= '',
            permanentArea= '',
            permanentCountry= '',
            permanentPinCode= '',
            permanentLandmark= '',
            communicationAddressLine1= '',
            communicationAddressLine2= '',
            communicationAddressLine3= '',
            communicationCity= '',
            communicationDistrict= '',
            communicationState= '',
            communicationArea= '',
            communicationCountry= '',
            communicationPinCode= '',
            communicationLandmark= ''    
         } = {}, 
        titleList = [], maritalStatusList = [],
        occupationList = [], gstTypeList = [], idProofList = [], handleClose, index,
         previousInsurerList = [], handleMemberSubmit, bindSubmitMemberForm,
        renderInput, renderSelect
    } = props

    const [isChecked, toggleCheck] = React.useState(false)



    const genderOptions = [{ value: 'male', label: 'Male' },
    { value: 'feMale', label: 'Female' },
    { value: 'others', label: 'Others' }];

    const getOccupationOptions = () => {
        const occupationOptions = []
        occupationList.map(occupation => occupationOptions.push({ value: occupation.name, label: occupation.name }))
        return occupationOptions
    }

    const getRelationOptions = () => {
        const relationOptions = [{ value: null, label: 'None' }]
        relationshipList.map(relation => relationOptions.push({ value: relation.name, label: relation.name }))
        return relationOptions
    }

    const getTitleOptions = () => {
        const titleOptions = []
        titleList.map(title => titleOptions.push({ value: title.name, label: title.name }))
        return titleOptions
    }

    const getIdProofOptions = () => {
        const idProofOptions = []
        idProofList.map(idProof => idProofOptions.push({ value: idProof.name, label: idProof.name }))
        return idProofOptions
      }

    const getMaritalStatusOptions = () => {
        const martialStatusOptions = []
        maritalStatusList.map(maritalStatus => martialStatusOptions.push({ value: maritalStatus.name, label: maritalStatus.name }))
        return martialStatusOptions
    }

    const getGstTypeOptions = () => {
        const gstTypeOptions = [{ value: null, label: 'None' }]
        gstTypeList.map(gst => gstTypeOptions.push({ value: gst.name, label: gst.name }))
        return gstTypeOptions
    }

    const getPreviousInsuranceOptions = () => {
        const previousInsuranceOptions = []
        previousInsurerList.forEach(val => {
            previousInsuranceOptions.push({ value: val, label: val })
        })
        return previousInsuranceOptions
    }

    let initialValues = {
        title,
        firstName,
        middleName,
        lastName,
        dob,
        email,
        phone,
        gender,
        maritalStatus,
        occupation,
        idProofType,
        idProofNumber,
        gstType,
        GSTIN,
        relation,
        heightInFeet,
        heightInInches,
        weight,
        previousInsuranceDetails,
        permanentAddressLine1,
        permanentAddressLine2,
        permanentAddressLine3,
        permanentCity,
        permanentDistrict,
        permanentState,
        permanentArea,
        permanentCountry,
        permanentPinCode,
        permanentLandmark,
        communicationAddressLine1,
        communicationAddressLine2,
        communicationAddressLine3,
        communicationCity,
        communicationDistrict,
        communicationState,
        communicationArea,
        communicationCountry,
        communicationPinCode,
        communicationLandmark
    };
    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Required'),
        firstName: Yup.string().required('Required'),
        middleName: Yup.string(),
        lastName: Yup.string().required('Required'),
        dob: Yup.date().required('Required').nullable(),
        email: Yup.string().email(),
        phone: Yup.string().required('Requried').length(10),
        gender: Yup.string().required('Required'),
        maritalStatus: Yup.string().required('Required'),
        occupation: Yup.string(),
        idProofType: Yup.string().required('Required'),
        idProofNumber: Yup.string().when('idProofType', {
            is: 'Aadhar',
            then: Yup.string().required('Required').length(12, 'Aadhar Number should be of 12 digits')
        }),
        gstType: Yup.string(),
        GSTIN: Yup.string(),
        relation: Yup.string().required('Required'),
        heightInFeet: Yup.string().required('Required'),
        heightInInches: Yup.string().required('Required'),
        weight: Yup.string().required('Required'),
        permanentAddressLine1: Yup.string().required('Required'),
        permanentAddressLine2: Yup.string().required('Required'),
        permanentAddressLine3: Yup.string(),
        permanentCity: Yup.string().required('Required'),
        permanentDistrict: Yup.string(),
        permanentState: Yup.string().required('Required'),
        permanentArea: Yup.string(),
        permanentCountry: Yup.string().required('Required'),
        permanentPinCode: Yup.string().required('Required'),
        permanentLandmark: Yup.string(),
        communicationAddressLine1: Yup.string().required('Required'),
        communicationAddressLine2: Yup.string().required('Required'),
        communicationAddressLine3: Yup.string(),
        communicationCity: Yup.string().required('Required'),
        communicationDistrict: Yup.string(),
        communicationState: Yup.string().required('Required'),
        communicationArea: Yup.string(),
        communicationCountry: Yup.string().required('Required'),
        communicationPinCode: Yup.string().required('Required'),
        communicationLandmark: Yup.string()
    });

    const handleIsChecked = (event, values, setFieldValue) => {
        const checked = event.target.checked
    
        // checked ? props.permToCommAdd(index - 1, true) : props.permToCommAdd(index - 1, false)
        if(checked) {
            setFieldValue('communicationAddressLine1', values.permanentAddressLine1);
            setFieldValue('communicationAddressLine2', values.permanentAddressLine2);
            setFieldValue('communicationAddressLine3', values.permanentAddressLine3);
            setFieldValue('communicationCity', values.permanentCity);
            setFieldValue('communicationDistrict', values.permanentDistrict);
            setFieldValue('communicationState', values.permanentState);
            setFieldValue('communicationArea', values.permanentArea);
            setFieldValue('communicationCountry', values.permanentCountry);
            setFieldValue('communicationPinCode', values.permanentPinCode);
            setFieldValue('communicationLandmark', values.permanentLandmark);
        }else {
            setFieldValue('communicationAddressLine1', '');
            setFieldValue('communicationAddressLine2', '');
            setFieldValue('communicationAddressLine3', '');
            setFieldValue('communicationCity', '');
            setFieldValue('communicationDistrict', '');
            setFieldValue('communicationState', '');
            setFieldValue('communicationArea', '');
            setFieldValue('communicationCountry', '');
            setFieldValue('communicationPinCode', '');
            setFieldValue('communicationLandmark', '');  
        }
        
        toggleCheck(checked)
      }

    return (
        <Card className={classes.cardBackground}>
            <CardHeader text style={{ display: 'flex' }}>
                <div className={classes.leftAlign}>
                    <Typography variant="h6"> Member - {index}</Typography>
                </div>
                {handleClose && <div className={classes.rightAlign}>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={() => handleClose(index)}>
                        <CloseIcon />
                    </IconButton>
                </div>}
            </CardHeader>
            <CardBody>
                <Formik
                    initialValues={initialValues}
                    onSubmit={(e) => handleMemberSubmit(e, index)}
                    validationSchema={validationSchema} >
                    {
                        (formProps) => {
                            const {
                                values,
                                touched,
                                errors,
                                dirty,
                                isSubmitting,
                                isValid,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                handleReset,
                                setFieldValue
                            } = formProps;
                            bindSubmitMemberForm(handleSubmit)
                            return (
                                <form onSubmit={handleSubmit}>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={2}>
                                            {renderSelect({
                                                name: "title", label: "Title", options: getTitleOptions(), touched, errors,
                                                handleChange: handleChange('title'), values, handleBlur, isRequired: true
                                            })}
                                        </GridItem>

                                        <GridItem xs={12} sm={12} md={4}>
                                            {
                                                renderInput({
                                                    label: "First Name", name: "firstName",
                                                    isRequired: true, touched, errors, handleChange, handleBlur, values
                                                })
                                            }
                                        </GridItem>

                                        <GridItem xs={12} sm={12} md={3}>
                                            {
                                                renderInput({
                                                    label: "Middle Name", name: "middleName",
                                                    isRequired: false, touched, errors, handleChange, handleBlur, values
                                                })
                                            }
                                        </GridItem>

                                        <GridItem xs={12} sm={12} md={3}>
                                            {
                                                renderInput({
                                                    label: "Last Name", name: "lastName",
                                                    isRequired: true, touched, errors, handleChange, handleBlur, values
                                                })
                                            }
                                        </GridItem>

                                        <GridItem xs={12} sm={6} md={3}>
                                            <Field name="dob" component={renderDatePicker} format="dd/MM/yyyy" label="Date of Birth" isRequired />
                                        </GridItem>

                                        <GridItem xs={12} sm={12} md={5}>
                                            {
                                                renderInput({
                                                    label: "Email", name: "email",
                                                    isRequired: false, touched, errors, handleChange, handleBlur, values
                                                })
                                            }
                                        </GridItem>

                                        <GridItem xs={12} sm={12} md={4}>
                                            {
                                                renderInput({
                                                    label: "Phone Number", name: "phone",
                                                    isRequired: true, touched, errors, handleChange, handleBlur, values, type: 'number'
                                                })
                                            }
                                        </GridItem>

                                        <GridItem xs={12} sm={12} md={2}>
                                            {renderSelect({
                                                name: "gender", label: "Gender", options: genderOptions, touched, errors,
                                                handleChange: handleChange('gender'), values, handleBlur, isRequired: true
                                            })}
                                        </GridItem>

                                        <GridItem xs={12} sm={12} md={2}>
                                            {renderSelect({
                                                name: "maritalStatus", label: "Marital Status", options: getMaritalStatusOptions(), touched, errors,
                                                handleChange: handleChange('maritalStatus'), values, handleBlur, isRequired: true
                                            })}
                                        </GridItem>

                                        <GridItem xs={12} sm={12} md={5}>
                                            {renderSelect({
                                                name: "occupation", label: "Occupation", options: getOccupationOptions(), touched, errors,
                                                handleChange: handleChange('occupation'), values, handleBlur, isRequired: false
                                            })}
                                        </GridItem>

                                        <GridItem xs={12} sm={12} md={3}>
                                            {renderSelect({
                                                name: "idProofType", label: "Id Proof Type", options: getIdProofOptions(), touched, errors,
                                                handleChange: handleChange('idProofType'), values, handleBlur, isRequired: true
                                            })}

                                        </GridItem>

                                        <GridItem xs={12} sm={12} md={3}>
                                            {
                                                renderInput({
                                                    label: "Id Proof Number", name: "idProofNumber",
                                                    isRequired: true, touched, errors, handleChange, handleBlur, values
                                                })
                                            }
                                        </GridItem>

                                        <GridItem xs={12} sm={12} md={3}>
                                            {renderSelect({
                                                name: "gstType", label: "GST Type", options: getGstTypeOptions(), touched, errors,
                                                handleChange: handleChange('gstType'), values, handleBlur, isRequired: false
                                            })}

                                        </GridItem>

                                        <GridItem xs={12} sm={12} md={3}>
                                            {
                                                renderInput({
                                                    label: "GSTIN Number", name: "GSTIN",
                                                    isRequired: false, touched, errors, handleChange, handleBlur, values
                                                })
                                            }

                                        </GridItem>

                                        <GridItem xs={12} sm={12} md={4}>
                                            {renderSelect({
                                                name: "relation", label: "Relation", options: getRelationOptions(), touched, errors,
                                                handleChange: handleChange('relation'), values, handleBlur, isRequired: true
                                            })}
                                        </GridItem>

                                        <GridItem xs={12} sm={12} md={2}>
                                            {
                                                renderInput({
                                                    label: "Height (in feet)", name: "heightInFeet",
                                                    isRequired: true, touched, errors, handleChange, handleBlur, values
                                                })
                                            }
                                        </GridItem>

                                        <GridItem xs={12} sm={12} md={2}>
                                            {
                                                renderInput({
                                                    label: "Height (in Inches)", name: "heightInInches",
                                                    isRequired: true, touched, errors, handleChange, handleBlur, values
                                                })
                                            }
                                        </GridItem>

                                        <GridItem xs={12} sm={12} md={4}>
                                            {
                                                renderInput({
                                                    label: "Weight (in kgs)", name: "weight",
                                                    isRequired: true, touched, errors, handleChange, handleBlur, values
                                                })
                                            }
                                        </GridItem>

                                        <GridItem xs={12} sm={12} md={12}>
                                            {renderSelect({
                                                name: "previousInsuranceDetails", label: "Previous Insurance Details", options: getPreviousInsuranceOptions(), touched, errors,
                                                handleChange: handleChange('previousInsuranceDetails'), values, handleBlur, isRequired: false
                                            })}
                                        </GridItem>
                                        <GridItem>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <Card plain style={{ marginBottom: '0px' }}>
                                                        <CardHeader text style={{ paddingBottom: '0px' }}>
                                                            <Typography variant="button">Permanent Address</Typography>
                                                        </CardHeader>
                                                        <CardBody style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                                                            <GridContainer>
                                                                {
                                                                    [{ label: 'Address Line 1', name: 'permanentAddressLine1', isRequired: true },
                                                                    { label: 'Address Line 2', name: 'permanentAddressLine2', isRequired: true },
                                                                    { label: 'Address Line 3', name: 'permanentAddressLine3', isRequired: false }
                                                                    ].map(val => (
                                                                        <GridItem xs={12} sm={12} md={12}>

                                                                            {
                                                                                renderInput({
                                                                                    label: val.label, name: val.name,
                                                                                    isRequired: val.isRequired, touched, errors, handleChange, handleBlur, values,

                                                                                })
                                                                            }

                                                                        </GridItem>
                                                                    ))
                                                                }
                                                                {
                                                                    [
                                                                        { label: 'City', name: 'permanentCity', isRequired: true },
                                                                        { label: 'District', name: 'permanentDistrict', isRequired: false },
                                                                        { label: 'State', name: 'permanentState', isRequired: true },
                                                                        { label: 'Area', name: 'permanentArea', isRequired: false },
                                                                        { label: 'Country', name: 'permanentCountry', isRequired: true },
                                                                        { label: 'Pincode', name: 'permanentPinCode', isRequired: true, type: 'number' },
                                                                        { label: 'Landmark', name: 'permanentLandmark', isRequired: false }
                                                                    ].map(val => (
                                                                        <GridItem xs={12} sm={12} md={3}>
                                                                            {
                                                                                renderInput({
                                                                                    label: val.label, name: val.name,
                                                                                    isRequired: val.isRequired, touched, errors, handleChange, handleBlur, values,
                                                                                    type: val.type || ''
                                                                                })
                                                                            }
                                                                        </GridItem>
                                                                    ))
                                                                }
                                                            </GridContainer>
                                                        </CardBody>
                                                    </Card>
                                                </GridItem>

                                                <GridItem xs={12}>
                                                    <Card plain style={{ marginBottom: '0px' }} >
                                                        <CardHeader text style={{ paddingBottom: '0px', display: 'flex' }}>
                                                            <Typography style={{ alignSelf: 'flex-start' }} variant="button">Communication Address</Typography>
                                                            <FormGroup row style={{ marginLeft: 'auto' }}>
                                                                <FormControlLabel className={classes.checkBoxLabel}
                                                                    control={
                                                                        <Checkbox checked={isChecked} value="checked"
                                                                            className={classes.checkBox}
                                                                            onChange={(event) => handleIsChecked(event, values, setFieldValue)}
                                                                        />
                                                                    }
                                                                    label="Mark if communication address is same as permanent address"
                                                                />

                                                            </FormGroup>
                                                        </CardHeader>
                                                        <CardBody style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                                                            <GridContainer>
                                                                {
                                                                    [{ label: 'Address Line 1', name: 'communicationAddressLine1', isRequired: true },
                                                                    { label: 'Address Line 2', name: 'communicationAddressLine2', isRequired: true },
                                                                    { label: 'Address Line 3', name: 'communicationAddressLine3', isRequired: false }
                                                                    ].map(val => (
                                                                        <GridItem xs={12} sm={12} md={12}>

                                                                            {
                                                                                renderInput({
                                                                                    label: val.label, name: val.name,
                                                                                    isRequired: val.isRequired, touched, errors, handleChange, handleBlur, values,

                                                                                })
                                                                            }

                                                                        </GridItem>
                                                                    ))
                                                                }
                                                                {
                                                                    [
                                                                        { label: 'City', name: 'communicationCity', isRequired: true },
                                                                        { label: 'District', name: 'communicationDistrict', isRequired: false },
                                                                        { label: 'State', name: 'communicationState', isRequired: true },
                                                                        { label: 'Area', name: 'communicationArea', isRequired: false },
                                                                        { label: 'Country', name: 'communicationCountry', isRequired: true },
                                                                        { label: 'Pincode', name: 'communicationPinCode', isRequired: true, type: 'number' },
                                                                        { label: 'Landmark', name: 'communicationLandmark', isRequired: false }
                                                                    ].map(val => (
                                                                        <GridItem xs={12} sm={12} md={3}>
                                                                            {
                                                                                renderInput({
                                                                                    label: val.label, name: val.name,
                                                                                    isRequired: val.isRequired, touched, errors, handleChange, handleBlur, values,
                                                                                    type: val.type || ''
                                                                                })
                                                                            }
                                                                        </GridItem>
                                                                    ))
                                                                }
                                                            </GridContainer>
                                                        </CardBody>
                                                    </Card>
                                                </GridItem>

                                            </GridContainer>
                                        </GridItem>
                                    </GridContainer>
                                </form>
                            )
                        }
                    }
                </Formik >

            </CardBody >
        </Card >
    );
}
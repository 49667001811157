import { getCookie } from "./common"

export const getUserPermissions = () => {
  const allowedPermissions = JSON.parse(getCookie("permissions"));
}

export const getHiearchyPermisionName = (featurePermission, record) => {
  // console.log("featurePermission, record", featurePermission, record)
  if (featurePermission) {
    const permission = featurePermission.permissions.find(p => p.name === record);
    return permission ? permission.hiearchy : "";
  }
  return "";


}

export const hasDataAccess = (featurePermission, record, dataType) => {
  let hasPermission = false
  const recordPermission = featurePermission ? featurePermission.permissions.find(p => p.name === record) : undefined;
  if (recordPermission) {
    if (recordPermission.hiearchy === "my" && dataType === "my") {
      hasPermission = true;
    }

    if (recordPermission.hiearchy === "sub_ordinate" && dataType === "subordinate") {
      hasPermission = true;
    }

    if (recordPermission.hiearchy === "my_and_subordinates" &&
      (dataType === "my" || dataType === "subordinate")) {
      hasPermission = true;
    }

    if (recordPermission.hiearchy === "all") {
      hasPermission = true;
    }
  }
  return hasPermission;
}

export const getFeaturePermission = (feature) => {
  const allowedPermissions = JSON.parse(getCookie("permissions"));

  let featurePermission;

  if (allowedPermissions !== null) {
    featurePermission = allowedPermissions.find(p => p.feature === feature);
  }

  return featurePermission
}
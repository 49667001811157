import React from 'react'; 
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from 'components/CustomButtons/Button'

export default function NotAuthorizedDialogBox(props) {

  const { open, closeNotAuthorizedDialog, message} = props;

  const handleClose = () => {
    closeNotAuthorizedDialog(false);
  };

  return (
    <div> 
      <Dialog
        open={open}
        onClose={handleClose} 
      >
        <DialogTitle >{"Not Authorized"}</DialogTitle>
        <DialogContent>
          <DialogContentText  >
           {message} 
          </DialogContentText>
        </DialogContent>
        <DialogActions> 
          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button> 
        </DialogActions>
      </Dialog>
    </div>
  );
}
import React, { useState, useEffect }  from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent'; 
import DialogTitle from '@material-ui/core/DialogTitle';
import PermissionContainer from './PermissionContainer';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import Typography from "@material-ui/core/Typography";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete'; 
import ErrorIcon from '@material-ui/icons/Error';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Divider, makeStyles } from '@material-ui/core';

import {updateAlertMsg} from 'store/actions/commonAction'
import { splitAndCamelCase } from 'utils/common';
import { styles } from './PermissionContainerStyle'
import CustomInput from 'components/CustomInput/CustomInput';
import SelectOption from 'components/CustomSelect/SelectOption';
import { Button as MuiButtom}  from '@material-ui/core'
import Button from 'components/CustomButtons/Button';

const useStyles = makeStyles(styles);

export default function ConfigurePermissionModal(props) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { 
    open
    , toggleConfigureModal 
    , roleData
    , updatedRolesPermissions
    , addPermission
    , removePermission
    , savePermissions
  } = props;  

  const [value, setValue]=useState("");
  const [cloneFrom, setCloneFrom] = useState("");
  const allowPermissionList = roleData.permissions.map(p=>p.feature); 

  const masterPermissions = useSelector((state) => state.adminRoleReducer.masterPermissions);
  const allRolesArr  = useSelector(state=>state.adminRoleReducer.rolePermissions);
  const allRoles  = allRolesArr.map(rp=>rp.name.toLowerCase());
   
  const [leftPermissions, setLeftPermissions] = useState(
    masterPermissions.filter(p=>allowPermissionList.indexOf(p.feature)===-1)
  );

  const options =  leftPermissions;

  const handleClose = (type) => {
    toggleConfigureModal(type);
  };

  const addPermissionToRole = (e, value)=>{
    addPermission(value, roleData.name);
  }

  const removeRolePermission = (roleName,  feature) => { 
    removePermission(roleName, feature);
  }

  let isUpdate = allRoles.indexOf(roleData.name.toLowerCase())>-1;

  const isNewRoleValid = () => {

    let valid = true;
    if(value.trim().length===0){
      const alertMsg = {
        color: 'danger',
        icon: ErrorIcon,
        message: "Please provid a role name."
      }

      dispatch(updateAlertMsg(alertMsg)) ;

      valid = false;
    }

    if(allRoles.indexOf(value.trim().toLowerCase())>-1 || value.trim().toLowerCase()=== "super admin"){ 

      const alertMsg = {
        color: 'danger',
        icon: ErrorIcon,
        message: "Provided Role name already exists."
      }

      dispatch(updateAlertMsg(alertMsg)) ;

      valid = false;
    }

    if(roleData.permissions.length===0){ 

      const alertMsg = {
        color: 'danger',
        icon: ErrorIcon,
        message: "Plese select atleast one featue."
      }

      dispatch(updateAlertMsg(alertMsg)) ;

      valid = false;
    } 
   
    return valid;
  }
  const saveRolePemissions = (type) => { 
    if(type==="update"){
      savePermissions(roleData.name);
      handleClose();
    } else if(type==="reset"){
      savePermissions(roleData.name, true);
      handleClose();
    } else {
      if(isNewRoleValid()){
        savePermissions(value);
        handleClose();
      }
    } 
  }


  const handleRoleNameChange = (event)=>{
    setValue(event.target.value);
  }

  const handleCloneFromChange = (event) =>{
    setCloneFrom(event.target.value);

    const permissionsToClone = allRolesArr.find(r=>r.name.toLowerCase()===event.target.value.toLowerCase()).permissions;
    roleData.permissions.forEach(p=>{
      removeRolePermission( roleData.name, p.feature)
    })
    permissionsToClone.forEach(p => {
      addPermissionToRole(null, p);
    });
  }

  useEffect(()=>{
    setLeftPermissions(masterPermissions.filter(p=>allowPermissionList.indexOf(p.feature)===-1))
  },[roleData]);

  return (
    <div> 
      <Dialog
      fullWidth={true}
      maxWidth={'md'}
        open={open}
        onClose={()=>handleClose("cancelled")}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          {
            isUpdate &&(
              <span> Configure <b>{splitAndCamelCase( roleData.name)}</b> Permissions </span>
            )
          }

          {
            !isUpdate &&(
              <span> Add New Role </span>
             
            )
          }
         
        </DialogTitle>
        <DialogContent dividers={true}>
        {
            !isUpdate &&(
              <>
              <GridContainer>
                <GridItem sx={12} sm={6}>   
                  <CustomInput 
                    value={value}
                    labelText="Role Name"
                    onChange={handleRoleNameChange}

                    formControlProps={{
                      fullWidth: true 
                    }} 
                  />  
                </GridItem> 
                {/* </GridContainer>
                <GridContainer> */}
                <GridItem sx={12} sm={6}>  
                <SelectOption 
                  labelText="Clone Role"
                  selectOptions={ allRolesArr.map(r=>{
                    return {
                      label:r.name,
                      value:r.name
                    }
                  })} 
                  formControlProps={{
                    fullWidth: true 
                  }} 
                  onSelectChange={handleCloneFromChange}
                  inputProps={{
                    value: cloneFrom
                  }}
                />
              </GridItem> 
              </GridContainer>
              <div style={{margin:"30px 0px"}}/>
              </>
            )
        } 
        {/* <Divider style={{margin:"10px 0px"}}/> */}
        {
          roleData.permissions.length>0 &&
          <>
            <GridContainer>
              <GridItem sx={12} sm={4}>
                <Typography align="left">
                  <b>Feature</b>
                </Typography>
              </GridItem>
                <GridItem sx={12} sm={6} textAlign="center">
                <Typography align="center">
                  <b>Feature Permissions</b>
                </Typography>
                </GridItem>
              </GridContainer> 
              <GridContainer className={classes.mtb}>
                <GridItem sx={12} sm={4}> 
                </GridItem>
                  <GridItem sx={12} sm={8}>
                    <div className={classes.flexContainer}> 
                      <div>
                        <span>Create</span> 
                      </div>
                      <div>
                        <span>Read</span> 
                      </div>
                      <div>
                        <span>Update</span> 
                      </div>
                      <div>
                        <span>Delete</span> 
                      </div>
                      <div>
                        <span> </span> 
                      </div>
                    </div>
                  </GridItem>
              </GridContainer>
              <Divider style={{margin:"10px 0px"}}/>
            </>
          }
          {
            roleData.permissions.map((permission=>{
              return (
              <PermissionContainer
              permissionObj = { permission }
              updatedRolesPermissions = { updatedRolesPermissions }
              roleName = { roleData.name }
              removePermission = { removePermission } 
              />
              )
            }))
          }
          {
            options.length>0 && (
              <Autocomplete
                id="select-new-permissions"
                onChange={addPermissionToRole}
                options={
                  options
                  .sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))
                  .sort((b, a) => (a.module > b.module) ? 1 : ((b.module > a.module) ? -1 : 0))
                }
                groupBy={(option) => splitAndCamelCase(option.module)}
                // value={[]}
                getOptionLabel={(option) => splitAndCamelCase( option.feature)}
                style={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Add New Feature Permissions" variant="outlined" />}
              /> 
            )
          }
       
        </DialogContent>
        <DialogActions>
          {
            isUpdate && roleData.order !==99999 &&(
              <Button 
              style={{marginRight:20}} 
              onClick={()=>saveRolePemissions("reset")} color="info">
                Reset
              </Button>
            )
          }
          <MuiButtom onClick={()=>handleClose("cancelled")} variant="outlined" style={{padding:"7px 17px"}}>
            Cancel
          </MuiButtom> 
          <Button onClick={()=>saveRolePemissions(isUpdate ? "update" : "save")} color="primary">
           {
             isUpdate&&(<span>Update</span>)
           }
           {
             !isUpdate&&(<span>Save</span>)
           }
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

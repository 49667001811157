import React, { useEffect, useState } from "react"; 
import { useDispatch } from 'react-redux'

import Button from "components/CustomButtons/Button";
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles' 

import GridItem from '../../components/Grid/GridItem'
import GridContainer from '../../components/Grid/GridContainer'
import Card from '../../components/Card/Card'
import CardHeader from '../../components/Card/CardHeader'
import CardBody from '../../components/Card/CardBody'
import Role from "../../components/Role/Role"; 
import {styles} from './AdminDashboardStyle'
import { saveRolePermissionsWatcher } from "store/actions/adminRoleActions";
import ConfigurePermissionModal from "components/ConfigurePermission/ConfigurePermissionModal";
 

const useStyles = makeStyles(styles);

const Dashboard = ({roles, forceRefresh}) => {
  const dispatch = useDispatch();
  const classes = useStyles(); 

  const [openModal, setOpenModal] = useState(false);
  const [newRole, setNewRole] = useState({
    name:"",
    permissions:[ ]
  })

  const rolesCopy = roles.slice();
  rolesCopy
  .sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0))
  .sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order  ) ? -1 : 0)); 

  const [rolesPermissions, setRolesPermissions] = useState(rolesCopy);

  const isRoleMatched = (roleName) => rolesPermissions.find(rp=>rp.name===roleName)?true:false;

  const updatedRolesPermissions = (roleName, permissionObj, permissions) => { 
    if(isRoleMatched(roleName)){
      const temp = rolesPermissions.map(rp=>{ 
          if(rp.name !== roleName ) return rp;
        return {
          ...rp,
          permissions: rp.permissions.map(p=>{ 
            if(p.feature!==permissionObj.feature) return p; 
            return {
              ...p,
              permissions
            }
          })
        }
      })
      setRolesPermissions(temp)
    } else { 
      newRole.permissions =  newRole.permissions.map(np=>{ 
        if(np.feature!==permissionObj.feature) return np; 
        return {
          ...np, 
            permissions 
        }
      })
      setNewRole({...newRole});
    }
  }

  const addPermission = (permission, roleName)=> { 
    if(isRoleMatched(roleName)){
      const temp = rolesPermissions.map(rp=>{ 
        if(rp.name !== roleName ) return rp; 
          return {
            ...rp,
            permissions: [
              ...rp.permissions, 
              {
                ...permission, 
                possiblePermissions:permission.possible_permissions,
                permissions:[]
              } 
            ]
          }
      })   
      setRolesPermissions(temp)
    } else { 
      newRole.permissions = [
        ...newRole.permissions ,{  
          ...permission,
          possiblePermissions:permission.possible_permissions||permission.possiblePermissions,
          permissions:permission.permissions||[]
        
      } ];
      setNewRole({...newRole});
    }
  }

  const removePermission = (roleName, feature) =>{
    if(isRoleMatched(roleName)){
      const temp = rolesPermissions.map(rp=>{ 
        if(rp.name !== roleName ) return rp; 
          return {
            ...rp,
            permissions: rp.permissions.filter(p=>p.feature!==feature)
          }
      })  
      setRolesPermissions(temp);
    } else { 
      newRole.permissions = newRole.permissions.filter(p=>p.feature!==feature) ;
      setNewRole({...newRole});
    }
  }

  const savePermissions = ( roleName , reset=false) => { 
    console.log("reset", reset);
    if(reset) {
      dispatch(saveRolePermissionsWatcher({
        name: roleName,
        permissions: [],
        action: "reset"
      })) 
    }
    else {
      if(isRoleMatched(roleName)){
        let  dataToUpdate = rolesPermissions.find(rp=>rp.name===roleName); 
        console.log("dataToUpdate", dataToUpdate)
        dispatch(saveRolePermissionsWatcher(dataToUpdate))  
      } else {
        let roleToAdd = {...newRole, name:roleName} 
        console.log("roleToAdd", roleToAdd)

        dispatch(saveRolePermissionsWatcher(roleToAdd))  
        setNewRole({
          name:"",
          permissions:[ ]
        })
      }
    }
    
  }
   
  useEffect(()=>{
    setRolesPermissions(rolesCopy) 
  },[roles]); 

  const addNewRole = () => { 
    toggleConfigureModal();
  }

  const toggleConfigureModal = (type) => {
    setOpenModal(!openModal);
    if(type==="cancelled"){
      forceRefresh();
      setNewRole({
        name:"",
        permissions:[ ]
      })
    }
  } 

  return ( 
    <GridContainer>
      <GridItem sx={12} sm={12} md={12}>
        <Card>
          
        <CardHeader
          color="primary"   
        >
            <span style={{ float: 'left' }}>
              <h4 className={classes.cardTitleWhite}>Admin Console Roles</h4>

            </span>  
            <span style={{ float: 'right' }}>

              <Button onClick={addNewRole} color="white" className={classes.paddingStyle} aria-label="edit" simple >
                <AddIcon /> {' '} Add New Role
              </Button> 
            </span>
              
          </CardHeader>
          <CardBody>
            <GridContainer>
              {
                rolesPermissions.map(role=>(
                  <Role 
                    role={role} 
                    updatedRolesPermissions={ updatedRolesPermissions }
                    addPermission={ addPermission }
                    removePermission = { removePermission }
                    savePermissions = { savePermissions } 
                    forceRefresh = {forceRefresh}
                  />))
              }
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      {
        openModal && (
          <ConfigurePermissionModal 
            open={openModal}
            roleData={newRole}
            toggleConfigureModal={toggleConfigureModal}  
            updatedRolesPermissions = { updatedRolesPermissions }
            addPermission = { addPermission }
            removePermission = { removePermission }

            savePermissions = { savePermissions }
          />  
        )
     }
    </GridContainer>
  ); 
} 

export default Dashboard;

import * as React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import Admin from '../DashBoard/Admin';
import DsaList from './DsaList';

import { fetchDsaListWatcher } from '../../store/actions/dsaAction';
import { isEmpty } from "../../utils/common";

class DsaPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentWillMount() {
    if(isEmpty(this.props.dsaList))
    this.props.fetchDsaListWatcher();
  }

  getDetails = (event, id) => {
    event.preventDefault();
    this.props.history.push({ pathname: `/dsa-detail/${id}`, state: { dsaId: id } })
    //  console.log("pos details id", id);
  }


  render() {
    const { dsaList = [] } = this.props
    return (
      <Admin>
        <DsaList
          dsaList={dsaList}
          getDetails={this.getDetails}
          history={this.props.history}
        />
      </Admin>
    )
  }
}
function mapStateToProps(state) {
  const { dsaReducer } = state;
  return {
    dsaList: dsaReducer.dsaList

  };
}
// mapping dispatch functions to the props of LoginForm component
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchDsaListWatcher,
      // add other watcher sagas to this object to map them to props
    },
    dispatch
  )
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DsaPage);
import * as React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux"; 
import Admin from '../DashBoard/Admin';
import Dashboard from './Dashboard';
import { fetchRolesPermissionsWatcher, fetchMasterPermissions } from "../../store/actions/adminRoleActions";
import {isEmpty} from "../../utils/common"; 
 
class AdminDasboardPage extends React.Component {

  constructor(props) {
    super(props); 
    this.state = {
      refresh: true
    }
 
  }

  componentWillMount() {
    if(isEmpty(this.props.rolesPermissions))
    this.props.fetchRolesPermissionsWatcher();

    if(isEmpty(this.props.masterPermissions))
    this.props.fetchMasterPermissions();
  }
   forceRefresh = () =>{
     console.log("[AdminDasboardPage]", this.props.rolesPermissions)
    this.setState({refresh:!this.state.refresh});
   }

  render() { 
    const { rolesPermissions } = this.props
    return (
      <Admin>
          <Dashboard 
          roles={[...this.props.rolesPermissions]}
          forceRefresh={this.forceRefresh}
        />
      </Admin>
    );
  }
}

function mapStateToProps(state) {
  const { adminRoleReducer } = state;  
  return {
    rolesPermissions: adminRoleReducer.rolePermissions,
    masterPermissions: adminRoleReducer.masterPermissions
  };
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { 
      fetchRolesPermissionsWatcher,
      fetchMasterPermissions
    },
    dispatch
  )
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminDasboardPage); 
 
 
import {
    dangerColor,
    whiteColor
  } from '../material-dashbord-react'

const monthPickerStyle ={
    muipickerTextBox: {
        // marginTop: "27px",
        // marginBottom: "10px"
        margin: '12px 0px 17px 0',
        maxWidth: '162px',
        '& > .MuiFormLabel-root': {
          fontSize: '12px',
          color: '#3C4858 !important',
          '& > .MuiInputLabel-asterisk': {
            color: 'red'
          }
        },
        '& > .MuiInputBase-root': {
            color: whiteColor,
            '& > .MuiInputBase-input': {
            textAlign: 'center',
            fontWeight:'bolder'
        }
        },
        '& > .MuiFormHelperText-root': {
          fontSize: '12px',
          color: dangerColor[0]
        },
    
        '& > .MuiInput-underline': {
          '&:hover:not($disabled):before,&:before': {
            borderColor: whiteColor + '!important',
            borderWidth: '1px !important'
          },
          '&:after': {
            borderColor: whiteColor
          },
          '& + p': {
            fontWeight: '300'
          }
        },
        '& > .Mui-disabled': {
          color: '#495057'
        }
    
      },
}
export default monthPickerStyle;
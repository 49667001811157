import * as actions from '../actions/types';

const initialState = {};

const commonReducer = (state = initialState, action) => {
    
    switch(action.type){

        case actions.NEXT_BUTTON:
        
            return {
              ...state,
              tabLocation: action.payload,
            };
        case actions.OPEN_BACKDROP:
            return {
                ...state,
                openBackDrop: action.openBackDrop
            };
        case actions.ALERT_MSG: 
        return {
            ...state,
            alertMsg: action.alertMsg
        }
        case actions.COMPANIES_LIST: 
        return {
            ...state,
            companies: action.data
        }
            default:
                return state;
    }
};
export default commonReducer;


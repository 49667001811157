import React from "react";
import styles from "../../assets/jss/componentsStyles/stepperStyle";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import Button from '../CustomButtons/Button';

import {LoaderWrapper} from "../../Pages/Login/LoginStyle";

const useStyles = makeStyles(styles);

export default function CustomStepper(props) {

    const {steps=[], getStepContent, activeStep, handleBack, handleNext} = props;

    const classes = useStyles();



      return(
        <div className={classes.root}>
        <Stepper activeStep={activeStep} alternativeLabel className= {classes.cardRaised}>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <span style={{display: "inline"}}>
            <Typography className={classes.instructions}>All steps completed</Typography>
            <Button color="primary" simple>
            <LoaderWrapper>
                <div className="loader" />
              </LoaderWrapper>
            </Button>
          </span>
        ) : (
          <div>
            <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
            <div>
              <Button
                color="primary"
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.backButton}
              >
                Back
              </Button>
              <Button variant="contained" color="primary" onClick={handleNext}>
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </div>
          </div>
        )}
      </div>
        </div>
      );


}
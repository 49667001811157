import React from 'react'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import {
    Formik, Form, Field, ErrorMessage,
} from 'formik';
import { renderDatePicker } from 'components/ReduxComponents/reduxComponents';

import * as Yup from 'yup';

export default function NomineeForm(props) {
    const { classes,
        handleSubmit = () => { }, bindSubmitForm, relationshipList = [],
        setHeaderName, renderSelect, renderInput
        , nomineeForm: {
            nomineeFirstName = '',
            nomineeMiddleName = '',
            nomineeLastName = '',
            nomineeDOB = null,
            nomineeRelation = '',
            nomineeGender = '',
        } } = props;

    React.useEffect(() => { setHeaderName('Nominee Details') }, [])

    const getRelationOptions = () => {
        const relationOptions = [{ value: null, label: 'None' }];
        relationshipList.map(relation => relationOptions.push({ value: relation.name, label: relation.name }));
        return relationOptions;
    }
    const genderOptions = [{ value: 'male', label: 'Male' },
    { value: 'feMale', label: 'Female' },
    { value: 'others', label: 'Others' }];


    const initialValues = {
        nomineeFirstName,
        nomineeMiddleName,
        nomineeLastName,
        nomineeDOB,
        nomineeRelation,
        nomineeGender

    }

    const validationSchema = Yup.object().shape({
        nomineeFirstName: Yup.string(),
        nomineeMiddleName: Yup.string(),
        nomineeLastName: Yup.string(),
        nomineeDOB: Yup.date().nullable(),
        nomineeRelation: Yup.string(),
        nomineeGender: Yup.string()

    })

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(e) => handleSubmit(e, 'nomineeForm')}
            validationSchema={validationSchema} >
            {
                (formProps) => {
                    const {
                        values,
                        touched,
                        errors,
                        dirty,
                        isSubmitting,
                        isValid,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        handleReset,
                        setFieldValue
                    } = formProps;
                    bindSubmitForm(handleSubmit)
                    return (
                        <form onSubmit={handleSubmit}>
                            <GridContainer>

                                <GridItem xs={12} sm={12} md={4}>
                                    {
                                        renderInput({
                                            label: "First Name", name: "nomineeFirstName",
                                            isRequired: false, touched, errors, handleChange, handleBlur, values
                                        })
                                    }
                                </GridItem>

                                <GridItem xs={12} sm={12} md={3}>
                                    {
                                        renderInput({
                                            label: "Middle Name", name: "nomineeMiddleName",
                                            isRequired: false, touched, errors, handleChange, handleBlur, values
                                        })
                                    }
                                </GridItem>

                                <GridItem xs={12} sm={12} md={3}>
                                    {
                                        renderInput({
                                            label: "Last Name", name: "nomineeLastName",
                                            isRequired: false, touched, errors, handleChange, handleBlur, values
                                        })
                                    }
                                </GridItem>

                                <GridItem xs={12} sm={6} md={3}>
                                    <Field name="nomineeDOB" component={renderDatePicker} format="dd/MM/yyyy" label="Date of Birth" />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={2}>
                                    {renderSelect({
                                        name: "nomineeGender", label: "Gender", options: genderOptions, touched, errors,
                                        handleChange: handleChange('nomineeGender'), values, handleBlur, isRequired: true
                                    })}
                                </GridItem>

                                <GridItem xs={12} sm={12} md={4}>
                                    {renderSelect({
                                        name: "nomineeRelation", label: "Relation", options: getRelationOptions(), touched, errors,
                                        handleChange: handleChange('nomineeRelation'), values, handleBlur, isRequired: false
                                    })}
                                </GridItem>

                            </GridContainer>
                        </form>
                    )
                }
            }
        </Formik>

    );
}


import * as React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import PosOnboardingList from "./PosOnboardingList";
import Admin from '../DashBoard/Admin';
import { fetchposOnboardingListWatcher, updatePosApprovalStatusWatcher } from "../../store/actions/posAction";
import { getCookie, isEmpty } from "../../utils/common";
import { ONBOARD_STATUS } from '../../utils/constants';
import AlertBox from "../../components/DialogBox/DialogBox";
import SelectOption from "../../components/CustomSelect/SelectOption";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Snackbar from '../../components/Snackbar/Snackbar';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import BackDrop from '../../components/BackDrop/Backdrop';

class PosOnboardingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      requestStatus: true,
      rejectionReason: '',
      openBackdrop: false,
      alertMsg: {}

    }
  }
  componentDidMount() {
    if(isEmpty(this.props.posList))
    this.props.fetchposOnboardingListWatcher();
  }

  getDetails = (event, id) => {
    event.preventDefault();
    this.props.history.push({ pathname: `/pos-detail/${id}`, state: { posId: id } })
    console.log("pos details id", id);
  }
  getRejectionReason = () => {
    const rejectReasonOption = [{ value: "REJECTED_BECAUSE_REGISTERED_WITH_OTHER_COMPANY", label: "Registered with some other company" },
    { value: "REJECTED_BECAUSE_HIGH_SCHOOL_NOT_PASSED", label: "Minimum education qualification, high school not passed" }];
    return rejectReasonOption;
  }

  setAlertMsg = (event) => {
    let alertMsg = {};
   
      alertMsg = {
        message: event.message,
        color: event.errorCode? 'danger': 'success',
        icon: event.errorCode? ErrorIcon : CheckCircleOutlineIcon,
      }
      this.setState({alertMsg: {...alertMsg}});

      setTimeout(() => {
        this.setState({alertMsg:{}})
      }, 4000);
    
  }

  //While updating any status
  uploadStatus = (event, param) => {
    event.preventDefault();
    const adminUserId = getCookie("adminUserId");
    param.adminUserId = adminUserId;


    // this.openModal(param).then(() => {
    new Promise((resolve, reject) => {
      this.props.updatePosApprovalStatusWatcher(param, resolve, reject);
      this.setState({ openBackdrop: true });
    })
      .then(event => {
        this.setState({ openBackdrop: false });
        this.setAlertMsg(event);

      })
      .catch(error => {
        console.log("Error while updating  aaasdfefdfgsdfhs the status");
        this.setState({ openBackdrop: false });
        this.setAlertMsg(error);
      });

    // })
    // .catch( event => {
    //   console.log("Status update cancelled");
    // })
  }
  handleClose = (event, param) => {
    event.preventDefault();

    this.setState({
      open: !this.state.open,
      rejectionReason: ''
    });

    if (param === 'okay') {
      const args = { ...this.state.param };
      args.approvalStatus = args.status ? ONBOARD_STATUS.ONBOARDED : ONBOARD_STATUS.REJECTED;
      if (!args.status) args.rejectStatusReason = this.state.rejectionReason;

      delete args.status;
      this.uploadStatus(event, args);
      console.log("okay was pressed");
    }
    else {
      this.setState({
        param,
        requestStatus: param.status
      });
    }
  }

  render() {
    const { posList = [] } = this.props;
    const dialogTitle = "Are you sure, you want to update the Onboarding Status?";
    const dialogDescription = "";
    const { requestStatus, rejectionReason, alertMsg={} } = this.state;

    return (
      <Admin>
        <Snackbar
          place="tc"
          color={alertMsg.color}
          icon={alertMsg.icon}
          message={alertMsg.message}
          open={!isEmpty(alertMsg)}
          closeNotification={() => this.setState({ alertMsg: {} })}
          close
        />
        <BackDrop
          openBackdrop={this.state.openBackdrop}
        />

        <PosOnboardingList
          posList={posList}
          getDetails={this.getDetails}
          uploadStatus={this.uploadStatus}
          handleClose={this.handleClose}
        />
        <AlertBox
          open={this.state.open}
          handleClose={this.handleClose}
          dialogTitle={dialogTitle}

          dialogDescription={dialogDescription}
        >
          {!requestStatus &&
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <SelectOption
                  id="rejectionReason"
                  labelText="Reason to Reject"
                  selectOptions={this.getRejectionReason()}
                  formControlProps={{
                    fullWidth: true
                  }}
                  isRequired
                  onSelectChange={({ target: { value } }) => this.setState({ rejectionReason: value })}
                  inputProps={{
                    value: (rejectionReason)
                  }}
                />
              </GridItem>
            </GridContainer>


          }
        </AlertBox>
      </Admin>
    );
  }
}
function mapStateToProps(state) {
  const { posReducer } = state;
  return {
    posList: posReducer.posOnboardingList

  };
}
// mapping dispatch functions to the props of LoginForm component
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchposOnboardingListWatcher,
      updatePosApprovalStatusWatcher
      // add other watcher sagas to this object to map them to props
    },
    dispatch
  )
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PosOnboardingPage);
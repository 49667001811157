import React from 'react'
import Avatar from '@material-ui/core/Avatar'
// import Button from "@material-ui/core/Button";
import Button from '../../components/CustomButtons/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
// import Box from "@material-ui/core/Box";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import { isEmpty } from '../../utils/common'
import { LoaderWrapper, useStyles } from './LoginStyle'

export default function LoginForm (props) {
  const classes = useStyles()
  const { onSubmit, handleChange, errors = {}, alertMessage = '', isSubmitting } = props
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        {!isEmpty(alertMessage) && <SnackbarContent message={alertMessage}
          className={classes.dangerBox}
          color="danger" />}
        <form
          className={classes.form}
          noValidate
          onSubmit={event => onSubmit(event)}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            type="email"
            autoFocus
            onChange={event => handleChange(event, 'email')}
            error={!errors.email}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={event => handleChange(event, 'password')}
            error={!errors.password}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {isSubmitting ? (
              <LoaderWrapper>
                <div className="loader" />
              </LoaderWrapper>

            ) : (
              'Sign In'
            )}
          </Button>
        </form>
      </div>
      {/* <Box mt={8}>
        <Copyright />
      </Box> */}
    </Container>
  )
}

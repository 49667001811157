import React from "react";
import SelectOption from '../CustomSelect/SelectOption';
import CustomInput from '../CustomInput/CustomInput';
import CustomDatePicker from '../DatePicker/CustomDatePicker';
import { isEmpty } from 'utils/common';


export const renderSelectField = ({ input, meta: { touched, error }, custom }) => (
  <SelectOption
    onSelectChange={(event, index, value) => input.onChange(value)}
    helperText={touched && error}
    {...input}
    {...custom} />
);

export const renderTextField = ({ input, meta: { touched, invalid, error }, custom }) => {
  const { value, onChange, onBlur } = input;
  return (
    <CustomInput
      helperText={touched && error}
      error={touched && invalid}
      {...input}
      {...custom} />
  )
}

export const renderDatePicker = ({ field, form,isRequired, ...other }) => {
  const currentError = form.errors[field.name];

  return (
    <CustomDatePicker
      value={field.value}
      onChange={date => form.setFieldValue(field.name, date, true)}
      isRequired={isRequired}
      rest={{
        name: field.name,
        helperText: form.touched[field.name] && currentError,
        onBlur: form.handleBlur,

        error: form.touched[field.name] && Boolean(currentError),
        onError: error => {
          // handle as a side effect
          if (form.touched[field.name] && !isEmpty(error) && error !== currentError) {
            form.setFieldError(field.name, error);
          }
        },
        ...other
      }} />
  )
}




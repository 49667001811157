import React from 'react';
import { Button as MuiButtom, makeStyles} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from 'components/CustomButtons/Button'

import TextField from '@material-ui/core/TextField'; 
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import {LocalizationProvider, DateRangeDelimiter, StaticDateRangePicker } from "@material-ui/pickers";
import {styles} from './DateRangeSelectorStyle';

const useStyles = makeStyles(styles);

export default function DateRangeSelector(props) {
  const classes = useStyles();
  const { 
    open, 
    closeDateRangeSelector,
    getSelectedValue
  } = props; 
  const [value, setValue] = React.useState([null, null]);
  const [dateError, setDateError] = React.useState(false);
  const handleClose = () => {
    closeDateRangeSelector(false);
  };

  const handleSubmit = () => {
    if(!dateError){
      getSelectedValue(value)
    }
  }

  const handleDateChange = (error) => {
    setDateError(error)
  }

  return (
    <div> 
      <Dialog
        open={open}
        onClose={handleClose} 
        color="primary"
      > 
        <DialogContent style={{padding:0}}>
          <DialogContentText id="alert-dialog-description">
          <LocalizationProvider   dateAdapter={DateFnsUtils}>
              <StaticDateRangePicker
              className={classes.drbg}
                startText="From"
                endText="To"
                disableFuture
                value={value}
                inputFormat="dd/MM/yyyy"
                onChange={(newValue) => setValue(newValue)}
                renderInput={(startProps, endProps) => { 
                  handleDateChange(startProps.error )
                  handleDateChange(endProps.error )
                  return( 
                  <React.Fragment>
                    <TextField {...startProps} />
                    <DateRangeDelimiter> to </DateRangeDelimiter>
                    <TextField {...endProps} />
                  </React.Fragment>
                )}
              }
              
              />
              </LocalizationProvider>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MuiButtom   onClick={handleClose}  style={{padding:"7px 17px"}} color="default" variant="outlined"  >
            Cancel
          </MuiButtom>
          <Button onClick={handleSubmit} color="primary" autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import React from 'react'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import SelectOption from 'components/CustomSelect/SelectOption'
import CustomInput from 'components/CustomInput/CustomInput'
import { sortBy } from 'utils/common'
import * as CONSTANT from 'utils/constants'
import CustomDatePicker from 'components/DatePicker/CustomDatePicker'
import {
    Formik, Form, Field, ErrorMessage,
} from 'formik';
import { renderDatePicker } from 'components/ReduxComponents/reduxComponents';

import { isEmpty } from 'utils/common';
import * as Yup from 'yup';

export default function InitialForm(props) {
    const { classes, insuranceCompanies = [], fetchProducts, insuranceProduct = [],
        initialForm: { userId = '',
            policyIssuanceType = '',
            insuranceType = '',
            companyId = '',
            productId = '',
            tenure = '',
            sumInsured = '',
            policyTerm = '',
            premiumPaymentFrequency = '',
            premium = '',
            tpPremium = '',
            subTotal = '',
            policyNo = '',
            dateOfPayment = null,
            modeOfPayment = '',
            dateOfPolicyIssued = null } = {},
        insuranceTypeList = [], posList = [], policyType, setPolicyType,
        handleSubmit = () => { }, bindSubmitForm, renderSelect, setSteps,
        renderInput, setHeaderName, setInsuranceType, fetchInsuranceCompanies } = props;



    const insuranceTypeOptions = []
    React.useEffect(() => { setHeaderName('Initial Details') }, [])

    insuranceTypeList.filter(ins => ins.enabled).map(insurance =>
        (insuranceTypeOptions.push({ value: insurance.ins_type, label: insurance.ins_disp_name })))

    // React.useEffect(() => {
    //     insuranceTypeList.filter(ins => ins.enabled).map(insurance =>
    //         (insuranceTypeOptions.push({ value: insurance.ins_type, label: insurance.ins_disp_name })))
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [insuranceTypeList]);

    const getPosOptions = () => {
        const posOptions = []
        posList.filter(pos => (pos.approvalStatus === CONSTANT.ONBOARD_STATUS.APPROVED || pos.approvalStatus === CONSTANT.ONBOARD_STATUS.ONBOARDED)).map(pos => (
            posOptions.push({ value: pos.userId, label: `${pos.fullName.toUpperCase()} (M: ${pos.phone})` })))
        posOptions.sort((a, b) => sortBy(a, b, 'label'))
        return posOptions
    }

    const paymentOptions = [{ value: 'Cheque', label: 'Cheque' },
    { value: 'Cash', label: 'Cash' },
    { value: 'Credit Card', label: 'Credit Card' },
    { value: 'Debit Card', label: 'Debit Card' },
    { value: 'Payment link to customer', label: 'Payment link to customer' }]

    const getInsuranceCompanyOptions = () => {
        const insuranceCompanyOptions = []
        insuranceCompanies.map(company => insuranceCompanyOptions.push({ value: company.companyId, label: company.companyName }))
        insuranceCompanyOptions.sort((a, b) => sortBy(a, b, 'label'))
        return insuranceCompanyOptions
    }

    const getInsuranceProductOptions = () => {
        const insuranceProductOptions = []
        insuranceProduct.map(product => insuranceProductOptions.push({ value: product.productId, label: product.policyType ? `${product.planName} (${product.policyType})` : product.planName }))
        insuranceProductOptions.sort((a, b) => sortBy(a, b, 'label'))
        return insuranceProductOptions
    }

    const policyIssuanceTypeOptions = [
        { value: "NEW", label: "NEW" },
        { value: "RENEW", label: 'RENEW' }]

 
    const tenureOptions =[{ value: 1, label: '1 Year' },
    { value: 2, label: '2 Years' }, { value: 3, label: '3 Years' }]
    const tenureOptionsTerm = [{ value: 1, label: '1 Year' },
    { value: 2, label: '2 Years' }]
    

    const paymentFrequencyOptions = [{ value: 'monthly', label: 'Monthly' },
    { value: 'halfYearly', label: 'Half Yearly' },
    { value: 'yearly', label: 'Yearly' }];

    const policyTermOptions = [{ value: 5, label: '5' }, { value: 7, label: '7' },
    { value: 10, label: '10' }, { value: 15, label: '15' }, { value: 60, label: '60' }]


    const initialValues = {
        userId,
        policyIssuanceType,
        insuranceType,
        companyId,
        productId,
        tenure,
        sumInsured,
        tpPremium,
        subTotal,
        policyTerm,
        premiumPaymentFrequency,
        premium,
        policyNo,
        dateOfPayment,
        modeOfPayment,
        dateOfPolicyIssued
    }

    const validationSchema = Yup.object().shape({
        userId: Yup.string().required('Required'),
        policyIssuanceType: Yup.string().required('Required'),
        insuranceType: Yup.string().required('Required'),
        companyId: Yup.string().required('Required'),
        productId: Yup.string().required('Required'),
        tenure: Yup.string().when('insuranceType', {
            is: 'TERM',
            then: Yup.string(),
            otherwise: Yup.string().required('Required')
        }),
        sumInsured: Yup.number().required('Required').moreThan(0, "Sum Insured cannot be 0"),
        tpPremium: Yup.number().when('insuranceType', (insuranceType, schema) => {
            
            if ((insuranceType === 'MOTOR_4W' || insuranceType === 'MOTOR_2W') && (!policyType.toUpperCase().includes('DAMAGE'))) {
                return schema.required('Required').moreThan(0, "TP Premium cannot be 0")
            }
            else return schema
        })
        ,
        subTotal: Yup.number().when('insuranceType', {
            is: 'MOTOR_4W',
            then: Yup.number().required('Required').moreThan(0, "Subtotal cannot be 0"),
            otherwise: Yup.number()
        }).when('insuranceType', {
            is: 'MOTOR_2W',
            then: Yup.number().required('Required').moreThan(0, "Subtotal cannot be 0"),
            otherwise: Yup.number()
        }),
        policyTerm: Yup.string().when('insuranceType', {
            is: 'TERM',
            then: Yup.string().required('Required'),
            otherwise: Yup.string()
        }),
        premiumPaymentFrequency: Yup.string().when('insuranceType', {
            is: 'TERM',
            then: Yup.string().required('Required'),
            otherwise: Yup.string()
        }),
        premium: Yup.number().required('Required').moreThan(0, "Premium cannot be 0"),
        policyNo: Yup.string().required('Required'),
        dateOfPayment: Yup.date().required('Required').nullable(),
        modeOfPayment: Yup.string().required('Required'),
        dateOfPolicyIssued: Yup.date().required('Required').nullable(),


    });

    const isMotorSelected = (values={}) => {
        const isMotor = values.insuranceType === 'MOTOR_4W' || values.insuranceType === 'MOTOR_2W';
        return isMotor;
    }


    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(e) => handleSubmit(e, 'initialForm')}
            validationSchema={validationSchema} >
            {
                (formProps) => {
                    const {
                        values,
                        touched,
                        errors,
                        dirty,
                        isSubmitting,
                        isValid,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        handleReset,
                        setFieldValue
                    } = formProps;
                    debugger;
                    bindSubmitForm(handleSubmit)
                    return (
                        <form onSubmit={handleSubmit}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                    {renderSelect({
                                        name: "userId", label: "Policy Sold By?", options: getPosOptions(), touched, errors,
                                        handleChange: handleChange('userId'), values, handleBlur, isRequired: true
                                    })}
                                </GridItem>

                                <GridItem xs={12} sm={12} md={3}>
                                    {renderSelect({
                                        name: "policyIssuanceType", label: "Policy Issuance Type", options: policyIssuanceTypeOptions, touched, errors,
                                        handleChange: handleChange('policyIssuanceType'), values, handleBlur, isRequired: true
                                    })}
                                </GridItem>

                                <GridItem xs={12} sm={12} md={3}>
                                    {renderSelect({
                                        name: "insuranceType", label: "Insurance Type", options: insuranceTypeOptions, touched, errors,
                                        handleChange: ({ target: { value } }) => {
                                            setFieldValue('insuranceType', value);
                                            setFieldValue('companyId', '');
                                            setFieldValue('productId', '');
                                            setInsuranceType(value)
                                            if (!isEmpty(value)) {
                                                fetchInsuranceCompanies(value)
                                                props.fetchPreviousInsurerWatcher(value)
                                                if (value === 'TERM') {
                                                    setSteps(3)} 
                                        
                                            }
                                        }, values, handleBlur,
                                        isRequired: true
                                    })
                                    }
                                </GridItem>

                                <GridItem xs={12} sm={12} md={3}>
                                    {renderSelect({
                                        name: "companyId", label: "Insurance Company", options: getInsuranceCompanyOptions(), touched, errors,
                                        handleChange: ({ target: { value } }) => {
                                            setFieldValue('companyId', value);
                                            if (!isEmpty(value)) {
                                                const param = { companyId: value, insuranceType: values.insuranceType }
                                                fetchProducts(param)
                                                props.fetchGstTypeInfoWatcher(value)
                                                props.fetchAllIdProofInfoWatcher(value)
                                            }
                                        },
                                        values, handleBlur,
                                        isRequired: true
                                    })}
                                </GridItem>

                                <GridItem xs={12} sm={12} md={6}>
                                    {renderSelect({
                                        name: "productId", label: "Product Sold", options: getInsuranceProductOptions(), touched, errors,
                                        handleChange: ({ target: { value } }) => {

                                            setFieldValue('productId', value);
                                            if (!isEmpty(value)) {
                                                debugger;
                                                const product = insuranceProduct.find(prod => prod.productId === value) || {};

                                                setPolicyType(product.policyType || '')
                                                const param = { companyId: values.companyId, productId: value };
                                                props.fetchAllOccupationDetailsWatcher(param);
                                            }
                                        }, values,
                                        handleBlur,
                                        isRequired: true
                                    })}
                                </GridItem>

                                {values.insuranceType !== 'TERM' && <GridItem xs={12} sm={12} md={3}>
                                    {renderSelect({
                                        name: "tenure", label: "Tenure", options: values.insuranceType === 'TERM'? tenureOptionsTerm : tenureOptions, touched, errors,
                                        handleChange: handleChange('tenure'), values, handleBlur, isRequired: true
                                    })}
                                </GridItem>}

                                <GridItem xs={12} sm={12} md={3}>
                                    {
                                        renderInput({
                                            label: isMotorSelected(values)? "IDV": "Sum Insured", name: "sumInsured",
                                            isRequired: true, touched, errors, handleChange, handleBlur, values, type: 'number'
                                        })
                                    }
                                </GridItem>


                                {values.insuranceType === 'TERM' && <GridItem xs={12} sm={12} md={3}>
                                    {renderSelect({
                                        name: "policyTerm", label: "Premium Policy Term", options: policyTermOptions, touched, errors,
                                        handleChange: handleChange('policyTerm'), values, handleBlur, isRequired: true
                                    })}
                                </GridItem>}

                                {values.insuranceType === 'TERM' && <GridItem xs={12} sm={12} md={3}>
                                    {renderSelect({
                                        name: "premiumPaymentFrequency", label: "Premium Payment Frequency", options: paymentFrequencyOptions, touched, errors,
                                        handleChange: handleChange('premiumPaymentFrequency'), values, handleBlur, isRequired: true
                                    })}
                                </GridItem>}

                                <GridItem xs={12} sm={12} md={3}>
                                    {
                                        renderInput({
                                            label: "Premium", name: "premium",
                                            isRequired: true, touched, errors, handleChange, handleBlur, values, type: 'number'
                                        })
                                    }
                                </GridItem>

                                {isMotorSelected(values) && <GridItem xs={12} sm={12} md={3}>
                                    {
                                        renderInput({
                                            label: "Total Premium (without GST)", name: "subTotal",
                                            isRequired: true, touched, errors, handleChange, handleBlur, values, type: 'number'
                                        })
                                    }
                                </GridItem>}

                                {(isMotorSelected(values) && !policyType.includes('Damage')) && <GridItem xs={12} sm={12} md={3}>
                                    {
                                        renderInput({
                                            label: "Third Party Premium", name: "tpPremium",
                                            isRequired: true, touched, errors, handleChange, handleBlur, values, type: 'number'
                                        })
                                    }
                                </GridItem>}

                                <GridItem xs={12} sm={12} md={3}>
                                    {
                                        renderInput({
                                            label: "Policy Number", name: "policyNo",
                                            isRequired: true, touched, errors, handleChange, handleBlur, values
                                        })
                                    }
                                </GridItem>

                                <GridItem xs={12} sm={12} md={3}>
                                    <Field name="dateOfPayment" component={renderDatePicker} format="dd/MM/yyyy" label="Date of Payment" isRequired />
                                </GridItem>

                                <GridItem xs={12} sm={12} md={3}>
                                    {renderSelect({
                                        name: "modeOfPayment", label: "Mode Of Payment", options: paymentOptions, touched, errors,
                                        handleChange: handleChange('modeOfPayment'), values, handleBlur, isRequired: true
                                    })}
                                </GridItem>

                                <GridItem xs={12} sm={12} md={3}>
                                    <Field name="dateOfPolicyIssued" component={renderDatePicker} format="dd/MM/yyyy" label="Date of Policy Issued" isRequired />
                                </GridItem>

                            </GridContainer>
                        </form>
                    );
                }
            }
        </Formik>
    )
}

import React from 'react'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import CustomStepper from '../../../components/CustomStepper/Stepper'
import { isEmpty } from '../../../utils/common'
import Snackbar from '../../../components/Snackbar/Snackbar'
import ErrorIcon from '@material-ui/icons/Error'
import BasicFormStep from './BasicFormStep'
import OperationFormStep from './OperationFormStep'

const compStyle = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: 'Montserrat',
    marginBottom: '3px',
    textDecoration: 'none'
  },
  approved: {
    color: '#008000'
  },
  unapproved: {
    color: '#f00'
  },
  cardCategory: {
    marginBlockStart: '1.33em',
    marginBlockEnd: '1.33em'
  },
  cardBody: {
    paddingLeft: '0px',
    paddingRight: '0px'
  },

  cardTitle: {
    margin: '0px'
  },
  cardBackground: {
    background: '#ffffff'
  },
  tabHeader: {
    width: 'calc(100% + 40px)',
    marginLeft: '-20px'
  },
  error: {
    fontSize: '11px',
    color: 'red'
  }

}

const useStyles = makeStyles(compStyle)

export default function AddDsaLayout (props) {
  const classes = useStyles()

  const { formErrors, operationDetail, basicDetail, validateInput, updateKeyValueInState, onSubmit, resetStep, history } = props

  const isError = (obj, key) => {
    let isError = false
    if (!isEmpty(formErrors[obj])) {
      isError = !isEmpty(formErrors[obj][key])
    }
    return isError
  }

  const [activeStep, setActiveStep] = React.useState(0)
  const [errorMessage, setErrorMsg] = React.useState(null)

  const handleNext = (event) => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
    activeStep === steps.length - 1 && onSubmitForm(event)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  const onSubmitForm = (event) => {
    event.preventDefault()
    onSubmit(event).then(resolve => {
      console.log(resolve)
      setTimeout(() => {
        history.push('/dsa-list')
      }, 3000)
    }).catch((reject) => {
      console.log(reject)
      setErrorMsg(reject.data.message)
      setTimeout(() => {
        setErrorMsg(null)
      }, 5000)
      handleReset()
    })
  }

  const steps = ['Basic Details', 'Operation Details']

  const step1 = (
    <BasicFormStep classes={classes}
      basicDetail={basicDetail}
      validateInput={validateInput}
      formErrors={formErrors}
      isError={isError}
      updateKeyValueInState={updateKeyValueInState} />)

  const step2 = (
    <OperationFormStep classes={classes}
      operationDetail={operationDetail}
      validateInput={validateInput}
      formErrors={formErrors}
      isError={isError}
      updateKeyValueInState={updateKeyValueInState} />
  )

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return step1
      case 1:
        return step2
      default:
        break
    }
  }

  return (
    <>
      {/* {!isEmpty(errorMessage) && <SnackbarContent
            message={errorMessage}
            color="danger"
        />} */}
      <Snackbar
        place="tc"
        color={'danger'}
        icon={ErrorIcon}
        message={errorMessage}
        open={!isEmpty(errorMessage)}
        // closeNotification={() => this.setState({ errorMessage: '' })}
        // close
      />
      <CustomStepper
        steps={steps}
        getStepContent={getStepContent}
        resetStep={resetStep}
        activeStep={activeStep}
        handleBack={handleBack}
        handleNext={handleNext}
      />

    </>
  )
}

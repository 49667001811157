import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { loginWatcher } from "../../store/actions/loginAction";
import LoginForm from "./LoginForm";
import { isEmpty, isEmailValid } from "../../utils/common";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      errors: { 'email': true, 'password': true },
      isSubmitting: false,
      alertMessage: ''
    };

  }
  handleChange = (event, key) => {
    const errors = { ...this.state.errors }
    errors[key] = true;
    this.setState({
      [key]: event.target.value,
      errors
    });

  };
  onSubmit = e => {
    e.preventDefault();

    this.validate().then(() => {
      this.setState({
        errors: { 'email': true, 'password': true },
        isSubmitting: true
      });
      new Promise((resolve, reject) => {
        this.props.loginWatcher({ ...this.state }, resolve, reject);
      })
        .catch(e => {
          this.setState({
            isSubmitting: false,
            alertMessage: "UserId and Password doesn't match. Please Try Again"
          });
          setTimeout(() => {
            this.setState({
              alertMessage: ""
            })
          }, 3000);
        });
    })
      .catch(errors => {
        this.setState({ errors });
      });
  };

  validate = () => {
    const { email, password } = this.state;

    return new Promise((resolve, reject) => {
      let errors = {};
      let isValid = true;
      if (isEmpty(email) || !isEmailValid(email)) {
        isValid = false;
        errors.email = false;
      }
      if (isEmpty(password)) {
        isValid = false;
        errors.password = false;
      }
      isValid ? resolve() : reject(errors);
    });
  };

  //render function
  render() {
    const { errors, alertMessage, isSubmitting } = this.state;
    return (
      <div className="login-form">
        <LoginForm onSubmit={this.onSubmit}
          handleChange={this.handleChange}
          errors={errors}
          alertMessage={alertMessage}
          isSubmitting={isSubmitting} />
      </div>
    );
  }
}
// mapping dispatch functions to the props of LoginForm component
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      loginWatcher
      // add other watcher sagas to this object to map them to props
    },
    dispatch
  );
};
export default connect(
  null,
  mapDispatchToProps
)(LoginPage);


import * as React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from 'components/CustomButtons/Button';
import FormLabel from "@material-ui/core/FormLabel";
import { isEmpty } from "utils/common";
import { Typography } from "@material-ui/core";
import CustomInput from 'components/CustomInput/CustomInput'

export default function PayoutFrequencyDisplay(props) {
    const { classes, globalPayoutFrequency = {}, editData } = props;
    debugger
    const { frequencyType } = globalPayoutFrequency;


    return (
        <GridContainer>

            <GridItem xs={12} sm={12} md={12} >
                {
                    [{ label: 'Frequency Type', value: frequencyType }].filter(val => !isEmpty(val.value)).map(val => (
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={4}>
                                <FormLabel className={classes.labelHorizontal}>
                                    {val.label}
                                </FormLabel>
                                {/* <Typography variant="body1" gutterBottom> {val.label}</Typography> */}
                            </GridItem>
                            <GridItem xs={12} sm={6} md={8}>
                                <CustomInput
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        type: "text",
                                        disabled: true,
                                        value: (val.value),
                                    }}
                                />
                                {/* <Typography variant="h6" glutterBottom> {val.value}</Typography> */}
                            </GridItem>
                        </GridContainer>
                    ))
                }

            </GridItem>
            {/* <GridItem xs={12} sm={12} md={12} style={{ marginTop: '15px' }}>
                <Button raised color="primary" onClick={() => editData()} >Update</Button>
            </GridItem> */}

        </GridContainer>
    );
}

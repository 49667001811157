import React from 'react'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
// core components
import GridItem from '../../components/Grid/GridItem'
import GridContainer from '../../components/Grid/GridContainer'
import Card from '../../components/Card/Card'
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import Button from '../../components/CustomButtons/Button'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ErrorIcon from '@material-ui/icons/Error'
import styleHeader from '../../assets/jss/componentsStyles/headerLinksStyle'
import EditIcon from '@material-ui/icons/Edit';
import AlertBox from '../../components/DialogBox/DialogBox'
import Tooltip from '@material-ui/core/Tooltip'
import ClearIcon from '@material-ui/icons/Clear';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import BackDrop from '../../components/BackDrop/Backdrop';
import GlobalChainCommissionTable from './GlobalChainCommissionTable'
import {customParseFloat} from '../../utils/common';
import Slide from '@material-ui/core/Slide';

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "Montserrat",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    inputSearchType: {
        color: "white"
    },
    paddingStyle: {
        padding: '0 12px 0'
    },

    buttonStyle: {
        margin: "0 12px 0",
    }

};

function createData(roleCode, commissionPercentage) {
    return { roleCode, commissionPercentage }
}

const useStyles = makeStyles(styles)
const headerStyle = makeStyles(styleHeader)

export default function GlobalChainCommissionList(props) {
    const classes = useStyles()
    const headerClass = headerStyle()

    const { roleWiseCommission = [], fetchGlobalChainCommission, updateGlobalChainCommission, setAlert } = props
    let rows = [];

    const [isEdit, toggleEdit] = React.useState(false);

    const [showModal, toggleModal] = React.useState(false);

    const [openBackdrop, toggleBackDrop] = React.useState(false);

    const [filteredList, setList] = React.useState([]);

    roleWiseCommission.forEach(role => {
        let row = createData(role.roleCode, role.commissionPercentage);
        rows.push(row);
    });

    React.useEffect(() => {
        setList(rows) // api.data.count from api after update store
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [roleWiseCommission])

    const onSubmit = (event, obj) => {
        event.preventDefault();
        if(obj) {
            toggleBackDrop(true);
            new Promise((resolve, reject) => {
                updateGlobalChainCommission({roleWiseCommission: filteredList}, resolve, reject);
            }).then(data => {
                toggleBackDrop(false);
                fetchGlobalChainCommission();
                toggleEdit(!isEdit);
                const alertMsg = {
                  color: 'success',
                  icon: CheckCircleOutlineIcon,
                  message: 'Global Commission updated successfully'
                }
                setAlert(alertMsg);
            }).catch(error => {
                toggleBackDrop(false);
                const alertMsg = {
                  color: 'danger',
                  icon: ErrorIcon,
                  message: 'Error occured. Please try again'
                }
                setAlert(alertMsg)
              });     
        }
        // else {
        //     onCancel(event);
        // }
        toggleModal(!showModal);

    }



    const handleChange = (value, row) => {
  
        console.log(value);
        const index = rows.findIndex(val => val.roleCode === row.roleCode);
       let val = "0";
       let flag = false;
       
        if(value >= 0 && value <= 100) {
          val = customParseFloat(value);
          flag =true;
        } 
        if (flag) {
            filteredList[index].commissionPercentage = val;
          setList([...filteredList]);
        }
      }

      const onCancel = (event) => {
        event.preventDefault();
        setList(rows);
        toggleEdit(!isEdit);
      }



    const headCells = [
        { id: 'roleCode', label: 'Role' },
        { id: 'commissionPercentage', label: 'Commission Percentage' }];

    return (
        <>
            <AlertBox
                open={showModal}
                handleClose={onSubmit}
                dialogTitle="Are you sure, you want to update?"
                dialogDescription=""
            />
            <BackDrop
                openBackdrop={openBackdrop}
            />
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary">
                            <span style={{ float: "left" }}>
                                <h4 className={classes.cardTitleWhite}>Global Chain Commission List</h4>
                            </span>
                        {!isEdit ?  <span className={headerClass.searchWrapper} style={{ float: "right" }}>
                                <Tooltip title="Edit" aria-label="add">
                                    <Button color="white" className={classes.paddingStyle} aria-label="edit" simple onClick={(event) => toggleEdit(!isEdit)}>
                                        <EditIcon /> {' '} Edit
                                    </Button>
                                </Tooltip>
                            </span> : 
                             <Slide direction="left" in={isEdit} mountOnEnter unmountOnExit>
                                <span className={headerClass.searchWrapper} style={{ float: "right" }}>
                                <Tooltip title="Cancel" aria-label="add">
                                  <Button className={classes.paddingStyle} color="white" aria-label="edit" simple onClick={(event) => onCancel(event)}>
                                    <ClearIcon /> {' '}Cancel
                              </Button>
                                </Tooltip>
                                <Tooltip title="Save" aria-label="add">
                                  <Button color="white" className={classes.paddingStyle} aria-label="edit" simple onClick={(event) => toggleModal(!showModal)}>
                                    <SaveOutlinedIcon /> {' '} Save
                              </Button>
                                </Tooltip>
                              </span>
                              </Slide>}
                        </CardHeader>
                        <CardBody>

                            <GlobalChainCommissionTable
                                tableHeaderColor="primary"
                                headCells={headCells}
                                isSelectRequired={false}
                                orderByParameter="roleCode"
                                orderAs="asc"
                                rows={filteredList}
                                isEdit={isEdit}
                                handleChange={handleChange}

                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>

        </>
    );

}

/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
// core components
import GridItem from '../../components/Grid/GridItem'
import GridContainer from '../../components/Grid/GridContainer'
import PosTableEnhanced from './PosTableEnhanced'
import Card from '../../components/Card/Card'
import Search from '@material-ui/icons/Search'
import CustomInput from '../../components/CustomInput/CustomInput'
import Button from '../../components/CustomButtons/Button'
import HistoryIcon from '@material-ui/icons/History'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import styleHeader from '../../assets/jss/componentsStyles/headerLinksStyle'
import { isEmpty, exportCsv, getStatus } from '../../utils/common'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import CustomHeaderContentTabs from '../../components/CustomTabs/CustomHeaderContentTabs'
import FuzzySearch from 'fuzzy-search'
import { ELIGIBILITY_STATUS } from '../../utils/constants'

const styles = {
  cardCategoryWhite: {
    '&,& a,& a:hover,& a:focus': {
      color: 'rgba(255,255,255,.62)',
      margin: '0',
      fontSize: '14px',
      marginTop: '0',
      marginBottom: '0'
    },
    '& a,& a:hover,& a:focus': {
      color: '#FFFFFF'
    }
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: 'Montserrat',
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontSize: '65%',
      fontWeight: '400',
      lineHeight: '1'
    }
  },
  inputSearchType: {
    color: 'white',
    padding: '0'
  },
  paddingStyle: {
    padding: '0 12px 0'
  }
}
function createData (fullName, phone, pinCode, panNo, approvalStatus, _id, approval) {
  return { fullName, phone, pinCode, panNo, approvalStatus, _id, approval }
}

const useStyles = makeStyles(styles)
const headerStyle = makeStyles(styleHeader)

export default function PosEligibilityCheckList (props) {
  const classes = useStyles()
  const headerClass = headerStyle()
  const { posList = [], getDetails, handleClose } = props
  const rows = []

  const [filteredList, setList] = React.useState()

  const [showInput, toggleInput] = React.useState(false)

  // const detailButton =  (<Button color="primary">Details</Button>)
  posList.forEach(pos => {
    const status = getStatus(pos);
    const row = createData(pos.fullName, pos.phone, pos.pinCode, pos.panNo, status, pos._id, pos.eligibilityStatus)
    rows.push(row)
  })

  React.useEffect(() => {
    setList(rows) // api.data.count from api after update store
  }, [posList])

  React.useEffect(() => { document.getElementById('searchInput') && document.getElementById('searchInput').focus() }, [showInput])

  const filterList = (e) => {
    // Variable to hold the original version of the list
    let currentList = []
    // Variable to hold the filtered list before putting into state

    let newList = []
    // If the search bar isn't empty
    if (e.target.value !== '') {
      // Assign the original list to currentList
      currentList = rows
      const searcher = new FuzzySearch(currentList, ['fullName', 'phone', 'pincode', 'panNo', 'approvalStatus', '_id', 'approval'], {
        caseSensitive: false
      })
      newList = searcher.search(e.target.value.toLowerCase())

    } else {
      // If the search bar is empty, set newList to original task list
      newList = [...rows]
    }

    setList(newList)
  }

  const exportPosp = (event) => {
    event.preventDefault()

    const data = []
    posList.filter(pos => !isEmpty(pos.fullName)).forEach(pos => {
      data.push({ Name: pos.fullName, 'Mobile No': pos.phone, Pincode: pos.pinCode, PanNo: pos.panNo, 'Email Id': pos.email, 'Partner Code': pos.partnerCode ? pos.partnerCode : 'Not Available', 'Eligibility Status': pos.eligibilityStatus })
    })
    const param = {
      fileName: 'POSP_ELIGIBILITY_LIST',
      title: 'POSP Eligibility List',
      data: data
    }
    return exportCsv(param)
  }

  const headCells = [
    { id: 'fullName', label: 'Name' },
    { id: 'phone', label: 'Phone No' },
    { id: 'pinCode', label: 'Pin Code' },
    { id: 'panNo', label: 'PAN Number' },
    { id: 'approvalStatus', label: 'Status' },
    { id: 'approval', label: 'Action' }]

  const posTableData = (tabName) => {
    return (
      <PosTableEnhanced
        tableHeaderColor="primary"
        headCells={headCells}
        isSelectRequired={false}
        orderByParameter="name"
        orderAs="asc"
        rows={filteredList}
        getDetails={getDetails}
        isApproval={true}
        handleClose={handleClose}
        tabName={tabName}
      />
    )
  }

  const tabs = [{ tabName: 'Pending', tabIcon: HistoryIcon, tabContent: posTableData('') },
    { tabName: 'Accepted', tabIcon: CheckCircleOutlineIcon, tabContent: posTableData(ELIGIBILITY_STATUS.ACCEPTED) },
    { tabName: 'Rejected', tabIcon: NotInterestedIcon, tabContent: posTableData(ELIGIBILITY_STATUS.REJECTED) }]

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CustomHeaderContentTabs color="primary"
            headerColor="primary"
            tabs={tabs}
            plainTabs={false}
            classHeader={classes.tabHeader}
          >
            <span style={{ alignSelf: 'flex-start' }}>
              <h4 className={classes.cardTitleWhite}>POSP Eligibility List</h4>
            </span>
            <span className={headerClass.searchWrapper} style={{ marginLeft: 'auto', alignSelf: 'flex-end' }}>
              {showInput && <CustomInput
                id="searchInput"
                formControlProps={{
                  className: classNames(headerClass.margin, headerClass.search)
                }}
                inputProps={{
                  inputProps: {
                    'aria-label': 'Search',
                    className: classes.inputSearchType
                  }
                }}
                onChange={(event) => filterList(event)}
              />}
              <Button className={classes.paddingStyle} color="white" aria-label="edit" simple onClick={() => toggleInput(!showInput)}>
                <Search /> {' '}Search
              </Button>
              <Button color="white" className={classes.paddingStyle} aria-label="edit" simple onClick={(event) => exportPosp(event)}>
                <ImportExportIcon /> {' '} Export
              </Button>
            </span>
          </CustomHeaderContentTabs>
          {/* <CardHeader color="primary">
            <span style={{ float: "left" }}>
              <h4 className={classes.cardTitleWhite}>POSP Eligibility List</h4>
            </span>
            <span className={headerClass.searchWrapper} style={{ float: "right" }}>
              {showInput && <CustomInput
                id="searchInput"
                formControlProps={{
                  className: classNames(headerClass.margin, headerClass.search)
                }}
                inputProps={{
                  inputProps: {
                    "aria-label": "Search",
                    className: classes.inputSearchType
                  }
                }}
                onChange={(event) => filterList(event)}
              />}
              <Button className={classes.paddingStyle} color="white" aria-label="edit" simple onClick={() => toggleInput(!showInput)}>
                <Search /> {' '}Search
              </Button>
              <Button color="white" className={classes.paddingStyle} aria-label="edit" simple onClick={(event) => exportPosp(event)}>
                <ImportExportIcon /> {' '} Export
              </Button>
            </span>
          </CardHeader> */}
          {/* <CardBody>
            <CustomTabs
              headerColor="primary"
              tabs={tabs}
              plainTabs={false}
              classHeader={classes.tabHeader}
            />

          </CardBody> */}
        </Card>
      </GridItem>
    </GridContainer>
  )
}

import * as actions from '../actions/types';

const initialState = {};

const specialUserReducer = (state = initialState, action) => {

  switch (action.type) {

    case actions.SPECIAL_USER_LIST:
      return {
        ...state,
        specialUserList: action.specialUserList,
      };
    case actions.PARTNERS_LIST:
      return {
        ...state,
        partnerList: action.partnerList,
      };
    case actions.SPECIAL_USER_DETAIL:
      return {
        ...state,
        specialUserDetail: action.specialUserDetail,
      };
    case actions.ASSISTED_SALES:
      return {
        ...state,
        assistedSales: action.assistedSales,
      };
    default:
      return state;
  }
}
export default specialUserReducer;
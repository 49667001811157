import * as actions from './types';

export function fetchDsaListWatcher() {
  return { type: actions.DSA_LIST_WATCHER };
}

export function fetchDsaDetailWatcher(dsaId) {
  return {
    type: actions.DSA_DETAIL_WATCHER,
    payload: dsaId,
  };
}

export function fetchDsaCommissionDetailWatcher(dsaId) {
  return {
    type: actions.DSA_COMMISSION_DETAIL_WATCHER,
    payload: dsaId,
  };
}

export function updateDsaChainCommissionWatcher(param, resolve, reject) {
  return {
    type: actions.UPDATE_DSA_CHAIN_COMMISSION_WATCHER,
    payload: param,
    resolve,
    reject
  }
}

export function fetchPospListUnderDsaWatcher(dsaId, resolve, reject) {
  return {
    type: actions.POSP_LIST_UNDER_DSA_WATCHER,
    payload: dsaId, resolve, reject
  };
}

export function createDsaWatcher(param, resolve, reject) {
  return ({
    type: actions.CREATE_DSA_WATCHER,
    payload: param, resolve, reject
  });
}

export function updateDsaWatcher(param, resolve, reject) {
  return ({
    type: actions.UPDATE_DSA_WATCHER,
    payload: param, resolve, reject
  });
}

export function fetchGlobalChainCommission() {
  return({
    type: actions.FETCH_GLOBAL_CHAIN_COMMISSION_WATCHER
  });
}

export function updateGlobalChainCommission(param, resolve, reject) {
  return ({
    type: actions.UPDATE_GLOBAL_CHAIN_COMMISSION_WATCHER,
    payload: param, resolve, reject
  });
}

export function dsaList(dsaList) {
  return { type: actions.DSA_LIST, dsaList }
}

export function dsaDetail(dsaDetail) {
  return { type: actions.DSA_DETAIL, dsaDetail }
}

export function pospListUnderDsa(pospListUnderDsa){
  return {type: actions.POSP_LIST_UNDER_DSA, pospListUnderDsa};
}

export function dsaCommissionDetail(dsaCommissionDetail) {
  return { type: actions.DSA_COMMISSION_DETAIL, dsaCommissionDetail }
}

export function globalChainCommission(globalChainCommission) {
  return {type: actions.GLOBAL_CHAIN_COMMISSION, globalChainCommission}
}
import * as React from "react";
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardText from 'components/Card/CardText'
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardHeader from 'components/Card/CardHeader';
import Button from 'components/CustomButtons/Button';
import TextField from '@material-ui/core/TextField';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import { grayColor, primaryColor, dangerColor } from 'assets/jss/material-dashbord-react';
import styles from "assets/jss/componentsStyles/selectOptionStyle";
import { isEmpty } from 'utils/common';

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: grayColor[2],
    },
    '& label.Mui-error': {
      color: dangerColor[0] + " !important"
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: primaryColor[0],
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: grayColor[4],
      },
      '&:hover fieldset': {
        borderColor: grayColor[4],
      },
      '&.Mui-error': {
        '&.Mui-focused fieldset': {
          borderColor: dangerColor[0] + " !important",
        }
      },
      '&.Mui-focused fieldset': {
        borderColor: primaryColor[0],
      },

    },
  },
})(TextField);





const useStyles = makeStyles(styles);
export default function NotificationForm(props) {

  const selectStyleClass = useStyles();
  const { classes, handleChange, formErrors = {}, selectedPosp, posList = [], submitForm, isImageUrlValid, isSubmitDisabled, sendToAll } = props;

const sortList =(a,b) => {
  var nameA = a.fullName.toUpperCase(); // ignore upper and lowercase
  var nameB = b.fullName.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
}

  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={12} md={8} >
        <Card>

          <CardHeader
            text
            // className={`${classes.cardHeader} ${classes.textCenter}`}
            color="primary"
          >
            <CardText color="primary">
              <h4 className={classes.cardTitle}>Create Notification</h4>
            </CardText>

          </CardHeader>
          <CardBody formHorizontal>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} >

                <CssTextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="title"
                  label="Title"
                  placeholder="Limit is 45 characters"
                  name="title"
                  autoComplete="title"

                  autoFocus

                  onChange={event => handleChange(event, 'title')}
                  error={formErrors.title}
                />
                {!isEmpty(formErrors.title) ? (
                  <span className={classes.error}>
                    {formErrors.title}
                  </span>
                ) : null}

              </GridItem>

              <GridItem xs={12} sm={12} md={12} >
                <CssTextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="content"
                  label="Message"
                  multiline
                  placeholder="Limit is 500 characters"
                  name="content"
                  autoComplete="content"
                  rows="7"
                  onChange={event => handleChange(event, 'content')}
                  error={formErrors.content}
                />
                {!isEmpty(formErrors.content) ? (
                  <span className={classes.error}>
                    {formErrors.content}
                  </span>
                ) : null}
              </GridItem>
              {/* <GridItem xs={12} sm={12} md={12}>

                <Button
                  fullWidth
                  simple
                  block
                  className={classes.buttonStyle}
                  color="primary"
                  component="label"
                >
                  {
                    props.imagePreviewUrl ? (<img src={props.imagePreviewUrl} alt="..." />) : 'Upload Image'
                  }

                  <input style={{ display: 'none' }} type="file" id='file' name="file" onChange={handleImageChange} />
                </Button>
              </GridItem>
              {props.imagePreviewUrl && <GridItem xs={12} sm={12} md={12}>

                <Button
                  fullWidth

                  block
                  // className={classes.buttonStyle}
                  color="rose"
                  onClick={removeImage}
                >
                  Remove Image
                </Button>
              </GridItem>} */}

              <GridItem xs={12} sm={12} md={12} >


                <CssTextField
                  variant="outlined"
                  margin="normal"
                  
                  fullWidth
                  id="imageUrl"
                  label="Image Url"
               
                  placeholder="Enter Image Url (Optional)"
                  name="imageUrl"
                  autoComplete="imageUrl"
                onBlur={ ({target: {value}}) => isImageUrlValid(value) }
                  onChange={event => handleChange(event, 'imageUrl')}
                  error={formErrors.imageUrl}
                />
                {!isEmpty(formErrors.imageUrl) ? (
                  <span className={classes.error}>
                    {formErrors.imageUrl}
                  </span>
                ) : null}
              </GridItem>

              <GridItem xs={12} sm={12} md={12}>
                <CssTextField id="select" label="POSP"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  select
                  SelectProps={{
                    multiple: true,
                    value: selectedPosp,
                    onChange: (event) => handleChange(event, 'selectedPosp')
                  }}
                >
                  <MenuItem

                    value={sendToAll ? 'unselectAll'  :'selectAll'}>
                    <Button simple color="primary" >{sendToAll ? 'Unselect All' : `Select All`}</Button>
                  </MenuItem>
                  {
          
                    posList.slice().sort((a,b) => sortList(a,b)).map(pos => {
                      return (
                        <MenuItem
                          classes={{
                            root: selectStyleClass.selectMenuItem,
                            selected: selectStyleClass.selectMenuItemSelectedMultiple
                          }}
                          style={{textTransform: 'capitalize'}}
                          value={pos}>
                          {/* <CustomCheckbox checked={selectedPosp.map(val => val.phone === pos.phone)}/> */}
                          {`${pos.fullName} (${pos.phone})`}
                        </MenuItem>
                      )
                    })
                  }
                </CssTextField>
              </GridItem >
              <GridItem xs={12} sm={12} md={12}>
                <Button raised color="primary" className={classes.buttonFloat} onClick={submitForm} disabled={isSubmitDisabled} > Submit</Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>


  );
}
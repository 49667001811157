import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import GridItem from '../Grid/GridItem'
import Card from '../Card/Card' 
import CardBody from '../Card/CardBody'
import {styles} from './RoleStyle' 

import ConfigurePermissionModal from '../ConfigurePermission/ConfigurePermissionModal'
import {splitAndCamelCase} from '../../utils/common'

const useStyles = makeStyles(styles);

const Role = (props) => {
  const classes = useStyles();

  const {
    role
    , updatedRolesPermissions
    , addPermission
    , removePermission
    , savePermissions 
    , forceRefresh
  } = props; 

  const [openModal, setOpenModal] = useState(false);

  const toggleConfigureModal = (type) => {
    console.log("type", type)
    setOpenModal(!openModal);
    if(type==="cancelled"){
      forceRefresh()
    }
  }

  return (
        <GridItem xs = { 12 }sm = { 6 } >
          <Card >
            <CardBody >
              <div className={classes.roleName}>{ role.name }</div>
              <hr/>
              <div className={classes.pc}>
                <span className={classes.configText} onClick={toggleConfigureModal}>Configure Permissions</span>
                <span  className={classes.permissionCount}>{role.permissions.length}</span>
              </div>
            </CardBody> 
          </Card> 
          <ConfigurePermissionModal 
            open={openModal}
            toggleConfigureModal={toggleConfigureModal}
            roleData = {role}
            updatedRolesPermissions = { updatedRolesPermissions }
            addPermission = { addPermission }
            removePermission = { removePermission }
            savePermissions = { savePermissions }
          />
        </GridItem>  
  )
}

export default Role
import React from "react";

import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { Provider } from "react-redux";
import { store } from "./store/index";
import router from "./Router.js";
import "./assets/css/material-dashboard-react.css?v=1.8.0";

const muiTheme = createMuiTheme({
  typography: {
    fontFamily: "Montserrat",
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#9c27b0",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: "#9c27b0",
      },
      daySelected: {
        backgroundColor: "#9c27b0",
      },
      dayDisabled: {
        color: "#9c27b0",
      },
      current: {
        color: "#9c27b0",
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: "#9c27b0",
      },
    },
    MuiInputLabel: {
      root: {
        color: "#3C4858 !important",
        fontSize: "12px",
        fontFamily: "Montserrat",
        fontWeight: "200",
        lineHeight: "1.42857"
      }
    },
  },
});

const App = (props) => {
  console.log("windows url is", window.location.host);
  return (
    <Provider store={store}>
      <ThemeProvider theme={muiTheme}>{router}</ThemeProvider>
    </Provider>
  );
};

export default App;

import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '../CustomButtons/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

const useStyles = makeStyles({
    root: {
      
      flexGrow: 1,
      // '& .MuiMobileStepper-progress': {
      //     width: '100%'
      // }
    },
    
  });

  export default function ProgressMobileStepper({steps, activeStep=0, handleNext, handleBack}) {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <MobileStepper
          variant="progress"
          steps={steps}
          position="static"
          activeStep={activeStep}
          className={classes.root}
          nextButton={
            activeStep === steps-1 ? (<Button type="submit" color="primary" onClick={handleNext} >Submit</Button>) : 
           ( <Button type="submit" simple color="primary" onClick={handleNext} disabled={activeStep === steps}>
              Next
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
              
            </Button>)
          }
          backButton={
            <Button  simple color="primary" onClick={handleBack} disabled={activeStep === 0}>
              {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
              Back
            </Button>
          }
        />
      );
  }